export const handleCPFChange = (event: any, formik: any, key?: string) => {
	let { value } = event.target;
	value = value.replace(/\D/g, '');
	if (value.length <= 11) {
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	}
	formik.setFieldValue(key ?? 'cpf', value);
};

export const handleCPFChangeReturn = (event: any) => {
	let { value } = event.target;
	value = value.replace(/\D/g, '');
	if (value.length <= 11) {
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	}
	return value;
};