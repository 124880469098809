/** @format */

import { EmptyData } from "./emptyData";

export function FormatBool(value: any) {
  switch (value) {
    case true:
      return "Sim";
    case false:
      return "Não";

    default:
      return EmptyData(value);
  }
}
