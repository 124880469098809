import {configureStore} from '@reduxjs/toolkit';
import companyReducer from './reducers/company';
import userReducer from './reducers/user';
import commonReducer from './reducers/common';

export default configureStore({
	reducer: {
		company: companyReducer,
		user: userReducer,
		common: commonReducer
	},
});
