/** @format */

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { routers } from "../constants";
import NavItem from "./navItem";

const NavCollapse = ({ menu, level }: any) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const permissions = useSelector((state: any) => state.user.permissions);
  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };
  const { pathname } = useLocation();
  const checkOpenForParent = (child: any, id: any) => {
    child.forEach((item: any) => {
      if (item.url.includes(pathname)) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (menu.children) {
      menu.children.forEach((item: any) => {
        if (item.children !== undefined && item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        if (pathname.includes(item.url)) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  const menus = menu.children?.map((item: any) => {
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Menu Items Error
          </Typography>
        );
    }
  });

  const menuIcon = menu.icon ? (
    menu.icon
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  const menuVisibility = menu.children.map((item: any) => {
    const key = routers.filter((option: any) => item.url === option.url)[0].id;
    return permissions?.indexOf(key) !== -1 ?? false;
  });

  return menuVisibility?.indexOf(true) === -1 ? null : (
    <>
      <ListItemButton
        sx={{
          mb: 0.5,
          borderRadius: "16px",
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: level > 1 ? 1 : 1.25,
        }}
        selected={selected === menu.id}
        onClick={handleClick}>
        <ListItemIcon
          sx={{
            my: "auto",
            minWidth: !menu.icon ? 18 : 36,
            color: selected === menu.id ? theme.palette.primary.main : "auto",
          }}>
          {menuIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={{ cursor: "pointer" }}
              color={selected === menu.id ? "primary" : "inherit"}
              fontWeight={selected === menu.id ? 800 : 0}>
              {menu.title}
            </Typography>
          }
        />
        {open ? (
          <ArrowDropUp style={{ marginTop: "auto", marginBottom: "auto" }} />
        ) : (
          <ArrowDropDown style={{ marginTop: "auto", marginBottom: "auto" }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List
          component='div'
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "32px",
              top: 0,
              height: "100%",
              width: "2px",
              opacity: 1,
              background: theme.palette.primary.light,
            },
          }}>
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
