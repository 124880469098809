/** @format */

import { useTheme } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routers } from "../../config/constants";

const NavItem = ({ item, level }: any) => {
  const router = useNavigate();
  const theme = useTheme();
  const permissions = useSelector((state: any) => state.user.permissions);
  const company = useSelector((state: any) => state.company.company);

  const itemIcon = item?.icon ? item.icon : null;

  const menuKey = routers.filter((option: any) => item.url === option.url)[0]
    .id;

  return permissions !== null &&
    permissions.indexOf(menuKey) !== -1 &&
    item.demo &&
    company === "3ClicksRH" ? (
    <ListItemButton
      disabled={item.disabled}
      sx={{
        mb: 0.5,
        borderRadius: "16px",
        alignItems: "flex-start",
        backgroundColor: "inherit",
        marginLeft: level > 1 ? "38px" : "auto",
        py: level > 1 ? 1 : 1.25,
      }}
      selected={window.location.pathname.includes(item.url)}
      onClick={() => router(item.url)}>
      <ListItemIcon
        sx={{
          my: "auto",
          minWidth: !item?.icon ? 0 : 36,
          color: window.location.pathname.includes(item.url)
            ? theme.palette.primary.main
            : "auto",
        }}>
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            color={
              window.location.pathname.includes(item.url)
                ? "primary"
                : "inherit"
            }
            fontWeight={window.location.pathname.includes(item.url) ? 800 : 0}
            sx={{ cursor: "pointer" }}>
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  ) : permissions !== null &&
    permissions.indexOf(menuKey) !== -1 &&
    item.demo !== true ? (
    <ListItemButton
      disabled={item.disabled}
      sx={{
        mb: 0.5,
        borderRadius: "16px",
        alignItems: "flex-start",
        backgroundColor: "inherit",
        marginLeft: level > 1 ? "38px" : "auto",
        py: level > 1 ? 1 : 1.25,
      }}
      selected={window.location.pathname.includes(item.url)}
      onClick={() => router(item.url)}>
      <ListItemIcon
        sx={{
          my: "auto",
          minWidth: !item?.icon ? 0 : 36,
          color: window.location.pathname.includes(item.url)
            ? theme.palette.primary.main
            : "auto",
        }}>
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            color={
              window.location.pathname.includes(item.url)
                ? "primary"
                : "inherit"
            }
            fontWeight={window.location.pathname.includes(item.url) ? 800 : 0}
            sx={{ cursor: "pointer" }}>
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  ) : null;
};

export default NavItem;
