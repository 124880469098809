/** @format */

import { permissionsMock } from "../config/constants";
import { FormatDate, FormatDateComplete } from "../helpers/formatDate";
import { FormatStatus } from "../helpers/formatStatus";
import { FormatCPF } from "./formatCPF";

export function EmptyData(value: string) {
  return value ? value : "Não possui";
}

export function FormatBool(value: boolean) {
  return value ? "Sim" : "Não";
}

export function FilterEmpty(array: any) {
  for (let i = 0; i < array.length; i++) {
    let objeto = array[i];
    for (let chave in objeto) {
      switch (chave) {
        case "cpf":
          objeto[chave] = FormatCPF(objeto[chave]);
          break;
        case "active":
          objeto[chave] = FormatBool(objeto[chave]);
          break;
        case "status":
        case "solicitation_status":
          objeto[chave] = FormatStatus(objeto[chave]);
          break;
        case "role":
          objeto[chave] =
            permissionsMock.filter(
              (item: any) => item.uuid === objeto[chave]
            )[0]?.name ?? "Não possui";
          break;
        case "abono_pecuniario":
        case "thirteen_anticipation":
          objeto[chave] = FormatBool(objeto[chave]);
          break;
        case "acquisition_period_start":
        case "acquisition_period_end":
        case "created_at":
          objeto[chave] = FormatDate(objeto[chave]);
          break;
        case "approved_at":
          objeto[chave] = FormatDate(objeto[chave]);
          break;
        case "completed_at":
          objeto[chave] = FormatDate(objeto[chave]);
          break;
        case "executed_at":
          objeto[chave] = FormatDateComplete(objeto[chave]);
          break;
        case "business_unit_job_security_date":
          break;
        case "business_unit_contract_type":
          objeto[chave] = objeto[chave] ? objeto[chave] === "999" ? "PJ" : "CLT" : " - "
          break
        default:
          objeto[chave] =
            chave.indexOf("date") !== -1
              ? FormatDate(objeto[chave])
              : EmptyData(objeto[chave]);
          break;
      }
    }
  }
  return array;
}
