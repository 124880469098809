/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  aviso_e_social,
  banks_e_social,
  country_e_social,
  education_level_e_social,
  gender_e_social,
  marital_status_e_social,
  race_e_social,
  type_of_account,
} from "../../../../config/constants";
import { handleCEPChange } from "../../../../helpers/handleCEPChange";
import { handleCPFChange } from "../../../../helpers/handleCPFChange";
import { handleCellPhoneChange } from "../../../../helpers/handleCellPhoneChange";
import { handleRGChange } from "../../../../helpers/handleRGChange";

export function Personal({ formik, data }: any) {
  const [cityOptions, setCityOptions] = useState<any>([]);
  const [stateOptions, setStateOptions] = useState<any>([]);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        setStateOptions(response.data);
      })
      .catch(() => {
        toast.error(
          "Falha ao buscar os estados, por favor tente novamente mais tarde."
        );
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${formik.values.state_of_birth}/municipios`
      )
      .then((response) => {
        setCityOptions(response.data);
      })
      .catch(() => {
        toast.error(
          "Falha ao buscar as cidades deste estado, por favor tente novamente mais tarde."
        );
      });
  }, [formik.values.state_of_birth]);

  return (
    <Grid container flexDirection={"column"} mt={2}>
      <Grid>
        <Typography fontSize={16} fontWeight={500}>
          Dados obrigatórios
        </Typography>
      </Grid>
      <Grid
        gridTemplateColumns={"1fr 1fr 1fr 1fr"}
        mt={2}
        display={"grid"}
        gap={2}>
        <TextField
          fullWidth
          id='name'
          label='Nome'
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
        <TextField
          fullWidth
          id='matricula_esocial'
          label='Matrícula eSocial'
          value={formik.values.matricula_esocial}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.matricula_esocial)}
          helperText={formik.errors.matricula_esocial}
        />
        <TextField
          fullWidth
          id='username'
          label='Username'
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.username)}
          helperText={formik.errors.username}
        />

        <TextField
          fullWidth
          id='cpf'
          label='CPF'
          placeholder={"000.000.000-00"}
          inputProps={{
            maxLength: 14,
          }}
          value={formik.values.cpf}
          onChange={(event: any) => handleCPFChange(event, formik)}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.cpf)}
          helperText={formik.errors.cpf}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "birth_date",
              onBlur: formik.handleBlur,
              error: Boolean(formik.errors.birth_date),
              helperText: formik.errors.birth_date,
            },
          }}
          disabled={false}
          label='Data de nascimento'
          value={formik.values.birth_date}
          onChange={(value: any) => {
            formik.setFieldValue("birth_date", value);
          }}
        />
      </Grid>
      <Grid mt={2}>
        <Typography fontSize={16} fontWeight={500}>
          Dados de perfil
        </Typography>
      </Grid>
      <Grid
        gridTemplateColumns={"1fr 1fr 1fr 1fr"}
        mt={2}
        display={"grid"}
        gap={2}>
        <TextField
          fullWidth
          id='email'
          label='Email corporativo'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
        />
        <TextField
          fullWidth
          id='corporate_phone'
          label='Telefone corporativo'
          placeholder={"(00) 00000-0000"}
          value={formik.values.corporate_phone}
          onChange={(event: any) =>
            handleCellPhoneChange(event, formik, "corporate_phone")
          }
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.corporate_phone)}
          helperText={formik.errors.corporate_phone}
        />
      </Grid>
      <Grid mt={2}>
        <Typography fontSize={16} fontWeight={500}>
          Dados pessoais
        </Typography>
      </Grid>
      <Grid gridTemplateColumns={"2fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='rg'
          label='RG'
          inputProps={{
            maxLength: 13,
          }}
          value={formik.values.rg}
          onChange={(event: any) => handleRGChange(event, formik)}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.rg)}
          helperText={formik.errors.rg}
        />
        <TextField
          fullWidth
          id='social_name'
          label='Nome social'
          value={formik.values.social_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.social_name)}
          helperText={formik.errors.social_name}
        />
        <TextField
          fullWidth
          id='marital_status'
          name='marital_status'
          select
          label='Estado civil'
          value={formik.values.marital_status}
          onChange={(event: any) =>
            formik.setFieldValue("marital_status", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.marital_status)}
          helperText={formik.errors.marital_status}>
          {marital_status_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='race'
          name='race'
          select
          label='Raça'
          value={formik.values.race}
          onChange={(event: any) =>
            formik.setFieldValue("race", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.race)}
          helperText={formik.errors.race}>
          {race_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='education_level'
          name='education_level'
          select
          label='Grau de instrução'
          value={formik.values.education_level}
          onChange={(event: any) =>
            formik.setFieldValue("education_level", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.education_level &&
            Boolean(formik.errors.education_level)
          }
          helperText={
            formik.touched.education_level && formik.errors.education_level
          }>
          {education_level_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='gender'
          name='gender'
          select
          label='Sexo'
          value={formik.values.gender}
          onChange={(event: any) =>
            formik.setFieldValue("gender", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}>
          {gender_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='phone'
          label='Telefone'
          placeholder={"(00) 00000-0000"}
          value={formik.values.phone}
          onChange={(event: any) =>
            handleCellPhoneChange(event, formik, "phone")
          }
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
        <TextField
          fullWidth
          id='nationality'
          name='nationality'
          label='Nacionalidade'
          select
          value={formik.values.nationality}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.nationality && Boolean(formik.errors.nationality)
          }
          helperText={formik.touched.nationality && formik.errors.nationality}
        >
          {country_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='is_retired'
          name='is_retired'
          select
          label='Aposentado'
          value={formik.values.is_retired}
          onChange={(event: any) =>
            formik.setFieldValue("is_retired", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.is_retired && Boolean(formik.errors.is_retired)}
          helperText={formik.touched.is_retired && formik.errors.is_retired}>
          {aviso_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='personal_email'
          label='Email pessoal'
          value={formik.values.personal_email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.personal_email &&
            Boolean(formik.errors.personal_email)
          }
          helperText={
            formik.touched.personal_email && formik.errors.personal_email
          }
        />
      </Grid>
      <Grid mt={2}>
        <Typography fontSize={14} fontWeight={400}>
          Antecedentes criminais
        </Typography>
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='criminal_record'
          name='criminal_record'
          select
          label='Antec. criminais'
          value={formik.values.criminal_record}
          onChange={(event: any) =>
            formik.setFieldValue("criminal_record", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.criminal_record &&
            Boolean(formik.errors.criminal_record)
          }
          helperText={
            formik.touched.criminal_record && formik.errors.criminal_record
          }>
          {aviso_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {formik.values.criminal_record === true && (
          <>
            <TextField
              fullWidth
              id='court_license'
              label='Alvará judicial'
              value={formik.values.court_license}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.court_license &&
                Boolean(formik.errors.court_license)
              }
              helperText={
                formik.touched.court_license && formik.errors.court_license
              }
            />
            <TextField
              fullWidth
              id='process'
              label='Processo'
              type={"number"}
              value={formik.values.process}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.process && Boolean(formik.errors.process)}
              helperText={formik.touched.process && formik.errors.process}
            />
          </>
        )}
      </Grid>
      <Grid mt={2}>
        <Typography fontSize={14} fontWeight={400}>
          Pessoa com deficiência
        </Typography>
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='is_disability'
          name='is_disability'
          select
          label='PCD'
          value={formik.values.is_disability}
          onChange={(event: any) =>
            formik.setFieldValue("is_disability", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.is_disability && Boolean(formik.errors.is_disability)
          }
          helperText={
            formik.touched.is_disability && formik.errors.is_disability
          }>
          {aviso_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {formik.values.is_disability === true && (
        <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2} mt={1}>
          <FormControl component='fieldset' variant='standard'>
            <FormLabel id='type_of_disability'>Tipo do PCD</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.type_of_disability.indexOf(
                        "defAuditiva"
                      ) !== -1
                    }
                    onChange={() => {
                      const index =
                        formik.values.type_of_disability.indexOf("defAuditiva");
                      if (index !== -1) {
                        formik.values.type_of_disability.splice(index, 1);
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      } else {
                        formik.values.type_of_disability.push("defAuditiva");
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      }
                    }}
                    name='Auditiva'
                  />
                }
                label='Auditiva'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.type_of_disability.indexOf("defFisica") !==
                      -1
                    }
                    onChange={() => {
                      const index =
                        formik.values.type_of_disability.indexOf("defFisica");
                      if (index !== -1) {
                        formik.values.type_of_disability.splice(index, 1);
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      } else {
                        formik.values.type_of_disability.push("defFisica");
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      }
                    }}
                    name='Física'
                  />
                }
                label='Física'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.type_of_disability.indexOf(
                        "defIntelectual"
                      ) !== -1
                    }
                    onChange={() => {
                      const index =
                        formik.values.type_of_disability.indexOf(
                          "defIntelectual"
                        );
                      if (index !== -1) {
                        formik.values.type_of_disability.splice(index, 1);
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      } else {
                        formik.values.type_of_disability.push("defIntelectual");
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      }
                    }}
                    name='Intelectual'
                  />
                }
                label='Intelectual'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.type_of_disability.indexOf("defMental") !==
                      -1
                    }
                    onChange={() => {
                      const index =
                        formik.values.type_of_disability.indexOf("defMental");
                      if (index !== -1) {
                        formik.values.type_of_disability.splice(index, 1);
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      } else {
                        formik.values.type_of_disability.push("defMental");
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      }
                    }}
                    name='Mental'
                  />
                }
                label='Mental'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.type_of_disability.indexOf("defVisual") !==
                      -1
                    }
                    onChange={() => {
                      const index =
                        formik.values.type_of_disability.indexOf("defVisual");
                      if (index !== -1) {
                        formik.values.type_of_disability.splice(index, 1);
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      } else {
                        formik.values.type_of_disability.push("defVisual");
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      }
                    }}
                    name='Visual'
                  />
                }
                label='Visual'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.type_of_disability.indexOf("reabReadap") !==
                      -1
                    }
                    onChange={() => {
                      const index =
                        formik.values.type_of_disability.indexOf("reabReadap");
                      if (index !== -1) {
                        formik.values.type_of_disability.splice(index, 1);
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      } else {
                        formik.values.type_of_disability.push("reabReadap");
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      }
                    }}
                    name='Reabilitação ao trabalho'
                  />
                }
                label='Reabilitação ao trabalho'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formik.values.type_of_disability.indexOf("infoCota") !==
                      -1
                    }
                    onChange={() => {
                      const index =
                        formik.values.type_of_disability.indexOf("infoCota");
                      if (index !== -1) {
                        formik.values.type_of_disability.splice(index, 1);
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      } else {
                        formik.values.type_of_disability.push("infoCota");
                        formik.setFieldValue(
                          "type_of_disability",
                          formik.values.type_of_disability
                        );
                      }
                    }}
                    name='Deficiência estabelecida pelo governo'
                  />
                }
                label='Deficiência estabelecida pelo governo'
              />
            </FormGroup>
          </FormControl>
        </Grid>
      )}
      <Grid mt={2}>
        <Typography fontSize={16} fontWeight={500}>
          Endereço
        </Typography>
      </Grid>
      <Grid
        gridTemplateColumns={"1fr 2fr 1fr 1fr"}
        mt={2}
        display={"grid"}
        gap={2}>
        <TextField
          fullWidth
          id='postal_code'
          placeholder={"00.000-000"}
          label='CEP'
          value={formik.values.postal_code}
          onChange={(event: any) => {
            formik.setFieldValue("postal_code", handleCEPChange(event));
            if (event.target.value.replace(/\D/g, "").length === 8) {
              axios
                .get(
                  `https://viacep.com.br/ws/${event.target.value.replace(
                    /\D/g,
                    ""
                  )}/json/`
                )
                .then((response) => {
                  formik.setFieldValue("street", response.data.logradouro);
                  formik.setFieldValue("city", response.data.localidade);
                  formik.setFieldValue("neighborhood", response.data.bairro);
                  formik.setFieldValue("state", response.data.uf);
                })
                .catch((error) => {
                  toast.error("CEP não encontrado. Verifique o CEP digitado.");
                });
            } else {
              formik.setFieldValue("street", "");
              formik.setFieldValue("city", "");
              formik.setFieldValue("neighborhood", "");
              formik.setFieldValue("state", "");
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
          helperText={formik.touched.zip_code && formik.errors.zip_code}
        />
        <TextField
          fullWidth
          id='street'
          disabled
          label='Logradouro'
          value={formik.values.street}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.street && Boolean(formik.errors.street)}
          helperText={formik.touched.street && formik.errors.street}
        />
        <TextField
          fullWidth
          id='number'
          type={"number"}
          label='Número'
          value={formik.values.number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.number && Boolean(formik.errors.number)}
          helperText={formik.touched.number && formik.errors.number}
        />
        <TextField
          fullWidth
          id='complement'
          label='Complemento'
          value={formik.values.complement}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.complement && Boolean(formik.errors.complement)}
          helperText={formik.touched.complement && formik.errors.complement}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='neighborhood'
          disabled
          label='Bairro'
          value={formik.values.neighborhood}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.neighborhood && Boolean(formik.errors.neighborhood)
          }
          helperText={formik.touched.neighborhood && formik.errors.neighborhood}
        />
        <TextField
          fullWidth
          id='city'
          disabled
          label='Cidade'
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
        />
        <TextField
          fullWidth
          id='state'
          disabled
          label='UF'
          value={formik.values.state}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.touched.state && formik.errors.state}
        />
      </Grid>
      <Grid mt={2}>
        <Typography fontSize={16} fontWeight={500}>
          Dados de nascimento
        </Typography>
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='country'
          name='country'
          select
          label='País'
          value={formik.values.country}
          onChange={(event: any) =>
            formik.setFieldValue("country", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.country && Boolean(formik.errors.country)}
          helperText={formik.touched.country && formik.errors.country}>
          {country_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value} - {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='state_of_birth'
          name='state_of_birth'
          select
          label='Estado'
          value={formik.values.state_of_birth}
          onChange={(event: any) => {
            formik.setFieldValue("state_of_birth", event.target.value);
            formik.setFieldValue("origin_city", "");
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.state_of_birth &&
            Boolean(formik.errors.state_of_birth)
          }
          helperText={
            formik.touched.state_of_birth && formik.errors.state_of_birth
          }>
          {stateOptions.map((option: any) => (
            <MenuItem key={option.sigla} value={option.sigla}>
              {option.nome}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='city_of_birth'
          name='city_of_birth'
          select
          disabled={
            formik.values.state_of_birth
              ? formik.values.state_of_birth.length < 1
              : true
          }
          label='Cidade'
          value={formik.values.city_of_birth}
          onChange={(event: any) =>
            formik.setFieldValue("city_of_birth", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.city_of_birth && Boolean(formik.errors.city_of_birth)
          }
          helperText={
            formik.touched.city_of_birth && formik.errors.city_of_birth
          }>
          {cityOptions.map((option: any) => (
            <MenuItem key={option.nome} value={option.nome}>
              {option.nome}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid mt={2}>
        <Typography fontSize={16} fontWeight={500}>
          Filiação
        </Typography>
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='father_name'
          label='Nome do pai'
          value={formik.values.father_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.father_name && Boolean(formik.errors.father_name)
          }
          helperText={formik.touched.father_name && formik.errors.father_name}
        />
        <TextField
          fullWidth
          id='father_cpf'
          placeholder={"000.000.000-00"}
          label='CPF do pai'
          inputProps={{
            maxLength: 14,
          }}
          value={formik.values.father_cpf}
          onChange={(event: any) =>
            handleCPFChange(event, formik, "father_cpf")
          }
          onBlur={formik.handleBlur}
          error={formik.touched.father_cpf && Boolean(formik.errors.father_cpf)}
          helperText={formik.touched.father_cpf && formik.errors.father_cpf}
        />
        <TextField
          fullWidth
          id='father_country_of_birth'
          name='father_country_of_birth'
          select
          label='País de nascimento do pai'
          value={formik.values.father_country_of_birth}
          onChange={(event: any) =>
            formik.setFieldValue("father_country_of_birth", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.father_country_of_birth &&
            Boolean(formik.errors.father_country_of_birth)
          }
          helperText={
            formik.touched.father_country_of_birth &&
            formik.errors.father_country_of_birth
          }>
          {country_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value} - {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='paternal_grandmother_name'
          label='Avó paterna'
          value={formik.values.paternal_grandmother_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.paternal_grandmother_name &&
            Boolean(formik.errors.paternal_grandmother_name)
          }
          helperText={
            formik.touched.paternal_grandmother_name &&
            formik.errors.paternal_grandmother_name
          }
        />
        <TextField
          fullWidth
          id='paternal_grandfather_name'
          label='Avô paterno'
          value={formik.values.paternal_grandfather_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.paternal_grandfather_name &&
            Boolean(formik.errors.paternal_grandfather_name)
          }
          helperText={
            formik.touched.paternal_grandfather_name &&
            formik.errors.paternal_grandfather_name
          }
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='mother_name'
          label='Nome da mãe'
          value={formik.values.mother_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.mother_name && Boolean(formik.errors.mother_name)
          }
          helperText={formik.touched.mother_name && formik.errors.mother_name}
        />
        <TextField
          fullWidth
          id='mother_cpf'
          placeholder={"000.000.000-00"}
          label='CPF da mãe'
          inputProps={{
            maxLength: 14,
          }}
          value={formik.values.mother_cpf}
          onChange={(event: any) =>
            handleCPFChange(event, formik, "mother_cpf")
          }
          onBlur={formik.handleBlur}
          error={formik.touched.mother_cpf && Boolean(formik.errors.mother_cpf)}
          helperText={formik.touched.mother_cpf && formik.errors.mother_cpf}
        />
        <TextField
          fullWidth
          id='mother_country_of_birth'
          name='mother_country_of_birth'
          select
          label='País de nascimento da mãe'
          value={formik.values.mother_country_of_birth}
          onChange={(event: any) =>
            formik.setFieldValue("mother_country_of_birth", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.mother_country_of_birth &&
            Boolean(formik.errors.mother_country_of_birth)
          }
          helperText={
            formik.touched.mother_country_of_birth &&
            formik.errors.mother_country_of_birth
          }>
          {country_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value} - {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='maternal_grandmother_name'
          label='Avó materna'
          value={formik.values.maternal_grandmother_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.maternal_grandmother_name &&
            Boolean(formik.errors.maternal_grandmother_name)
          }
          helperText={
            formik.touched.maternal_grandmother_name &&
            formik.errors.maternal_grandmother_name
          }
        />
        <TextField
          fullWidth
          id='maternal_grandfather_name'
          label='Avô materno'
          value={formik.values.maternal_grandfather_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.maternal_grandfather_name &&
            Boolean(formik.errors.maternal_grandfather_name)
          }
          helperText={
            formik.touched.maternal_grandfather_name &&
            formik.errors.maternal_grandfather_name
          }
        />
      </Grid>
      <Grid mt={2}>
        <Typography fontSize={16} fontWeight={500}>
          Naturalização
        </Typography>
      </Grid>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='is_naturalized'
          name='is_naturalized'
          select
          label='Naturalizado'
          value={formik.values.is_naturalized}
          onChange={(event: any) =>
            formik.setFieldValue("is_naturalized", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.is_naturalized &&
            Boolean(formik.errors.is_naturalized)
          }
          helperText={
            formik.touched.is_naturalized && formik.errors.is_naturalized
          }>
          {aviso_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "arrival_in_brazil_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.arrival_in_brazil_date &&
                Boolean(formik.errors.arrival_in_brazil_date),
              helperText:
                formik.touched.arrival_in_brazil_date &&
                formik.errors.arrival_in_brazil_date,
            },
          }}
          disabled={false}
          label='Data de chegada ao Brasil'
          value={formik.values.arrival_in_brazil_date}
          onChange={(value: any) => {
            formik.setFieldValue("arrival_in_brazil_date", value);
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "naturalization_date",
              onBlur: formik.handleBlur,
              error:
                formik.touched.naturalization_date &&
                Boolean(formik.errors.naturalization_date),
              helperText:
                formik.touched.naturalization_date &&
                formik.errors.naturalization_date,
            },
          }}
          disabled={false}
          label='Data de naturalização'
          value={formik.values.naturalization_date}
          onChange={(value: any) => {
            formik.setFieldValue("naturalization_date", value);
          }}
        />
        <TextField
          fullWidth
          id='married_to_a_brazilian'
          name='married_to_a_brazilian'
          select
          label='Casado com Brasileiro(a)'
          value={formik.values.married_to_a_brazilian}
          onChange={(event: any) =>
            formik.setFieldValue("married_to_a_brazilian", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.married_to_a_brazilian &&
            Boolean(formik.errors.married_to_a_brazilian)
          }
          helperText={
            formik.touched.married_to_a_brazilian &&
            formik.errors.married_to_a_brazilian
          }>
          {aviso_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='has_brazilian_children'
          name='has_brazilian_children'
          select
          label='Possui filhos no Brasil'
          value={formik.values.has_brazilian_children}
          onChange={(event: any) =>
            formik.setFieldValue("has_brazilian_children", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.has_brazilian_children &&
            Boolean(formik.errors.has_brazilian_children)
          }
          helperText={
            formik.touched.has_brazilian_children &&
            formik.errors.has_brazilian_children
          }>
          {aviso_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid mt={2}>
        <Typography fontSize={16} fontWeight={500}>
          Conta bancária
        </Typography>
      </Grid>
      <Grid
        gridTemplateColumns={"1fr 1fr 1fr 1fr"}
        mt={2}
        display={"grid"}
        gap={2}>
        <TextField
          fullWidth
          id='bank_code'
          name='bank_code'
          select
          label='Código do banco'
          value={formik.values.bank_code}
          onChange={(event: any) =>
            formik.setFieldValue("bank_code", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.bank_code && Boolean(formik.errors.bank_code)}
          helperText={formik.touched.bank_code && formik.errors.bank_code}>
          {banks_e_social.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value} - {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='bank_account_type'
          name='bank_account_type'
          select
          label='Tipo da conta'
          value={formik.values.bank_account_type}
          onChange={(event: any) =>
            formik.setFieldValue("bank_account_type", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.bank_account_type &&
            Boolean(formik.errors.bank_account_type)
          }
          helperText={
            formik.touched.bank_account_type && formik.errors.bank_account_type
          }>
          {type_of_account.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='bank_agency'
          label='Agência com DV'
          type={"number"}
          value={formik.values.bank_agency}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.bank_agency && Boolean(formik.errors.bank_agency)
          }
          helperText={formik.touched.bank_agency && formik.errors.bank_agency}
        />
        <TextField
          fullWidth
          id='bank_account'
          type={"number"}
          label='Conta com DV'
          value={formik.values.bank_account}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.bank_account && Boolean(formik.errors.bank_account)
          }
          helperText={formik.touched.bank_account && formik.errors.bank_account}
        />
      </Grid>
    </Grid>
  );
}
