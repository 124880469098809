/** @format */

import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { aviso_e_social } from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../config/hiddenCheck";
import { FormatCurrency } from "../../../../../helpers/formatCurrency";

export function Remuneration({ formik, data }: any) {
  useEffect(() => {
    if (formik.values.union_id) {
      const unionData = data.options.union.filter(
        (item: any) => item.value === formik.values.union_id
      )[0];
      formik.setValues({
        ...formik.values,
        union_id_name: unionData.name,
        union_id_cnpj: unionData.cnpj,
        union_id_email: unionData.email,
        union_id_phone: unionData.phone,
        union_id_representative_name: unionData.representative_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.union_id]);
  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid>
          <Typography fontSize={16} fontWeight={500}>
            Estrutura
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='department_id'
              name='department_id'
              select
              label='Departamento'
              value={formik.values.department_id}
              onChange={(event: any) =>
                formik.setFieldValue("department_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.department_id &&
                Boolean(formik.errors.department_id)
              }
              helperText={
                formik.touched.department_id && formik.errors.department_id
              }>
              {data.options.departments.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='job_role_id'
              name='job_role_id'
              select
              label='Cargo'
              value={formik.values.job_role_id}
              onChange={(event: any) =>
                formik.setFieldValue("job_role_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.job_role_id && Boolean(formik.errors.job_role_id)
              }
              helperText={
                formik.touched.job_role_id && formik.errors.job_role_id
              }>
              {data.options.job_role.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>

          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='position_code'
              label='Código da posição'
              value={formik.values.position_code}
              onBlur={formik.handleBlur}
              disabled
            />
          </HiddenCheck>

          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='remuneration_value'
              label='Salário base'
              placeholder={"R$0,00"}
              value={formik.values.remuneration_value}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "remuneration_value",
                  FormatCurrency(event)
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.remuneration_value &&
                Boolean(formik.errors.remuneration_value)
              }
              helperText={
                formik.touched.remuneration_value &&
                formik.errors.remuneration_value
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='manager_id'
              name='manager_id'
              select
              label='Superior matrícula'
              value={formik.values.manager_id}
              onChange={(event: any) => {
                formik.setFieldValue("manager_id", event.target.value);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.manager_id && Boolean(formik.errors.manager_id)
              }
              helperText={
                formik.touched.manager_id && formik.errors.manager_id
              }>
              {data.options.managers?.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='remuneration_trust_position_amount'
              label='Cargo confiança(R$)'
              placeholder={"R$0,00"}
              value={formik.values.remuneration_trust_position_amount}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "remuneration_trust_position_amount",
                  FormatCurrency(event)
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.remuneration_trust_position_amount &&
                Boolean(formik.errors.remuneration_trust_position_amount)
              }
              helperText={
                formik.touched.remuneration_trust_position_amount &&
                formik.errors.remuneration_trust_position_amount
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='remuneration_trust_position_percentage'
              type='number'
              label='Cargo confiança(%)'
              inputProps={{
                max: 100,
                min: 0,
              }}
              value={formik.values.remuneration_trust_position_percentage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.remuneration_trust_position_percentage &&
                Boolean(formik.errors.remuneration_trust_position_percentage)
              }
              helperText={
                formik.touched.remuneration_trust_position_percentage &&
                formik.errors.remuneration_trust_position_percentage
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='salary_deduction'
              label='Pgto desc. salário'
              placeholder={"R$0,00"}
              value={formik.values.salary_deduction}
              onChange={(event: any) =>
                formik.setFieldValue("salary_deduction", FormatCurrency(event))
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.salary_deduction &&
                Boolean(formik.errors.salary_deduction)
              }
              helperText={
                formik.touched.salary_deduction &&
                formik.errors.salary_deduction
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='is_commissioned'
              name='is_commissioned'
              select
              label='Comissionado'
              value={formik.values.is_commissioned}
              onChange={(event: any) =>
                formik.setFieldValue("is_commissioned", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.is_commissioned &&
                Boolean(formik.errors.is_commissioned)
              }
              helperText={
                formik.touched.is_commissioned && formik.errors.is_commissioned
              }>
              {aviso_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Sindicato
          </Typography>
        </Grid>
        <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='union_id'
              name='union_id'
              select
              label='Código'
              value={formik.values.union_id}
              onChange={(event: any) =>
                formik.setFieldValue("union_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={formik.touched.union_id && Boolean(formik.errors.union_id)}
              helperText={formik.touched.union_id && formik.errors.union_id}>
              {data.options.union.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label='Nome'
              disabled
              value={formik.values.union_id_name}
            />
            <TextField
              fullWidth
              label='Nome do representante'
              disabled
              value={formik.values.union_id_representative_name}
            />
            <TextField
              fullWidth
              label='Telefone'
              disabled
              value={formik.values.union_id_phone}
            />
            <TextField
              fullWidth
              label='CNPJ'
              disabled
              value={formik.values.union_id_cnpj}
            />
            <TextField
              fullWidth
              label='Email'
              disabled
              value={formik.values.union_id_email}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Filiação sindicato
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='join_to_union'
              name='join_to_union'
              select
              label='Deseja filiar-se'
              value={formik.values.join_to_union}
              onChange={(event: any) =>
                formik.setFieldValue("join_to_union", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.join_to_union &&
                Boolean(formik.errors.join_to_union)
              }
              helperText={
                formik.touched.join_to_union && formik.errors.join_to_union
              }>
              {aviso_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='deduct_union_dues'
              name='deduct_union_dues'
              select
              label='Descontar contribuição sindical?'
              value={formik.values.deduct_union_dues}
              onChange={(event: any) =>
                formik.setFieldValue("deduct_union_dues", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.deduct_union_dues &&
                Boolean(formik.errors.deduct_union_dues)
              }
              helperText={
                formik.touched.deduct_union_dues &&
                formik.errors.deduct_union_dues
              }>
              {aviso_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='deduct_federation_dues'
              name='deduct_federation_dues'
              select
              label='Descontar contribuição federativa?'
              value={formik.values.deduct_federation_dues}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "deduct_federation_dues",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.deduct_federation_dues &&
                Boolean(formik.errors.deduct_federation_dues)
              }
              helperText={
                formik.touched.deduct_federation_dues &&
                formik.errors.deduct_federation_dues
              }>
              {aviso_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='deduct_salary_reversion'
              name='deduct_salary_reversion'
              select
              label='Descontar reversão salárial?'
              value={formik.values.deduct_salary_reversion}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "deduct_salary_reversion",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.deduct_salary_reversion &&
                Boolean(formik.errors.deduct_salary_reversion)
              }
              helperText={
                formik.touched.deduct_salary_reversion &&
                formik.errors.deduct_salary_reversion
              }>
              {aviso_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "contribution_starting_from",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.contribution_starting_from &&
                    Boolean(formik.errors.contribution_starting_from),
                  helperText:
                    formik.touched.contribution_starting_from &&
                    formik.errors.contribution_starting_from,
                },
              }}
              label='Data inicio contribuição'
              value={formik.values.contribution_starting_from}
              onChange={(value: any) => {
                formik.setFieldValue("contribution_starting_from", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
