/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { Cancel, Edit, UploadFile } from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import animationData from "../assets/lotties/loading_modal.json";
import { fields } from "../config/constants";
import { IsPermissions } from "../config/isPermission";
import { updateLoading } from "../redux/reducers/common";
import {
  PostInstante,
  PostInstanteFormData,
  RemoveInstance,
} from "../services/api";

export function ActionUploadDocument({
  buttonTitle,
  keyPrivate,
  id,
  url,
  data,
  touched,
  urlBack,
  form,
  errors
}: any) {
  const fileInputRef: any = useRef([]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const router = useNavigate();
  const [filesForm, setFileForm]: any = useState([]);
  const [fieldsFile, setFieldsFile]: any = useState([]);
  const user = useSelector((state: any) => state.user.data);
  const loading = useSelector((state: any) => state.common.loading);
  const dependentsRequired =
    fieldsFile.indexOf("dependents") !== -1 ? data["dependents"].length - 1 : 0;
  const itensRequired = fieldsFile.length + dependentsRequired;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [hasFile, setHasFile] = useState(false);
  const formik: any = useFormik({
    initialValues: {
      files: [],
    },
    validationSchema: yup.object({
      files: yup
        .array()
        .min(itensRequired, `Precisa ter ${itensRequired} anexo`)
        .nullable()
        .required("Arquivo é obrigatório"),
    }),
    onSubmit: () => {
      dispatch(updateLoading(true));
      const dataSend: any = {};
      // eslint-disable-next-line array-callback-return
      Object.entries(data).map(([key, value]: any) => {
        if (Object.keys(touched).indexOf(key) !== -1) {
          dataSend[key] = value;
        }
        if (Object.keys(touched).indexOf("postal_code") !== -1) {
          dataSend["street"] = data["street"];
          dataSend["neighborhood"] = data["neighborhood"];
          dataSend["city"] = data["city"];
          dataSend["state"] = data["state"];
        }
      });
      PostInstante(null, { payload: dataSend, target_user_uuid: id }, url)
        .then((response: any) => {
          const formData = new FormData();
          // eslint-disable-next-line array-callback-return
          formik.values.files.map((file: any) => {
            formData.append("files[]", file);
          });
          PostInstanteFormData(
            null,
            formData,
            "/solicitations/" +
              response.data.data.solicitation_uuid +
              "/upload_files"
          )
            .then(() => {
              dispatch(updateLoading(false));
              toast.success(response?.data?.message);
              setTimeout(() => {
                router(urlBack);
              }, 3000);
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                RemoveInstance(id, url)
                  .then(() => {
                    dispatch(updateLoading(false));
                    window.location.reload();
                  })
                  .catch((e: any) => {
                    if (e.code !== "ERR_CANCELED") {
                      dispatch(updateLoading(false));
                      toast.error(
                        e?.response?.data?.message ??
                          "Erro inesperado, tente novamente."
                      );
                    }
                  });
              }
            });
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  function onSubmit() {
    dispatch(updateLoading(true));
    const dataSend: any = {};
    // eslint-disable-next-line array-callback-return
    Object.entries(data).map(([key, value]: any) => {
      if (Object.keys(touched).indexOf(key) !== -1) {
        dataSend[key] = value;
      }
    });
    PostInstante(null, { payload: dataSend, target_user_uuid: id }, url)
      .then((response: any) => {
        form.setTouched({});
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        if (data.status !== "pending") {
          setTimeout(() => {
            router("/request/updateInformation");
          }, 3000);
        }
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  }

  const onFileDrop = async (
    file: any,
    keyValueString: string,
    index: number
  ) => {
    const fileNew: any = filesForm;
    // eslint-disable-next-line array-callback-return
    fileNew.map((item: any) => {
      if (item.keyValue === keyValueString) {
        if (Array.isArray(item.file)) {
          item.file[index] = file;
        } else {
          item.file = file;
        }
      }
    });
    setFileForm([]);
    setTimeout(() => {
      setFileForm(fileNew);
    }, 1);
    formik.setErrors({});
    const filesAll: any = [];
    // eslint-disable-next-line array-callback-return
    fileNew.map((item: any) => {
      if (item.file !== null) {
        if (Array.isArray(item.file)) {
          item.file.map((item2: any) => filesAll.push(item2));
        } else {
          filesAll.push(item.file);
        }
      }
    });
    formik.setFieldValue("files", filesAll);
  };

  const openFileDialog = (index: any) => {
    fileInputRef.current[index].click();
  };

  const handleDrop = (e: any, key: string, index: number) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file, key, index);
    }
  };

  const handleFileInputChange = (e: any, key: string, index: number) => {
    const file = e.target.files[0];
    if (file) {
      onFileDrop(file, key, index);
    }
  };

  const handleDragOver: any = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  useEffect(() => {
    const filesInit: any = [];
    Object.keys(touched)
      .filter((element) =>
        data.fields_with_required_documents.includes(element)
      )
      // eslint-disable-next-line array-callback-return
      .map((item: any) => {
        if (item === "dependents") {
          const file: any = [];
          // eslint-disable-next-line array-callback-return
          data[item].map((opt: any, index: any) => {
            file.push(null);
          });
          filesInit.push({ keyValue: item, file });
        } else {
          filesInit.push({ keyValue: item, file: null });
        }
      });
    // eslint-disable-next-line array-callback-return
    Object.keys(touched).map((item: any) => {
      switch (item) {
        case "postal_code":
        case "street":
        case "number":
        case "complement":
        case "neighborhood":
        case "city":
        case "state":
          if (
            filesInit.filter((opt: any) => opt.keyValue === "address")
              .length === 0
          )
            filesInit.push({ keyValue: "address", file: null });
          break;
        case "bank_code":
        case "bank_account_type":
        case "bank_agency":
        case "bank_account":
          if (
            filesInit.filter((opt: any) => opt.keyValue === "bank").length === 0
          )
            filesInit.push({ keyValue: "bank", file: null });
          break;
        default:
          break;
      }
    });
    setFileForm(filesInit);
    setHasFile(data.status !== "pending" && filesInit.length > 0);
    setFieldsFile(filesInit);
  }, [data, touched]);

  function UploadDocument({ option, index, index2 }: any) {
    return (
      <AlertDialog.Description key={option.keyValue} size='2' mt={"4"}>
        {option.file === null || option.file[index2] === null ? (
          <div
            style={{
              border: `1px ${
                formik.errors.files
                  ? theme.palette.error.main
                  : theme.palette.text.secondary
              } dashed`,
              color: formik.errors.files
                ? theme.palette.error.main
                : theme.palette.text.secondary,
              cursor: "pointer",
              borderRadius: 10,
              width: "inherit",
            }}
            className='ContextMenuTrigger'
            onDragOver={(e) => handleDragOver(e, option.keyValue)}
            onDrop={(e) => handleDrop(e, option.keyValue, index2)}
            onClick={() => openFileDialog(index)}>
            <input
              key={index + option.keyValue}
              type='file'
              accept='*'
              ref={(el) => (fileInputRef.current[index] = el)}
              onChange={(e) => {
                handleFileInputChange(e, option.keyValue, index2);
              }}
              style={{ display: "none" }}
            />
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                margin: 16,
                alignItems: "center",
              }}>
              <UploadFile style={{ marginRight: 12 }} /> Arraste e solte um
              arquivo aqui ou clique para selecionar um arquivo.
            </p>
          </div>
        ) : (
          <Tooltip title={"Remover arquivo"}>
            <Chip
              color={"primary"}
              onDelete={() => {
                const fileNew = filesForm;
                // eslint-disable-next-line array-callback-return
                fileNew.map((item: any) => {
                  if (item.keyValue === option.keyValue) {
                    if (Array.isArray(item.file)) {
                      item.file[index2] = null;
                    } else {
                      item.file = null;
                    }
                  }
                });
                setFileForm([]);
                setTimeout(() => {
                  setFileForm(fileNew);
                }, 1);
                formik.setErrors({});
                const filesAll: any = [];
                fileNew.map(
                  (item: any) => item.file !== null && filesAll.push(item.file)
                );
                formik.setFieldValue("files", filesAll);
              }}
              label={
                Array.isArray(option.file)
                  ? option.file[index2].name
                  : option.file.name
              }
              variant='outlined'
            />
          </Tooltip>
        )}
      </AlertDialog.Description>
    );
  }

  const valideManager = user.uuid === data.uuid || user.role === "rh";

  return valideManager && hasFile ? (
    <IsPermissions keyPrivate={keyPrivate}>
      <form>
        <AlertDialog.Root>
          <AlertDialog.Trigger>
            <Tooltip
              title={
                Object.keys(touched).length === 0
                  ? "Não existem dados alterados"
                  : "Enviar solicitação"
              }>
              <div>
                <Button
                  disabled={Object.keys(touched).length === 0}
                  variant={"contained"}
                  endIcon={<Edit />}>
                  {buttonTitle ?? "Solicitar"}
                </Button>
              </div>
            </Tooltip>
          </AlertDialog.Trigger>
          <AlertDialog.Content maxWidth='450px'>
            {loading ? (
              <Lottie options={defaultOptions} height={"auto"} width={200} />
            ) : (
              <>
                <AlertDialog.Title>
                  Inclusão de documentos de comprovação
                </AlertDialog.Title>
                <AlertDialog.Description size='2'>
                  Insira os documentos que comprovem as alterações realizadas
                  abaixo:
                </AlertDialog.Description>
                {filesForm.map((option: any, index: number) => (
                  <div id={option.keyValue} key={option.keyValue}>
                    <AlertDialog.Description size='2' mt={"2"}>
                      <Typography fontWeight={800}>
                        -{" "}
                        {fields.filter(
                          (item: any) => option.keyValue === item.value
                        )[0]?.label ?? "N/A"}
                        :
                      </Typography>
                    </AlertDialog.Description>
                    {Array.isArray(data[option.keyValue]) ? (
                      data[option.keyValue].map((sub: any, index2: number) => (
                        <>
                          <AlertDialog.Description size='2' mt={"4"} ml='4'>
                            <Typography fontSize={14} fontWeight={500}>
                              -{" Dependente "}
                              {index2 + 1}:
                            </Typography>
                          </AlertDialog.Description>
                          <UploadDocument
                            option={option}
                            index={index + index2 + 10}
                            index2={index2}
                          />
                        </>
                      ))
                    ) : (
                      <UploadDocument option={option} index={index} />
                    )}
                  </div>
                ))}
                {formik.errors.files && (
                  <AlertDialog.Description size='2' mt={"2"}>
                    <Grid container justifyContent={"center"}>
                      <Typography
                        fontSize={"0.75rem"}
                        textAlign={"center"}
                        color={"error"}>
                        {formik.errors.files}
                      </Typography>
                    </Grid>
                  </AlertDialog.Description>
                )}
                <Flex gap='3' mt='4' justify='end'>
                  <AlertDialog.Cancel onClick={() => formik.handleReset()}>
                    <Button variant={"outlined"} endIcon={<Cancel />}>
                      Cancelar
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant={"contained"}
                      onClick={formik.handleSubmit}
                      endIcon={<UploadFile />}>
                      Enviar
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </>
            )}
          </AlertDialog.Content>
        </AlertDialog.Root>
      </form>
    </IsPermissions>
  ) : data.status === "pending" ? (
    <IsPermissions keyPrivate={keyPrivate}>
      <Tooltip
        title={
          Object.keys(touched).length === 0
            ? "Não existem dados alterados"
            : "Enviar solicitação"
        }>
        <div>
          <Button
            disabled={Object.keys(touched).length === 0}
            variant={"contained"}
            onClick={onSubmit}
            endIcon={<Edit />}>
            Atualizar cadastro
          </Button>
        </div>
      </Tooltip>
    </IsPermissions>
  ) : valideManager ? (
    <IsPermissions keyPrivate={keyPrivate}>
      <Tooltip
        title={
          Object.keys(touched).length === 0
            ? "Não existem dados alterados"
            : "Enviar solicitação"
        }>
        <div>
          <Button
            disabled={Object.keys(touched).length === 0 || Object.keys(errors).length > 0}
            variant={"contained"}
            onClick={onSubmit}
            endIcon={<Edit />}>
            {buttonTitle ?? "Solicitar"}
          </Button>
        </div>
      </Tooltip>
    </IsPermissions>
  ) : null;
}
