/** @format */

export function FormatStatus(value: string) {
  const statusDict = {
    pending: "Pendente",
    approved: "Aprovado",
    disapproved: "Reprovada",
    archived: "Arquivado",
    active: "Ativo",
    on_leave: "Afastado",
    accepted: "Férias aceita",
    inactive: "Inativo",
    open: "Aberta",
    awaiting_acceptance: "Aguardando aceite",
    scheduled: "Agendada",
    waiting_schedule: "Aguardando agendamento",
    vacations_scheduled: "Férias agendadas",
    vacations_waiting_schedule: "Férias aguardando agendamento",
    returned: "Retornada",
    occupied: "Ocupada",
    hiring: "Em contratação",
    processed: "Processado",
    failed: "Não processado",
    waiting_manager_user: "Aguardando gestor",
    waiting_rh_user: "Aguardando RH",
    waiting_transfer_approval: "Aguardando RH",
    cancelled: "Cancelado",
    completed: "Concluído",
    accounting_return: "Devolução folha",
  };
  const exist = Object.entries(statusDict).filter(
    (opt: any) => opt[0] === value
  );
  return value ? (exist.length > 0 ? exist[0][1] : "Não possui") : "Não possui";
}
