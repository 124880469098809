/** @format */

import { Edit, InfoOutlined, Undo, Visibility } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionAccountingReturned } from "../../../../../components/actionAccountingReturned";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionReturned } from "../../../../../components/actionReturned";
import { IsPermissions } from "../../../../../config/isPermission";
import { EmptyData } from "../../../../../helpers/emptyData";
import { ClearCurrency } from "../../../../../helpers/formatCurrency";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";

const validationSchema = (min: number, max: number) =>
  yup.object({
    movement_date: yup
      .date()
      .min(new Date(), "Data da movimentação precisa ser futura")
      .nullable()
      .required("Data da movimentação é obrigatório"),
    // target_business_unit_id: yup
    //   .string()
    //   .nullable()
    //   .required("Unidade de negócio destino é obrigatório"),
    // target_sector_id: yup
    //   .string()
    //   .nullable()
    //   .required("Setor / Locação destino é obrigatório"),
    // target_cost_center_id: yup
    //   .string()
    //   .nullable()
    //   .required("Centro de custo destino é obrigatório"),
    target_job_role_id: yup
      .string()
      .nullable()
      .required("Cargo destino é obrigatório"),
    target_position_id: yup
      .string()
      .nullable()
      .required("Posição destino é obrigatório"),
    percent: yup
      .number()
      .nullable()
      .min(min, `Reajuste não pode ser menor de ${min}%`)
      .max(max, `Reajuste não pode ser maior de ${max}%`)
      .required("Porcentagem é obrigatório"),
    target_salary: yup.string().nullable().required("Salário é obrigatório"),
    observations: yup.string().nullable().required("Comentário é obrigatório"),
  });

const validationSchemaRH = yup.object({
  movement_date: yup
    .date()
    .min(new Date(), "Data da movimentação precisa ser futura")
    .nullable()
    .required("Data da movimentação é obrigatório"),
  // target_business_unit_id: yup
  //   .string()
  //   .nullable()
  //   .required("Unidade de negócio destino é obrigatório"),
  // target_sector_id: yup
  //   .string()
  //   .nullable()
  //   .required("Setor / Locação destino é obrigatório"),
  // target_cost_center_id: yup
  //   .string()
  //   .nullable()
  //   .required("Centro de custo destino é obrigatório"),
  target_job_role_id: yup
    .string()
    .nullable()
    .required("Cargo destino é obrigatório"),
  target_position_id: yup
    .string()
    .nullable()
    .required("Posição destino é obrigatório"),
  percent: yup.number().nullable().required("Porcentagem é obrigatório"),
  target_salary: yup.string().nullable().required("Salário é obrigatório"),
  observations: yup.string().nullable().required("Comentário é obrigatório"),
});

export function PromoteDetails() {
  const router = useNavigate();
  const [data, setData]: any = useState({
    options: {
      business_unit: [],
      sector: [],
      cost_center: [],
      job_role: [],
      job_role_group: [],
      positions: [],
    },
    user_data: {},
    collaborator_data: {},
    solicitation_data: {},
    rules: {},
  });
  const { id } = useParams();
  const permissions = useSelector((state: any) => state.user.permissions);
  const edit =
    permissions !== null &&
    permissions.indexOf("requestPromoteDetailsEdit") !== -1 &&
    data?.solicitation_data.status === "waiting_rh_user";
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state?.user?.data);
  const formik: any = useFormik({
    initialValues: {
      target_business_unit_id: "",
      movement_date: null,
      percent: "",
      target_salary: "",
      observations: "",
      promote_position: false,
      target_sector_id: "",
      target_cost_center_id: "",
      target_position_id: "",
      target_job_role_id: "",
      actual_salary: "",
    },
    validationSchema:
      user.role === "rh"
        ? validationSchemaRH
        : validationSchema(
            data.rules.promotions_min_percent,
            data.rules.promotions_max_percent
          ),
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(id, values, "/promotions")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/request/promote");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });
  useEffect(() => {
    GetInstance({}, "/promotions/" + id)
      .then((response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData((old: any) => ({ ...old, ...data }));
        const movement_date = dayjs(data.record.movement_date);
        GetInstance(
          { user_uuid: data.collaborator_data.uuid },
          "/promotions_form"
        )
          .then(async (response: any) => {
            formik.setFieldValue(
              "actual_salary",
              ClearCurrency(response.data.data.user_data.remuneration_value) ??
                "0"
            );
            dispatch(updateLoading(false));
            setData((old: any) => ({
              ...old,
              collaborator_data: {
                ...old.collaborator_data,
                ...response.data.data.user_data,
              },
              options: response.data.data.options,
            }));
            formik.setValues({
              target_business_unit_id: data.record.target_business_unit_id,
              movement_date,
              percent: data.record.percent,
              new_salary: data.record.target_salary,
              observations: data.record.observations,
              target_sector_id: data.record.target_sector_id,
              target_cost_center_id: data.record.target_cost_center_id,
              target_job_role_id: data.record.target_job_role_id,
              target_position_id: data.record.target_position_id,
              actual_salary: ClearCurrency(
                data.collaborator_data.remuneration_value
              ),
              promote_position:
                data.record.target_position_id ===
                response.data.data.user_data.position_id,
            });
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.collaborator_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.collaborator_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_data.job_role_name),
    },
    {
      title: "Posição",
      value: EmptyData(data.collaborator_data.position_name),
    },
    {
      title: "Salário atual",
      value: data.collaborator_data.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_data.status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/promote'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/promote'>
              Promoções
            </Link>
            <Typography fontWeight={800}>Ver solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"} mt={2}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid key={item.title}>
                      <Typography fontSize={12} fontWeight={600}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={400}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da promoção
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <FormControlLabel
                disabled={!edit}
                id={"promote_position"}
                checked={formik.values.promote_position}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "promote_position",
                    event.target.checked
                  );
                  if (event.target.checked) {
                    const option =
                      data.options.positions.filter(
                        (item: any) => item.value === data.user_data.position_id
                      )[0] ?? {};
                    formik.setFieldValue(
                      "target_position_id",
                      data.user_data.position_id
                    );
                    formik.setFieldValue(
                      "target_job_role_id",
                      option.job_role_id
                    );
                    formik.setFieldValue("target_sector_id", option.sector_id);
                    formik.setFieldValue(
                      "target_cost_center_id",
                      option.cost_center_id
                    );
                    formik.setFieldValue(
                      "target_business_unit_id",
                      option.business_unit_id
                    );
                  }
                }}
                control={<Checkbox />}
                label='A promoção será para a mesma posição?'
              />
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id='target_position_id'
                select
                label='Posição destino'
                disabled={formik.values.promote_position || !edit}
                value={formik.values.target_position_id}
                onChange={(event: any) => {
                  const option =
                    data.options.positions.filter(
                      (item: any) => item.value === event.target.value
                    )[0] ?? {};
                  formik.setFieldValue(
                    "target_position_id",
                    event.target.value
                  );
                  formik.setFieldValue(
                    "target_job_role_id",
                    option.job_role_id
                  );
                  formik.setFieldValue("target_sector_id", option.sector_id);
                  formik.setFieldValue(
                    "target_cost_center_id",
                    option.cost_center_id
                  );
                  formik.setFieldValue(
                    "target_business_unit_id",
                    option.business_unit_id
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.target_position_id &&
                  Boolean(formik.errors.target_position_id)
                }
                helperText={
                  formik.touched.target_position_id &&
                  formik.errors.target_position_id
                }>
                {formik.values.promote_position ? (
                  <MenuItem
                    key={data.collaborator_data.position_id}
                    value={data.collaborator_data.position_id}>
                    {data.collaborator_data.position_name}
                  </MenuItem>
                ) : (
                  data.options.positions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.code} {option.job_role_name}
                    </MenuItem>
                  ))
                )}
              </TextField>
              <TextField
                fullWidth
                id='target_job_role_id'
                select
                disabled={!edit}
                label='Cargo destino'
                value={formik.values.target_job_role_id}
                onChange={(event: any) =>
                  formik.setFieldValue("target_job_role_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.target_job_role_id &&
                  Boolean(formik.errors.target_job_role_id)
                }
                helperText={
                  formik.touched.target_job_role_id &&
                  formik.errors.target_job_role_id
                }>
                {data.options.job_role.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "movement_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.movement_date &&
                      Boolean(formik.errors.movement_date),
                    helperText:
                      formik.touched.movement_date &&
                      formik.errors.movement_date,
                  },
                }}
                disabled={!edit}
                label='Data da movimentação'
                value={formik.values.movement_date}
                onChange={(value: any) => {
                  formik.setFieldValue("movement_date", value);
                }}
              />
              {/* {!formik.values.promote_position && (
                <>
                  <TextField
                    fullWidth
                    id='target_business_unit_id'
                    select
                    disabled
                    label='Un. de negócio destino'
                    value={formik.values.target_business_unit_id}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "target_business_unit_id",
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.target_business_unit_id &&
                      Boolean(formik.errors.target_business_unit_id)
                    }
                    helperText={
                      formik.touched.target_business_unit_id &&
                      formik.errors.target_business_unit_id
                    }>
                    {data.options.business_unit.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    fullWidth
                    id='target_sector_id'
                    select
                    disabled
                    label='Setor / Lotação destino'
                    value={formik.values.target_sector_id}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "target_sector_id",
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.target_sector_id &&
                      Boolean(formik.errors.target_sector_id)
                    }
                    helperText={
                      formik.touched.target_sector_id &&
                      formik.errors.target_sector_id
                    }>
                    {data.options.sector.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    id='target_cost_center_id'
                    select
                    disabled
                    label='Centro de custo destino'
                    value={formik.values.target_cost_center_id}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "target_cost_center_id",
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.target_cost_center_id &&
                      Boolean(formik.errors.target_cost_center_id)
                    }
                    helperText={
                      formik.touched.target_cost_center_id &&
                      formik.errors.target_cost_center_id
                    }>
                    {data.options.cost_center.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )} */}

              <TextField
                fullWidth
                id='percent'
                type={"number"}
                disabled={!edit}
                label='Porcentagem (%)'
                value={formik.values.percent}
                onChange={(event: any) =>
                  formik.setFieldValue("percent", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={formik.touched.percent && Boolean(formik.errors.percent)}
                helperText={formik.touched.percent && formik.errors.percent}
              />
              <TextField
                fullWidth
                id='new_salary'
                disabled={!edit}
                label='Salário futuro'
                value={formik.values.new_salary}
                onChange={(event: any) =>
                  formik.setFieldValue("new_salary", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.new_salary && Boolean(formik.errors.new_salary)
                }
                helperText={
                  formik.touched.new_salary && formik.errors.new_salary
                }
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Comentários'
                  disabled={!edit}
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <Grid container gap={2} justifyContent={"end"}>
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  fullWidth={isMobile}
                  endIcon={<Undo />}>
                  Voltar
                </Button>
                {(data.solicitation_data.status === "pending" ||
                  data.solicitation_data.status === "waiting_rh_user") && (
                  <>
                    {edit && (
                      <Button
                        variant={"contained"}
                        type={"submit"}
                        fullWidth={isMobile}
                        endIcon={<Edit />}>
                        Editar
                      </Button>
                    )}
                    <ActionReturned
                      keyPrivate={"requestPromoteDetailsDisapprove"}
                      id={id}
                      url={"promotions"}
                    />
                    <ActionAproved
                      keyPrivate={"requestPromoteDetailsApprove"}
                      id={id}
                      url={"promotions"}
                    />
                  </>
                )}

                {user.role === "accountant" &&
                  data?.solicitation_data.status === "approved" && (
                    <>
                      <ActionCompleted
                        keyPrivate={"requestDetailsComplete"}
                        id={id}
                        url={"processes"}
                      />

                      <ActionAccountingReturned
                        keyPrivate={"requestDetailsComplete"}
                        id={id}
                        url={"processes"}
                      />
                    </>
                  )}

                {data.solicitation_data.status === "approved" && (
                  <IsPermissions keyPrivate={"requestPromoteDetailsView"}>
                    <Button
                      fullWidth={isMobile}
                      onClick={() =>
                        router("/request/promote/details/letter/" + id)
                      }
                      variant={"contained"}
                      type={"submit"}
                      endIcon={<Visibility />}>
                      Ver carta de comunicação
                    </Button>
                  </IsPermissions>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
