/** @format */

import { Visibility } from "@mui/icons-material";
import {
  Breadcrumbs,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionRemove } from "../../../../components/actionRemove";
import { Filter } from "../../../../components/filter";
import { optionsTable } from "../../../../config/constants";
import { IsPermissions } from "../../../../config/isPermission";
import { FilterEmpty } from "../../../../helpers/emptyData";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";

const columns = [
  { name: "id", label: "Código" },
  { name: "target_user_name", label: "Nome" },
  { name: "days_quantity", label: "Dias solicitados" },
  { name: "start_date", label: "Data inicial" },
  { name: "end_date", label: "Data final" },
  { name: "status", label: "Status" },
];

let timerSearch: any;

export function DashboardVacation() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const formik: any = useFormik({
    initialValues: {
      from: dayjs(new Date().setDate(new Date().getUTCDate() - 30)),
      to: dayjs(new Date()),
    },
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      const sendRequest = {
        ...values,
        from: values.from.toISOString().split("T")[0],
        to: values.to.toISOString().split("T")[0],
        page,
        order,
        search,
      };
      if (sendRequest.status === "all") {
        delete sendRequest.status;
      }

      GetInstance(sendRequest, "/vacation_items_dashboard")
        .then((response: any) => {
          dispatch(updateLoading(false));
          setData(FilterEmpty(response.data.data.records.data));
          setTotal(response.data.data.records.total_count);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container gap={2}>
      <Grid container>
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography fontWeight={400}>Inicio</Typography>
          <Typography fontWeight={800}>Dashboard</Typography>
          <Typography fontWeight={800}>Férias</Typography>
        </Breadcrumbs>
      </Grid>
      <Filter
        formik={formik}
        keys={{ from: { label: "Data inicio" }, to: { label: "Data final" } }}>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "from",
              onBlur: formik.handleBlur,
              error: formik.touched.from && Boolean(formik.errors.from),
              helperText: formik?.errors?.from,
            },
          }}
          label='Data Inícial'
          value={formik.values.from}
          onChange={(value: any) => {
            formik.setFieldValue("from", value);
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "to",
              onBlur: formik.handleBlur,
              error: formik.touched.to && Boolean(formik.errors.to),
              helperText: formik?.errors?.to,
            },
          }}
          label='Data Final'
          value={formik.values.to}
          onChange={(value: any) => {
            formik.setFieldValue("to", value);
          }}
        />
      </Filter>
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container>
                      <IsPermissions keyPrivate={"requestVacationDetails"}>
                        <Tooltip title={"Ver programação"}>
                          <IconButton
                            onClick={() =>
                              router(
                                `/request/vacation/details/${data[dataIndex].uuid}`
                              )
                            }>
                            <Visibility color='primary' />
                          </IconButton>
                        </Tooltip>
                      </IsPermissions>
                      <ActionRemove
                        statusList={data[dataIndex].solicitation_status}
                        url='/vacations'
                        id={data[dataIndex].uuid}
                      />
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: false,
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
