/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { FormatCurrency } from "../../../../../helpers/formatCurrency";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";

const validationSchema = yup.object({
  initial_date: yup
    .date()
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .nullable()
    .required("Data é obrigatório"),
  job_role_id: yup.string().nullable(),
  salary: yup.string().nullable(),
  position_id: yup.string().nullable().required("Esse campo é obrigatório"),
});

export function PersonalNew() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [optionsRequisition, setOptionsRequisition] = useState<any>({
    job_role: [],
    job_role_group: [],
  });
  const [currentPosition, setCurrentPosition] = useState<any>({});
  const params = new URLSearchParams(window.location.search);
  const position = params.get("position");

  const formik = useFormik({
    initialValues: {
      job_role_id: "",
      job_role_group_id: "",
      position_id: "",
      salary: "",
      initial_date: null,
      observations: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/personnel_requisitions")
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/request/personal");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/personnel_requisitions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptionsRequisition(response.data.data.options);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (position) {
      PostInstante(null, null, `/positions/${position}/current_position`)
        .then((res) => {
          formik.setFieldValue("position_id", res.data.id);
          formik.setFieldValue("job_role_id", res.data.job_role_id);
          formik.setFieldValue("salary", res.data.salary);
          setCurrentPosition(res.data);
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  useEffect(() => {
    GetInstance({}, "/positions/status_by_company")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions(response.data);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/personal'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/personal'>
              Requisição de pessoal
            </Link>
            <Typography fontWeight={800}>Nova solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar requisição de pessoal
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              {position ? (
                <TextField
                  id='position_id'
                  fullWidth
                  label='Posição'
                  value={`${currentPosition.code} ${currentPosition.job_role}`}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.position_id &&
                    Boolean(formik.errors.position_id)
                  }
                  helperText={
                    formik.touched.position_id && formik.errors.position_id
                  }
                />
              ) : (
                <TextField
                  select
                  fullWidth
                  id='position_id'
                  label='Posição'
                  value={formik.values.position_id}
                  onChange={(event: any) => {
                    const value: any = options.find(
                      (p: any) => p.id === event.target.value
                    );
                    formik.setFieldValue("position_id", event.target.value);
                    formik.setFieldValue("job_role_id", value.job_role_id);
                    formik.setFieldValue("salary", value.salary);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.position_id &&
                    Boolean(formik.errors.position_id)
                  }
                  helperText={
                    formik.touched.position_id && formik.errors.position_id
                  }>
                  {options?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.id === formik.values.position_id ? (
                        <Typography>
                          {option.code} {option.job_role}
                        </Typography>
                      ) : (
                        <Grid>
                          <Typography>
                            {option.code} {option.job_role}
                          </Typography>
                        </Grid>
                      )}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                fullWidth
                id='job_role_id'
                select
                label='Cargo'
                value={formik.values?.job_role_id}
                disabled
                onChange={(event: any) =>
                  formik.setFieldValue("job_role_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.job_role_id &&
                  Boolean(formik.errors.job_role_id)
                }
                helperText={
                  formik.touched.job_role_id && formik.errors.job_role_id
                }>
                {optionsRequisition?.job_role.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "initial_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.initial_date &&
                      Boolean(formik.errors.initial_date),
                    helperText:
                      formik.touched.initial_date && formik.errors.initial_date,
                  },
                }}
                disabled={false}
                label='Data prevista de início'
                value={formik.values.initial_date}
                onChange={(value: any) => {
                  formik.setFieldValue("initial_date", value);
                }}
              />
              <TextField
                fullWidth
                id='salary'
                label='Remuneração'
                variant='outlined'
                value={formik.values.salary}
                onChange={(event: any) =>
                  formik.setFieldValue("salary", FormatCurrency(event))
                }
                onBlur={formik.handleBlur}
                error={formik.touched.salary && Boolean(formik.errors.salary)}
                helperText={formik.touched.salary && formik.errors.salary}
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Responsabilidades'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                fullWidth={isMobile}
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}>
                Cadastrar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
