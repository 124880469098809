/** @format */

import { useSelector } from "react-redux";

export function IsPermissions({ keyPrivate, children }: any) {
  const permissions = useSelector((state: any) => state.user.permissions);
  if (permissions !== null && permissions.indexOf(keyPrivate) === -1) {
    return null;
  }
  return children;
}
