/** @format */

import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

export function PermissionCheck({ keyPrivate, children, mobile }: any) {
  const permissions = useSelector((state: any) => state.user.permissions);
  if (permissions !== null && permissions.indexOf(keyPrivate) === -1) {
    window.location.href = "/notPermission";
  }
  if (isMobile && mobile) {
    window.location.href = "/notMobile";
  }
  return children;
}
