/**
 * eslint-disable @typescript-eslint/no-unused-expressions
 *
 * @format
 */

/* eslint-disable no-sequences */
import { createSlice } from "@reduxjs/toolkit";

export const initial: any = {
  data: { delegation_data: { delegation_options: [] } },
  permissions: null,
};
export const loadingSlice = createSlice({
  name: "user",
  initialState: initial,
  reducers: {
    updateUser: (state, action) => {
      state.data = action?.payload;
    },
    updateUserPermissions: (state, action) => {
      state.permissions = action?.payload ?? null;
    },
    updateUserDelegation: (state, action) => {
      state.delegation_data = action?.payload ?? { delegation_options: [] };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser, updateUserPermissions, updateUserDelegation } =
  loadingSlice.actions;

export default loadingSlice.reducer;
