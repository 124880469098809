/** @format */

import { MoreVert } from "@mui/icons-material";
import {
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { DropdownMenu } from "@radix-ui/themes";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { optionsTable } from "../../../../config/constants";
import { IsPermissions } from "../../../../config/isPermission";
import { FilterEmpty } from "../../../../helpers/emptyData";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";

const columns = [
  { name: "identification", label: "Matrícula" },
  { name: "name", label: "Nome" },
  { name: "cpf", label: "CPF" },
  { name: "job_role_name", label: "Cargo" },
  { name: "manager_name", label: "Gestor" },
  { name: "department_name", label: "Departamento" },
  { name: "status", label: "Status" },
];

let timerSearch: any;

export function Inactive() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      {
        page: page + 1,
        per_page: perPage,
        search,
        order_by: order,
        status: "inactive",
      },
      "users"
    )
      .then((response: any) => {
        setData((old: any) =>
          page === 0
            ? FilterEmpty(response.data.data.data.data)
            : [...old, ...FilterEmpty(response.data.data.data.data)]
        );
        setTotal(response.data.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);
  return (
    <Grid container gap={2}>
      <Grid container>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography fontWeight={400}>Colaboradores</Typography>
          <Typography fontWeight={800}>Desligados</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "AÇÕES",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <>
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <Tooltip title={"Ver ações"}>
                            <IconButton>
                              <MoreVert />
                            </IconButton>
                          </Tooltip>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                          <IsPermissions keyPrivate='requestUpdateInformationRequest'>
                            <DropdownMenu.Item
                              onClick={() =>
                                router(
                                  `/request/updateInformation/request/${data[dataIndex].uuid}`
                                )
                              }
                              shortcut='->'>
                              Informações
                            </DropdownMenu.Item>
                          </IsPermissions>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome ou CPF",
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
