export function validationCPF(cpf: any) {
  // when cpf is not required but need validation
  if (!cpf || cpf.length <= 0) return true;

  const cpfNumber = cpf.replace(/[^\d]+/g, '')

  if (cpfNumber.length < 11) return false;

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    const currentNumber = parseInt(cpfNumber.substring(i, i + 1));
    sum = sum + (currentNumber * (10 - i));
  }

  // validate first digit
  if (parseInt(cpfNumber.substring(9, 10)) !== ((sum * 10) % 11)) return false;

  sum = 0

  for (let i = 0; i < 10; i++) {
    const currentNumber = parseInt(cpfNumber.substring(i, i + 1));
    sum = sum + (currentNumber * (11 - i));
  }

  // validate second digit
  if (parseInt(cpfNumber.substring(10, 11)) !== ((sum * 10) % 11)) return false;
  return true;
}