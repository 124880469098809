/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { AddCircleOutline, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { IsPermissions } from "../../../../config/isPermission";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";
import { Personal } from "./personal";
import { validationCPF } from "../../../../helpers/validationCPF";

const validationSchema = yup.object({
  username: yup.string().nullable().required("Campo é obrigatório"),
  name: yup.string().nullable().required("Campo é obrigatório"),
  cpf: yup.string().nullable().required("Campo é obrigatório").test('invalid-cpf','CPF inválido!',(cpf) => validationCPF(cpf)),
  birth_date: yup.string().nullable().required("Campo é obrigatório"),
  father_cpf: yup.string().nullable().test('invalid-cpf','CPF inválido!',(cpf) => validationCPF(cpf)),
  mother_cpf: yup.string().nullable().test('invalid-cpf','CPF inválido!',(cpf) => validationCPF(cpf)),
});
export function EmployeeNew() {
  const router = useNavigate();
  const [positionId, setPositionId] = useState<any>([]);
  const [data, setData]: any = useState({
    options: { personnel_requisition: [] },
    fields_with_required_documents: [],
  });
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.common.loading);
  const formik = useFormik({
    initialValues: { type_of_disability: [], position_id: "" },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      if (!values.is_disability) {
        delete values.type_of_disability;
      }
      delete values.personnel_requisition;
      PostInstante(null, values, "/users")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/employee/pending");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useLayoutEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/users_form")
      .then((response: any) => {
        dispatch(updateLoading(false));
        setData({
          ...response.data.data,
          options: {
            ...response.data.data.options,
          },
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });

      GetInstance({}, '/users/positions_by_company').then((res) => {
        setPositionId(res.data)
      }).catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/employee/pending'>
              Pendentes
            </Link>
            <Typography fontWeight={800}>Novo colaborador</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Instruções
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                <Typography fontSize={14} fontWeight={400}>
                  Realize o cadastro de novos colaboradores.
                </Typography>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Cadastrar novo colaborador
              </Typography>
            </Grid>
            {!loading && (
              <>
                <Grid>
                  <Typography fontSize={16} fontWeight={500}>
                    Solicitação
                  </Typography>
                </Grid>
                <Grid
                  gridTemplateColumns={"1fr "}
                  mt={2}
                  display={"grid"}
                  gap={2}>
                  <TextField
                    fullWidth
                    id='position_id'
                    name='position_id'
                    select
                    label='Posição'
                    value={formik.values.position_id}
                    disabled={!positionId.length}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "position_id",
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.position_id &&
                      Boolean(formik.errors.position_id)
                    }
                    helperText={
                      formik.touched.position_id &&
                      Boolean(formik.errors.position_id)
                    }>
                    {positionId.map((option: any) => (
                      <MenuItem key={option.value} value={option.id}>
                        {option.code} {option.job_role}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Personal formik={formik} data={data} />
                <Grid
                  gridTemplateColumns={"1fr"}
                  mt={2}
                  display={"grid"}
                  gap={2}>
                  <Grid container gap={2} justifyContent={"end"}>
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        window.history.back();
                      }}
                      endIcon={<Undo />}>
                      Voltar
                    </Button>
                    <IsPermissions keyPrivate={"employeeNew"}>
                      <Button
                        variant={"contained"}
                        type={"submit"}
                        endIcon={<AddCircleOutline />}>
                        Criar usuário
                      </Button>
                    </IsPermissions>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
