/** @format */

import { Info, MoveUp } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { optionsTable } from "../../../config/constants";
import { FilterEmpty } from "../../../helpers/emptyData";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../../services/api";

const columns = [
  { name: "delegated_user_name", label: "Delegado" },
  { name: "target_user_name", label: "Atribuído a" },
  { name: "created_by_name", label: "Criado por" },
  { name: "initial_date", label: "Data início" },
  { name: "final_date", label: "Data fim" },
  { name: "active", label: "Ativo" },
];

let timerSearch: any;

export function Delegation() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      { page: page + 1, per_page: perPage, search, order_by: order },
      "delegations"
    )
      .then((response: any) => {
        setData((old: any) =>
          page === 0
            ? FilterEmpty(response.data.data.data)
            : [...old, ...FilterEmpty(response.data.data.data)]
        );
        setTotal(response.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);

  return (
    <Grid container gap={2}>
      <Grid container gap={2} justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography fontWeight={800}>Delegações</Typography>
        </Breadcrumbs>
        <Button
          variant={"contained"}
          onClick={() => {
            router("/delegation/new");
          }}
          fullWidth={isMobile}
          endIcon={<MoveUp />}>
          Delegar colaborador
        </Button>
      </Grid>
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Tooltip title={"Ver informações"}>
                      <IconButton
                        onClick={() =>
                          router(`/delegation/details/${data[dataIndex].uuid}`)
                        }>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: false,
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
