/** @format */

import { Send } from "@mui/icons-material";
import {
  Button,
  Grid,
  Hidden,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ModeTheme } from "../../../config/mode";
import { handleCPFChangeReturn } from "../../../helpers/handleCPFChange";
import { updateLoading } from "../../../redux/reducers/common";
import { PostInstante } from "../../../services/api";

const validationSchemaUsername = yup.object({
  email: yup.string().required("Email é obrigatório"),
});

const validationSchemaCPF = yup.object({
  cpf: yup.string().min(14, "CPF inválido").required("CPF é obrigatório"),
});

export function ForgetPasswordStandardPage() {
  const company = useSelector((state: any) => state.company);
  const [type, setType] = useState("email");
  const router = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues:
      type === "email"
        ? {
            email: "",
          }
        : { cpf: "" },
    validationSchema:
      type === "email" ? validationSchemaUsername : validationSchemaCPF,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        type === "email"
          ? { username: values.email }
          : { username: values.cpf },
        "/password/generate_default_password"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/successPassword");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={"100vh"}
        padding={10}>
        <Grid style={{ position: "absolute", top: 10, right: 10 }}>
          <ModeTheme />
        </Grid>
        <Grid item xs={12} lg={6} md={4}>
          <Grid container justifyContent={"center"}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              maxWidth={500}
              gap={2}>
              <img {...company.logoDefault} alt='Logo da empresa' />
              <div>
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  fontSize={"2.4rem"}>
                  Redefinição de senha
                </Typography>
                <Typography textAlign={"center"} color={"grey"}>
                  Bem vindo a seção de recuperação de senha por email para o
                  padrão do nosso sistema de gestão de RH. Digite o seu email ou
                  <Link
                    style={{ cursor: "pointer", fontWeight: 800 }}
                    underline={"hover"}
                    onClick={() => {
                      formik.resetForm();
                      setType(type === "email" ? "cpf" : "email");
                    }}>
                    {" "}
                    recupere por {type === "cpf" ? "usuário" : "CPF"}
                  </Link>
                </Typography>
              </div>
              {type === "email" ? (
                <TextField
                  fullWidth
                  key={"email"}
                  id={"email"}
                  label={"Email"}
                  variant='outlined'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              ) : (
                <TextField
                  fullWidth
                  key={"cpf"}
                  id={"cpf"}
                  placeholder={"000.000.000-00"}
                  inputProps={{
                    maxLength: 14,
                  }}
                  label={"CPF"}
                  variant='outlined'
                  value={formik.values.cpf}
                  onChange={(event: any) => {
                    formik.setFieldValue(`cpf`, handleCPFChangeReturn(event));
                  }}
                  onBlur={(event: any) => {
                    formik.setFieldValue(`cpf`, handleCPFChangeReturn(event));
                  }}
                  error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                  helperText={formik.touched.cpf && formik.errors.cpf}
                />
              )}
              <Button
                type={"submit"}
                fullWidth
                variant='contained'
                endIcon={<Send />}>
                Enviar
              </Button>
              <Link
                style={{ cursor: "pointer" }}
                underline={"hover"}
                href={"/login"}>
                Voltar
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item lg={6} md={8}>
            <Grid container justifyContent={"center"}>
              <img
                src={company.image}
                style={{
                  objectFit: "contain",
                  objectPosition: "center",
                  height: "auto",
                  borderRadius: 20,
                  width: "80%",
                  maxHeight: 700,
                }}
                width={0}
                height={0}
                sizes='100vw'
                alt='Imagem da empresa'
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </form>
  );
}
