/** @format */

import { Cancel, CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import animationData from "../assets/lotties/loading_modal.json";

export function ActionConfirm({
  keyPrivate,
  id,
  url,
  open,
  onClose,
  onConfirm,
  title,
}: any) {
  const loading = useSelector((state: any) => state.common.loading);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Content maxWidth='450px'>
        {loading ? (
          <Lottie options={defaultOptions} height={"auto"} width={200} />
        ) : (
          <>
            <AlertDialog.Title>{title}</AlertDialog.Title>
            <Flex gap='3' mt='4' justify='end'>
              <AlertDialog.Cancel onClick={onClose}>
                <Button variant={"outlined"} endIcon={<Cancel />}>
                  Cancelar
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <Button
                  variant={"contained"}
                  onClick={onConfirm}
                  endIcon={<CheckCircle />}>
                  Confirmar
                </Button>
              </AlertDialog.Action>
            </Flex>
          </>
        )}
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}
