/**
 * eslint-disable @typescript-eslint/no-unused-expressions
 *
 * @format
 */

/* eslint-disable no-sequences */
import { createSlice } from "@reduxjs/toolkit";

export const initial: any = {
  loading: false,
  notifications: [],
  totalNotifications: 0,
};
export const loadingSlice = createSlice({
  name: "common",
  initialState: initial,
  reducers: {
    updateLoading: (state, action) => {
      state.loading = action?.payload ?? initial.loading;
    },
    udateNotification: (state, action) => {
      state.notifications =
        action?.payload.notifications ?? initial.notifications;
      state.totalNotifications =
        action?.payload.totalNotifications ?? initial.totalNotifications;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateLoading, udateNotification } = loadingSlice.actions;

export default loadingSlice.reducer;
