/** @format */

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import { BrowserView, MobileView } from "react-device-detect";
import { useSelector } from "react-redux";
import { drawerWidth } from "../constants";
import MenuList from "./menuList";

const Sidebar = ({ drawerOpen, drawerToggle, window }: any) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const company = useSelector((state: any) => state.company);

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box
          sx={{ display: "flex", p: 2, mx: "auto", justifyContent: "center" }}>
          <img {...company.logoSmall} alt='Logo da empresa' />
        </Box>
      </Box>
      <BrowserView>
        <div
          style={{
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}>
          <MenuList />
        </div>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component='nav'
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label='mailbox folders'>
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor='left'
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "88px",
              height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color='inherit'>
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
