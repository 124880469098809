/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";
import { ConfigSelection } from "../../../../components/configSelection";


export function PermissionsLeave() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);
  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            dispatch(updateLoading(false));
            setOptions(response2.data.data);
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(
                response.data.data.record.user_update_request_rules
              ).length > 0
            ) {
              if (
                response.data.data.record.user_update_request_rules.approvers
              ) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.user_update_request_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.user_update_request_rules
                  .approvers                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit() {
    dispatch(updateLoading(true));
    PutInstante(
      null,
      {
        user_update_request_rules: {
          approvers: rightApprovers,
        },
      },
      "/company_settings"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  }

  return (
    <form>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ cursor: "pointer" }}
              color="inherit"
              href="/"
            >
              Inicio
            </Link>
            <Link
              underline="hover"
              sx={{ cursor: "pointer" }}
              color="inherit"
              href="/settings/leave"
            >
              Configurações
            </Link>
            <Link
              underline="hover"
              sx={{ cursor: "pointer" }}
              color="inherit"
              href="/settings/leave"
            >
              Afastamento
            </Link>
            <Typography fontWeight={800}>Ver detalhes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size="2" style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Nesta tela é permitido a configuração de usuários com permissão
                aos fluxos de afastamento.
              </Typography>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
              <Typography fontSize={16} fontWeight={800} mt={1}>
                Esse procedimento afeta as ações: VER DETALHES, APROVAR E
                REPROVAR.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel="Usuários sem permissão:"
          rightLabel="Usuários com permissão:"
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />

        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <Button
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}
          >
            Voltar
          </Button>
          <Button variant={"contained"} onClick={onSubmit} endIcon={<Edit />}>
            Salvar modificações
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
