/** @format */

import {
  Cancel,
  CheckCircle,
  Download,
  Edit,
  InfoOutlined,
  Send,
  Undo,
} from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout, Flex } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionAccountingReturned } from "../../../../../components/actionAccountingReturned";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionReturned } from "../../../../../components/actionReturned";
import { aviso_e_social } from "../../../../../config/constants";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstante,
  PutInstante,
  PutInstanteFormData,
} from "../../../../../services/api";

const validationSchema = yup.object({
  dismissal_date: yup
    .date()
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .nullable()
    .required("Data de saída é obrigatório"),
  early_warning: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_type: yup.string().nullable().required("Tipo é obrigatório"),
  dismissal_reason: yup.string().nullable().required("Motivo é obrigatório"),
  files: yup
    .array()
    .min(1, "Precisa ter ao menos 1 anexo")
    .nullable()
    .required("Arquivo é obrigatório"),
});

export function DismissalsDetails() {
  const [openModalAproved, setOpenModalAproved] = useState(false);
  const [valueSelectModal, setValueSeletModal] = useState(false);

  const router = useNavigate();
  // const fileInputRef: any = useRef(null);
  const { id } = useParams();
  const theme = useTheme();

  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
  });
  const permissions = useSelector((state: any) => state.user.permissions);
  const user = useSelector((state: any) => state.user.data);
  const edit =
    permissions !== null &&
    permissions.indexOf("requestDismissalsDetailsEdit") !== -1 &&
    data?.solicitation_data.status === "waiting_rh_user" &&
    user.role === "rh";
  const formik: any = useFormik({
    initialValues: {
      dismissal_date: null,
      early_warning: "",
      dismissal_type: "",
      dismissal_reason: "",
      files: [],
      dismissal_type_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(null, { ...values }, "/dismissals")
        .then((response: any) => {
          const formData = new FormData();
          // eslint-disable-next-line array-callback-return
          formik.values.files.map((file: any) => {
            formData.append("file[]", file);
          });
          PutInstanteFormData(
            null,
            formData,
            "/solicitations/" +
              response.data.data.solicitation_uuid +
              "/upload_files"
          )
            .then(() => {
              dispatch(updateLoading(false));
              router("/request/dismissals");
              toast.success(response?.data?.message);
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                dispatch(updateLoading(false));
                toast.error(
                  e?.response?.data?.message ??
                    "Erro inesperado, tente novamente."
                );
              }
            });
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/dismissals/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const formData = response.data.data;
        setData(formData);
        formik.setValues({
          dismissal_date: dayjs(formData.record.dismissal_date),
          early_warning: formData.record.early_warning,
          dismissal_type: formData.record.dismissal_type,
          dismissal_reason: formData.record.dismissal_reason,
          files: formData.solicitation_files ?? [],
          dismissal_type_name: formData.record.dismissal_type_name,
          dismissal_reason_name: formData.record.dismissal_reason_name,
          comment: formData.record.comment,
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.collaborator_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.collaborator_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_data.job_role_name),
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_data.status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  // const onFileDrop = (file: any) => {
  //   if (edit) {
  //     const filesAll: any = formik.values.files;
  //     filesAll.push(file);
  //     formik.setFieldValue("files", filesAll);
  //   }
  // };

  // const openFileDialog = () => {
  //   fileInputRef.current.click();
  // };

  // const handleDrop = (e: any) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   const file = e.dataTransfer.files[0];
  //   if (file) {
  //     onFileDrop(file);
  //   }
  // };

  // const handleFileInputChange = (e: any) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     onFileDrop(file);
  //   }
  // };

  // const handleDragOver: any = (e: any) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   e.dataTransfer.dropEffect = "copy";
  // };

  const renderActions = () => {
    if (
      data?.solicitation_data.status === "waiting_rh_user" &&
      user.role === "rh"
    ) {
      return true;
    } else if (
      data?.solicitation_data.status === "waiting_manager_user" &&
      user.role === "manager"
    ) {
      return true;
    }
    return false;
  };

  const aprove = () => {
    dispatch(updateLoading(true));
    PostInstante(
      null,
      {
        action_type: "approved",
        action_justification: null,
        position_delete: valueSelectModal,
      },
      "/dismissals/" + id + "/action"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setOpenModalAproved(false);
        setTimeout(() => {
          window.history.back();
        }, 3000);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          setOpenModalAproved(false);
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/dismissals'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/dismissals'>
              Desligamento
            </Link>
            <Typography fontWeight={800}>Ver solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações do colaborador
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {userInfo.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Instruções
                </Typography>
                <Typography fontSize={12} fontWeight={400}>
                  Para solicitar o desligamento digite todos os dados abaixo,
                  após anexe a carta demissional.
                </Typography>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes do desligamento
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "dismissal_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.dismissal_date &&
                      Boolean(formik.errors.dismissal_date),
                    helperText:
                      formik.touched.dismissal_date &&
                      formik.errors.dismissal_date,
                  },
                }}
                disabled={!edit}
                label='Data de saída'
                value={formik.values.dismissal_date}
                onChange={(value: any) => {
                  formik.setFieldValue("dismissal_date", value);
                }}
              />
              <TextField
                fullWidth
                id='early_warning'
                select
                label='Vai cumprir aviso prévio'
                value={formik.values.early_warning}
                disabled={!edit}
                onChange={(event: any) =>
                  formik.setFieldValue("early_warning", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.early_warning &&
                  Boolean(formik.errors.early_warning)
                }
                helperText={
                  formik.touched.early_warning && formik.errors.early_warning
                }>
                {aviso_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              {/* <TextField
                fullWidth
                id="dismissal_type_name"
                select
                label="Tipo de demissão"
                value={formik.values.dismissal_type_name}
                disabled={!edit}
                onChange={(event: any) =>
                  formik.setFieldValue("dismissal_type_name", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.dismissal_type_name &&
                  Boolean(formik.errors.dismissal_type_name)
                }
                helperText={
                  formik.touched.dismissal_type_name && formik.errors.dismissal_type_name
                }
              >
                {aviso_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField> */}
              <TextField
                fullWidth
                id='dismissal_type_name'
                label='Tipo de demissão'
                value={formik.values.dismissal_type_name}
                disabled
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              {/* <TextField
                fullWidth
                id="dismissal_reason_name"
                select
                label="Motivo de demissão"
                value={formik.values.dismissal_reason_name}
                disabled={!edit}
                onChange={(event: any) =>
                  formik.setFieldValue("dismissal_reason_name", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.dismissal_reason_name &&
                  Boolean(formik.errors.dismissal_reason_name)
                }
                helperText={
                  formik.touched.dismissal_reason_name && formik.errors.dismissal_reason_name
                }
              >
                {aviso_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField> */}
              <TextField
                fullWidth
                id='dismissal_reason_name'
                label='Motivo de demissão'
                value={formik.values.dismissal_reason_name}
                disabled
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='comment'
                  label='Comentários'
                  variant='outlined'
                  multiline={true}
                  disabled={!edit}
                  rows={4}
                  value={formik.values.comment}
                  onChange={(event: any) =>
                    formik.setFieldValue("comment", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.comment && Boolean(formik.errors.comment)
                  }
                  helperText={formik.touched.comment && formik.errors.comment}
                />
              </div>
            </Grid>
            {formik.values.files.length > 0 && (
              <Grid container mt={2}>
                <Typography>Arquivos anexados</Typography>
              </Grid>
            )}
            <Grid container mt={2} gap={2}>
              {formik.values.files.map((file: any, index: number) => (
                <Tooltip title={"Baixar arquivo"}>
                  <a
                    title={file.name}
                    href={file.url}
                    target='_blank'
                    style={{
                      border: theme.palette.primary.main + " 1px solid",
                      color: theme.palette.primary.main,
                      padding: 8,
                      fontSize: 12,
                      borderRadius: 24,
                      fontWeight: 800,
                      textDecorationLine: "none",
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                    }}
                    download={file.name}
                    rel='noreferrer'>
                    <Download fontSize={"small"} />
                    {file.name}
                  </a>
                </Tooltip>
              ))}
              {formik.values.files.length === 0 && (
                <Typography>Não existem arquivos anexados</Typography>
              )}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </Button>
              {renderActions() && (
                <>
                  {edit && (
                    <Button
                      variant={"contained"}
                      type={"submit"}
                      endIcon={<Edit />}>
                      Editar
                    </Button>
                  )}
                  <ActionReturned
                    keyPrivate={"requestDismissalsDetailsDisapprove"}
                    id={id}
                    url={"dismissals"}
                  />
                  <ActionDisapproved
                    keyPrivate={"requestDismissalsDetailsGiveBack"}
                    id={id}
                    url={"dismissals"}
                  />
                  {user.role === "rh" ? (
                    <Button
                      variant={"contained"}
                      onClick={() => setOpenModalAproved(true)}
                      color={"success"}
                      endIcon={<Send />}>
                      Aprovar
                    </Button>
                  ) : (
                    <ActionAproved
                      keyPrivate={"requestDismissalsDetailsApprove"}
                      id={id}
                      url={"dismissals"}
                    />
                  )}
                </>
              )}

              {user.role === "accountant" &&
                data?.solicitation_data.status === "approved" && (
                  <>
                    <ActionCompleted
                      keyPrivate={"requestDetailsComplete"}
                      id={id}
                      url={"processes"}
                    />

                    <ActionAccountingReturned
                      keyPrivate={"requestDetailsComplete"}
                      id={id}
                      url={"processes"}
                    />
                  </>
                )}
            </Grid>
          </Paper>
        </Grid>
        <Dialog open={openModalAproved}>
          <DialogContent>
            <>
              <DialogTitle>
                Deseja realmente aprovar o desligamento? Por favor, escolha o
                destino da posição do colaborador:
              </DialogTitle>
              <Grid marginBottom={2}>
                <TextField
                  fullWidth
                  id='position_delete'
                  select
                  label='Vai manter a posição?'
                  value={valueSelectModal}
                  onChange={(event: any) =>
                    setValueSeletModal(event.target.value)
                  }>
                  {aviso_e_social.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Flex gap='2' mt='4' justify='end'>
                <Button
                  variant={"outlined"}
                  fullWidth={isMobile}
                  onClick={() => setOpenModalAproved(false)}
                  endIcon={<Cancel />}>
                  Cancelar
                </Button>
                <Button
                  sx={{ marginLeft: 2 }}
                  variant={"contained"}
                  onClick={() => {
                    aprove();
                  }}
                  fullWidth={isMobile}
                  endIcon={<CheckCircle />}>
                  Confirmar
                </Button>
              </Flex>
            </>
          </DialogContent>
        </Dialog>
      </Grid>
    </form>
  );
}
