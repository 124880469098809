/** @format */

import {
  AddCircle,
  Cancel,
  CheckCircle,
  InfoOutlined,
  Undo,
  UploadFile,
} from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout, Flex } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { aviso_e_social } from "../../../../../config/constants";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstante,
  PostInstanteFormData,
  RemoveInstance,
} from "../../../../../services/api";

const validationSchema = yup.object({
  dismissal_date: yup
    .string()
    .nullable()
    .required("Data de saída é obrigatório"),
  early_warning: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_type: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_reason: yup.string().nullable().required("Campo é obrigatório"),
  comment: yup
    .string()
    .nullable()
    .test({
      name: "dismissal_reason",
      message: "Campo é obrigatório",
      test: (_value, context) => {
        const valueField = context.options?.context?.dismissal_reason;
        return valueField;
      },
    }),
  files: yup
    .array()
    .min(1, "Precisa ter ao menos 1 anexo")
    .nullable()
    .required("Arquivo é obrigatório"),
});

const validationSchema2 = yup.object({
  dismissal_date: yup
    .string()
    .nullable()
    .required("Data de saída é obrigatório"),
  early_warning: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_type: yup.string().nullable().required("Campo é obrigatório"),
  dismissal_reason: yup.string().nullable().required("Campo é obrigatório"),
  comment: yup
    .string()
    .nullable()
    .test({
      name: "dismissal_reason",
      message: "Campo é obrigatório",
      test: (_value, context) => {
        const valueField = context.options?.context?.dismissal_reason;
        return valueField;
      },
    }),
});

export function DismissalsNew() {
  const fileInputRef: any = useRef(null);
  const router = useNavigate();
  const { id } = useParams();
  const [valide, setValide] = useState(false);
  const [user, setUser]: any = useState({});
  const [dismissalTypes, setDismissalTypes] = useState([]);
  const [dismissal, setDismissals] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPosition, setOpenModalPosition] = useState(false);
  const userApp = useSelector((state: any) => state.user.data);

  const onSubmit = (values: any) => {
    dispatch(updateLoading(true));
    PostInstante(null, { ...values, collaborator_id: id }, "/dismissals")
      .then((response: any) => {
        const formData = new FormData();
        // eslint-disable-next-line array-callback-return
        formik.values.files.map((file: any) => {
          formData.append("files[]", file);
        });
        PostInstanteFormData(
          null,
          formData,
          "/solicitations/" +
            response.data.data.solicitation_uuid +
            "/upload_files"
        )
          .then(() => {
            dispatch(updateLoading(false));
            toast.success(response?.data?.message);
            router("/request/dismissals");
            setOpenModal(false);
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              RemoveInstance(id, "/dismissals")
                .then(() => {
                  dispatch(updateLoading(false));
                  router("/request/dismissals");
                  setOpenModal(false);
                })
                .catch((e: any) => {
                  if (e.code !== "ERR_CANCELED") {
                    dispatch(updateLoading(false));
                    toast.error(
                      e?.response?.data?.message ??
                        "Erro inesperado, tente novamente."
                    );
                  }
                });
            }
          });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  };

  const theme = useTheme();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      dismissal_date: null,
      early_warning: "",
      comment: "",
      dismissal_reason: "",
      dismissal_type: "",
      files: [],
      position_delete: "",
    },
    validationSchema:
      userApp.role === "collaborator" ? validationSchema : validationSchema2,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values) => {
      setValide(true);
      if (
        userApp.role === "rh" &&
        user.business_unit_job_security_date &&
        new Date(user.business_unit_job_security_date) >= new Date()
      ) {
        setOpenModal(true);
        return;
      } else if (userApp.role === "rh") {
        setOpenModalPosition(true);
        return;
      } else {
        onSubmit(values);
      }
    },
  });
  const onFileDrop = (file: any) => {
    const filesAll: any = formik.values.files;
    filesAll.push(file);
    formik.setFieldValue("files", filesAll);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleDragOver: any = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  const userInfo = [
    {
      title: "Nome",
      value: user.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(user.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(user.job_role_name),
    },
    {
      title: "Status",
      value: FormatStatus(user.status),
    },
  ];

  useEffect(() => {
    GetInstance({}, "/dismissal_types")
      .then((response) => {
        dispatch(updateLoading(false));
        if (user?.user_role === "rh" || user?.user_role === "manager") {
          const array = response.data.group_0.filter((d: any) => d.associated);
          setDismissals(array);
        } else {
          const array = response.data.group_1.filter((d: any) => d.associated);
          setDismissals(array);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    GetInstance({}, "/dismissal_reasons")
      .then((response) => {
        dispatch(updateLoading(false));
        if (user?.user_role === "rh" || user?.user_role === "manager") {
          const array = response.data.group_0.filter((d: any) => d.associated);
          setDismissalTypes(array);
        } else {
          const array = response.data.group_1.filter((d: any) => d.associated);
          setDismissalTypes(array);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetInstance({}, "/dismissals_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser(response.data.data.user_data);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/dismissals'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/dismissals'>
              Desligamento
            </Link>
            <Typography fontWeight={800}>Nova solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Dialog open={openModalPosition}>
            <DialogContent>
              <>
                <DialogTitle>
                  Deseja realmente aprovar o desligamento? Por favor, escolha o
                  destino da posição do colaborador:
                </DialogTitle>
                <Grid marginBottom={2}>
                  <TextField
                    fullWidth
                    id='position_delete'
                    select
                    label='Vai manter a posição?'
                    value={formik.values.position_delete}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "position_delete",
                        event.target.value
                      )
                    }>
                    {aviso_e_social.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Flex gap='2' mt='4' justify='end'>
                  <Button
                    variant={"outlined"}
                    onClick={() => setOpenModalPosition(false)}
                    endIcon={<Cancel />}>
                    Cancelar
                  </Button>
                  <Button
                    sx={{ marginLeft: 2 }}
                    variant={"contained"}
                    onClick={() => onSubmit(formik.values)}
                    endIcon={<CheckCircle />}>
                    Confirmar
                  </Button>
                </Flex>
              </>
            </DialogContent>
          </Dialog>
          <Dialog
            open={openModal}
            keepMounted
            onClose={() => setOpenModal(false)}
            aria-describedby='alert-dialog'>
            <DialogTitle color='InfoText'>
              Em periodo de estabilidade
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                gap={1}
                color='InfoText'
                display='flex'
                flexDirection='column'
                id='alert-dialog-slide-description'>
                <Typography variant='subtitle1'>
                  Se deseja desligar, clique no botão abaixo para confirmar sua
                  escolha{" "}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
              }}>
              <Button
                variant='outlined'
                fullWidth
                sx={{ maxWidth: "200px", alignSelf: "center" }}
                onClick={() => setOpenModal(false)}>
                Cancelar
              </Button>
              <Button
                type='submit'
                variant='contained'
                fullWidth
                sx={{ maxWidth: "200px", alignSelf: "center" }}
                onClick={() => onSubmit(formik.values)}>
                Desligar
              </Button>
            </DialogActions>
          </Dialog>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid>
                    <Typography fontSize={12} fontWeight={400}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={300}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Instruções
                </Typography>
                <Typography fontSize={12} fontWeight={400}>
                  Para solicitar o desligamento digite todos os dados abaixo,
                  após anexe a carta demissional.
                </Typography>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar desligamento
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "dismissal_date",
                    onBlur: async (event) => {
                      formik.handleBlur(event);
                      if (event.target.value) {
                        const { data } = await PostInstante(
                          null,
                          { dismissal_date: event.target.value },
                          "/dismissals/validate_date"
                        );
                        if (data.message) {
                          formik.setErrors({
                            dismissal_date:
                              "A data de desligamento excede o limite permitido de D+1. Por favor, contate o departamento de RH para proceder com o desligamento retroativo.",
                          });
                        }
                      }
                    },
                    error: Boolean(formik.errors.dismissal_date),
                    helperText: formik.errors.dismissal_date,
                  },
                }}
                disabled={false}
                label='Data de saída'
                value={formik.values.dismissal_date}
                onChange={(value: any) => {
                  formik.validateField("dismissal_date");
                  formik.setFieldTouched("dismissal_date", true);
                  formik.setFieldValue("dismissal_date", value);
                }}
              />
              <TextField
                fullWidth
                id='early_warning'
                select
                label='Vai cumprir aviso prévio'
                value={formik.values.early_warning}
                onChange={(event: any) =>
                  formik.setFieldValue("early_warning", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.early_warning)}
                helperText={formik.errors.early_warning}>
                {aviso_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='dismissal_type'
                select
                label='Tipo de desligamento'
                value={formik.values.dismissal_type}
                onChange={(event: any) =>
                  formik.setFieldValue("dismissal_type", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.dismissal_type)}
                helperText={formik.errors.dismissal_type}>
                {dismissal?.map((option: any) => (
                  <MenuItem key={option} value={option.id}>
                    {option.id} - {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='dismissal_reason'
                select
                label='Motivo do desligamento'
                value={formik.values.dismissal_reason}
                onChange={(event: any) =>
                  formik.setFieldValue("dismissal_reason", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.dismissal_reason)}
                helperText={formik.errors.dismissal_reason}>
                {dismissalTypes.map((option: any) => (
                  <MenuItem key={option} value={option.id}>
                    {option.id} - {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='comment'
                  label='Comentários'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.comment}
                  onChange={(event: any) =>
                    formik.setFieldValue("comment", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.comment)}
                  helperText={formik.errors.comment}
                />
              </div>
            </Grid>
            {(userApp.role === "collaborator" || userApp.id === user.id) && (
              <>
                <Grid container mt={2}>
                  <div
                    style={{
                      border: `1px ${
                        formik.errors.files && formik.touched.files
                          ? theme.palette.error.main
                          : theme.palette.text.secondary
                      } dashed`,
                      color:
                        formik.errors.files && formik.touched.files
                          ? theme.palette.error.main
                          : theme.palette.text.secondary,
                      cursor: "pointer",
                      borderRadius: 10,
                      width: "inherit",
                    }}
                    className='ContextMenuTrigger'
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={openFileDialog}>
                    <input
                      type='file'
                      accept='*'
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                      style={{ display: "none" }}
                    />
                    <p style={{ display: "flex", justifyContent: "center" }}>
                      <UploadFile style={{ marginRight: 12 }} /> Arraste e solte
                      um arquivo aqui ou clique para selecionar um arquivo.
                    </p>
                  </div>
                </Grid>
                {formik.errors.files && formik.touched.files && (
                  <Grid container mt={0.5} ml={2}>
                    <Typography
                      fontSize={"0.75rem"}
                      textAlign={"center"}
                      color={"error"}>
                      {formik.errors.files}
                    </Typography>
                  </Grid>
                )}

                {formik.values.files.length > 0 && (
                  <Grid container mt={2}>
                    <Typography>Arquivos anexados</Typography>
                  </Grid>
                )}
                <Grid container mt={2} gap={2}>
                  {formik.values.files.map((file: any, index: number) => (
                    <Tooltip title={"Remover arquivo"}>
                      <Chip
                        color={"primary"}
                        onDelete={() => {
                          const filesAll: any = formik.values.files;
                          filesAll.splice(index, 1);
                          formik.setFieldValue("files", filesAll);
                        }}
                        label={file.name}
                        variant='outlined'
                      />
                    </Tooltip>
                  ))}
                </Grid>
              </>
            )}
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                fullWidth={isMobile}
                variant={"contained"}
                type='submit'
                endIcon={<AddCircle />}>
                Cadastrar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
