/** @format */

import { createTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

export function ThemeMui() {
  const mode: any = Cookies.get("theme") ?? "light";
  const company = useSelector((state: any) => state?.company);
  document.title = company.company;
  const link: any =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = company.icon;
  document.head.appendChild(link);
  return createTheme({
    palette: {
      mode: mode,
      primary: {
        main: company.primary,
      },
      secondary: {
        main: company.secondary,
      },
    },
    shape: {
      borderRadius: 12,
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
      },
      MuiInputLabel: {
        defaultProps: { shrink: true },
      },
      MuiOutlinedInput: {
        defaultProps: {
          notched: true,
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background:
              mode === "light"
                ? "white"
                : "color(display-p3 0.163 0.163 0.163)",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            cursor: "default",
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          paper: {
            width: "inherit",
          },
        },
      },
    },
  });
}
