/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  cnh_category_e_social,
  level_cnh_e_social,
} from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../config/hiddenCheck";

export function Documents({ formik, data }: any) {
  const [stateOptions, setStateOptions] = useState<any>([]);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        setStateOptions(response.data);
      })
      .catch(() => {
        toast.error(
          "Falha ao buscar os estados, por favor tente novamente mais tarde."
        );
      });
  }, []);

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid>
          <Typography fontSize={16} fontWeight={500}>
            RG - Registro geral
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='general_registry_number'
              label='Número'
              value={formik.values.general_registry_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.general_registry_number &&
                Boolean(formik.errors.general_registry_number)
              }
              helperText={
                formik.touched.general_registry_number &&
                formik.errors.general_registry_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='general_registry_issuing_authority'
              label='Orgão de expedição'
              value={formik.values.general_registry_issuing_authority}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.general_registry_issuing_authority &&
                Boolean(formik.errors.general_registry_issuing_authority)
              }
              helperText={
                formik.touched.general_registry_issuing_authority &&
                formik.errors.general_registry_issuing_authority
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='general_registry_federal_unit'
              name='general_registry_federal_unit'
              select
              label='Estado de expedição'
              value={formik.values.general_registry_federal_unit}
              onChange={(event: any) => {
                formik.setFieldValue(
                  "general_registry_federal_unit",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.general_registry_federal_unit &&
                Boolean(formik.errors.general_registry_federal_unit)
              }
              helperText={
                formik.touched.general_registry_federal_unit &&
                formik.errors.general_registry_federal_unit
              }>
              {stateOptions.map((option: any) => (
                <MenuItem key={option.sigla} value={option.sigla}>
                  {option.sigla} - {option.nome}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "general_registry_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.general_registry_date &&
                    Boolean(formik.errors.general_registry_date),
                  helperText:
                    formik.touched.general_registry_date &&
                    formik.errors.general_registry_date,
                },
              }}
              disabled={false}
              label='Data de expedição'
              value={formik.values.general_registry_date}
              onChange={(value: any) => {
                formik.setFieldValue("general_registry_date", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            RIC - Registro de identidade civil
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='civil_id_register_number'
              label='Número'
              value={formik.values.civil_id_register_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.civil_id_register_number &&
                Boolean(formik.errors.civil_id_register_number)
              }
              helperText={
                formik.touched.civil_id_register_number &&
                formik.errors.civil_id_register_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='civil_register_issuing_authority'
              label='Orgão de expedição'
              value={formik.values.civil_register_issuing_authority}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.civil_register_issuing_authority &&
                Boolean(formik.errors.civil_register_issuing_authority)
              }
              helperText={
                formik.touched.civil_register_issuing_authority &&
                formik.errors.civil_register_issuing_authority
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "civil_id_register_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.civil_id_register_date &&
                    Boolean(formik.errors.civil_id_register_date),
                  helperText:
                    formik.touched.civil_id_register_date &&
                    formik.errors.civil_id_register_date,
                },
              }}
              disabled={false}
              label='Data de expedição'
              value={formik.values.civil_id_register_date}
              onChange={(value: any) => {
                formik.setFieldValue("civil_id_register_date", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            RNE - Registro nacional de estrangeiros
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='national_foreigner_registry_number'
              label='Número'
              value={formik.values.national_foreigner_registry_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.national_foreigner_registry_number &&
                Boolean(formik.errors.national_foreigner_registry_number)
              }
              helperText={
                formik.touched.national_foreigner_registry_number &&
                formik.errors.national_foreigner_registry_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='national_foreigner_registry_issuing_authority'
              label='Orgão de expedição'
              value={
                formik.values.national_foreigner_registry_issuing_authority
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.national_foreigner_registry_issuing_authority &&
                Boolean(
                  formik.errors.national_foreigner_registry_issuing_authority
                )
              }
              helperText={
                formik.touched.national_foreigner_registry_issuing_authority &&
                formik.errors.national_foreigner_registry_issuing_authority
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "national_foreigner_registry_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.national_foreigner_registry_date &&
                    Boolean(formik.errors.national_foreigner_registry_date),
                  helperText:
                    formik.touched.national_foreigner_registry_date &&
                    formik.errors.national_foreigner_registry_date,
                },
              }}
              disabled={false}
              label='Data de expedição'
              value={formik.values.national_foreigner_registry_date}
              onChange={(value: any) => {
                formik.setFieldValue("national_foreigner_registry_date", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            Certificado de reservista
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='reservist_number'
              type='number'
              placeholder='0000000'
              label='Número'
              value={formik.values.reservist_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.reservist_number &&
                Boolean(formik.errors.reservist_number)
              }
              helperText={
                formik.touched.reservist_number &&
                formik.errors.reservist_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "reservist_date_of_issuance",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.reservist_date_of_issuance &&
                    Boolean(formik.errors.reservist_date_of_issuance),
                  helperText:
                    formik.touched.reservist_date_of_issuance &&
                    formik.errors.reservist_date_of_issuance,
                },
              }}
              disabled={false}
              label='Data de emissão'
              value={formik.values.reservist_date_of_issuance}
              onChange={(value: any) => {
                formik.setFieldValue("reservist_date_of_issuance", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            Título de eleitor
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='voter_card_number'
              label='Número'
              type='number'
              placeholder='00000000'
              value={formik.values.voter_card_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.voter_card_number &&
                Boolean(formik.errors.voter_card_number)
              }
              helperText={
                formik.touched.voter_card_number &&
                formik.errors.voter_card_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='voter_card_zone'
              label='Zona'
              value={formik.values.voter_card_zone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.voter_card_zone &&
                Boolean(formik.errors.voter_card_zone)
              }
              helperText={
                formik.touched.voter_card_zone && formik.errors.voter_card_zone
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='voter_card_section'
              label='Seção'
              value={formik.values.voter_card_section}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.voter_card_section &&
                Boolean(formik.errors.voter_card_section)
              }
              helperText={
                formik.touched.voter_card_section &&
                formik.errors.voter_card_section
              }
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            Carteira de trabalho
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='work_permit_number'
              label='Número'
              value={formik.values.work_permit_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.work_permit_number &&
                Boolean(formik.errors.work_permit_number)
              }
              helperText={
                formik.touched.work_permit_number &&
                formik.errors.work_permit_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='work_permit_series'
              label='Série'
              value={formik.values.work_permit_series}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.work_permit_series &&
                Boolean(formik.errors.work_permit_series)
              }
              helperText={
                formik.touched.work_permit_series &&
                formik.errors.work_permit_series
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              name='work_permit_federal_unit'
              id='work_permit_federal_unit'
              select
              label='Estado de expedição'
              value={formik.values.work_permit_federal_unit}
              onChange={(event: any) => {
                formik.setFieldValue(
                  "work_permit_federal_unit",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.work_permit_federal_unit &&
                Boolean(formik.errors.work_permit_federal_unit)
              }
              helperText={
                formik.touched.work_permit_federal_unit &&
                formik.errors.work_permit_federal_unit
              }>
              {stateOptions.map((option: any) => (
                <MenuItem key={option.sigla} value={option.sigla}>
                  {option.sigla} - {option.nome}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={14} fontWeight={400}>
            PIS/PSET/NIT - Benefícios do trabalhador
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='social_integration_program_number'
              type='number'
              placeholder='00000000000'
              label='Número'
              value={formik.values.social_integration_program_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.social_integration_program_number &&
                Boolean(formik.errors.social_integration_program_number)
              }
              helperText={
                formik.touched.social_integration_program_number &&
                formik.errors.social_integration_program_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "social_integration_program_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.social_integration_program_date &&
                    Boolean(formik.errors.social_integration_program_date),
                  helperText:
                    formik.touched.social_integration_program_date &&
                    formik.errors.social_integration_program_date,
                },
              }}
              disabled={false}
              label='Data'
              value={formik.values.social_integration_program_date}
              onChange={(value: any) => {
                formik.setFieldValue("social_integration_program_date", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            CNH - Carteira nacional de habilitação
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='driver_license_registration_number'
              label='Número'
              type='number'
              placeholder='000000000-0'
              value={formik.values.driver_license_registration_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.driver_license_registration_number &&
                Boolean(formik.errors.driver_license_registration_number)
              }
              helperText={
                formik.touched.driver_license_registration_number &&
                formik.errors.driver_license_registration_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='driver_license_issuing_authority'
              label='Orgão de expedição'
              value={formik.values.driver_license_issuing_authority}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.driver_license_issuing_authority &&
                Boolean(formik.errors.driver_license_issuing_authority)
              }
              helperText={
                formik.touched.driver_license_issuing_authority &&
                formik.errors.driver_license_issuing_authority
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "driver_license_date_of_issuance",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.driver_license_date_of_issuance &&
                    Boolean(formik.errors.driver_license_date_of_issuance),
                  helperText:
                    formik.touched.driver_license_date_of_issuance &&
                    formik.errors.driver_license_date_of_issuance,
                },
              }}
              disabled={false}
              label='Data de emissão'
              value={formik.values.driver_license_date_of_issuance}
              onChange={(value: any) => {
                formik.setFieldValue("driver_license_date_of_issuance", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "driver_license_due_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.driver_license_due_date &&
                    Boolean(formik.errors.driver_license_due_date),
                  helperText:
                    formik.touched.driver_license_due_date &&
                    formik.errors.driver_license_due_date,
                },
              }}
              disabled={false}
              label='Data de validade'
              value={formik.values.driver_license_due_date}
              onChange={(value: any) => {
                formik.setFieldValue("driver_license_due_date", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='driver_license_category'
              name='driver_license_category'
              select
              label='Categoria'
              value={formik.values.driver_license_category}
              onChange={(event: any) => {
                formik.setFieldValue(
                  "driver_license_category",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.driver_license_category &&
                Boolean(formik.errors.driver_license_category)
              }
              helperText={
                formik.touched.driver_license_category &&
                formik.errors.driver_license_category
              }>
              {cnh_category_e_social.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='driver_license_quantity'
              name='driver_license_quantity'
              select
              label='Nível'
              value={formik.values.driver_license_quantity}
              onChange={(event: any) => {
                formik.setFieldValue(
                  "driver_license_quantity",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.driver_license_quantity &&
                Boolean(formik.errors.driver_license_quantity)
              }
              helperText={
                formik.touched.driver_license_quantity &&
                formik.errors.driver_license_quantity
              }>
              {level_cnh_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='driver_license_federal_unit'
              name='driver_license_federal_unit'
              select
              label='Estado de expedição'
              value={formik.values.driver_license_federal_unit}
              onChange={(event: any) => {
                formik.setFieldValue(
                  "driver_license_federal_unit",
                  event.target.value
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.driver_license_federal_unit &&
                Boolean(formik.errors.driver_license_federal_unit)
              }
              helperText={
                formik.touched.driver_license_federal_unit &&
                formik.errors.driver_license_federal_unit
              }>
              {stateOptions.map((option: any) => (
                <MenuItem key={option.sigla} value={option.sigla}>
                  {option.sigla} - {option.nome}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={14} fontWeight={400}>
            OC - Orgão de classe
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='oc_number'
              label='Número'
              type='number'
              placeholder='0000000'
              value={formik.values.oc_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.oc_number && Boolean(formik.errors.oc_number)
              }
              helperText={formik.touched.oc_number && formik.errors.oc_number}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='oc_issuing_authority'
              label='Orgão de expedição'
              value={formik.values.oc_issuing_authority}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.oc_issuing_authority &&
                Boolean(formik.errors.oc_issuing_authority)
              }
              helperText={
                formik.touched.oc_issuing_authority &&
                formik.errors.oc_issuing_authority
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='oc_federal_unit'
              name='oc_federal_unit'
              select
              label='Estado de expedição'
              value={formik.values.oc_federal_unit}
              onChange={(event: any) => {
                formik.setFieldValue("oc_federal_unit", event.target.value);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.oc_federal_unit &&
                Boolean(formik.errors.oc_federal_unit)
              }
              helperText={
                formik.touched.oc_federal_unit && formik.errors.oc_federal_unit
              }>
              {stateOptions.map((option: any) => (
                <MenuItem key={option.sigla} value={option.sigla}>
                  {option.sigla} - {option.nome}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "oc_date_of_issuance",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.oc_date_of_issuance &&
                    Boolean(formik.errors.oc_date_of_issuance),
                  helperText:
                    formik.touched.oc_date_of_issuance &&
                    formik.errors.oc_date_of_issuance,
                },
              }}
              disabled={false}
              label='Data de expedição'
              value={formik.values.oc_date_of_issuance}
              onChange={(value: any) => {
                formik.setFieldValue("oc_date_of_issuance", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={14} fontWeight={400}>
            MOOP - Movimentação Operacional de Produtos Perigosos
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='mopp_number'
              label='Número'
              placeholder='000000000'
              type='number'
              value={formik.values.mopp_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.mopp_number && Boolean(formik.errors.mopp_number)
              }
              helperText={
                formik.touched.mopp_number && formik.errors.mopp_number
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "mopp_due_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.mopp_due_date &&
                    Boolean(formik.errors.mopp_due_date),
                  helperText:
                    formik.touched.mopp_due_date && formik.errors.mopp_due_date,
                },
              }}
              disabled={false}
              label='Data de validade'
              value={formik.values.mopp_due_date}
              onChange={(value: any) => {
                formik.setFieldValue("mopp_due_date", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
