/** @format */

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
// import NotificationSection from "./notification";
import {
  ChangeCircleOutlined,
  ExitToAppOutlined,
  Menu,
} from "@mui/icons-material";
import { Button, Chip, IconButton, Tooltip } from "@mui/material";
import { Dialog, Flex, RadioGroup } from "@radix-ui/themes";
import Cookies from "js-cookie";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormatGroupPermission } from "../../helpers/formatGroupPermission";
import { getNotificationsUnread } from "../../pages/protected/notifications";
import { updateLoading } from "../../redux/reducers/common";
import {
  updateUser,
  updateUserDelegation,
  updateUserPermissions,
} from "../../redux/reducers/user";
import { GetInstance, PostInstante } from "../../services/api";
import { permissionsMock } from "../constants";
import { ModeTheme } from "../mode";
import NotificationSection from "./notification";
import ProfileSection from "./profile";

const Header = ({ handleLeftDrawerToggle, open }: any) => {
  const theme = useTheme();
  const company = useSelector((state: any) => state.company);
  const user = useSelector((state: any) => state.user.data);
  const delegation = useSelector((state: any) => state.user.delegation_data);
  const [delegationModal, setDelegationModal] = useState(false);
  const [delegationUser, setDelegationUser]: any = useState(null);
  const dispatch = useDispatch();
  const router = useNavigate();

  function onDelegate() {
    if (delegationUser !== null) {
      dispatch(updateLoading(true));
      setDelegationModal(false);
      PostInstante(delegationUser, null, "/delegations/sign_in/")
        .then((response: any) => {
          dispatch(updateUser(response.data.data.user));
          Cookies.set("auth", response.data.data.token);
          GetInstance({}, "users/sessao")
            .then((response: any) => {
              dispatch(
                updateUserPermissions(
                  permissionsMock.filter(
                    (item: any) => item.uuid === response.data.data.record.role
                  )[0].permissions
                )
              );
              dispatch(updateLoading(false));
              dispatch(updateUser(response.data.data.record));
              dispatch(
                updateUserDelegation(response.data.data.delegation_data)
              );
              getNotificationsUnread(dispatch);
              const interval = setInterval(() => {
                getNotificationsUnread(dispatch);
              }, 30000);
              clearInterval(interval);
              toast.success("Sucesso ao entrar como delegado");
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                dispatch(updateLoading(false));
                router("/login");
              }
            });
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              "Erro ao tentar entrar no perfil delegado, por favor tente novamente mais tarde ou entre em contato com o suporte."
            );
          }
        });
    } else {
      toast.error("Não foi selecionado nenhum usuário para acesso.");
    }
  }

  const handleLogout = async () => {
    dispatch(updateLoading(true));
    PostInstante(null, {}, "/logout")
      .then(() => {
        dispatch(updateLoading(false));
        Cookies.remove("auth");
        router("/login");
      })
      .catch(() => {
        dispatch(updateLoading(false));
        toast.error(
          "Erro ao tentar sair, tente novamente ou então entre em contato com o suporte"
        );
      });
  };

  return (
    <>
      <Dialog.Root open={delegationModal}>
        <Dialog.Content maxWidth='450px'>
          <Dialog.Title>Acessar delegação</Dialog.Title>
          <Dialog.Description size='2' mb='4'>
            Acesse um perfil que você possui função de delegado, selecione ele
            abaixo e clique em entrar:
          </Dialog.Description>
          <Flex direction='column' gap='3'>
            <RadioGroup.Root
              defaultValue={delegationUser}
              onClick={(e: any) => setDelegationUser(e.target.value)}>
              {delegation?.delegation_options?.map((item: any) => (
                <RadioGroup.Item value={item.uuid}>
                  {item.delegated_user_name}
                </RadioGroup.Item>
              ))}
            </RadioGroup.Root>
          </Flex>
          <Flex gap='3' mt='4' justify='end'>
            <Button
              onClick={() => setDelegationModal(false)}
              variant='outlined'>
              Cancelar
            </Button>
            <Button onClick={onDelegate} variant='contained' color={"primary"}>
              Entrar
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>

      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          alignItems: "center",
        }}>
        <Avatar
          variant='rounded'
          sx={{
            transition: "all .2s ease-in-out",
            background: "transparent",
            color: theme.palette.grey.A700,
            "&:hover": {
              background: theme.palette.grey.A700,
              color: theme.palette.common.white,
            },
          }}
          onClick={handleLeftDrawerToggle}
          color='inherit'>
          <Menu />
        </Avatar>
        <Box
          component='span'
          sx={{
            display: { xs: "none", md: "block" },
            textAlign: "left",
            marginLeft: "20px",
            flexGrow: 1,
          }}>
          <img {...company.logoSmall} alt='Logo da empresa' />
        </Box>
        {company.logos &&
          company.logos.map((item: any) => (
            <Box
              component='span'
              sx={{
                display: { xs: "none", md: "block" },
                textAlign: "left",
                marginLeft: "50px",
                flexGrow: 1,
              }}>
              <img {...item} alt='Logo da empresa' />
            </Box>
          ))}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      {delegation?.delegated_session && (
        <>
          <div
            style={{
              width: 2,
              height: 40,
              backgroundColor: theme.palette.grey[400],
              marginRight: 12,
            }}
          />
          <Chip
            style={{ marginRight: 8 }}
            color={"primary"}
            label={"Acessando como delegado"}
          />
          <IconButton onClick={handleLogout}>
            <Tooltip title={"Sair"}>
              <ExitToAppOutlined />
            </Tooltip>
          </IconButton>
          <div
            style={{
              width: 2,
              height: 40,
              backgroundColor: theme.palette.grey[400],
              marginRight: 12,
              marginLeft: 12,
            }}
          />
        </>
      )}
      {delegation?.delegation_options?.length > 0 && (
        <>
          <div
            style={{
              width: 2,
              height: 40,
              backgroundColor: theme.palette.grey[400],
              marginRight: 12,
            }}
          />
          <IconButton onClick={() => setDelegationModal(!delegationModal)}>
            <Tooltip title={"Acessar como delegado"}>
              <ChangeCircleOutlined />
            </Tooltip>
          </IconButton>
          <div
            style={{
              width: 2,
              height: 40,
              backgroundColor: theme.palette.grey[400],
              marginRight: 12,
              marginLeft: 12,
            }}
          />
        </>
      )}
      <Chip
        style={{ marginRight: 8 }}
        color={"primary"}
        label={FormatGroupPermission(user.role)}
      />
      <Avatar
        sx={{
          background: "transparent",
          color: theme.palette.grey.A700,
          "&:hover": {
            background: theme.palette.grey.A700,
            color: theme.palette.common.white,
          },
        }}
        variant={"rounded"}>
        <ModeTheme />
      </Avatar>
      <NotificationSection />
      {!delegation?.delegated_session && <ProfileSection />}
    </>
  );
};

export default Header;
