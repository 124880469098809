/** @format */
import { MoreHoriz } from "@mui/icons-material";
import { Breadcrumbs, Grid, Skeleton, Typography } from "@mui/material";
import { gray } from "@radix-ui/colors";
import { IconButton, Tooltip } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../../services/api";
import { CardUser } from "./helpers/cardUser";

//separar por linhas


export function Organizational() {
  const user = useSelector((state: any) => state.user.data);
  const companyData = useSelector((state: any) => state.company);
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const [data, setData]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timeOut = 1000;

  useEffect(() => {
    setLoading(true);
    GetInstance(null, "/organograma")
      .then(async (res) => {
        const usersList = (await res?.data?.data) ?? [];
        // 1 - novo array separando o gestor com seus filhos
        const managerList: any = [];
        // eslint-disable-next-line array-callback-return
        await usersList.map(async (item: any) => {
          const userActive = item.id === user.id;
          if (userActive) {
            item.open = true;
            item.userActive = true;
          } else {
            item.userActive = false;
            item.open = false;
          }
          const userManager = item.manager_id === user.id;
          if (userManager) {
            item.userManager = true;
            item.open = true;
          } else if (!userActive) {
            item.userManager = false;
            item.open = false;
          }
          if (item.manager_id === null) {
            managerList.push({ ...item, firstLine: true, children: [] });
          } else {
            const index = managerList.findIndex(
              (manager: any) => manager.id === item.manager_id
            );
            if (index === -1) {
              const managerAdd = usersList.find(
                (manager: any) => manager.id === item.manager_id
              ) ?? { id: item.manager_id };
              managerList.push({ ...managerAdd, children: [item] });
            } else {
              managerList[index].children.push(item);
            }
          }
        });

        setData(managerList);
        setLoading(false);
      })
      .catch((e) => {
        if (e.code !== "ERR_CANCELED") {
          setLoading(false);
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Grid container>
      <Grid container>
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography fontWeight={800}>Inicio</Typography>
          <Typography fontWeight={800}>Organograma</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid
        p={2}
        container
        ref={ref}
        style={{ overflow: "auto" }}
        flexDirection={"row"}
        alignItems={"center"}
        gap={1}>
        {companyData !== undefined && !loading ? (
          <Tree
            lineWidth={"2px"}
            lineColor={companyData.secondary}
            lineBorderRadius={"10px"}
            label={
              <TreeNode
                label={
                  <img
                    style={{ borderRadius: 12 }}
                    {...companyData.logoDefault}
                    alt='Logo da empresa'
                  />
                }
              />
            }
            children={data.map(
              (item: any) =>
                item.firstLine && (
                  <TreeNode
                    label={
                      <CardUser
                        navigate={navigate}
                        company={companyData}
                        user={item}
                      />
                    }
                    children={
                      item?.children && item?.children.length > 0 ? (
                        item.children[0]?.open ? (
                          <TreeNode
                            key={item.name + "otherOptions"}
                            label={
                              <Tooltip content='Ver menos'>
                                <IconButton
                                  color={companyData.primary}
                                  variant='ghost'
                                  style={{ cursor: "pointer", margin: 8 }}
                                  onClick={() => {
                                    setLoading(true);
                                    item.children.map(
                                      (option: any) => (option.open = false)
                                    );
                                    setTimeout(() => {
                                      setLoading(false);
                                    }, timeOut);
                                  }}>
                                  <MoreHoriz width='14' height='14' />
                                </IconButton>
                              </Tooltip>
                            }
                            children={
                              item?.children &&
                              item.children[0]?.open &&
                              item?.children.map((child: any) => (
                                <Tree
                                  lineWidth={"2px"}
                                  lineColor={companyData.secondary}
                                  lineBorderRadius={"10px"}
                                  label={
                                    <TreeNode
                                      label={
                                        <CardUser
                                          navigate={navigate}
                                          company={companyData}
                                          user={child}
                                        />
                                      }
                                    />
                                  }
                                  children={undefined}
                                />
                              ))
                            }
                          />
                        ) : (
                          <TreeNode
                            key={data.name + "otherOptions"}
                            label={
                              <Tooltip content='Ver mais'>
                                <IconButton
                                  color={companyData.primary}
                                  variant='ghost'
                                  style={{ cursor: "pointer", margin: 8 }}
                                  onClick={() => {
                                    setLoading(true);
                                    item.children.map(
                                      (option: any) => (option.open = true)
                                    );
                                    setTimeout(() => {
                                      setLoading(false);
                                    }, timeOut);
                                  }}>
                                  <MoreHoriz width='14' height='14' />
                                </IconButton>
                              </Tooltip>
                            }
                          />
                        )
                      ) : null
                    }
                  />
                )
            )}
          />
        ) : (
          <Tree
            lineWidth={"2px"}
            lineColor={gray.gray5}
            lineBorderRadius={"10px"}
            label={
              <Skeleton
                style={{ display: "inline-block" }}
                width='200px'
                height='80px'
              />
            }>
            <TreeNode
              label={
                <Skeleton
                  style={{ display: "inline-block" }}
                  width='200px'
                  height='80px'
                />
              }>
              <TreeNode
                label={
                  <Skeleton
                    style={{ display: "inline-block" }}
                    width='200px'
                    height='80px'
                  />
                }
              />
              <TreeNode
                label={
                  <Skeleton
                    style={{ display: "inline-block" }}
                    width='200px'
                    height='80px'
                  />
                }
              />
            </TreeNode>
            <TreeNode
              label={
                <Skeleton
                  style={{ display: "inline-block" }}
                  width='200px'
                  height='80px'
                />
              }>
              <TreeNode
                label={
                  <Skeleton
                    style={{ display: "inline-block" }}
                    width='200px'
                    height='80px'
                  />
                }
              />
              <TreeNode
                label={
                  <Skeleton
                    style={{ display: "inline-block" }}
                    width='200px'
                    height='80px'
                  />
                }
              />
            </TreeNode>
            <TreeNode
              label={
                <Skeleton
                  style={{ display: "inline-block" }}
                  width='200px'
                  height='80px'
                />
              }>
              <TreeNode
                label={
                  <Skeleton
                    style={{ display: "inline-block" }}
                    width='200px'
                    height='80px'
                  />
                }
              />
              <TreeNode
                label={
                  <Skeleton
                    style={{ display: "inline-block" }}
                    width='200px'
                    height='80px'
                  />
                }
              />
            </TreeNode>
          </Tree>
        )}
      </Grid>
    </Grid>
  );
}
