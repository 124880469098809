/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { aviso_e_social } from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../config/hiddenCheck";
import { FormatCurrency } from "../../../../../helpers/formatCurrency";

export function Benefits({ formik, data }: any) {
  useEffect(() => {
    formik.setFieldValue(
      "health_insurance_name",
      data.options.health_insurance.filter(
        (opt: any) => opt.value === formik.values.health_insurance_id
      )[0]?.description
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.health_insurance_id]);
  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid id={"health_insurance_id"}>
          <Typography fontSize={16} fontWeight={500}>
            Plano de saúde
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='health_insurance_id'
              name='health_insurance_id'
              select
              label='Operadora'
              value={formik.values.health_insurance_id}
              onChange={(event: any) => {
                formik.setFieldValue("health_insurance_id", event.target.value);
                formik.setFieldValue(
                  "health_insurance_name",
                  data.options.health_insurance.filter(
                    (opt: any) => opt.value === event.target.value
                  )[0].description
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.health_insurance_id &&
                Boolean(formik.errors.health_insurance_id)
              }
              helperText={
                formik.touched.health_insurance_id &&
                formik.errors.health_insurance_id
              }>
              {data.options.health_insurance.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value} - {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='health_insurance_name'
              label='Plano'
              value={formik.values.health_insurance_name}
              onChange={formik.handleChange}
              disabled
              onBlur={formik.handleBlur}
              error={
                formik.touched.health_insurance_name &&
                Boolean(formik.errors.health_insurance_name)
              }
              helperText={
                formik.touched.health_insurance_name &&
                formik.errors.health_insurance_name
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='health_insurance_price'
              label='Preço'
              placeholder={"R$0,00"}
              value={formik.values.health_insurance_price}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "health_insurance_price",
                  FormatCurrency(event)
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.health_insurance_price &&
                Boolean(formik.errors.health_insurance_price)
              }
              helperText={
                formik.touched.health_insurance_price &&
                formik.errors.health_insurance_price
              }
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Vale alimentação
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='food_voucher_id'
              name='food_voucher_id'
              select
              label='Operadora'
              value={formik.values.food_voucher_id}
              onChange={(event: any) =>
                formik.setFieldValue("food_voucher_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.food_voucher_id &&
                Boolean(formik.errors.food_voucher_id)
              }
              helperText={
                formik.touched.food_voucher_id && formik.errors.food_voucher_id
              }>
              {data.options.food_voucher.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value} - {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='food_voucher_price'
              label='Preço'
              placeholder={"R$0,00"}
              value={formik.values.food_voucher_price}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "food_voucher_price",
                  FormatCurrency(event)
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.food_voucher_price &&
                Boolean(formik.errors.food_voucher_price)
              }
              helperText={
                formik.touched.food_voucher_price &&
                formik.errors.food_voucher_price
              }
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Cesta básica
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='is_basic_basket_option'
              name='is_basic_basket_option'
              select
              label='Optante cesta básica'
              value={formik.values.is_basic_basket_option}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "is_basic_basket_option",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.is_basic_basket_option &&
                Boolean(formik.errors.is_basic_basket_option)
              }
              helperText={
                formik.touched.is_basic_basket_option &&
                formik.errors.is_basic_basket_option
              }>
              {aviso_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
