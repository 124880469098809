/** @format */

import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  admission_type_e_social,
  aviso_e_social,
  contract_type_e_social,
  nature_e_social,
  overtime_type_e_social,
  salary_e_social,
  security_type_e_social,
  social_security_regime_e_social,
  status_e_social,
} from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../config/hiddenCheck";
import { FormatCurrency } from "../../../../../helpers/formatCurrency";

export function Contract({ formik, data }: any) {
  useEffect(() => {
    const hasError = Boolean(formik.errors.business_unit_contract_type);
    if (hasError && formik.values.position_type !== "pj") {
      toast.error(
        "Esse colaborador está em uma posição CLT, o tipo de contratação não poderá ser PJ."
      );
    } else if (hasError && formik.values.position_type === "pj") {
      toast.error(
        "Esse colaborador está em uma posição PJ, o tipo de contratação deverá ser PJ também."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors.business_unit_contract_type]);

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid>
          <Typography fontSize={16} fontWeight={500}>
            Unidade de negócio
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='business_unit_id'
              name='business_unit_id'
              select
              label='Código'
              value={formik.values.business_unit_id}
              onChange={(event: any) =>
                formik.setFieldValue("business_unit_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.business_unit_id &&
                Boolean(formik.errors.business_unit_id)
              }
              helperText={
                formik.touched.business_unit_id &&
                formik.errors.business_unit_id
              }>
              {data.options.business_unit.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value} - {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='sector_id'
              name='sector_id'
              select
              label='Setor'
              value={formik.values.sector_id}
              onChange={(event: any) =>
                formik.setFieldValue("sector_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.sector_id && Boolean(formik.errors.sector_id)
              }
              helperText={formik.touched.sector_id && formik.errors.sector_id}>
              {data.options.sector.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='work_section_id'
              name='work_section_id'
              select
              label='Seção'
              value={formik.values.work_section_id}
              onChange={(event: any) =>
                formik.setFieldValue("work_section_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.work_section_id &&
                Boolean(formik.errors.work_section_id)
              }
              helperText={
                formik.touched.work_section_id && formik.errors.work_section_id
              }>
              {data.options.work_section.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='cost_center_id'
              name='cost_center_id'
              select
              label='Centro de custo'
              value={formik.values.cost_center_id}
              onChange={(event: any) =>
                formik.setFieldValue("cost_center_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.cost_center_id &&
                Boolean(formik.errors.cost_center_id)
              }
              helperText={
                formik.touched.cost_center_id && formik.errors.cost_center_id
              }>
              {data.options.cost_center.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value} - {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='managerial_cost_center_id'
              name='managerial_cost_center_id'
              select
              label='Centro de custo gerencial'
              value={formik.values.managerial_cost_center_id}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "managerial_cost_center_id",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.managerial_cost_center_id &&
                Boolean(formik.errors.managerial_cost_center_id)
              }
              helperText={
                formik.touched.managerial_cost_center_id &&
                formik.errors.managerial_cost_center_id
              }>
              {data.options.managerial_cost_center.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.code} - {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='category_id'
              name='category_id'
              select
              label='Categoria do colaborador'
              value={formik.values.category_id}
              onChange={(event: any) =>
                formik.setFieldValue("category_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.category_id && Boolean(formik.errors.category_id)
              }
              helperText={
                formik.touched.category_id && formik.errors.category_id
              }>
              {data.options.work_category.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='business_unit_contract_type'
              name='business_unit_contract_type'
              select
              label='Tipo de contrato'
              value={formik.values.business_unit_contract_type}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "business_unit_contract_type",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.business_unit_contract_type)}
              helperText={formik.errors.business_unit_contract_type}>
              {contract_type_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='work_schedule_id'
              name='work_schedule_id'
              select
              label='Horário de trabalho'
              value={formik.values.work_schedule_id}
              onChange={(event: any) =>
                formik.setFieldValue("work_schedule_id", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.work_schedule_id &&
                Boolean(formik.errors.work_schedule_id)
              }
              helperText={
                formik.touched.work_schedule_id &&
                formik.errors.work_schedule_id
              }>
              {data.options.work_schedule.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='business_unit_job_security_type'
              name='business_unit_job_security_type'
              select
              label='Tipo de estabilidade'
              value={formik.values.business_unit_job_security_type}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "business_unit_job_security_type",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.business_unit_job_security_type &&
                Boolean(formik.errors.business_unit_job_security_type)
              }
              helperText={
                formik.touched.business_unit_job_security_type &&
                formik.errors.business_unit_job_security_type
              }>
              {security_type_e_social.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "business_unit_job_security_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.business_unit_job_security_date &&
                    Boolean(formik.errors.business_unit_job_security_date),
                  helperText:
                    formik.touched.business_unit_job_security_date &&
                    formik.errors.business_unit_job_security_date,
                },
              }}
              label='Data garantia estabilidade'
              value={formik.values.business_unit_job_security_date}
              onChange={(value: any) => {
                formik.setFieldValue("business_unit_job_security_date", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "business_unit_employment_bond",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.business_unit_employment_bond &&
                    Boolean(formik.errors.business_unit_employment_bond),
                  helperText:
                    formik.touched.business_unit_employment_bond &&
                    formik.errors.business_unit_employment_bond,
                },
              }}
              label='Contrato determ. térm.'
              value={formik.values.business_unit_employment_bond}
              onChange={(value: any) => {
                formik.setFieldValue("business_unit_employment_bond", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "staffing_number_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.staffing_number_date &&
                    Boolean(formik.errors.staffing_number_date),
                  helperText:
                    formik.touched.staffing_number_date &&
                    formik.errors.staffing_number_date,
                },
              }}
              label='Lotação organ. período'
              value={formik.values.staffing_number_date}
              onChange={(value: any) => {
                formik.setFieldValue("staffing_number_date", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "business_unit_due_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.business_unit_due_date &&
                    Boolean(formik.errors.business_unit_due_date),
                  helperText:
                    formik.touched.business_unit_due_date &&
                    formik.errors.business_unit_due_date,
                },
              }}
              label='Data de admissão'
              value={formik.values.business_unit_due_date}
              onChange={(value: any) => {
                formik.setFieldValue("business_unit_due_date", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "business_unit_exp_extension",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.business_unit_exp_extension &&
                    Boolean(formik.errors.business_unit_exp_extension),
                  helperText:
                    formik.touched.business_unit_exp_extension &&
                    formik.errors.business_unit_exp_extension,
                },
              }}
              label='Data exp. prorrogação'
              value={formik.values.business_unit_exp_extension}
              onChange={(value: any) => {
                formik.setFieldValue("business_unit_exp_extension", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "business_unit_exp_finish_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.business_unit_exp_finish_date &&
                    Boolean(formik.errors.business_unit_exp_finish_date),
                  helperText:
                    formik.touched.business_unit_exp_finish_date &&
                    formik.errors.business_unit_exp_finish_date,
                },
              }}
              label='Data exp. término'
              value={formik.values.business_unit_exp_finish_date}
              onChange={(value: any) => {
                formik.setFieldValue("business_unit_exp_finish_date", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "business_unit_leave_date",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.business_unit_leave_date &&
                    Boolean(formik.errors.business_unit_leave_date),
                  helperText:
                    formik.touched.business_unit_leave_date &&
                    formik.errors.business_unit_leave_date,
                },
              }}
              label='Data de afastamento'
              value={formik.values.business_unit_leave_date}
              onChange={(value: any) => {
                formik.setFieldValue("business_unit_leave_date", value);
              }}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <DatePicker
              slotProps={{
                textField: {
                  fullWidth: true,
                  id: "business_unit_contract_finish",
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.business_unit_contract_finish &&
                    Boolean(formik.errors.business_unit_contract_finish),
                  helperText:
                    formik.touched.business_unit_contract_finish &&
                    formik.errors.business_unit_contract_finish,
                },
              }}
              label='Data de desligamento'
              value={formik.values.business_unit_contract_finish}
              onChange={(value: any) => {
                formik.setFieldValue("business_unit_contract_finish", value);
              }}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Contrato
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='contract_status'
              name='contract_status'
              select
              label='Status'
              value={formik.values.contract_status}
              onChange={(event: any) =>
                formik.setFieldValue("contract_status", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.contract_status &&
                Boolean(formik.errors.contract_status)
              }
              helperText={
                formik.touched.contract_status && formik.errors.contract_status
              }>
              {status_e_social.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='contract_cbo'
              label='CBO número'
              type='number'
              value={formik.values.contract_cbo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contract_cbo &&
                Boolean(formik.errors.contract_cbo)
              }
              helperText={
                formik.touched.contract_cbo && formik.errors.contract_cbo
              }
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='contract_nature'
              name='contract_nature'
              select
              label='Natureza'
              value={formik.values.contract_nature}
              onChange={(event: any) =>
                formik.setFieldValue("contract_nature", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.contract_nature &&
                Boolean(formik.errors.contract_nature)
              }
              helperText={
                formik.touched.contract_nature && formik.errors.contract_nature
              }>
              {nature_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='contract_salary'
              name='contract_salary'
              select
              label='Tipo de salário'
              value={formik.values.contract_salary}
              onChange={(event: any) =>
                formik.setFieldValue("contract_salary", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.contract_salary &&
                Boolean(formik.errors.contract_salary)
              }
              helperText={
                formik.touched.contract_salary && formik.errors.contract_salary
              }>
              {salary_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value} - {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='salary_payment_method_id'
              name='salary_payment_method_id'
              select
              label='Método de Pagamento do Salário'
              value={formik.values.salary_payment_method_id}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "salary_payment_method_id",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.salary_payment_method_id &&
                Boolean(formik.errors.salary_payment_method_id)
              }
              helperText={
                formik.touched.salary_payment_method_id &&
                formik.errors.salary_payment_method_id
              }>
              {data.options.salary_payment_method.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='contract_base_salary'
              label='Salário base'
              placeholder={"R$0,00"}
              value={formik.values.contract_base_salary}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "contract_base_salary",
                  FormatCurrency(event)
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.contract_base_salary &&
                Boolean(formik.errors.contract_base_salary)
              }
              helperText={
                formik.touched.contract_base_salary &&
                formik.errors.contract_base_salary
              }
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Dados de trabalho
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='is_pregnant_apprentice'
              name='is_pregnant_apprentice'
              select
              label='Aprendiz grávida'
              value={formik.values.is_pregnant_apprentice}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "is_pregnant_apprentice",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.is_pregnant_apprentice &&
                Boolean(formik.errors.is_pregnant_apprentice)
              }
              helperText={
                formik.touched.is_pregnant_apprentice &&
                formik.errors.is_pregnant_apprentice
              }>
              {aviso_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='work_from_home'
              name='work_from_home'
              select
              label='Teletrabalho'
              value={formik.values.work_from_home}
              onChange={(event: any) =>
                formik.setFieldValue("work_from_home", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.work_from_home &&
                Boolean(formik.errors.work_from_home)
              }
              helperText={
                formik.touched.work_from_home && formik.errors.work_from_home
              }>
              {aviso_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='admission_type'
              name='admission_type'
              select
              label='Tipo de admissão'
              value={formik.values.admission_type}
              disabled={data.user_form_disabled_fields.includes(
                "admission_type"
              )}
              onChange={(event: any) =>
                formik.setFieldValue("admission_type", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.admission_type &&
                Boolean(formik.errors.admission_type)
              }
              helperText={
                formik.touched.admission_type && formik.errors.admission_type
              }>
              {admission_type_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='social_security_regime'
              name='social_security_regime'
              select
              label='Regime Previdenciário'
              value={formik.values.social_security_regime}
              disabled={data.user_form_disabled_fields.includes(
                "social_security_regime"
              )}
              onChange={(event: any) =>
                formik.setFieldValue(
                  "social_security_regime",
                  event.target.value
                )
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.social_security_regime &&
                Boolean(formik.errors.social_security_regime)
              }
              helperText={
                formik.touched.social_security_regime &&
                formik.errors.social_security_regime
              }>
              {social_security_regime_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='overtime_type'
              name='overtime_type'
              select
              label='Tipo de Horas Extras'
              value={formik.values.overtime_type}
              disabled={data.user_form_disabled_fields.includes(
                "overtime_type"
              )}
              onChange={(event: any) =>
                formik.setFieldValue("overtime_type", event.target.value)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.overtime_type &&
                Boolean(formik.errors.overtime_type)
              }
              helperText={
                formik.touched.overtime_type && formik.errors.overtime_type
              }>
              {overtime_type_e_social.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
