/** @format */

import { Edit, Info, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { optionsTable } from "../../../../config/constants";
import { EmptyData, FilterEmpty } from "../../../../helpers/emptyData";
import { FormatStatus } from "../../../../helpers/formatStatus";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

const validationSchema = yup.object().shape({
  acquisition_period_start: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido."),
  acquisition_period_end: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data final de aquisição não pode ser anterior a data inicial",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.acquisition_period_start).isValid() &&
          dayjs(all.options.context.acquisition_period_end).isValid()
        ) {
          return (
            new Date(all.options.context.acquisition_period_start).getTime() <
            new Date(all.options.context.acquisition_period_end).getTime()
          );
        }
        return false;
      }
    ),
  concessive_period_start: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data incial de concessão não pode ser anterior a data final de aquisição",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.acquisition_period_end).isValid() &&
          dayjs(all.options.context.concessive_period_start).isValid()
        ) {
          return (
            new Date(all.options.context.acquisition_period_end).getTime() <
            new Date(all.options.context.concessive_period_start).getTime()
          );
        }

        return false;
      }
    ),
  concessive_period_end: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data final de concessão não pode ser anterior a data inicial",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.concessive_period_end).isValid() &&
          dayjs(all.options.context.concessive_period_start).isValid()
        ) {
          return (
            new Date(all.options.context.concessive_period_end).getTime() >
            new Date(all.options.context.concessive_period_start).getTime()
          );
        }
        return false;
      }
    ),
  days_available: yup
    .number()
    .min(0, "Mínimo de 0")
    .max(30, "Máximo de 30")
    .required("Campo obrigatório"),
  days_used: yup
    .number()
    .min(0, "Mínimo de 0")
    .max(30, "Máximo de 30")
    .required("Campo obrigatório"),
});

const columns = [
  { name: "start_date", label: "Data inicial" },
  { name: "end_date", label: "Data final" },
  { name: "days_quantity", label: "Quantidade de dias" },
  { name: "thirteen_anticipation", label: "Antecipação 13º" },
  { name: "abono_pecuniario", label: "Abono pecuniário" },
  { name: "payment_date", label: "Data do pagamento" },
  { name: "description", label: "Descrição" },
];

export function VacationDetailsTime() {
  const router = useNavigate();
  const { id } = useParams();
  const [data, setData]: any = useState({});
  const [table, setTable]: any = useState([]);

  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      initialValues: {
        concessive_period_start: null,
        concessive_period_end: null,
        acquisition_period_start: null,
        acquisition_period_end: null,
        days_available: "",
        days_used: "",
      },
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(values.uuid, values, "/vacations")
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/vacation/time");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/vacations/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData({
          ...response.data.data.user_data,
          ...response.data.data.company_rules,
        });
        setTable(FilterEmpty(response.data.data.record.vacation_items));
        formik.setValues({
          ...response.data.data.record,
          concessive_period_start: dayjs(
            response.data.data.record.concessive_period_start
          ),
          concessive_period_end: dayjs(
            response.data.data.record.concessive_period_end
          ),
          acquisition_period_start: dayjs(
            response.data.data.record.acquisition_period_start
          ),
          acquisition_period_end: dayjs(
            response.data.data.record.acquisition_period_end
          ),
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.job_role_name),
    },
    {
      title: "Status",
      value: FormatStatus(data.status),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/vacation/time'>
              Férias
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/vacation/time'>
              Periodo
            </Link>
            <Typography fontWeight={800}>Detalhes do periodo</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações do colaborador
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {userInfo.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Editar periodo de férias
              </Typography>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              display={"grid"}
              mt={2}
              gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `acquisition_period_start`,
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.acquisition_period_start),
                    helperText: formik.errors.acquisition_period_start,
                  },
                }}
                label='Data de aquisição inicial'
                value={formik.values.acquisition_period_start}
                onChange={(value: any) => {
                  formik.setFieldValue(`acquisition_period_start`, value);
                  formik.setFieldValue(
                    `concessive_period_start`,
                    value.add(1, "year")
                  );
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `acquisition_period_end`,
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.acquisition_period_end),
                    helperText: formik.errors.acquisition_period_end,
                  },
                }}
                label='Data de aquisição final'
                value={formik.values.acquisition_period_end}
                onChange={(value: any) => {
                  formik.setFieldValue(`acquisition_period_end`, value);
                  formik.setFieldValue(
                    `concessive_period_end`,
                    value.add(1, "year")
                  );
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `concessive_period_start`,
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.concessive_period_start),
                    helperText: formik.errors.concessive_period_start,
                  },
                }}
                label='Data de inicio da concessão'
                value={formik.values.concessive_period_start}
                onChange={(value: any) => {
                  formik.setFieldValue(`concessive_period_start`, value);
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `concessive_period_end`,
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.concessive_period_end),
                    helperText: formik.errors.concessive_period_end,
                  },
                }}
                label='Data final da concessão'
                value={formik.values.concessive_period_end}
                onChange={(value: any) => {
                  formik.setFieldValue(`concessive_period_end`, value);
                }}
              />
              <TextField
                fullWidth
                id={`days_available`}
                type='number'
                label='Quantidade de dias disponiveis'
                variant='outlined'
                value={formik.values.days_available}
                onChange={(event: any) => {
                  formik.setFieldValue(`days_available`, event.target.value);
                }}
                error={Boolean(formik.errors.days_available)}
                helperText={formik.errors.days_available}
              />
              <TextField
                fullWidth
                id={`days_used`}
                type='number'
                label='Quantidade de dias usados'
                variant='outlined'
                value={formik.values.days_used}
                onChange={(event: any) => {
                  formik.setFieldValue(`days_used`, event.target.value);
                }}
                error={Boolean(formik.errors.days_used)}
                helperText={formik.errors.days_used}
              />
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                variant={"contained"}
                onClick={() => {
                  formik.handleSubmit();
                }}
                endIcon={<Edit />}>
                Editar
              </Button>
            </Grid>
          </Paper>
        </Grid>
        <Grid container>
          <MUIDataTable
            title={"Programações"}
            data={table}
            columns={[
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                  customBodyRenderLite: (dataIndex: number) => {
                    return (
                      <Tooltip title={"Ver informações"}>
                        <IconButton
                          onClick={() =>
                            router(
                              `/request/vacation/details/${table[dataIndex].uuid}`
                            )
                          }>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    );
                  },
                },
              },
              ...columns,
            ]}
            options={{
              ...optionsTable,
              searchAlwaysOpen: false,
              order: false,
              download: false,
              search: false,
              count: table.length,
              page: 1,
              rowsPerPage: table.length,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
