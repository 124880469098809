/** @format */

import {
  AppBar,
  Box,
  Grid,
  Modal,
  Paper,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createBrowserRouter, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PermissionCheck } from "../config/permissionCheck";
import { Audit } from "../pages/protected/audit";
import DataPulsePage from "../pages/protected/datapulse";
import { Delegation } from "../pages/protected/delegation";
import { DelegationNew } from "../pages/protected/delegation/form";
import { Active } from "../pages/protected/employee/active";
import { Import } from "../pages/protected/employee/import";
import { ImportNew } from "../pages/protected/employee/import/new";
import { Inactive } from "../pages/protected/employee/inactive";
import { EmployeeNew } from "../pages/protected/employee/new";
import { Pending } from "../pages/protected/employee/pending";
import { Position } from "../pages/protected/employee/position";
import { Home } from "../pages/protected/home";
import {
  getNotificationsUnread,
  Notifications,
} from "../pages/protected/notifications";
import { Organizational } from "../pages/protected/organizational";
import { Processes } from "../pages/protected/processes";
import { Leave } from "../pages/protected/request/leave";
import { LeaveDetails } from "../pages/protected/request/leave/details";
import { LeaveNew } from "../pages/protected/request/leave/new";
import { Personal } from "../pages/protected/request/personal";
import { PersonalDetails } from "../pages/protected/request/personal/details";
import { PersonalNew } from "../pages/protected/request/personal/new";
import { PositionRequest } from "../pages/protected/request/position";
import { PositionDetails } from "../pages/protected/request/position/details";
import { PositionNew } from "../pages/protected/request/position/new";
import { Promote } from "../pages/protected/request/promote";
import { PromoteDetails } from "../pages/protected/request/promote/details";
import { PromoteLetter } from "../pages/protected/request/promote/letter";
import { PromoteNew } from "../pages/protected/request/promote/new";
import { ReviewSalary } from "../pages/protected/request/reviewSalary";
import { SalaryReviewDetails } from "../pages/protected/request/reviewSalary/details";
import { SalaryReviewLetter } from "../pages/protected/request/reviewSalary/letter";
import { SalaryReviewNew } from "../pages/protected/request/reviewSalary/new";
import { Termination } from "../pages/protected/request/termination";
import { DismissalsDetails } from "../pages/protected/request/termination/details";
import { DismissalsNew } from "../pages/protected/request/termination/new";
import { Transfer } from "../pages/protected/request/transfer";
import { TransferDetails } from "../pages/protected/request/transfer/details";
import { TransferLetter } from "../pages/protected/request/transfer/letter";
import { TransferNew } from "../pages/protected/request/transfer/new";
import { UpdateInformation } from "../pages/protected/request/updateInformation";
import { UpdateInformationDetails } from "../pages/protected/request/updateInformation/details";
import { UpdateNew } from "../pages/protected/request/updateInformation/request";
import { Vacation } from "../pages/protected/request/vacation";
import { VacationDetails } from "../pages/protected/request/vacation/details";
import { VacationNew } from "../pages/protected/request/vacation/new";
import { PermissionsAudit } from "../pages/protected/settings/audit";
import { PermissionsDismissals } from "../pages/protected/settings/dismissals";
import { PermissionsForgetPassword } from "../pages/protected/settings/forgetPassword";
import { GroupPermission } from "../pages/protected/settings/group";
import { PermissionGroupDetails } from "../pages/protected/settings/group/details";
import { PermissionGroupNew } from "../pages/protected/settings/group/new";
import { PermissionsLeave } from "../pages/protected/settings/leave";
import { LetterRequest } from "../pages/protected/settings/letter";
import { LetterDetails } from "../pages/protected/settings/letter/details";
import { LetterNew } from "../pages/protected/settings/letter/new";
import { PermissionsPersonnel } from "../pages/protected/settings/personnel";
import { PermissionsPosition } from "../pages/protected/settings/position";
import { PermissionsPromote } from "../pages/protected/settings/promote";
import { PermissionssalaryReview } from "../pages/protected/settings/salaryReview";
import { PermissionsTransfer } from "../pages/protected/settings/transfer";
import { PermissionsUpdateInformation } from "../pages/protected/settings/updateInformation";
import { UsersRole } from "../pages/protected/settings/usersRole";
import { PermissionsVacation } from "../pages/protected/settings/vacation";
import { SettingsAccount } from "../pages/protected/settingsAccount";
import { SecretAnswers } from "../pages/protected/settingsAccount/secretAnswers";
import { VacationTime } from "../pages/protected/vacation";
import { DashboardVacation } from "../pages/protected/vacation/dashboard";
import { VacationDetailsTime } from "../pages/protected/vacation/details";
import { VacationNewTime } from "../pages/protected/vacation/new";
import { CompanyOptionsPage } from "../pages/unprotected/companyOptions";
import { CreatePasswordPage } from "../pages/unprotected/createPassword";
import ErrorPage from "../pages/unprotected/error-page";
import { ForgetPasswordEmailPage } from "../pages/unprotected/forgetPasswordEmail";
import { ForgetPasswordSecretQuestions } from "../pages/unprotected/forgetPasswordSecretQuestions";
import { ForgetPasswordStandardPage } from "../pages/unprotected/forgetPasswordStandard";
import { LoginPage } from "../pages/unprotected/login";
import { MethodForgetPasswordPage } from "../pages/unprotected/methodForgetPassword";
import NotMobilePage from "../pages/unprotected/notMobile";
import NotPermissionPage from "../pages/unprotected/notPermission";
import SuccessPasswordPage from "../pages/unprotected/successPassword";
import { updateLoading } from "../redux/reducers/common";
import { updateCompany } from "../redux/reducers/company";
import {
  updateUser,
  updateUserDelegation,
  updateUserPermissions,
} from "../redux/reducers/user";
import { GetInstance } from "../services/api";
import { companysOptions } from "./companies";
import { drawerWidth, permissionsMock, routers } from "./constants";
import Header from "./header/header";
import Sidebar from "./header/sidebar";

const SecretQuestionsRoute = ({ children }: any) => {
  const dispatch = useDispatch();
  const [hasUnansweredQuestions, setHasUnansweredQuestions]: any =
    useState(false);

  useEffect(() => {
    const find_secret_questions = () => {
      dispatch(updateLoading(true));
      GetInstance({}, `/secret_questions`)
        .then(async (response: any) => {
          dispatch(updateLoading(false));
          const {
            secret_questions: secretQuestions,
            unanswered_questions: unansweredQuestions,
          } = response.data.data.records;
          // eslint-disable-next-line array-callback-return
          secretQuestions.map((question: any) => {
            if (unansweredQuestions.includes(question.id)) {
              setHasUnansweredQuestions(true);
            }
          });
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    };
    find_secret_questions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {children}
      <Modal open={hasUnansweredQuestions}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            outline: "none",
            p: 4,
          }}>
          <Typography fontSize={20} fontWeight={800}>
            Perguntas secretas
          </Typography>
          <Grid container mb={2}>
            <Typography fontSize={14} fontWeight={400}>
              Foi identificado que existem perguntas secretas ainda não
              respondidas pelo o usuário
            </Typography>
          </Grid>

          <SecretAnswers />
        </Paper>
      </Modal>
    </>
  );
};

const ProtectedRoute = ({ children }: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mode: any = Cookies.get("theme") ?? "light";
  const router = useNavigate();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [leftDrawerOpened, setLeftDrawer]: any = useState(!matchDownMd);
  const user = Cookies.get("auth");
  if (!user) {
    return <Navigate to='/login' replace />;
  } else {
    dispatch(updateLoading(true));
    GetInstance({}, "users/sessao")
      .then((response: any) => {
        dispatch(
          updateUserPermissions(
            permissionsMock.filter(
              (item: any) => item.uuid === response.data.data.record.role
            )[0].permissions
          )
        );
        dispatch(updateLoading(false));
        dispatch(updateUser(response.data.data.record));
        dispatch(updateUserDelegation(response.data.data.delegation_data));
        getNotificationsUnread(dispatch);
        const interval = setInterval(() => {
          getNotificationsUnread(dispatch);
        }, 30000);
        clearInterval(interval);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          router("/login");
        }
      });
  }

  const Main = styled("main", {
    shouldForwardProp: (prop: any) => prop !== "open" && prop !== "theme",
  })(() => ({
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: 64,
    padding: "24px",
    borderTopLeftRadius: leftDrawerOpened ? 20 : 0,
    height: `calc(100dvh - 64px)`,
    backgroundColor:
      mode === "light" ? theme.palette.grey.A100 : theme.palette.grey.A700,
    transition: theme.transitions.create(
      "margin",
      leftDrawerOpened
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }
    ),
    [theme.breakpoints.up("md")]: {
      marginLeft: leftDrawerOpened ? 0 : -drawerWidth,
      width: leftDrawerOpened ? `calc(100% - ${drawerWidth}px)` : "100%",
      borderTopLeftRadius: leftDrawerOpened ? 20 : 0,
      height: "auto",
      minHeight: `calc(100dvh - 64px)`,
    },
    [theme.breakpoints.down("md")]: {
      width: `100dvw`,
      height: "auto",
      borderTopLeftRadius: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: `100dvw`,
      height: "auto",
      padding: "16px",
      borderTopLeftRadius: 0,
    },
  }));

  return (
    <SecretQuestionsRoute>
      <Box sx={{ display: "flex" }}>
        <AppBar
          enableColorOnDark
          position='fixed'
          color='inherit'
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened
              ? theme.transitions.create("width")
              : "none",
          }}>
          <Toolbar style={{ minHeight: "64px" }}>
            <Header
              open={leftDrawerOpened}
              handleLeftDrawerToggle={() => setLeftDrawer(!leftDrawerOpened)}
            />
          </Toolbar>
        </AppBar>
        <Sidebar
          drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
          drawerToggle={() => setLeftDrawer(!leftDrawerOpened)}
        />
        <Main>{children}</Main>
      </Box>
    </SecretQuestionsRoute>
  );
};

const CompanyRouter = ({ children }: any) => {
  let url = window.location.href;
  const dispatch = useDispatch();
  if (
    window.location.pathname === "/" &&
    Cookies.get("company") === undefined
  ) {
    if (url.indexOf("development") !== -1 || url.indexOf("localhost") !== -1) {
      return <Navigate to='/companyOptions' replace />;
    }
  }
  const company: any =
    url.indexOf("development") !== -1 || url.indexOf("localhost") !== -1
      ? Cookies.get("company") ?? "3ClicksRH"
      : companysOptions.filter(
          (data: any) => url.split(".")[0].indexOf(data.url) !== -1
        )[0].company ??
        "3ClicksRH" ??
        "3ClicksRH";
  Cookies.set("company", company);
  const companySelect = companysOptions.filter(
    (item: any) => item.company === company
  )[0];
  dispatch(updateCompany(companySelect));
  return children;
};

export const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <CompanyRouter>
        <LoginPage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/methodForgetPassword",
    element: (
      <CompanyRouter>
        <MethodForgetPasswordPage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgetPasswordSendEmail",
    element: (
      <CompanyRouter>
        <ForgetPasswordEmailPage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgetPasswordStandard",
    element: (
      <CompanyRouter>
        <ForgetPasswordStandardPage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgetPasswordSecretQuestions",
    element: (
      <CompanyRouter>
        <ForgetPasswordSecretQuestions />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/createPassword",
    element: (
      <CompanyRouter>
        <CreatePasswordPage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/successPassword",
    element: (
      <CompanyRouter>
        <SuccessPasswordPage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/notMobile",
    element: (
      <CompanyRouter>
        <NotMobilePage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/notPermission",
    element: (
      <CompanyRouter>
        <NotPermissionPage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/companyOptions",
    element: (
      <CompanyRouter>
        <CompanyOptionsPage />
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/settingsAccount",
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"settingsAccount"}>
            <SettingsAccount />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "home")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"home"}>
            <Home />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "dataPulse")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"dataPulse"}>
            <DataPulsePage />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "organizational")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"organizational"}>
            <Organizational />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "audit")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"audit"}>
            <Audit />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "notification")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"notification"}>
            <Notifications />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "vacationTimeDashboard")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"vacationTimeDashboard"}>
            <DashboardVacation />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "vacationCollaborator")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"vacationCollaborator"}>
            <VacationTime />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "vacationCollaboratorNew"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"vacationCollaboratorNew"}>
            <VacationNewTime />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "vacationCollaboratorDetails"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"vacationCollaboratorDetails"}>
            <VacationDetailsTime />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "delegation")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"delegation"}>
            <Delegation />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "processes")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"processes"}>
            <Processes />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "employeePending")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"employeePending"}>
            <Pending />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "delegationNew")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"delegationNew"}>
            <DelegationNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "delegationDetails")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"delegationDetails"}>
            <DelegationNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "employeeActive")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"employeeActive"}>
            <Active />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "employeeInactive")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"employeeInactive"}>
            <Inactive />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "employeeImport")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"employeeImport"}>
            <Import />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "employeeImportNew")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"employeeImportNew"}>
            <ImportNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "employeePosition")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"employeePosition"}>
            <Position />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "usersRole")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck mobile={true} keyPrivate={"usersRole"}>
            <UsersRole />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestLeave")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestLeave"}>
            <Leave />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestLeaveNew")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestLeaveNew"}>
            <LeaveNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestLeaveDetails")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestLeaveDetails"}>
            <LeaveDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestDismissals")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestDismissals"}>
            <Termination />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestDismissalsNew")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestDismissalsNew"}>
            <DismissalsNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "requestDismissalsDetails"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestDismissalsDetails"}>
            <DismissalsDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPosition")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPosition"}>
            <PositionRequest />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPositionNew")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPositionNew"}>
            <PositionNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPositionDetails")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPositionDetails"}>
            <PositionDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPromote")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPromote"}>
            <Promote />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPromoteNew")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPromoteNew"}>
            <PromoteNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPromoteDetails")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPromoteDetails"}>
            <PromoteDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "requestPromoteDetailsView"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPromoteDetailsView"}>
            <PromoteLetter />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestSalaryReview")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestSalaryReview"}>
            <ReviewSalary />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestSalaryReviewNew")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestSalaryReviewNew"}>
            <SalaryReviewNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "requestSalaryReviewDetails"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestSalaryReviewDetails"}>
            <SalaryReviewDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "requestSalaryReviewDetailsView"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestSalaryReviewDetailsView"}>
            <SalaryReviewLetter />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestTransfer")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestTransfer"}>
            <Transfer />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestTransferNew")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestTransferNew"}>
            <TransferNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestTransferDetails")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestTransferDetails"}>
            <TransferDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "requestTransferDetailsView"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestTransferDetailsView"}>
            <TransferLetter />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "requestUpdateInformation"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestUpdateInformation"}>
            <UpdateInformation />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "requestUpdateInformationDetails"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestUpdateInformationDetails"}>
            <UpdateInformationDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "requestUpdateInformationRequest"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestUpdateInformationRequest"}>
            <UpdateNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestVacation")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestVacation"}>
            <Vacation />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestVacationNew")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestVacationNew"}>
            <VacationNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestVacationDetails")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestVacationDetails"}>
            <VacationDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPersonal")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPersonal"}>
            <Personal />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPersonalNew")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPersonalNew"}>
            <PersonalNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "requestPersonalDetails")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"requestPersonalDetails"}>
            <PersonalDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "permissionGroup")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck mobile={true} keyPrivate={"permissionGroup"}>
            <GroupPermission />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "permissionGroupNew")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck mobile={true} keyPrivate={"permissionGroupNew"}>
            <PermissionGroupNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "permissionGroupDetails")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck mobile={true} keyPrivate={"permissionGroupDetails"}>
            <PermissionGroupDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredUpdateInformation"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredUpdateInformation"}>
            <PermissionsUpdateInformation />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredPersonnel"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredPersonnel"}>
            <PermissionsPersonnel />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredVacation"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredVacation"}>
            <PermissionsVacation />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredDismissals"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredDismissals"}>
            <PermissionsDismissals />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredLeave"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredLeave"}>
            <PermissionsLeave />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredPosition"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredPosition"}>
            <PermissionsPosition />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredPromote"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredPromote"}>
            <PermissionsPromote />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredSalaryReview"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredSalaryReview"}>
            <PermissionssalaryReview />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredTransfer"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredTransfer"}>
            <PermissionsTransfer />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionsRequiredForgetPassword"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck
            mobile={true}
            keyPrivate={"permissionsRequiredForgetPassword"}>
            <PermissionsForgetPassword />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "permissionsAudit")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck mobile={true} keyPrivate={"permissionsAudit"}>
            <PermissionsAudit />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "employeeNew")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"employeeNew"}>
            <EmployeeNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "permissionLetter")[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"permissionLetter"}>
            <LetterRequest />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter((item: any) => item.id === "permissionLetterNew")[0]
      .url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"permissionLetterNew"}>
            <LetterNew />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: routers.filter(
      (item: any) => item.id === "permissionLetterDetails"
    )[0].url,
    element: (
      <CompanyRouter>
        <ProtectedRoute>
          <PermissionCheck keyPrivate={"permissionLetterDetails"}>
            <LetterDetails />
          </PermissionCheck>
        </ProtectedRoute>
      </CompanyRouter>
    ),
    errorElement: <ErrorPage />,
  },
]);
