/** @format */

import {
  Cancel,
  Download,
  InfoOutlined,
  Undo,
  UploadFile,
  Warning,
  WarningAmberOutlined,
} from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { ClearIcon } from "@mui/x-date-pickers";
import { AlertDialog, Callout, Flex } from "@radix-ui/themes";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { optionsTable } from "../../../../../config/constants";
import { updateLoading } from "../../../../../redux/reducers/common";
import { PostInstanteFormData } from "../../../../../services/api";

const validationSchema = yup.object({
  file: yup.string().nullable().required("Arquivo é obrigatório"),
});

export const handleDownload = () => {
  const downloadLink = document.createElement("a");
  downloadLink.href = "/Modelo_Importacao.xlsx";
  downloadLink.download = "Modelo_Importacao.xlsx";
  downloadLink.click();
};

export function ImportNew() {
  const fileInputRef: any = useRef(null);
  const router = useNavigate();
  const [error, setError] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      const formData: any = new FormData();
      formData.append("excel_file", values.file);
      PostInstanteFormData(null, formData, "/file_imports")
        .then((response: any) => {
          dispatch(updateLoading(false));
          if (response.data.success) {
            router("/employee/import");
          } else {
            const errors: any = [];
            // eslint-disable-next-line array-callback-return
            response.data.errors.map((log: any) => {
              errors.push({ error: log });
            });
            setError(errors);
          }
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  const onFileDrop = (file: any) => {
    formik.setFieldValue("file", file);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleDragOver: any = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/leave'>
              Colaboradores
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/leave'>
              Importações
            </Link>
            <Typography fontWeight={800}>Nova importação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography>
                Realize por aqui a importação da folha de colaboradores.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid container>
          <Callout.Root color='red' size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <WarningAmberOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography>
                Para realiza-lá corretamente, por favor verifique se está
                compativel com o modelo disponibilizado. Só funcionará caso o
                arquivo esteja no formato .XLSX
              </Typography>
              <Typography>
                Todos os dados precisão estar registrados com o código do
                e-social, caso contrário pode haver falhas na importação.
              </Typography>
              <Typography>
                Uma vez iniciada a importação, não tem a opção de cancelar.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar importação
              </Typography>
            </Grid>
            <Grid container>
              <div
                style={{
                  border: `1px ${
                    formik.errors.file
                      ? theme.palette.error.main
                      : theme.palette.text.secondary
                  } dashed`,
                  color: formik.errors.file
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
                  cursor: "pointer",
                  borderRadius: 10,
                  width: "inherit",
                }}
                className='ContextMenuTrigger'
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={openFileDialog}>
                <input
                  type='file'
                  accept='.xlsx'
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: 200,
                    alignItems: "center",
                  }}>
                  <UploadFile style={{ marginRight: 12 }} /> Arraste e solte um
                  arquivo aqui ou clique para selecionar um arquivo.
                </p>
              </div>
            </Grid>
            {formik.errors.file && (
              <Grid container mt={0.5} ml={2}>
                <Typography
                  fontSize={"0.75rem"}
                  textAlign={"center"}
                  color={"error"}>
                  {formik.errors.file}
                </Typography>
              </Grid>
            )}
            {formik.values.file !== null && (
              <>
                <Grid container mt={2}>
                  <Typography>Arquivo anexado</Typography>
                </Grid>
                <Grid container mt={2} gap={2}>
                  <Tooltip title={"Remover arquivo"}>
                    <Chip
                      color={"primary"}
                      onDelete={() => {
                        formik.setFieldValue("file", null);
                      }}
                      label={formik.values.file?.name}
                      variant='outlined'
                    />
                  </Tooltip>
                </Grid>
              </>
            )}
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                variant={"outlined"}
                onClick={handleDownload}
                endIcon={<Download />}>
                Baixar modelo
              </Button>
              {error.length > 0 ? (
                <>
                  <Button
                    variant={"outlined"}
                    onClick={() => {
                      setError([]);
                      formik.resetForm();
                    }}
                    endIcon={<ClearIcon />}>
                    Limpar tudo
                  </Button>
                  <Button
                    variant={"contained"}
                    type={"submit"}
                    endIcon={<UploadFile />}>
                    Reenviar importação
                  </Button>
                </>
              ) : (
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <Button
                      variant={"contained"}
                      disabled={formik.values.file === null}
                      endIcon={<UploadFile />}>
                      Iniciar importação
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content maxWidth='450px'>
                    <AlertDialog.Title>
                      Deseja realmente realizar a importação?
                    </AlertDialog.Title>
                    <AlertDialog.Description size='2'>
                      Após a confirmação, não será possivel cancelar o processo.
                      Tenha absoluta certeza antes de realizar essa ação.
                      Verificou todos os avisos em tela e principalmente que
                      todos os dados estão de acordo com o e-social.
                    </AlertDialog.Description>
                    <Flex gap='3' mt='4' justify='end'>
                      <AlertDialog.Cancel>
                        <Button variant={"outlined"} endIcon={<Cancel />}>
                          Cancelar
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Action>
                        <Button
                          variant={"contained"}
                          type='submit'
                          onClick={formik.handleSubmit}
                          endIcon={<UploadFile />}>
                          Enviar
                        </Button>
                      </AlertDialog.Action>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              )}
            </Grid>
          </Paper>
          {error.length > 0 && (
            <>
              <Grid container gap={2} mt={2}>
                <Callout.Root color={"red"}>
                  <Callout.Icon>
                    <Warning />
                  </Callout.Icon>
                  <Callout.Text>
                    Foram encontrados erros na planilha, Verifique os erros
                    abaixo, Corrija-os e tente novamente.
                  </Callout.Text>
                </Callout.Root>
              </Grid>
              <Grid container mt={2}>
                <MUIDataTable
                  title={""}
                  data={error}
                  columns={[
                    {
                      name: "error",
                      label: "Falha",
                    },
                  ]}
                  options={{
                    ...optionsTable,
                    searchPlaceholder: "Pesquise por solicitante",
                    download: false,
                    searchAlwaysOpen: false,
                    search: false,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
}
