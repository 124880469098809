/** @format */

import { Button, Grid, Hidden, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModeTheme } from "../../../config/mode";

export function CreatePasswordPage() {
  const company = useSelector((state: any) => state.company);
  const router = useNavigate();
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      padding={10}>
      <Grid style={{ position: "absolute", top: 10, right: 10 }}>
        <ModeTheme />
      </Grid>
      <Grid item xs={12} lg={6} md={4}>
        <Grid container justifyContent={"center"}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            maxWidth={500}
            gap={2}>
            <img {...company.logoDefault} alt='Logo da empresa' />
            <Typography fontWeight='400' textAlign='center' fontSize={18}>
              Sua senha foi gerada com sucesso! Ela tem o seguinte padrão: 3
              últimos dígitos do cpf + dia de nascimento + ano de nascimento.
            </Typography>
            <Button
              fullWidth
              variant='outlined'
              onClick={() => router("/login")}>
              Atualizar senha
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item lg={6} md={8}>
          <Grid container justifyContent={"center"}>
            <img
              src={company.image}
              style={{
                objectFit: "contain",
                objectPosition: "center",
                height: "auto",
                borderRadius: 20,
                width: "80%",
                maxHeight: 700,
              }}
              width={0}
              height={0}
              sizes='100vw'
              alt='Imagem da empresa'
            />
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}
