/** @format */

import { Mail, MoreVert, Phone } from "@mui/icons-material";
import { Typography } from "@mui/material";
import {
  Avatar,
  Card,
  DropdownMenu,
  IconButton,
  Tooltip,
} from "@radix-ui/themes";
import { toast } from "react-toastify";
import { IsPermissions } from "../../../../config/isPermission";

export function CardUser({ navigate, company, user }: any) {
  return (
    <Card
      style={{ display: "inline-block", height: 150, width: 230, margin: 12 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}>
        <div style={{ display: "flex", gap: 8 }}>
          <Avatar
            size='2'
            src={user?.avatar}
            radius='full'
            fallback={user?.name.split("")[0] ?? ""}
          />
          <div>
            <Typography fontSize={12} fontWeight={800}>
              {user?.name.toUpperCase()}
            </Typography>
            {user?.job_role_name && (
              <Typography fontSize={10}>{user?.job_role_name}</Typography>
            )}
            {user?.email && (
              <Typography fontSize={10}>{user?.email}</Typography>
            )}
            {user?.phone && (
              <Typography fontSize={10}>{user?.phone}</Typography>
            )}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip
            content={
              !user?.phone
                ? "Sem número de telefone"
                : "Copiar número de telefone"
            }>
            <IconButton
              disabled={!user?.phone}
              color={company.primary}
              variant='ghost'
              style={{ cursor: "pointer", margin: 2 }}
              onClick={() => {
                toast.success("Número de telefone copiado com sucesso.");
                navigator.clipboard.writeText(user.phone);
              }}>
              <Phone fontSize={"small"} />
            </IconButton>
          </Tooltip>
          <Tooltip
            content={
              !user?.email
                ? "Sem endereço de e-mail"
                : "Copiar endereço de e-mail"
            }>
            <IconButton
              disabled={!user?.email}
              color={company.primary}
              variant='ghost'
              style={{ cursor: "pointer", margin: 2 }}
              onClick={() => {
                toast.success("Endereço de e-mail copiado com sucesso.");
                navigator.clipboard.writeText(user.email);
              }}>
              <Mail fontSize={"small"} />
            </IconButton>
          </Tooltip>
          {(user.userActive || user.userManager) && (
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <IconButton
                  style={{
                    cursor: "pointer",
                    margin: 2,
                  }}
                  color={company.primary}
                  variant='ghost'>
                  <Tooltip content='Ver ações'>
                    <MoreVert fontSize={"small"} />
                  </Tooltip>
                </IconButton>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <IsPermissions keyPrivate='requestUpdateInformationRequest'>
                  <DropdownMenu.Item
                    onClick={() =>
                      navigate(
                        `/request/updateInformation/request/${user.uuid}`
                      )
                    }
                    shortcut='->'>
                    Informações
                  </DropdownMenu.Item>
                </IsPermissions>
                {user.userManager && (
                  <>
                    <IsPermissions keyPrivate='requestLeaveNew'>
                      <DropdownMenu.Item
                        onClick={() => {
                          navigate(`/request/leave/new/${user.uuid}`);
                        }}
                        shortcut='->'>
                        Afastar
                      </DropdownMenu.Item>
                    </IsPermissions>
                    <IsPermissions keyPrivate='requestVacationNew'>
                      <DropdownMenu.Item
                        onClick={() =>
                          navigate(`/request/vacation/new/${user.uuid}`)
                        }
                        shortcut='->'>
                        Férias
                      </DropdownMenu.Item>
                    </IsPermissions>
                    <IsPermissions keyPrivate='requestDismissalsNew'>
                      <DropdownMenu.Item
                        onClick={() => {
                          navigate(`/request/dismissals/new/${user.uuid}`);
                        }}
                        shortcut='->'>
                        Desligar
                      </DropdownMenu.Item>
                    </IsPermissions>
                    <IsPermissions keyPrivate='vacationCollaboratorNew'>
                      <DropdownMenu.Item
                        onClick={() => navigate(`/vacation/new/${user.uuid}`)}
                        shortcut='->'>
                        Periodo de férias
                      </DropdownMenu.Item>
                    </IsPermissions>
                    <IsPermissions keyPrivate='requestPromoteNew'>
                      <DropdownMenu.Item
                        onClick={() =>
                          navigate(`/request/promote/new/${user.uuid}`)
                        }
                        shortcut='->'>
                        Promover
                      </DropdownMenu.Item>
                    </IsPermissions>
                    <IsPermissions keyPrivate='requestSalaryReviewNew'>
                      <DropdownMenu.Item
                        onClick={() =>
                          navigate(`/request/salaryReview/new/${user.uuid}`)
                        }
                        shortcut='->'>
                        Remuneração
                      </DropdownMenu.Item>
                    </IsPermissions>
                    <IsPermissions keyPrivate='requestTransferManagerNew'>
                      <DropdownMenu.Item
                        onClick={() => {
                          navigate(
                            `/request/transfer/manager/new/${user.uuid}`
                          );
                        }}
                        shortcut='->'>
                        Transferir
                      </DropdownMenu.Item>
                    </IsPermissions>
                    <IsPermissions keyPrivate='requestTransferNew'>
                      <DropdownMenu.Item
                        onClick={() => {
                          navigate(`/request/transfer/new/${user.uuid}`);
                        }}
                        shortcut='->'>
                        Transferir
                      </DropdownMenu.Item>
                    </IsPermissions>
                  </>
                )}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          )}
        </div>
      </div>
    </Card>
  );
}
