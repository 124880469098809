/** @format */

import { Edit, InfoOutlined, Undo, Visibility } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionAccountingReturned } from "../../../../../components/actionAccountingReturned";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { motive_e_social } from "../../../../../config/constants";
import { IsPermissions } from "../../../../../config/isPermission";
import { EmptyData } from "../../../../../helpers/emptyData";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";

export function SalaryReviewDetails() {
  const router = useNavigate();
  const { id } = useParams();
  const user = useSelector((state: any) => state.user.data);

  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
    rules_data: {
      salary_review_min_percent: 0,
      salary_review_max_percent: 0,
    },
  });
  const permissions = useSelector((state: any) => state.user.permissions);
  const edit =
    permissions !== null &&
    permissions.indexOf("requestSalaryReviewDetailsEdit") !== -1 &&
    (data?.solicitation_data.status === "pending" ||
      (user.role === "rh" &&
        data?.solicitation_data.status === "waiting_rh_user"));

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    move_date: yup
      .date()
      .min(new Date(), "Data da movimentação precisa ser futura")
      .nullable()
      .required("Data da movimentação é obrigatório"),
    e_social_motive: yup
      .string()
      .nullable()
      .required("Motivo da revisão é obrigatório"),
    observations: yup.string().nullable().required("Comentário é obrigatório"),
    percent: yup
      .number()
      .nullable()
      .test(
        "between-min-and-max-or-rh-user",
        `O valor deve ser entre ${data.rules_data.salary_review_min_percent}% e ${data.rules_data.salary_review_max_percent}%`,
        (value: any) =>
          value >= data.rules_data.salary_review_min_percent &&
          value <= data.rules_data.salary_review_max_percent
      )
      .required("Porcentagem é obrigatório"),
    future_salary: yup
      .string()
      .nullable()
      .min(yup.ref("actual_salary"), "Salário não pode ser menor que o atual.")
      .required("Salário futuro é obrigatório"),
  });

  const formik: any = useFormik({
    initialValues: {
      e_social_motive: "",
      move_date: null,
      percent: "",
      future_salary: "",
      observations: "",
      actual_salary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          ...values,
          future_salary: ClearCurrency(values.future_salary),
          percent: Number(values.percent),
        },
        "/salary_reviews/" + id
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/request/salaryReview");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/salary_reviews/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData(data);
        const move_date = dayjs(data.record.move_date);
        formik.setValues({
          e_social_motive: data.record.e_social_motive,
          move_date,
          percent: data.record.percent.toString(),
          future_salary: FormatCurrency({
            target: {
              value: `${data.record.future_salary}` || "0",
            },
          }),
          observations: data.record.observations,
          actual_salary: ClearCurrency(
            data.collaborator_data.remuneration_value
          ),
          rules_data: data.rules_data,
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.collaborator_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.collaborator_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_data.job_role_name),
    },
    {
      title: "Salário atual",
      value: data.collaborator_data.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_data.status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/salaryReview'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/salaryReview'>
              Revisão salarial
            </Link>
            <Typography fontWeight={800}>Ver solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"} mt={2}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid key={item.title}>
                      <Typography fontSize={12} fontWeight={600}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={400}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da revisão salarial
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='e_social_motive'
                select
                label='Motivo da revisão'
                value={formik.values.e_social_motive}
                disabled={!edit}
                onChange={(event: any) =>
                  formik.setFieldValue("e_social_motive", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.e_social_motive &&
                  Boolean(formik.errors.e_social_motive)
                }
                helperText={
                  formik.touched.e_social_motive &&
                  formik.errors.e_social_motive
                }>
                {motive_e_social.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "move_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.move_date &&
                      Boolean(formik.errors.move_date),
                    helperText:
                      formik.touched.move_date && formik.errors.move_date,
                  },
                }}
                disabled={!edit}
                label='Data da movimentação'
                value={formik.values.move_date}
                onChange={(value: any) => {
                  formik.setFieldValue("move_date", value);
                }}
              />
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id='percent'
                type={"number"}
                label='Porcentagem (%)'
                value={formik.values.percent}
                disabled={!edit}
                onChange={(event: any) => {
                  formik.setFieldValue("percent", event.target.value);
                  formik.setFieldValue(
                    "future_salary",
                    MaskCurrency(
                      ((parseFloat(event.target.value) + 100) *
                        parseFloat(formik.values.actual_salary)) /
                        100
                    )
                  );
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.percent && Boolean(formik.errors.percent)}
                helperText={formik.touched.percent && formik.errors.percent}
              />
              <TextField
                fullWidth
                id='future_salary'
                label='Salário futuro'
                value={formik.values.future_salary}
                disabled={!edit}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "percent",
                    ReadjustSalary(
                      Number(formik.values.actual_salary),
                      ClearCurrency(FormatCurrency(event))
                    )
                  );
                  formik.setFieldValue("future_salary", FormatCurrency(event));
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.future_salary &&
                  Boolean(formik.errors.future_salary)
                }
                helperText={
                  formik.touched.future_salary && formik.errors.future_salary
                }
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Comentários'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  disabled={!edit}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              {(data.solicitation_data.status === "pending" ||
                (user.role === "rh" &&
                  data?.solicitation_data.status === "waiting_rh_user")) && (
                <>
                  {edit && (
                    <Button
                      variant={"contained"}
                      type={"submit"}
                      fullWidth={isMobile}
                      endIcon={<Edit />}>
                      Editar
                    </Button>
                  )}
                  <ActionDisapproved
                    keyPrivate={"requestSalaryReviewDetailsGiveBack"}
                    id={id}
                    url={"salary_reviews"}
                  />
                  <ActionAproved
                    keyPrivate={"requestSalaryReviewDetailsApprove"}
                    id={id}
                    url={"salary_reviews"}
                  />
                </>
              )}

              {user.role === "accountant" &&
                data?.solicitation_data.status === "approved" && (
                  <>
                    <ActionCompleted
                      keyPrivate={"requestDetailsComplete"}
                      id={id}
                      url={"processes"}
                    />

                    <ActionAccountingReturned
                      keyPrivate={"requestDetailsComplete"}
                      id={id}
                      url={"processes"}
                    />
                  </>
                )}

              {data.solicitation_data.status === "approved" && (
                <IsPermissions keyPrivate={"requestSalaryReviewDetailsView"}>
                  <Button
                    fullWidth={isMobile}
                    onClick={() =>
                      router("/request/salaryReview/details/letter/" + id)
                    }
                    variant={"contained"}
                    type={"submit"}
                    endIcon={<Visibility color='primary' />}>
                    Ver carta de comunicação
                  </Button>
                </IsPermissions>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
