/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { AddCircle, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { EmptyData } from "../../../../helpers/emptyData";
import { FormatStatus } from "../../../../helpers/formatStatus";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";

const validationSchema = yup.object().shape({
  acquisition_period_start: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido."),
  acquisition_period_end: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data final de aquisição não pode ser anterior a data inicial",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.acquisition_period_start).isValid() &&
          dayjs(all.options.context.acquisition_period_end).isValid()
        ) {
          return (
            new Date(all.options.context.acquisition_period_start).getTime() <
            new Date(all.options.context.acquisition_period_end).getTime()
          );
        }
        return false;
      }
    ),
  concessive_period_start: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data incial de concessão não pode ser anterior a data final de aquisição",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.acquisition_period_end).isValid() &&
          dayjs(all.options.context.concessive_period_start).isValid()
        ) {
          return (
            new Date(all.options.context.acquisition_period_end).getTime() <
            new Date(all.options.context.concessive_period_start).getTime()
          );
        }

        return false;
      }
    ),
  concessive_period_end: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data final de concessão não pode ser anterior a data inicial",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.concessive_period_end).isValid() &&
          dayjs(all.options.context.concessive_period_start).isValid()
        ) {
          return (
            new Date(all.options.context.concessive_period_end).getTime() >
            new Date(all.options.context.concessive_period_start).getTime()
          );
        }
        return false;
      }
    ),
  days_available: yup
    .number()
    .min(0, "Mínimo de 0")
    .max(30, "Máximo de 30")
    .required("Campo obrigatório"),
  days_used: yup
    .number()
    .min(0, "Mínimo de 0")
    .max(30, "Máximo de 30")
    .required("Campo obrigatório"),
});

export function VacationNewTime() {
  const router = useNavigate();
  const { id } = useParams();
  const [user, setUser]: any = useState({});
  const [valide, setValide] = useState(true);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      concessive_period_start: null,
      concessive_period_end: null,
      acquisition_period_start: null,
      acquisition_period_end: null,
      days_available: "",
      days_used: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        { ...values, target_user_uuid: user.uuid },
        "/vacations"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/vacation/time");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/vacations_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser({
          ...response.data.data.user_data,
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: user.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(user.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(user.job_role_name),
    },
    {
      title: "Status",
      value: FormatStatus(user.status),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/vacation/time'>
              Férias
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/vacation/time'>
              Periodo
            </Link>
            <Typography fontWeight={800}>Novo periodo</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid>
                    <Typography fontSize={12} fontWeight={400}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={300}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Typography fontSize={16} mt={2} fontWeight={800}>
                Instruções
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                <Typography fontSize={12} fontWeight={400}>
                  Nesta tela você cria de forma manual um periodo para um
                  colaborador.
                </Typography>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar periodo de férias
              </Typography>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              display={"grid"}
              mt={2}
              gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `acquisition_period_start`,
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.acquisition_period_start),
                    helperText: formik.errors.acquisition_period_start,
                  },
                }}
                label='Data de aquisição inicial'
                value={formik.values.acquisition_period_start}
                onChange={(value: any) => {
                  formik.setFieldValue(`acquisition_period_start`, value);
                  formik.setFieldValue(
                    `concessive_period_start`,
                    value.add(1, "year")
                  );
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `acquisition_period_end`,
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.acquisition_period_end),
                    helperText: formik.errors.acquisition_period_end,
                  },
                }}
                label='Data de aquisição final'
                value={formik.values.acquisition_period_end}
                onChange={(value: any) => {
                  formik.setFieldValue(`acquisition_period_end`, value);
                  formik.setFieldValue(
                    `concessive_period_end`,
                    value.add(1, "year")
                  );
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `concessive_period_start`,
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.concessive_period_start),
                    helperText: formik.errors.concessive_period_start,
                  },
                }}
                label='Data de inicio da concessão'
                value={formik.values.concessive_period_start}
                onChange={(value: any) => {
                  formik.setFieldValue(`concessive_period_start`, value);
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: `concessive_period_end`,
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.concessive_period_end),
                    helperText: formik.errors.concessive_period_end,
                  },
                }}
                label='Data final da concessão'
                value={formik.values.concessive_period_end}
                onChange={(value: any) => {
                  formik.setFieldValue(`concessive_period_end`, value);
                }}
              />
              <TextField
                fullWidth
                id={`days_available`}
                type='number'
                label='Quantidade de dias disponiveis'
                variant='outlined'
                value={formik.values.days_available}
                onChange={(event: any) => {
                  formik.setFieldValue(`days_available`, event.target.value);
                }}
                error={Boolean(formik.errors.days_available)}
                helperText={formik.errors.days_available}
              />
              <TextField
                fullWidth
                id={`days_used`}
                type='number'
                label='Quantidade de dias usados'
                variant='outlined'
                value={formik.values.days_used}
                onChange={(event: any) => {
                  formik.setFieldValue(`days_used`, event.target.value);
                }}
                error={Boolean(formik.errors.days_used)}
                helperText={formik.errors.days_used}
              />
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                variant={"contained"}
                onClick={() => {
                  setValide(true);
                  formik.handleSubmit();
                }}
                endIcon={<AddCircle />}>
                Criar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
