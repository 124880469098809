/** @format */

import { Download, InfoOutlined, Undo } from "@mui/icons-material";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatCurrency } from "../../../../../helpers/formatCurrency";
import { FormatDate } from "../../../../../helpers/formatDate";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";
import "./styles.css";

export function SalaryReviewLetter() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
    record: {},
  });
  const company = useSelector((state: any) => state.company);

  useEffect(() => {
    GetInstance({}, "/salary_reviews/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData(response.data.data);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.collaborator_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.collaborator_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_data.job_role_name),
    },
    {
      title: "Salário atual",
      value: data.collaborator_data.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_data.status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  return (
    <>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/salaryReview'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/salaryReview'>
              Revisão salarial
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href={"/request/salaryReview/details/" + id}>
              Detalhes da solicitação
            </Link>
            <Typography fontWeight={800}>Carta de comunicação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"} mt={2}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid key={item.title}>
                      <Typography fontSize={12} fontWeight={600}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={400}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Carta de comunicação
              </Typography>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr"}
              display={"grid"}
              gap={2}
              className='printable-content'>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <img {...company.logoSmall} alt='Logo da empresa' />
                <div style={{ gap: 12, display: "flex" }}>
                  {company?.logos?.map((item: any) => (
                    <img {...item} alt='Logo da empresa' />
                  ))}
                </div>
              </div>
              <Typography fontSize={16} color={"primary"} fontWeight={500}>
                {data.record.e_social_motive?.toUpperCase()}
              </Typography>
              <Typography fontSize={12}>
                Prezado(a) {data.collaborator_data.name},
              </Typography>
              <Typography fontSize={12}>
                A {company.company?.split("-")[0]} reconhece sua dedicação,
                empenho e contribuição pela busca constante de resultados,
                motivo pelo qual gostaríamos de parabenizá-lo(a) pela conquista
                de uma importante etapa em sua carreira.
              </Typography>
              <Typography fontSize={12}>
                Temos a convicção de que profissionais como você transformam
                desafios em realidade, consolidando nossa posição como uma
                empresa competitiva e líder de mercado.
              </Typography>
              <Typography fontSize={12}>
                Por essas razões, informamos que você está recebendo um mérito
                que reflete nossa confiança no seu potencial. Você está
                recebendo um aumento de {data.record.percent?.toString()}% e seu
                salário passará a ser{" "}
                {FormatCurrency({
                  target: { value: `${data.record.future_salary}` },
                }) || 0}{" "}
                a partir de {FormatDate(data.record.move_date)}.
              </Typography>
              <Typography fontSize={12}>Parabéns</Typography>
              <Typography fontSize={12}>
                {data.solicitation_data.created_by}
              </Typography>
              <Typography fontSize={12}>
                _____________________________________
              </Typography>
            </Grid>
          </Paper>
          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <Button
              variant={"outlined"}
              onClick={() => {
                window.history.back();
              }}
              fullWidth={isMobile}
              endIcon={<Undo />}>
              Voltar
            </Button>
            <Button
              fullWidth={isMobile}
              variant={"contained"}
              onClick={() => window.print()}
              endIcon={<Download />}>
              Baixar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
