/** @format */

import { AddCircle, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";

const validationSchema = yup.object({
  transfer_date: yup
    .date()
    .min(new Date(), "Data da tranferência precisa ser futura")
    .nullable()
    .required("Data da tranferência é obrigatório"),

  new_manager_id: yup.string().nullable().required("Esse campo é obrigatório"),

  observations: yup.string().nullable().required("Comentário é obrigatório"),
});

export function TransferNew() {
  const router = useNavigate();
  const [data, setData]: any = useState({
    options: {
      managers: [],
    },
    user_data: {},
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      transfer_date: null,
      observations: "",
      new_manager_id: "",
      with_position: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        { ...values, collaborator_id: data.user_data.id },
        "/transfers"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/request/transfer");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });
  useEffect(() => {
    GetInstance({ user_uuid: id }, "/transfers/create_form")
      .then((response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData((old: any) => ({ ...old, ...data }));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.user_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.user_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.user_data.job_role_name),
    },
    {
      title: "Salário atual",
      value: data.user_data.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(data.user_data.status),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/transfer'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/transfer'>
              Transferências
            </Link>
            <Typography fontWeight={800}>Criar solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"} mt={2}>
                <Typography fontSize={16} fontWeight={800}>
                  Instruções
                </Typography>
                <Typography fontSize={12} fontWeight={400}>
                  Para transferir o colaborador confira as informações abaixo e
                  digite os detalhes da transferência.
                </Typography>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar transferência
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <FormControlLabel
                id={"with_position"}
                checked={formik.values.with_position}
                onChange={(event: any) => {
                  formik.setFieldValue("with_position", event.target.checked);
                }}
                control={<Checkbox />}
                label='Transferência com a posição ?'
              />
            </Grid>

            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id='new_manager_id'
                select
                label='Gestor Futuro'
                value={formik.values.new_manager_id}
                onChange={(event: any) =>
                  formik.setFieldValue("new_manager_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.new_manager_id &&
                  Boolean(formik.errors.new_manager_id)
                }
                helperText={
                  formik.touched.new_manager_id && formik.errors.new_manager_id
                }>
                {data.options.managers.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.name}
                  </MenuItem>
                ))}
              </TextField>

              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "transfer_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.transfer_date &&
                      Boolean(formik.errors.transfer_date),
                    helperText:
                      formik.touched.transfer_date &&
                      formik.errors.transfer_date,
                  },
                }}
                label='Data da transferência'
                value={formik.values.transfer_date}
                onChange={(value: any) => {
                  formik.setFieldValue("transfer_date", value);
                }}
              />
            </Grid>

            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Comentários'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <Grid container gap={2} justifyContent={"end"}>
                <Button
                  fullWidth={isMobile}
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  endIcon={<Undo />}>
                  Voltar
                </Button>
                <Button
                  fullWidth={isMobile}
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<AddCircle />}>
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
