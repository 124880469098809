/** @format */

import axios from "axios";
import Cookies from "js-cookie";

// export const baseAPI = "https://api-development.3clicksapp.com.br/api/v2/";
export const baseAPI =
  process.env.REACT_APP_API_URL ?? "https://api.3clicksapp.com.br/api/v2/";
const CancelToken = axios.CancelToken;
let cancel: any;

// @ts-ignore
export const request = () =>
  axios.create({
    // baseURL: `${process.env.API_URL}`,
    baseURL: baseAPI,
    headers: {
      authorization: `Bearer ${Cookies.get("auth")}`,
      company: Cookies.get("company")?.split("-")[0] ?? "3ClicksRH",
      "accept-language": "pt-BR",
    },
  });

const requestFormData = () =>
  axios.create({
    baseURL: baseAPI,
    headers: {
      authorization: `Bearer ${Cookies.get("auth")}`,
      company: Cookies.get("company")?.split("-")[0] ?? "3ClicksRH",
      "accept-language": "pt-BR",
      "Content-Type": "multipart/form-data",
    },
  });

export const PostInstante = (id: any, values: any, route?: string) => {
  return request().post(`${route}${id ? `/${id}` : ""}`, values);
};

export const PostInstanteFormData = (
  id: any,
  formData: any,
  route?: string
) => {
  return requestFormData().post(`${route}${id ? `/${id}` : ""}`, formData);
};

export const PutInstante = (id: any, values: any, route?: string) => {
  return request().put(`${route}${id ? `/${id}` : ""}`, values);
};

export const PutInstanteFormData = (id: any, formData: any, route?: string) => {
  return requestFormData().put(`${route}${id ? `/${id}` : ""}`, formData);
};

export const PatchInstance = (id: any, values: any, route?: string) => {
  return request().patch(`${route}${id ? `/${id}` : ""}`, values);
};

export const RemoveInstance = (id: any, route?: string) => {
  return request().delete(`${route}/${id}`);
};

export const GetInstance = (values: any, route?: any) => {
  if (cancel && cancel[route]) {
    cancel[route]();
  }

  return request().get(`${route}${values?.id ? `/${values?.id}` : ""}`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = { ...cancel, [route]: c };
    }),
    params: {
      ...values,
    },
  });
};
