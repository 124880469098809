/** @format */
import {
  Checkbox,
  Breadcrumbs,
  Button,
  DialogActions,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Dialog, Flex } from "@radix-ui/themes";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { optionsTable } from "../../../../config/constants";
import { FilterEmpty } from "../../../../helpers/emptyData";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";
import { Cancel, CheckCircle } from "@mui/icons-material";

const roles: { [key: string]: number } = {
  Colaborador: 0,
  "Recursos humanos": 2,
  Gestor: 1,
  Contador: 3,
};

let timerSearch: any;

export function UsersRole() {
  const dispatch = useDispatch();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const [changedData, setChangedData] = useState<Record<string, keyof typeof roles>>({});
  const [modalText, setModalText] = useState({ title: "", description: "" });

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      {
        page: page + 1,
        per_page: perPage,
        search,
        order_by: order,
        status: "active",
      },
      "users"
    )
      .then((response: any) => {
        setData((old: any) =>
          page === 0
            ? FilterEmpty(response.data.data.data.data)
            : [...old, ...FilterEmpty(response.data.data.data.data)]
        );
        setTotal(response.data.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);

  const handleClickSave = () => {
    const reqBody = Object.entries(changedData).map(([id, role]) => ({
      id: parseInt(id, 10), // Converte a chave para número
      role: roles[role],
    }));

    if (Object.keys(changedData).length === 0) {
      toast.error("Nenhuma alteração realizada");
      setOpenModal(false);
    } else {
      PostInstante(null, { users: reqBody }, "/users/usersRole")
        .then((response: any) => {
          setChangedData({});
          setOpenModal(false);
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    }
  };

  const handleClickDontSave = () => {
    setChangedData({});
    setOpenModal(false);
  }

  const handleCheckboxChange = (dataIndex: any, role: string) => {
    setChangedData((prevChangedData: any) => {
      return {
        ...prevChangedData,
        [data[dataIndex].id]: role,
      };
    });

    setData((prevData: any) => {
      const newData = [...prevData];
      newData[dataIndex].role = role;
      return newData;
    })
  };

  return (
    <Grid container gap={2}>
      <Dialog.Root open={openModal}>
        <Dialog.Content>
          <Dialog.Title>{modalText.title}</Dialog.Title>
          <Dialog.Description size='2' mb='4'>
            {modalText.description}
          </Dialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <DialogActions>
              <Button
                variant={"outlined"}
                onClick={handleClickDontSave}
                endIcon={<Cancel />}
              >
                Não Salvar
              </Button>
            </DialogActions>

            <DialogActions>
              <Button
                variant={"contained"}
                onClick={handleClickSave}
                endIcon={<CheckCircle />}
              >
                Salvar
              </Button>
            </DialogActions>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>

      <Grid container>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/settings/usersRole'>
            Configurações
          </Link>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/settings/usersRole'>
            Perfis de usuários
          </Link>
          <Typography fontWeight={800}>Ver detalhes</Typography>
        </Breadcrumbs>
      </Grid>

      <Grid container justifyContent={"center"}>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "username",
              label: "Username",
            },
            {
              name: "name",
              label: "Nome",
            },
            {
              name: "colaborador",
              label: "Colaborador",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <Checkbox
                      checked={data[dataIndex].role === "Colaborador"}
                      onChange={() => handleCheckboxChange(dataIndex, "Colaborador")}
                    />
                  )
                },
              }
            },
            {
              name: "Gestor",
              label: "gestor",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <Checkbox
                      checked={data[dataIndex].role === "Gestor"}
                      onChange={() => handleCheckboxChange(dataIndex, "Gestor")}
                    />
                  )
                },
              }
            },
            {
              name: "rh",
              label: "RH",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <Checkbox
                      checked={data[dataIndex].role === "Recursos humanos"}
                      onChange={() => handleCheckboxChange(dataIndex, "Recursos humanos")}
                    />
                  )
                },
              }
            },
            {
              name: "accountant",
              label: "Contador",
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <Checkbox
                      checked={data[dataIndex].role === "Contador"}
                      onChange={() => handleCheckboxChange(dataIndex, "Contador")}
                    />
                  )
                },
              }
            }
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome ou CPF",
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              if (Object.keys(changedData).length > 0) {
                setModalText({
                  title: "Você não salvou as alterações, Deseja salvá-las?",
                  description: "Você perderá as alterações realizadas.",
                })
                setOpenModal(true);
              }
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
        <Flex width={"100%"} mt="4" justify="end">
          <Button
            variant={"contained"}
            onClick={() => {
              setModalText({
                title: "Deseja realmente salvar essas alterações?",
                description: "Você pode fazer alterações a qualquer momento. Deseja salvar as mudanças feitas até agora?",
              })
              setOpenModal(true)
            }}
          >
            Salvar
          </Button>
        </Flex>
      </Grid>
    </Grid>
  );
}
