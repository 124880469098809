/** @format */
import { MoreVert } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DropdownMenu } from "@radix-ui/themes";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Filter } from "../../../../components/filter";
import { optionsTable } from "../../../../config/constants";
import { IsPermissions } from "../../../../config/isPermission";
import { FilterEmpty } from "../../../../helpers/emptyData";
import { exportDataToCSV } from "../../../../helpers/exportDataToCSV";
import { FormatDate } from "../../../../helpers/formatDate";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";

const columns = [
  { name: "identification", label: "Matrícula" },
  { name: "name_formatted", label: "Nome" },
  { name: "cpf", label: "CPF" },
  { name: "job_role_name", label: "Cargo" },
  { name: "manager_name", label: "Gestor" },
  { name: "department_name", label: "Departamento" },
  { name: "status", label: "Status" },
];

const statusOptions = [
  { label: "Todos", value: "all" },
  { label: "Ativo", value: "active" },
  { label: "Afastado", value: "on_leave" },
];

let timerSearch: any;

export function Active() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const [data, setData]: any = useState([]);
  const [options, setOptions]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const [openModalDismissal, setOpenModalDismissal] = useState({
    open: false,
    type: "",
    uuid: "",
    date: "",
  });

  const formik: any = useFormik({
    initialValues: {
      job_role_id: "all",
      manager_id: "all",
      department_id: "all",
      status: "all",
    },
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      GetInstance(
        {
          job_role_id: values.job_role_id,
          manager_id: values.manager_id,
          department_id: values.department_id,
          status: values.status,
          page: page + 1,
          per_page: perPage,
          search,
          order_by: order,
        },
        "users"
      )
        .then((response: any) => {
          setData((old: any) =>
            page === 0
              ? FilterEmpty(response.data.data.data.data)
              : [...old, ...FilterEmpty(response.data.data.data.data)]
          );
          setTotal(response.data.data.data.total_count);

          const dataOptions = response.data.data.options;

          const validOptions = {
            job_roles: dataOptions?.job_roles ?? [],
            managers: dataOptions?.managers ?? [],
            departments: dataOptions?.departments ?? [],
          };

          validOptions.job_roles.unshift({
            label: "Todos",
            value: "all",
          });
          validOptions.managers.unshift({
            label: "Todos",
            value: "all",
          });
          validOptions.departments.unshift({
            label: "Todos",
            value: "all",
          });

          setOptions(validOptions);

          dispatch(updateLoading(false));
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  const handleDownloadButton = async () => {
    dispatch(updateLoading(true));
    const values = formik.values;
    GetInstance(
      {
        job_role_id: values.job_role_id,
        manager_id: values.manager_id,
        department_id: values.department_id,
        status: values.status,
      },
      "/users/export_csv"
    )
      .then((response: any) => {
        exportDataToCSV(response.data, "Colaboradores");
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  };

  const handleClose = () => {
    setOpenModalDismissal((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);


  return (
    <Grid container gap={2}>
      <Dialog
        open={openModalDismissal.open}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'>
        <DialogTitle color='InfoText'>Em periodo de estabilidade</DialogTitle>
        <DialogContent>
          <DialogContentText
            gap={1}
            color='InfoText'
            display='flex'
            flexDirection='column'
            id='alert-dialog-slide-description'>
            <Typography variant='subtitle1'>
              Até o dia: {openModalDismissal.date}
            </Typography>
            <Typography variant='subtitle1'>
              Motivo: {openModalDismissal.type}
            </Typography>
            <Typography variant='subtitle1'>
              Para solicitar o desligamento acione o RH
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
          <Button
            variant='contained'
            fullWidth
            sx={{ alignSelf: "center" }}
            onClick={() => {
              if (user?.role === "rh") {
                router(`/request/dismissals/new/${openModalDismissal.uuid}`);
              } else {
                handleClose();
              }
            }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link
          underline='hover'
          sx={{ cursor: "pointer" }}
          color='inherit'
          href='/'>
          Inicio
        </Link>
        <Typography fontWeight={400}>Colaboradores</Typography>
      </Breadcrumbs>
      <Filter
        formik={formik}
        keys={{
          job_role_id: { label: "Cargo", options: options?.job_roles ?? [] },
          manager_id: { label: "Gestor", options: options?.managers ?? [] },
          department_id: {
            label: "Departamento",
            options: options?.departments ?? [],
          },
          status: { label: "Status", options: statusOptions },
        }}>
        <TextField
          fullWidth
          id='job_role_id'
          name='job_role_id'
          select
          label='Cargo'
          value={formik.values.job_role_id}
          onChange={(event: any) =>
            formik.setFieldValue("job_role_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.job_role_id && Boolean(formik.errors.job_role_id)
          }
          helperText={formik?.errors?.job_role_id}>
          {options?.job_roles?.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='manager_id'
          name='manager_id'
          select
          label='Gestor'
          value={formik.values.manager_id}
          onChange={(event: any) =>
            formik.setFieldValue("manager_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.manager_id && Boolean(formik.errors.manager_id)}
          helperText={formik?.errors?.manager_id}>
          {options?.managers?.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='department_id'
          name='department_id'
          select
          label='Departamento'
          value={formik.values.department_id}
          onChange={(event: any) =>
            formik.setFieldValue("department_id", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.department_id && Boolean(formik.errors.department_id)
          }
          helperText={formik?.errors?.department_id}>
          {options?.departments?.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='status'
          name='status'
          select
          label='Status'
          value={formik.values.status}
          onChange={(event: any) =>
            formik.setFieldValue("status", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.status && Boolean(formik.errors.status)}
          helperText={formik?.errors?.status}>
          {statusOptions.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Filter>
      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "AÇÕES",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger>
                        <Tooltip title={"Ver ações"}>
                          <IconButton>
                            <MoreVert />
                          </IconButton>
                        </Tooltip>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content>
                        <IsPermissions keyPrivate='requestUpdateInformationRequest'>
                          <DropdownMenu.Item
                            onClick={() =>
                              router(
                                `/request/updateInformation/request/${data[dataIndex].uuid}`
                              )
                            }
                            shortcut='->'>
                            Informações
                          </DropdownMenu.Item>
                        </IsPermissions>
                        {user.identification !==
                          data[dataIndex].identification && (
                          <>
                            <IsPermissions keyPrivate='requestLeaveNew'>
                              <DropdownMenu.Item
                                onClick={() => {
                                  if (data[dataIndex].status === "Afastado") {
                                    PostInstante(
                                      null,
                                      null,
                                      `/users/${data[dataIndex].uuid}/user_absence_id`
                                    ).then((res) => {
                                      router(
                                        `/request/leave/new/${data[dataIndex].uuid}`
                                      );
                                    });
                                    return;
                                  }
                                  router(
                                    `/request/leave/new/${data[dataIndex].uuid}`
                                  );
                                }}
                                shortcut='->'>
                                Afastar
                              </DropdownMenu.Item>
                            </IsPermissions>
                            <IsPermissions keyPrivate='requestVacationNew'>
                              <DropdownMenu.Item
                                onClick={() =>
                                  router(
                                    `/request/vacation/new/${data[dataIndex].uuid}`
                                  )
                                }
                                shortcut='->'>
                                Férias
                              </DropdownMenu.Item>
                            </IsPermissions>
                            <IsPermissions keyPrivate='requestDismissalsNew'>
                              <DropdownMenu.Item
                                onClick={() => {
                                  if (
                                    data[dataIndex]
                                      .business_unit_job_security_date &&
                                    new Date(
                                      data[
                                        dataIndex
                                      ].business_unit_job_security_date
                                    ) >= new Date()
                                  ) {
                                    setOpenModalDismissal({
                                      open: true,
                                      uuid: data[dataIndex].uuid,
                                      type: data[dataIndex]
                                        .business_unit_job_security_type,
                                      date: FormatDate(
                                        data[dataIndex]
                                          .business_unit_job_security_date
                                      ),
                                    });
                                  } else {
                                    router(
                                      `/request/dismissals/new/${data[dataIndex].uuid}`
                                    );
                                  }
                                }}
                                shortcut='->'>
                                Desligar
                              </DropdownMenu.Item>
                            </IsPermissions>
                            <IsPermissions keyPrivate='vacationCollaboratorNew'>
                              <DropdownMenu.Item
                                onClick={() =>
                                  router(
                                    `/vacation/new/${data[dataIndex].uuid}`
                                  )
                                }
                                shortcut='->'>
                                Periodo de férias
                              </DropdownMenu.Item>
                            </IsPermissions>
                            <IsPermissions keyPrivate='requestPromoteNew'>
                              <DropdownMenu.Item
                                onClick={() =>
                                  router(
                                    `/request/promote/new/${data[dataIndex].uuid}`
                                  )
                                }
                                shortcut='->'>
                                Promover
                              </DropdownMenu.Item>
                            </IsPermissions>
                            <IsPermissions keyPrivate='requestSalaryReviewNew'>
                              <DropdownMenu.Item
                                onClick={() =>
                                  router(
                                    `/request/salaryReview/new/${data[dataIndex].uuid}`
                                  )
                                }
                                shortcut='->'>
                                Remuneração
                              </DropdownMenu.Item>
                            </IsPermissions>
                            <IsPermissions keyPrivate='requestTransferManagerNew'>
                              <DropdownMenu.Item
                                onClick={() => {
                                  router(
                                    `/request/transfer/manager/new/${data[dataIndex].uuid}`
                                  );
                                }}
                                shortcut='->'>
                                Transferir
                              </DropdownMenu.Item>
                            </IsPermissions>
                            <IsPermissions keyPrivate='requestTransferNew'>
                              <DropdownMenu.Item
                                onClick={() => {
                                  router(
                                    `/request/transfer/new/${data[dataIndex].uuid}`
                                  );
                                }}
                                shortcut='->'>
                                Transferir
                              </DropdownMenu.Item>
                            </IsPermissions>
                          </>
                        )}
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome ou CPF",
          download: true,
          onDownload: () => {
            handleDownloadButton();
            return false;
          },
          onSearchChange: (value: string) => {
            clearTimeout(timerSearch);
            timerSearch = setTimeout(() => {
              setPage(0);
              setSearch(value);
            }, 500);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
