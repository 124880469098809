/** @format */

import { Notifications } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainCard from "./mainCard";
import NotificationList from "./notificationList";
import Transitions from "./transitions";

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const anchorRef: any = useRef(null);
  const router = useNavigate();
  const data = useSelector((state: any) => state.common);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box
        sx={{
          ml: 1,
        }}>
        <Tooltip title={"Ver notificações"}>
          <Badge badgeContent={data.totalNotifications} color='primary'>
            <Avatar
              sx={{
                transition: "all .2s ease-in-out",
                background: "transparent",
                color: theme.palette.grey.A700,
                "&:hover": {
                  background: theme.palette.grey.A700,
                  color: theme.palette.common.white,
                },
              }}
              variant={"rounded"}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
              color='inherit'>
              <Notifications />
            </Avatar>
          </Badge>
        </Tooltip>
      </Box>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}>
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}>
                  <Grid container direction='column' spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ pt: 2, px: 2 }}>
                        <Grid item>
                          <Stack direction='row' spacing={2}>
                            <Typography variant='subtitle1'>
                              Notificações
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction='column' spacing={2}>
                        <Grid item xs={12} p={0}>
                          <Divider sx={{ my: 0 }} />
                        </Grid>
                      </Grid>
                      <NotificationList
                        setOpen={setOpen}
                        data={data.notifications}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
                    <Button
                      color='primary'
                      variant='text'
                      onClick={() => {
                        router("/notification");
                        setOpen(false);
                      }}>
                      Ver tudo
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
