/** @format */

import {
  AccountTreeOutlined,
  AddCircle,
  Analytics,
  CallSplit,
  Difference,
  DoneAll,
  EditOutlined,
  GroupAdd,
  Home,
  Mood,
  MoveUp,
  Notifications,
  PeopleOutline,
  PersonAddOutlined,
  PersonOffOutlined,
  PersonRemoveOutlined,
  Security,
  Settings,
  UploadFile,
} from "@mui/icons-material";

export const drawerWidth = 260;

export const typeLetter = [
  { label: "Desligamento", value: "dismissals" },
  { label: "Promoção", value: "promotion" },
  { label: "Transferências", value: "transfer" },
  { label: "Revisão salarial", value: "salaryReview" },
];

export const fields = [
  { value: "dependents", label: "Dependentes" },
  { value: "address", label: "Endereço" },
  { value: "bank", label: "Dados bancários" },
  { value: "health_insurance_id", label: "Plano de saúde" },
  { value: "health_insurance_price", label: "Valor do plano de saúde" },
  { value: "food_voucher_id", label: "Vale alimentação" },
  { value: "food_voucher_price", label: "Valor do vale alimentação" },
  { value: "is_basic_basket_option", label: "Cesta básica" },
  { value: "job_role_group_id", label: "Departamento" },
  { value: "job_role_id", label: "Cargo" },
  { value: "remuneration_value", label: "Salário" },
  { value: "manager_id", label: "Gestor" },
  { value: "remuneration_trust_position_amount", label: "Cargo confiança(R$)" },
  { value: "department_id", label: "Departamento" },
  {
    value: "remuneration_trust_position_percentage",
    label: "Cargo confiança(%)",
  },
  { value: "salary_deduction", label: "Pgto desc. salário" },
  { value: "is_commissioned", label: "Comissionado" },
  { value: "union_id", label: "Sindicato" },
  { value: "join_to_union", label: "Filiado sindicato" },
  { value: "deduct_union_dues", label: "Descontar contribuição sindical?" },
  {
    value: "deduct_federation_dues",
    label: "Descontar contribuição federativa?",
  },
  { value: "deduct_salary_reversion", label: "Descontar reversão salárial?" },
  {
    value: "contribution_starting_from",
    label: "Data inicio contribuição sindical",
  },
  { value: "identification", label: "Matrícula" },
  { value: "username", label: "Username" },
  {
    value: "matricula_esocial",
    label: "Matrícula eSocial",
    notRequiredCheck: true,
  },
  { value: "email", label: "Email corporativo" },
  { value: "cpf", label: "CPF" },
  { value: "corporate_phone", label: "Telefone corporativo" },
  { value: "name", label: "Nome" },
  { value: "rg", label: "RG" },
  { value: "birth_date", label: "Data de nascimento" },
  { value: "social_name", label: "Nome social" },
  { value: "marital_status", label: "Estado civil" },
  { value: "race", label: "Raça" },
  { value: "education_level", label: "Grau de instrução" },
  { value: "gender", label: "Sexo" },
  { value: "phone", label: "Telefone" },
  { value: "nationality", label: "Nacionalidade" },
  { value: "is_retired", label: "Aposentado" },
  { value: "personal_email", label: "Email pessoal" },
  { value: "criminal_record", label: "Antec. criminais" },
  { value: "court_license", label: "Alvará judicial" },
  { value: "process", label: "Processo judicial" },
  { value: "is_disability", label: "PCD" },
  { value: "type_of_disability", label: "Tipos do PCD" },
  { value: "postal_code", label: "CEP", hidden: true },
  { value: "street", label: "Logradouro", hidden: true },
  { value: "number", label: "Número residencial", hidden: true },
  { value: "complement", label: "Complemento residencial", hidden: true },
  { value: "neighborhood", label: "Bairro", hidden: true },
  { value: "city", label: "Cidade", hidden: true },
  { value: "state", label: "UF", hidden: true },
  { value: "country", label: "País de nascimento" },
  { value: "state_of_birth", label: "Estado de nascimento" },
  { value: "city_of_birth", label: "Cidade de nascimento" },
  { value: "father_name", label: "Nome do pai" },
  { value: "father_cpf", label: "CPF do pai" },
  { value: "father_country_of_birth", label: "País de nascimento do pai" },
  { value: "paternal_grandmother_name", label: "Avó paterna" },
  { value: "paternal_grandfather_name", label: "Avô paterno" },
  { value: "mother_name", label: "Nome da mãe" },
  { value: "mother_cpf", label: "CPF da mãe" },
  { value: "mother_country_of_birth", label: "País de nascimento da mãe" },
  { value: "maternal_grandmother_name", label: "Avó materna" },
  { value: "maternal_grandfather_name", label: "Avô materno" },
  { value: "is_naturalized", label: "Naturalizado" },
  { value: "arrival_in_brazil_date", label: "Data de chegada ao Brasil" },
  { value: "naturalization_date", label: "Data de naturalização" },
  { value: "married_to_a_brazilian", label: "Casado com Brasileiro(a)" },
  { value: "has_brazilian_children", label: "Possui filhos no Brasil" },
  { value: "bank_code", label: "Código do banco", hidden: true },
  { value: "bank_account_type", label: "Tipo da conta", hidden: true },
  { value: "bank_agency", label: "Agência com DV", hidden: true },
  { value: "bank_account", label: "Conta com DV", hidden: true },
  { value: "business_unit_id", label: "Unidade de negócio" },
  { value: "sector_id", label: "Setor" },
  { value: "work_section_id", label: "Seção" },
  { value: "cost_center_id", label: "Centro de custo" },
  { value: "managerial_cost_center_id", label: "Centro de custo gerencial" },
  { value: "category_id", label: "Categoria do colaborador" },
  { value: "business_unit_contract_type", label: "Tipo de contrato" },
  { value: "work_schedule_id", label: "Horário de trabalho" },
  { value: "business_unit_job_security_type", label: "Tipo de estabilidade" },
  {
    value: "business_unit_job_security_date",
    label: "Data garantia estabilidade",
  },
  { value: "business_unit_employment_bond", label: "Contrato determ. térm." },
  { value: "staffing_number_date", label: "Lotação organ. período" },
  { value: "business_unit_due_date", label: "Data de admissão" },
  { value: "business_unit_exp_extension", label: "Data exp. prorrogação" },
  { value: "business_unit_exp_finish_date", label: "Data exp. término" },
  { value: "business_unit_leave_date", label: "Data de afastamento" },
  { value: "business_unit_contract_finish", label: "Data de desligamento" },
  { value: "contract_status", label: "Status do contrato" },
  { value: "contract_cbo", label: "CBO número" },
  { value: "contract_nature", label: "Natureza do contrato" },
  { value: "contract_salary", label: "Tipo de salário" },
  {
    value: "salary_payment_method_id",
    label: "Método de Pagamento do Salário",
  },
  { value: "contract_base_salary", label: "Salário base" },
  { value: "is_pregnant_apprentice", label: "Aprendiz grávida" },
  { value: "work_from_home", label: "Teletrabalho" },
  { value: "admission_type", label: "Tipo de admissão" },
  { value: "social_security_regime", label: "Regime Previdenciário" },
  { value: "overtime_type", label: "Tipo de Horas Extras" },

  { value: "general_registry_number", label: "RG" },
  {
    value: "general_registry_issuing_authority",
    label: "RG - Orgão de expedição",
  },
  { value: "general_registry_federal_unit", label: "RG - Estado de expedição" },
  { value: "general_registry_date", label: "RG - Data de expedição" },
  { value: "civil_id_register_number", label: "RIC" },
  {
    value: "civil_register_issuing_authority",
    label: "RIC - Orgão de expedição",
  },
  { value: "civil_id_register_date", label: "RIC - Data de expedição" },
  { value: "national_foreigner_registry_number", label: "RNE" },
  {
    value: "national_foreigner_registry_issuing_authority",
    label: "RNE - Orgão de expedição",
  },
  {
    value: "national_foreigner_registry_date",
    label: "RNE - Data de expedição",
  },
  { value: "reservist_number", label: "Certificado de reservista" },
  {
    value: "reservist_date_of_issuance",
    label: "Certificado de reservista - Data de emissão",
  },
  { value: "voter_card_number", label: "Título de eleitor" },
  { value: "voter_card_zone", label: "Título de eleitor - Zona" },
  { value: "voter_card_section", label: "Título de eleitor - Seção" },
  { value: "work_permit_number", label: "Carteira de trabalho" },
  { value: "work_permit_series", label: "Carteira de trabalho - Série" },
  {
    value: "work_permit_federal_unit",
    label: "Carteira de trabalho - Estado de expedição",
  },
  { value: "social_integration_program_number", label: "PIS/PSET/NIT" },
  { value: "social_integration_program_date", label: "PIS/PSET/NIT - Data" },
  { value: "driver_license_registration_number", label: "CNH" },
  {
    value: "driver_license_issuing_authority",
    label: "CNH - Orgão de expedição",
  },
  { value: "driver_license_date_of_issuance", label: "CNH - Data de emissão" },
  { value: "driver_license_due_date", label: "CNH - Data de validade" },
  { value: "driver_license_category", label: "CNH - Categoria" },
  { value: "driver_license_quantity", label: "CNH - Nível" },
  { value: "driver_license_federal_unit", label: "CNH - Estado de expedição" },
  { value: "oc_number", label: "CNH - Orgão de classe" },
  {
    value: "oc_issuing_authority",
    label: "CNH - Orgão de classe - Orgão de expedição",
  },
  {
    value: "oc_federal_unit",
    label: "CNH - Orgão de classe - Estado de expedição",
  },
  {
    value: "oc_date_of_issuance",
    label: "CNH - Orgão de classe - Data de expedição",
  },
  {
    value: "mopp_number",
    label: "CNH - Movimentação Operacional de Produtos Perigosos",
  },
  {
    value: "mopp_due_date",
    label:
      "CNH - Movimentação Operacional de Produtos Perigosos - Data de validade",
  },
  { value: "degree_of_kinship", label: "Parentesco" },
  { value: "family_salary", label: "Renda familiar" },
  { value: "ir", label: "Imposto de renda" },

  { value: "emergency_contacts", label: "Contatos de emergência" },
];

export const routers = [
  {
    id: "home",
    url: "/",
    label: "Inicio",
  },
  {
    id: "dataPulse",
    url: "/datapulse",
    label: "Data Pulse",
  },
  {
    id: "organizational",
    url: "/organizational",
    label: "Inicio",
  },
  {
    id: "settingsAccount",
    url: "/settingsAccount",
    label: "Ver configurações da conta",
  },
  {
    id: "notification",
    url: "/notification",
    label: "Notificações",
  },
  {
    id: "audit",
    url: "/audit",
    label: "Auditoria",
  },
  {
    id: "permissionsAudit",
    url: "/settings/audit",
    label: "Configurações de auditória",
  },
  {
    id: "vacationTimeDashboard",
    url: "/vacation/dashboard",
    label: "Férias",
  },
  {
    id: "vacationCollaborator",
    url: "/vacation/time",
    label: "Ver periodos de férias",
  },
  {
    id: "vacationCollaboratorNew",
    url: "/vacation/new/:id",
    label: "Criar novo periodo de férias",
  },
  {
    id: "vacationCollaboratorDetails",
    url: "/vacation/details/:id",
    label: "Ver detalhes do periodo de férias",
  },
  {
    id: "employeeActive",
    url: "/employee/active",
    label: "Ver colaboradores ativos",
  },
  {
    id: "employeeInactive",
    url: "/employee/inactive",
    label: "Ver colaboradores desligados",
  },
  {
    id: "employeeNew",
    url: "/employee/new",
    label: "Criar novo colaborador",
  },
  {
    id: "employeePosition",
    url: "/employee/position",
    label: "Ver posições para colaborador",
  },
  {
    id: "employeePending",
    url: "/employee/pending",
    label: "Ver usuários pendentes",
  },
  {
    id: "employeePendingDetails",
    url: "/employee/pending/details/:id",
    label: "Ver detalhes da solicitação de usuários pendentes",
  },
  {
    id: "employeePendingActive",
    url: "/employee/pending",
    label: "Tornar um usuário pendente em ativo",
  },
  {
    id: "employeeImport",
    url: "/employee/import",
    label: "Ver importação de usuários",
  },
  {
    id: "employeeImportNew",
    url: "/employee/import/new",
    label: "Importar usuários",
  },
  {
    id: "delegation",
    url: "/delegation",
    label: "Ver delegações",
  },
  {
    id: "processes",
    url: "/processes",
    label: "Ver processos",
  },
  {
    id: "delegationNew",
    url: "/delegation/new",
    label: "Criar nova delegações",
  },
  {
    id: "delegationDetails",
    url: "/delegation/details/:id",
    label: "Ver detalhes da solicitação de delegação",
  },
  {
    id: "requestLeave",
    url: "/request/leave",
    label: "Ver detalhes da solicitação de afastamento",
  },
  {
    id: "requestLeaveNew",
    url: "/request/leave/new/:id",
    label: "Criar nova solicitação de afastamento",
  },
  {
    id: "requestLeaveDetails",
    url: "/request/leave/details/:id",
    label: "Ver detalhes da solicitação de afastamento",
  },
  {
    id: "requestDismissals",
    url: "/request/dismissals",
    label: "Ver solicitações de desligamento",
  },
  {
    id: "requestDismissalsNew",
    url: "/request/dismissals/new/:id",
    label: "Criar nova solicitação de desligamento",
  },
  {
    id: "requestDismissalsDetails",
    url: "/request/dismissals/details/:id",
    label: "Ver detalhes da solicitação de desligamento",
  },
  {
    id: "requestPosition",
    url: "/request/position",
    label: "Ver solicitações de posição",
  },
  {
    id: "requestPositionNew",
    url: "/request/position/new",
    label: "Criar nova solicitação de posição",
  },
  {
    id: "requestPositionDetails",
    url: "/request/position/details/:id",
    label: "Ver detalhes da solicitação de posição",
  },
  {
    id: "requestPromote",
    url: "/request/promote",
    label: "Ver solicitações de promoção",
  },
  {
    id: "requestPromoteNew",
    url: "/request/promote/new/:id",
    label: "Criar nova solicitação de promoção",
  },
  {
    id: "requestPromoteDetails",
    url: "/request/promote/details/:id",
    label: "Ver detalhes da solicitação de promoção",
  },
  {
    id: "requestPromoteDetailsView",
    url: "/request/promote/details/letter/:id",
    label: "Ver carta de comunicação de solicitação de promoção",
  },
  {
    id: "requestSalaryReview",
    url: "/request/salaryReview",
    label: "Ver solicitações de revisão salarial",
  },
  {
    id: "requestSalaryReviewNew",
    url: "/request/salaryReview/new/:id",
    label: "Criar nova solicitação de revisão salarial",
  },
  {
    id: "requestSalaryReviewDetails",
    url: "/request/salaryReview/details/:id",
    label: "Ver detalhes da solicitação de revisão salarial",
  },
  {
    id: "requestSalaryReviewDetailsView",
    url: "/request/salaryReview/details/letter/:id",
    label: "Ver carta de comunicação de solicitação de revisão salarial",
  },
  {
    id: "requestTransfer",
    url: "/request/transfer",
    label: "Ver solicitações de transferência",
  },
  {
    id: "requestTransferNew",
    url: "/request/transfer/new/:id",
    label: "Criar nova solicitação de transferência",
  },
  {
    id: "requestTransferManagerNew",
    url: "/request/transfer/manager/new/:id",
    label: "Criar nova solicitação de transferência de gestor",
  },
  {
    id: "requestTransferDetails",
    url: "/request/transfer/details/:id",
    label: "Ver detalhes da solicitação de transferência",
  },
  {
    id: "requestTransferManagerDetails",
    url: "/request/transfer/manager/details/:id",
    label: "Ver detalhes da solicitação de transferência de gestor",
  },
  {
    id: "requestTransferDetailsView",
    url: "/request/transfer/details/letter/:id",
    label: "Ver carta de comunicação de solicitação de transferência",
  },
  {
    id: "requestUpdateInformation",
    url: "/request/updateInformation",
    label: "Ver solicitações de alteração cadastral",
  },
  {
    id: "requestUpdateInformationRequest",
    url: "/request/updateInformation/request/:id",
    label: "Ver informações de alteração cadastral",
  },
  {
    id: "requestUpdateInformationRequestSolicitation",
    url: "/request/updateInformation/request/:id",
    label: "Criar nova solicitação de alteração cadastral",
  },
  {
    id: "requestUpdateInformationRequestSubmit",
    url: "/request/updateInformation/request/:id",
    label: "Alterar direto os dados de alteração cadastral",
  },
  {
    id: "requestUpdateInformationDetails",
    url: "/request/updateInformation/details/:id",
    label: "Ver detalhes da solicitação de alteração cadastral",
  },
  {
    id: "requestPersonal",
    url: "/request/personal",
    label: "Ver solicitações de requisição de pessoal",
  },
  {
    id: "requestPersonalNew",
    url: "/request/personal/new",
    label: "Criar nova solicitação de requisição de pessoal",
  },
  {
    id: "requestPersonalDetails",
    url: "/request/personal/details/:id",
    label: "Ver detalhes da solicitação de requisição de pessoal",
  },
  {
    id: "requestVacation",
    url: "/request/vacation",
    label: "Ver solicitações de férias",
  },
  {
    id: "requestVacationNew",
    url: "/request/vacation/new/:id",
    label: "Criar nova solicitação de férias",
  },
  {
    id: "requestVacationDetails",
    url: "/request/vacation/details/:id",
    label: "Ver detalhes da solicitação de férias",
  },
  {
    id: "permissionGroup",
    url: "/settings/group",
    label: "Ver Configurações por grupo",
  },
  {
    id: "usersRole",
    url: "/settings/usersRole",
    label: "Modificar perfis de usuários",
  },
  {
    id: "permissionLetter",
    url: "/settings/letter",
    label: "Ver modelos de carta",
  },
  {
    id: "permissionLetterNew",
    url: "/settings/letter/new",
    label: "Criar modelo de carta",
  },
  {
    id: "permissionLetterDetails",
    url: "/settings/letter/details",
    label: "Detalhes modelo de carta",
  },
  {
    id: "permissionGroupNew",
    url: "/settings/group/new",
    label: "Criar permissão de grupo",
  },
  {
    id: "permissionGroupDetails",
    url: "/settings/group/details/:id",
    label: "Ver detalhes de permissão de grupo",
  },
  {
    id: "permissionsRequiredUpdateInformation",
    url: "/settings/updateInformation",
    label: "Ver usuários com permissão para alteração cadastral",
  },
  {
    id: "permissionsRequiredPersonnel",
    url: "/settings/personnel",
    label: "Editar usuários com permissão para requisição de pessoal",
  },
  {
    id: "permissionsRequiredLeave",
    url: "/settings/leave",
    label: "Editar usuários com permissão para afastamento",
  },
  {
    id: "permissionsRequiredDismissals",
    url: "/settings/dismissals",
    label: "Editar usuários com permissão para desligamento",
  },
  {
    id: "permissionsRequiredPromote",
    url: "/settings/promote",
    label: "Editar usuários com permissão para promoção",
  },
  {
    id: "permissionsRequiredSalaryReview",
    url: "/settings/salaryReview",
    label: "Editar usuários com permissão para revisão salarial",
  },
  {
    id: "permissionsRequiredTransfer",
    url: "/settings/transfer",
    label: "Editar usuários com permissão para transferência",
  },
  {
    id: "permissionsRequiredForgetPassword",
    url: "/settings/forgetPassword",
    label: "Alterar como pode ser feito a recuperação de senha",
  },
  {
    id: "permissionsRequiredPosition",
    url: "/settings/position",
    label: "Editar usuários com permissão para posição",
  },
  {
    id: "permissionsRequiredVacation",
    url: "/settings/vacation",
    label: "Editar usuários com permissão para férias",
  },
  {
    id: "settingsRequiredUpdateInformation",
    url: "/settings/updateInformation",
    label: "Ver campos obrigatórios para alteração cadastral",
  },
];

export const permissionsMock = [
  {
    id: 1,
    uuid: "manager",
    name: "Gestor",
    permissions: [
      "home",
      "organizational",
      "dataPulse",
      "notification",
      "vacationTimeDashboard",
      "settingsAccount",
      "requestSalaryReviewNew",
      "requestSalaryReview",
      "requestSalaryReviewNew",
      "requestSalaryReviewDetails",
      "requestSalaryReviewRemove",
      "requestSalaryReviewDetailsView",
      "employeeActive",
      "delegation",
      "delegationNew",
      "delegationDetails",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationDetails",
      "requestUpdateInformationRequestSolicitation",
      "requestPersonal",
      "requestPersonalNew",
      "requestPersonalDetails",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
      "requestVacationDetailsView",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestDismissalsDetailsGiveBack",
      "requestDismissalsDetailsApprove",
      "employeePosition",
      "requestPosition",
      "requestPositionNew",
      "requestPositionDetails",
      "requestPromote",
      "requestPromoteNew",
      "requestPromoteDetails",
      "requestPromoteDetailsView",
      "requestPromoteDetailsEdit",
      "requestTransfer",
      "requestTransferNew",
      "requestTransferDetails",
      "requestTransferManagerDetails",
      "requestTransferDetailsEdit",
      "requestTransferDetailsDisapprove",
      "requestTransferDetailsGiveBack",
      "requestTransferDetailsApprove",
      "requestTransferDetailsView",
    ],
  },
  {
    id: 2,
    uuid: "rh",
    name: "Recursos humanos",
    permissions: [
      "home",
      "organizational",
      "dataPulse",
      "permissionLetter",
      "permissionLetterNew",
      "permissionLetterDetails",
      "requestPositionDetailsApprove",
      "requestPersonalDetailsApprove",
      "requestPersonalDetailsGiveBack",
      "notification",
      "audit",
      "requestPositionDetailsRemove",
      "permissionsAudit",
      "vacationTimeDashboard",
      "vacationCollaborator",
      "vacationCollaboratorNew",
      "vacationCollaboratorDetails",
      "settingsAccount",
      "settingsRequiredUpdateInformation",
      "employeeActive",
      "employeeInactive",
      "employeePending",
      "employeeNew",
      "employeePendingDetails",
      "employeePendingActive",
      "usersRole",
      "requestPromoteDetailsEdit",
      "requestPositionDetailsApprove",
      "requestPositionDetailsGiveBack",
      "requestPositionDetailsDisapprove",
      "requestPositionDetailsApprove",
      "requestLeaveDetailsApprove",
      "requestLeaveDetailsGiveBack",
      "employeePosition",
      "employeePosition",
      "delegation",
      "delegationNew",
      "delegationDetails",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestDismissalsDetailsGiveBack",
      "requestDismissalsDetailsApprove",
      "requestPosition",
      "requestPositionNew",
      "requestPositionDetails",
      "requestPromote",
      "requestPromoteNew",
      "requestPromoteDetailsDisapprove",
      "requestPromoteDetailsGiveBack",
      "requestPromoteDetailsApprove",
      "requestPromoteDetails",
      "requestPromoteDetailsView",
      "requestSalaryReview",
      "requestSalaryReviewNew",
      "requestSalaryReviewDetails",
      "requestSalaryReviewDetailsEdit",
      "requestSalaryReviewRemove",
      "requestSalaryReviewDetailsDisapprove",
      "requestSalaryReviewDetailsGiveBack",
      "requestSalaryReviewDetailsApprove",
      "requestSalaryReviewDetailsView",
      "requestTransfer",
      "requestTransferNew",
      "requestTransferDetails",
      "requestTransferManagerDetails",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationDetails",
      "requestUpdateInformationRequestSolicitation",
      "requestPersonal",
      "requestPersonalNew",
      "requestPersonalDetails",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
      "requestVacationDetailsView",
      "permissionsRequiredForgetPassword",
      "permissionGroup",
      "permissionGroupNew",
      "permissionGroupDetails",
      "permissionsRequiredPersonnel",
      "permissionsRequiredUpdateInformation",
      "permissionsRequiredDismissals",
      "permissionsRequiredLeave",
      "permissionsRequiredPosition",
      "permissionsRequiredPromote",
      "permissionsRequiredSalaryReview",
      "permissionsRequiredTransfer",
      "permissionsRequiredVacation",
      "permissionsRequiredUpdateInformationEdit",
      "requestTransferDetailsEdit",
      "requestTransferDetailsDisapprove",
      "requestTransferDetailsGiveBack",
      "requestTransferDetailsApprove",
      "requestTransferDetailsView",
      // "processes",
    ],
  },
  {
    id: 3,
    uuid: "collaborator",
    name: "Colaborador",
    permissions: [
      "home",
      "organizational",
      "dataPulse",
      "notification",
      "settingsAccount",
      "requestLeave",
      "requestLeaveNew",
      "requestLeaveDetails",
      "requestDismissals",
      "requestDismissalsNew",
      "requestDismissalsDetails",
      "requestUpdateInformation",
      "requestUpdateInformation",
      "requestUpdateInformationRequest",
      "requestUpdateInformationDetails",
      "requestUpdateInformationRequestSolicitation",
      "requestVacation",
      "requestVacationNew",
      "requestVacationDetails",
    ],
  },
  {
    id: 4,
    uuid: "accountant",
    name: "Contador",
    permissions: [
      "home",
      "notification",
      "requestLeaveDetails",
      "requestDismissalsDetails",
      "requestUpdateInformationDetails",
      "requestVacationDetails",
      "requestSalaryReviewDetails",
      "requestPromoteDetails",
      "requestTransferDetails",
      "requestPositionDetails",
      "processes",
      "requestDetailsComplete",
    ],
  },
];

export const actionsFast = [
  {
    id: "requestUpdateInformation",
    title: "Editar cadastro",
    description: "Edite seu cadastro de forma rápida.",
    icon: <EditOutlined />,
    url: "/request/updateInformation/request/",
    user: true,
  },
  {
    id: "requestLeave",
    title: "Informar afastamento",
    description: "Notifique um afastamento conforme período.",
    icon: <PersonRemoveOutlined />,
    url: "/request/leave/new/",
    user: true,
  },
  {
    id: "requestVacation",
    title: "Solicitar férias",
    description: "Solicite suas férias conforme período.",
    icon: <Mood />,
    url: "/request/vacation/new/",
    user: true,
  },
  {
    id: "requestDismissals",
    title: "Solicitar desligamento",
    description: "Solicite seu desligamento de forma simplificada.",
    icon: <PersonOffOutlined />,
    url: "/request/dismissals/new/",
    user: true,
  },
];

export const actionsFastTeam = [
  {
    id: "requestPositionNew",
    title: "Posição",
    description: "Solicite um aumento do seu time.",
    icon: <AddCircle />,
    url: "/request/position/new",
    user: false,
    delegation: false,
  },
  {
    id: "requestPersonalNew",
    title: "Requisição de pessoal",
    description: "Potencialize seu time de agora.",
    icon: <GroupAdd />,
    url: "/request/personal/new",
    user: false,
    delegation: false,
  },
  {
    id: "requestVacation",
    title: "Solicitações de férias",
    description: "Gerencie as férias do seu time.",
    icon: <Mood />,
    url: "/request/vacation",
    user: false,
    delegation: false,
  },
  {
    id: "employeeNew",
    title: "Novo colaborador",
    description: "Cadastre um novo colaborador",
    icon: <PersonAddOutlined />,
    url: "/employee/new",
    user: false,
    delegation: false,
  },
  {
    id: "delegationNew",
    title: "Delegação",
    description: "Delegue seus fluxos de trabalho.",
    icon: <MoveUp />,
    url: "/delegation/new",
    user: false,
    delegation: true,
  },
  {
    id: "employeeImportNew",
    title: "Importar",
    description: "Importe seus colaboradores por planilha.",
    icon: <UploadFile />,
    url: "/employee/import/new",
    user: false,
    delegation: false,
  },
];

export const menuItems = {
  items: [
    {
      id: "init",
      title: "",
      type: "group",
      children: [
        {
          id: "home",
          title: "Inicio",
          type: "item",
          url: routers.filter((item: any) => item.id === "home")[0].url,
          icon: <Home />,
        },
        {
          id: "employee",
          title: "Colaboradores",
          type: "collapse",
          icon: <PeopleOutline />,
          children: [
            {
              id: "active",
              title: "Ativos",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "employeeActive"
              )[0].url,
            },
            {
              id: "inactive",
              title: "Desligados",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "employeeInactive"
              )[0].url,
            },
            {
              id: "pending",
              title: "Pendentes",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "employeePending"
              )[0].url,
            },
            {
              id: "position",
              title: "Posições",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "employeePosition"
              )[0].url,
            },
            {
              id: "import",
              title: "Importações",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "employeeImport"
              )[0].url,
            },
          ].sort((a, b) =>
            a.title.toString().toLowerCase() > b.title.toString().toLowerCase()
              ? 1
              : -1
          ),
        },
        // {
        //   id: "organizational",
        //   title: "Organograma",
        //   type: "item",
        //   url: routers.filter((item: any) => item.id === "organizational")[0]
        //     .url,
        //   icon: <AccountTreeOutlined />,
        // },
        {
          id: "dataPulse",
          title: "DataPulse",
          type: "item",
          url: routers.filter((item: any) => item.id === "dataPulse")[0].url,
          icon: <Analytics />,
          demo: true,
        },
        {
          id: "delegation",
          title: "Delegações",
          type: "item",
          url: routers.filter((item: any) => item.id === "delegation")[0].url,
          icon: <CallSplit />,
          breadcrumbs: false,
        },
        {
          id: "vacation",
          title: "Férias",
          type: "collapse",
          icon: <Mood />,
          children: [
            {
              id: "vacationTimeDashboard",
              title: "Dashboard",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "vacationTimeDashboard"
              )[0].url,
            },
            {
              id: "vacationCollaborator",
              title: "Periodos",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "vacationCollaborator"
              )[0].url,
            },
          ].sort((a, b) =>
            a.title.toString().toLowerCase() > b.title.toString().toLowerCase()
              ? 1
              : -1
          ),
        },
        {
          id: "requests",
          title: "Solicitações",
          type: "collapse",
          icon: <Difference />,
          children: [
            {
              id: "updateInformation",
              title: "Alteração cadastral",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "requestUpdateInformation"
              )[0].url,
            },
            {
              id: "vacation",
              title: "Férias",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "requestVacation"
              )[0].url,
            },
            {
              id: "leave",
              title: "Afastamentos",
              type: "item",
              url: routers.filter((item: any) => item.id === "requestLeave")[0]
                .url,
            },
            {
              id: "dismissals",
              title: "Desligamentos",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "requestDismissals"
              )[0].url,
            },
            {
              id: "promote",
              title: "Promoções",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "requestPromote"
              )[0].url,
            },
            {
              id: "salaryReview",
              title: "Revisão salarial",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "requestSalaryReview"
              )[0].url,
            },
            {
              id: "transfer",
              title: "Transferências",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "requestTransfer"
              )[0].url,
            },
            {
              id: "position",
              title: "Posições",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "requestPosition"
              )[0].url,
            },
            {
              id: "personal",
              title: "Requisição de pessoal",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "requestPersonal"
              )[0].url,
            },
          ].sort((a, b) =>
            a.title.toString().toLowerCase() > b.title.toString().toLowerCase()
              ? 1
              : -1
          ),
        },
        // {
        //   id: "processes",
        //   title: "Processos",
        //   type: "item",
        //   url: routers.filter((item: any) => item.id === "processes")[0].url,
        //   icon: <DoneAll />,
        //   breadcrumbs: false,
        // },
        {
          id: "notification",
          title: "Notificações",
          type: "item",
          url: routers.filter((item: any) => item.id === "notification")[0].url,
          icon: <Notifications />,
        },
        {
          id: "audit",
          title: "Auditória",
          type: "item",
          url: routers.filter((item: any) => item.id === "audit")[0].url,
          icon: <Security />,
        },
        {
          id: "settings",
          title: "Configurações",
          type: "collapse",
          icon: <Settings />,
          children: [
            {
              id: "permissionsRequiredUpdateInformation",
              title: "Alteração cadastral",
              type: "item",
              url: routers.filter(
                (item: any) =>
                  item.id === "permissionsRequiredUpdateInformation"
              )[0].url,
            },
            {
              id: "permissionsRequiredVacation",
              title: "Férias",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredVacation"
              )[0].url,
            },
            {
              id: "permissionsRequiredLeave",
              title: "Afastamentos",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredLeave"
              )[0].url,
            },
            {
              id: "permissionsRequiredDismissals",
              title: "Desligamentos",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredDismissals"
              )[0].url,
            },
            {
              id: "permissionsRequiredPromote",
              title: "Promoções",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredPromote"
              )[0].url,
            },
            {
              id: "permissionsRequiredsalaryReview",
              title: "Revisão salarial",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredSalaryReview"
              )[0].url,
            },
            {
              id: "permissionsRequiredTransfer",
              title: "Transferências",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredTransfer"
              )[0].url,
            },
            {
              id: "permissionsRequiredPosition",
              title: "Posições",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredPosition"
              )[0].url,
            },
            {
              id: "permissionsRequiredPersonnel",
              title: "Requisição de pessoal",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredPersonnel"
              )[0].url,
            },
            {
              id: "permissionGroup",
              title: "Grupos",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionGroup"
              )[0].url,
            },
            {
              id: "permissionLetter",
              title: "Cartas",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionLetter"
              )[0].url,
            },
            {
              id: "usersRole",
              title: "Perfis de usuários",
              type: "item",
              url: routers.filter((item: any) => item.id === "usersRole")[0]
                .url,
            },
            {
              id: "permissionsRequiredForgetPassword",
              title: "Recuperar senha",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsRequiredForgetPassword"
              )[0].url,
            },
            {
              id: "permissionsAudit",
              title: "Auditória",
              type: "item",
              url: routers.filter(
                (item: any) => item.id === "permissionsAudit"
              )[0].url,
            },
          ].sort((a, b) =>
            a.title.toString().toLowerCase() > b.title.toString().toLowerCase()
              ? 1
              : -1
          ),
        },
      ],
    },
  ],
};

export const optionsTable: any = {
  filterType: "checkbox",
  serverSide: true,
  print: false,
  viewColumns: false,
  filter: false,
  fixedHeader: true,
  selectableRows: "none",
  rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
  searchAlwaysOpen: true,
  textLabels: {
    body: {
      noMatch: "Não existe dados para ser exibido.",
      toolTip: "Ordenar",
      columnHeaderTooltip: (column: any) => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: "Próxima página",
      previous: "Página anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Pesquisar",
      downloadCsv: "Exportar dados",
      print: "Imprimir",
      viewColumns: "Ver colunas",
      filterTable: "Filtrar tabela",
    },
  },
};

export const leave_e_social = [
  {
    value: "1",
    label: "Acidente/doença do trabalho",
  },
  {
    value: "3",
    label: "Acidente/doença não relacionada ao trabalho",
  },
  {
    value: "5",
    label:
      "Afastamento/licença de servidor público prevista em estatuto, sem remuneração",
  },
  {
    value: "6",
    label: "Aposentadoria por invalidez",
  },
  {
    value: "7",
    label:
      "Acompanhamento - Licença para acompanhamento de membro da família enfermo",
  },
  {
    value: "8",
    label:
      "Afastamento do empregado para participar de atividade do Conselho Curador do FGTS - Art. 65, § 6º, do Decreto 99.684/1990 (Regulamento do FGTS)",
  },
  {
    value: "10",
    label:
      "Afastamento/licença de servidor público prevista em estatuto, com remuneração",
  },
  {
    value: "11",
    label: "Cárcere",
  },
  {
    value: "12",
    label:
      "Cargo eletivo - Candidato a cargo eletivo - Art. 25, parágrafo único, da Lei 7.664/1988 - Celetistas em geral",
  },
  {
    value: "13",
    label:
      'Cargo eletivo - Candidato a cargo eletivo - Art. 1°, inciso II, alínea "l", da Lei Complementar 64/1990 - Servidor público, estatutário ou não, dos órgãos ou entidades da Administração Direta ou Indireta da União, dos Estados, do Distrito Federal, dos Municípios e dos Territórios, inclusive das fundações mantidas pelo Poder Público',
  },
  {
    value: "14",
    label: "Cessão/Requisição",
  },
  {
    value: "15",
    label:
      "Gozo de férias ou recesso - Afastamento temporário para o gozo de férias ou recesso",
  },
  {
    value: "16",
    label:
      "Licença remunerada - Lei, liberalidade da empresa ou Acordo/Convenção Coletiva de Trabalho",
  },
  {
    value: "17",
    label: "Licença maternidade",
  },
  {
    value: "18",
    label:
      "Licença maternidade - Prorrogação por 60 dias, Lei 11.770/2008 (Empresa Cidadã), inclusive para o cônjuge sobrevivente",
  },
  {
    value: "19",
    label:
      "Licença maternidade - Afastamento temporário por motivo de aborto não criminoso",
  },
  {
    value: "20",
    label:
      "Licença maternidade - Afastamento temporário por motivo de licença-maternidade para o cônjuge sobrevivente ou decorrente de adoção ou de guarda judicial de criança",
  },
  {
    value: "21",
    label: "Licença não remunerada ou sem vencimento",
  },
  {
    value: "22",
    label:
      "Mandato eleitoral - Afastamento temporário para o exercício de mandato eleitoral, sem remuneração",
  },
  {
    value: "23",
    label:
      "Mandato eleitoral - Afastamento temporário para o exercício de mandato eleitoral, com remuneração",
  },
  {
    value: "24",
    label:
      "Mandato sindical - Afastamento temporário para exercício de mandato sindical",
  },
  {
    value: "25",
    label:
      "Mulher vítima de violência - Art. 9º, § 2º, inciso II, da Lei 11.340/2006 - Lei Maria da Penha",
  },
  {
    value: "26",
    label:
      "Participação de empregado no Conselho Nacional de Previdência Social - CNPS - Art. 3º da Lei 8.213/1991",
  },
  {
    value: "27",
    label:
      "Qualificação - Afastamento por suspensão do contrato de acordo com o art. 476-A da CLT",
  },
  {
    value: "28",
    label:
      "Representante sindical - Afastamento pelo tempo que se fizer necessário, quando, na qualidade de representante de entidade sindical, estiver participando de reunião oficial de organismo internacional do qual o Brasil seja membro",
  },
  {
    value: "29",
    label:
      "Serviço militar - Afastamento temporário para prestar serviço militar obrigatório",
  },
  {
    value: "30",
    label: "Suspensão disciplinar - Art. 474 da CLT",
  },
  {
    value: "31",
    label: "Servidor público em disponibilidade",
  },
  {
    value: "33",
    label: "Licença maternidade - 180 dias, Lei 13.301/2016",
  },
  {
    value: "34",
    label:
      "Inatividade do trabalhador avulso (portuário ou não portuário) por período superior a 90 dias",
  },
  {
    value: "35",
    label:
      "Licença maternidade - Antecipação e/ou prorrogação mediante atestado médico",
  },
  {
    value: "36",
    label:
      "Afastamento temporário de exercente de mandato eletivo para cargo em comissão",
  },
  {
    value: "37",
    label:
      "Suspensão temporária do contrato de trabalho nos termos do Programa Emergencial de Manutenção do Emprego e da Renda",
  },
  {
    value: "38",
    label: "Impedimento de concorrência à escala para trabalho avulso",
  },
  {
    value: "39",
    label: "Suspensão de pagamento de servidor público por não recadastramento",
  },
  {
    value: "40",
    label: "Exercício em outro órgão de servidor ou empregado público cedido",
  },
  {
    value: "41",
    label:
      "Qualificação - Afastamento por suspensão do contrato de acordo com o art. 17 da MP 1.116/2022",
  },
  {
    value: "42",
    label:
      "Qualificação - Afastamento por suspensão do contrato de acordo com o art. 19 da MP 1.116/2022",
  },
];

export const aviso_e_social: { label: string; value: boolean }[] = [
  {
    label: "Sim",
    value: true,
  },
  {
    label: "Não",
    value: false,
  },
];

export const abono_settings: any = [
  {
    label: "Padrão CLT - 1/3",
    value: false,
  },
  {
    label: "Customizado",
    value: true,
  },
];

export const type_dismissals_e_social: any = [
  "Pedido de demissão",
  "Demissão sem justa causa",
  "Demissão por justa causa",
  "Término de contrato",
  "Antecipação de término de contrato",
  "Falecimento",
];

export const motive_e_social: any = [
  "Mérito",
  "Enquadramento Salarial",
  "Reajuste Coletivo",
  "Acordo ou Convenção Coletiva",
];

export const motive_e_social_manager: any = [
  "Mérito",
  "Enquadramento Salarial",
];

export const country_e_social: any = [
  {
    label: "Abu Dhabi",
    value: "8",
  },
  {
    label: "Dirce",
    value: "9",
  },
  {
    label: "Afeganistão",
    value: "13",
  },
  {
    label: "Aland, Ilhas",
    value: "15",
  },
  {
    label: "Albânia, República da",
    value: "17",
  },
  {
    label: "Alboran-Perejil, Ilhas",
    value: "20",
  },
  {
    label: "Alemanha",
    value: "23",
  },
  {
    label: "Alemanha, República Democrática",
    value: "25",
  },
  {
    label: "Burkina Faso",
    value: "31",
  },
  {
    label: "Andorra",
    value: "37",
  },
  {
    label: "Angola",
    value: "40",
  },
  {
    label: "Anguilla",
    value: "41",
  },
  {
    label: "Antártica",
    value: "42",
  },
  {
    label: "Antígua e Barbuda",
    value: "43",
  },
  {
    label: "Antilhas Holandesas",
    value: "47",
  },
  {
    label: "Arábia Saudita",
    value: "53",
  },
  {
    label: "Argélia",
    value: "59",
  },
  {
    label: "Argentina",
    value: "63",
  },
  {
    label: "Armênia, República da",
    value: "64",
  },
  {
    label: "Aruba",
    value: "65",
  },
  {
    label: "Austrália",
    value: "69",
  },
  {
    label: "Áustria",
    value: "72",
  },
  {
    label: "Azerbaijão, República do",
    value: "73",
  },
  {
    label: "Bahamas, Ilhas",
    value: "77",
  },
  {
    label: "Bahrein, Ilhas",
    value: "80",
  },
  {
    label: "Bangladesh",
    value: "81",
  },
  {
    label: "Barbados",
    value: "83",
  },
  {
    label: "Belarus, República da",
    value: "85",
  },
  {
    label: "Bélgica",
    value: "87",
  },
  {
    label: "Belize",
    value: "88",
  },
  {
    label: "Bermudas",
    value: "90",
  },
  {
    label: "Mianmar (Birmânia)",
    value: "93",
  },
  {
    label: "Bolívia, Estado Plurinacional da",
    value: "97",
  },
  {
    label: "Bósnia-Herzegovina, República da",
    value: "98",
  },
  {
    label: "Bonaire, Saint Eustatius e Saba",
    value: "99",
  },
  {
    label: "Botsuana",
    value: "101",
  },
  {
    label: "Bouvet, Ilha",
    value: "102",
  },
  {
    label: "Brasil",
    value: "105",
  },
  {
    label: "Brunei",
    value: "108",
  },
  {
    label: "Bulgária, República da",
    value: "111",
  },
  {
    label: "Burundi",
    value: "115",
  },
  {
    label: "Butão",
    value: "119",
  },
  {
    label: "Cabo Verde, República de",
    value: "127",
  },
  {
    label: "Cachemira",
    value: "131",
  },
  {
    label: "Cayman, Ilhas",
    value: "137",
  },
  {
    label: "Camboja",
    value: "141",
  },
  {
    label: "Camarões",
    value: "145",
  },
  {
    label: "Canadá",
    value: "149",
  },
  {
    label: "Ilhas do Canal (Jersey e Guernsey)",
    value: "150",
  },
  {
    label: "Canárias, Ilhas",
    value: "151",
  },
  {
    label: "Canal, Ilhas",
    value: "152",
  },
  {
    label: "Cazaquistão, República do",
    value: "153",
  },
  {
    label: "Catar",
    value: "154",
  },
  {
    label: "Chile",
    value: "158",
  },
  {
    label: "China, República Popular da",
    value: "160",
  },
  {
    label: "Formosa (Taiwan)",
    value: "161",
  },
  {
    label: "Chipre",
    value: "163",
  },
  {
    label: "Cocos (Keeling), Ilhas",
    value: "165",
  },
  {
    label: "Colômbia",
    value: "169",
  },
  {
    label: "Comores, Ilhas",
    value: "173",
  },
  {
    label: "Congo",
    value: "177",
  },
  {
    label: "Cook, Ilhas",
    value: "183",
  },
  {
    label: "Coreia (do Norte), República Popular Democrática da",
    value: "187",
  },
  {
    label: "Coreia (do Sul), República da",
    value: "190",
  },
  {
    label: "Costa do Marfim",
    value: "193",
  },
  {
    label: "Croácia, República da",
    value: "195",
  },
  {
    label: "Costa Rica",
    value: "196",
  },
  {
    label: "Kuwait",
    value: "198",
  },
  {
    label: "Cuba",
    value: "199",
  },
  {
    label: "Curaçao",
    value: "200",
  },
  {
    label: "Benim",
    value: "229",
  },
  {
    label: "Dinamarca",
    value: "232",
  },
  {
    label: "Dominica, Ilha",
    value: "235",
  },
  {
    label: "Dubai",
    value: "237",
  },
  {
    label: "Equador",
    value: "239",
  },
  {
    label: "Egito",
    value: "240",
  },
  {
    label: "Eritreia",
    value: "243",
  },
  {
    label: "Emirados Árabes Unidos",
    value: "244",
  },
  {
    label: "Espanha",
    value: "245",
  },
  {
    label: "Eslovênia, República da",
    value: "246",
  },
  {
    label: "Eslovaca, República",
    value: "247",
  },
  {
    label: "Estados Unidos",
    value: "249",
  },
  {
    label: "Estônia, República da",
    value: "251",
  },
  {
    label: "Etiópia",
    value: "253",
  },
  {
    label: "Falkland (Ilhas Malvinas)",
    value: "255",
  },
  {
    label: "Feroe, Ilhas",
    value: "259",
  },
  {
    label: "Fezzan",
    value: "263",
  },
  {
    label: "Filipinas",
    value: "267",
  },
  {
    label: "Finlândia",
    value: "271",
  },
  {
    label: "França",
    value: "275",
  },
  {
    label: "Gabão",
    value: "281",
  },
  {
    label: "Gâmbia",
    value: "285",
  },
  {
    label: "Gana",
    value: "289",
  },
  {
    label: "Geórgia, República da",
    value: "291",
  },
  {
    label: "Geórgia do Sul e Sandwich do Sul, Ilhas",
    value: "292",
  },
  {
    label: "Gibraltar",
    value: "293",
  },
  {
    label: "Granada",
    value: "297",
  },
  {
    label: "Grécia",
    value: "301",
  },
  {
    label: "Groenlândia",
    value: "305",
  },
  {
    label: "Guadalupe",
    value: "309",
  },
  {
    label: "Guam",
    value: "313",
  },
  {
    label: "Guatemala",
    value: "317",
  },
  {
    label: "Guernsey, Ilha do Canal",
    value: "321",
  },
  {
    label: "Guiana Francesa",
    value: "325",
  },
  {
    label: "Guiné",
    value: "329",
  },
  {
    label: "Guiné Equatorial",
    value: "331",
  },
  {
    label: "Guiné-Bissau",
    value: "334",
  },
  {
    label: "Guiana",
    value: "337",
  },
  {
    label: "Haiti",
    value: "341",
  },
  {
    label: "Heard e Ilhas McDonald, Ilha",
    value: "343",
  },
  {
    label: "Honduras",
    value: "345",
  },
  {
    label: "Hong Kong",
    value: "351",
  },
  {
    label: "Hungria, República da",
    value: "355",
  },
  {
    label: "Iêmen",
    value: "357",
  },
  {
    label: "Iêmen do Sul",
    value: "358",
  },
  {
    label: "Man, Ilha de",
    value: "359",
  },
  {
    label: "Índia",
    value: "361",
  },
  {
    label: "Indonésia",
    value: "365",
  },
  {
    label: "Inglaterra",
    value: "367",
  },
  {
    label: "Iraque",
    value: "369",
  },
  {
    label: "Irã, República Islâmica do",
    value: "372",
  },
  {
    label: "Irlanda",
    value: "375",
  },
  {
    label: "Islândia",
    value: "379",
  },
  {
    label: "Israel",
    value: "383",
  },
  {
    label: "Itália",
    value: "386",
  },
  {
    label: "Sérvia e Montenegro",
    value: "388",
  },
  {
    label: "Jamaica",
    value: "391",
  },
  {
    label: "Jersey, Ilha do Canal",
    value: "393",
  },
  {
    label: "Jammu",
    value: "395",
  },
  {
    label: "Johnston, Ilhas",
    value: "396",
  },
  {
    label: "Japão",
    value: "399",
  },
  {
    label: "Jordânia",
    value: "403",
  },
  {
    label: "Kiribati",
    value: "411",
  },
  {
    label: "Laos, República Popular Democrática do",
    value: "420",
  },
  {
    label: "Lebuan, Ilhas",
    value: "423",
  },
  {
    label: "Lesoto",
    value: "426",
  },
  {
    label: "Letônia, República da",
    value: "427",
  },
  {
    label: "Líbano",
    value: "431",
  },
  {
    label: "Libéria",
    value: "434",
  },
  {
    label: "Líbia",
    value: "438",
  },
  {
    label: "Liechtenstein",
    value: "440",
  },
  {
    label: "Lituânia, República da",
    value: "442",
  },
  {
    label: "Luxemburgo",
    value: "445",
  },
  {
    label: "Macau",
    value: "447",
  },
  {
    label: "Macedônia do Norte",
    value: "449",
  },
  {
    label: "Madagascar",
    value: "450",
  },
  {
    label: "Ilha da Madeira",
    value: "452",
  },
  {
    label: "Malásia",
    value: "455",
  },
  {
    label: "Malavi",
    value: "458",
  },
  {
    label: "Maldivas",
    value: "461",
  },
  {
    label: "Mali",
    value: "464",
  },
  {
    label: "Malta",
    value: "467",
  },
  {
    label: "Marianas do Norte",
    value: "472",
  },
  {
    label: "Marrocos",
    value: "474",
  },
  {
    label: "Marshall, Ilhas",
    value: "476",
  },
  {
    label: "Martinica",
    value: "477",
  },
  {
    label: "Maurício",
    value: "485",
  },
  {
    label: "Mauritânia",
    value: "488",
  },
  {
    label: "Mayotte",
    value: "489",
  },
  {
    label: "Midway, Ilhas",
    value: "490",
  },
  {
    label: "México",
    value: "493",
  },
  {
    label: "Moldávia, República da",
    value: "494",
  },
  {
    label: "Mônaco",
    value: "495",
  },
  {
    label: "Mongólia",
    value: "497",
  },
  {
    label: "Montenegro",
    value: "498",
  },
  {
    label: "Micronésia",
    value: "499",
  },
  {
    label: "Montserrat, Ilhas",
    value: "501",
  },
  {
    label: "Moçambique",
    value: "505",
  },
  {
    label: "Namíbia",
    value: "507",
  },
  {
    label: "Nauru",
    value: "508",
  },
  {
    label: "Christmas, Ilha (Navidad)",
    value: "511",
  },
  {
    label: "Nepal",
    value: "517",
  },
  {
    label: "Nicarágua",
    value: "521",
  },
  {
    label: "Níger",
    value: "525",
  },
  {
    label: "Nigéria",
    value: "528",
  },
  {
    label: "Niue, Ilha",
    value: "531",
  },
  {
    label: "Norfolk, Ilha",
    value: "535",
  },
  {
    label: "Noruega",
    value: "538",
  },
  {
    label: "Nova Caledônia",
    value: "542",
  },
  {
    label: "Papua Nova Guiné",
    value: "545",
  },
  {
    label: "Nova Zelândia",
    value: "548",
  },
  {
    label: "Vanuatu",
    value: "551",
  },
  {
    label: "Omã",
    value: "556",
  },
  {
    label: "Pacífico, Ilhas do (Administração dos EUA)",
    value: "563",
  },
  {
    label: "Pacífico, Ilhas do (Possessão dos EUA)",
    value: "566",
  },
  {
    label: "Pacífico, Ilhas do (Território em Fideicomisso dos)",
    value: "569",
  },
  {
    label: "Países Baixos (Holanda)",
    value: "573",
  },
  {
    label: "Palau",
    value: "575",
  },
  {
    label: "Paquistão",
    value: "576",
  },
  {
    label: "Palestina",
    value: "578",
  },
  {
    label: "Panamá",
    value: "580",
  },
  {
    label: "Papua Nova Guiné",
    value: "583",
  },
  {
    label: "Paraguai",
    value: "586",
  },
  {
    label: "Peru",
    value: "589",
  },
  {
    label: "Pitcairn, Ilha",
    value: "593",
  },
  {
    label: "Polinésia Francesa",
    value: "599",
  },
  {
    label: "Polônia, República da",
    value: "603",
  },
  {
    label: "Portugal",
    value: "607",
  },
  {
    label: "Porto Rico",
    value: "611",
  },
  {
    label: "Quênia",
    value: "623",
  },
  {
    label: "Quirguiz, República",
    value: "625",
  },
  {
    label: "Reino Unido",
    value: "628",
  },
  {
    label: "República Centro-Africana",
    value: "640",
  },
  {
    label: "República Dominicana",
    value: "647",
  },
  {
    label: "Reunião, Ilha",
    value: "660",
  },
  {
    label: "Zimbábue",
    value: "665",
  },
  {
    label: "Romênia",
    value: "670",
  },
  {
    label: "Ruanda",
    value: "675",
  },
  {
    label: "Rússia, Federação da",
    value: "676",
  },
  {
    label: "Salomão, Ilhas",
    value: "677",
  },
  {
    label: "Saint Kitts e Nevis",
    value: "678",
  },
  {
    label: "Saara Ocidental",
    value: "685",
  },
  {
    label: "El Salvador",
    value: "687",
  },
  {
    label: "Samoa",
    value: "690",
  },
  {
    label: "Samoa Americana",
    value: "691",
  },
  {
    label: "São Bartolomeu",
    value: "693",
  },
  {
    label: "São Cristóvão e Neves, Ilhas",
    value: "695",
  },
  {
    label: "San Marino",
    value: "697",
  },
  {
    label: "São Martinho, Ilha de (Parte Francesa)",
    value: "698",
  },
  {
    label: "São Martinho, Ilha de (Parte Holandesa)",
    value: "699",
  },
  {
    label: "São Pedro e Miquelon",
    value: "700",
  },
  {
    label: "São Vicente e Granadinas",
    value: "705",
  },
  {
    label: "Santa Helena",
    value: "710",
  },
  {
    label: "Santa Lúcia",
    value: "715",
  },
  {
    label: "São Tomé e Príncipe, Ilhas",
    value: "720",
  },
  {
    label: "Senegal",
    value: "728",
  },
  {
    label: "Seychelles",
    value: "731",
  },
  {
    label: "Serra Leoa",
    value: "735",
  },
  {
    label: "Sérvia",
    value: "737",
  },
  {
    label: "Sikkim",
    value: "738",
  },
  {
    label: "Singapura",
    value: "741",
  },
  {
    label: "Síria, República Árabe da",
    value: "744",
  },
  {
    label: "Somália",
    value: "748",
  },
  {
    label: "Sri Lanka",
    value: "750",
  },
  {
    label: "eSwatini (Essuatíni, Suazilândia)",
    value: "754",
  },
  {
    label: "Svalbard e Jan Mayen",
    value: "755",
  },
  {
    label: "África do Sul",
    value: "756",
  },
  {
    label: "Sudão",
    value: "759",
  },
  {
    label: "Sudão do Sul",
    value: "760",
  },
  {
    label: "Suécia",
    value: "764",
  },
  {
    label: "Suíça",
    value: "767",
  },
  {
    label: "Suriname",
    value: "770",
  },
  {
    label: "Tadjiquistão, República do",
    value: "772",
  },
  {
    label: "Tailândia",
    value: "776",
  },
  {
    label: "Tanzânia, República Unida da",
    value: "780",
  },
  {
    label: "Terras Austrais Francesas",
    value: "781",
  },
  {
    label: "Território Britânico Oceano Índico",
    value: "782",
  },
  {
    label: "Djibuti",
    value: "783",
  },
  {
    label: "Território da Alta Comissão do Pacífico Ocidental",
    value: "785",
  },
  {
    label: "Chade",
    value: "788",
  },
  {
    label: "Tchecoslováquia",
    value: "790",
  },
  {
    label: "Tcheca, República",
    value: "791",
  },
  {
    label: "Timor Leste",
    value: "795",
  },
  {
    label: "Togo",
    value: "800",
  },
  {
    label: "Toquelau, Ilhas",
    value: "805",
  },
  {
    label: "Tonga",
    value: "810",
  },
  {
    label: "Trinidad e Tobago",
    value: "815",
  },
  {
    label: "Tunísia",
    value: "820",
  },
  {
    label: "Turcas e Caicos, Ilhas",
    value: "823",
  },
  {
    label: "Turcomenistão, República do",
    value: "824",
  },
  {
    label: "Turquia",
    value: "827",
  },
  {
    label: "Tuvalu",
    value: "828",
  },
  {
    label: "Ucrânia",
    value: "831",
  },
  {
    label: "Uganda",
    value: "833",
  },
  {
    label: "Uniao das Repúblicas Socialistas Soviéticas",
    value: "840",
  },
  {
    label: "Uruguai",
    value: "845",
  },
  {
    label: "Uzbequistão, República do",
    value: "847",
  },
  {
    label: "Vaticano, Estado da Cidade do",
    value: "848",
  },
  {
    label: "Venezuela",
    value: "850",
  },
  {
    label: "Vietname Norte",
    value: "855",
  },
  {
    label: "Vietnã",
    value: "858",
  },
  {
    label: "Virgens, Ilhas (Britânicas)",
    value: "863",
  },
  {
    label: "Virgens, Ilhas (EUA)",
    value: "866",
  },
  {
    label: "Fiji",
    value: "870",
  },
  {
    label: "Wake, Ilha",
    value: "873",
  },
  {
    label: "Wallis e Futuna, Ilhas",
    value: "875",
  },
  {
    label: "Congo, República Democrática do",
    value: "888",
  },
  {
    label: "Zâmbia",
    value: "890",
  },
  {
    label: "Zona do Canal do Panamá",
    value: "895",
  },
  {
    label: "Bancos Centrais",
    value: "995",
  },
  {
    label: "Organizações Internacionais",
    value: "997",
  },
];

export const gender_e_social: any = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Feminino" },
];

export const cnh_category_e_social: any = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "AB",
  "AC",
  "AD",
  "AE",
];

export const level_cnh_e_social: any = [
  {
    label: "1ª Habilitação",
    value: "1",
  },
  {
    label: "2ª Habilitação",
    value: "2",
  },
  {
    label: "3ª Habilitação",
    value: "3",
  },
  {
    label: "Acima da 3ª Habilitação",
    value: "4",
  },
];

export const status_vacation: any = [
  {
    label: "Todos",
    value: "all",
  },
  {
    label: "Férias aguardando agendamento",
    value: "0",
  },
  {
    label: "Ferias agendadas",
    value: "1",
  },
  {
    label: "Férias aguardando ciência",
    value: "2",
  },
  {
    label: "Férias com ciência",
    value: "3",
  },
  {
    label: "Férias em andamento",
    value: "4",
  },
  {
    label: "Férias finalizadas",
    value: "5",
  },
  {
    label: "Férias vencidas",
    value: "6",
  },
];

export const education_level_e_social: any = [
  {
    label:
      "Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou",
    value: "1",
  },
  {
    label:
      "Até o 5º ano incompleto do Ensino Fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular",
    value: "2",
  },
  {
    label: "5º ano completo do Ensino Fundamental",
    value: "3",
  },
  {
    label:
      "Do 6º ao 9º ano do Ensino Fundamental incompleto (antiga 5ª a 8ª série)",
    value: "4",
  },
  {
    label: "Ensino Fundamental Completo",
    value: "5",
  },
  {
    label: "Ensino Médio incompleto",
    value: "6",
  },
  {
    label: "Ensino Médio completo",
    value: "7",
  },
  {
    label: "Educação Superior incompleta",
    value: "8",
  },
  {
    label: "Educação Superior completa",
    value: "9",
  },
  {
    label: "Pós-Graduação completa",
    value: "10",
  },
  {
    label: "Mestrado completo",
    value: "11",
  },
  {
    label: "Doutorado completo",
    value: "12",
  },
];

export const race_e_social = [
  {
    label: "Branca",
    value: "1",
  },
  {
    label: "Preta",
    value: "2",
  },
  {
    label: "Parda",
    value: "3",
  },
  {
    label: "Amarela",
    value: "4",
  },
  {
    label: "Indígena",
    value: "5",
  },
  {
    label: "Não informado",
    value: "6",
  },
];

export const marital_status_e_social: any = [
  {
    label: "Solteiro",
    value: "1",
  },
  {
    label: "Casado",
    value: "2",
  },
  {
    label: "Divorciado",
    value: "3",
  },
  {
    label: "Separado",
    value: "4",
  },
  {
    label: "Viúvo",
    value: "5",
  },
];

export const type_of_account: any = [
  {
    label: "Conta poupança",
    value: "1",
  },
  {
    label: "Conta salário",
    value: "2",
  },
  {
    label: "Conta corrente",
    value: "3",
  },
];

export const banks_e_social: any = [
  {
    label: "Banco do Brasil S.A.",
    value: "1",
  },
  {
    label: "Banco da Amazonia S.A.",
    value: "3",
  },
  {
    label: "Banco Do Nordeste Do Brasil S.A.",
    value: "4",
  },
  {
    label: "Banco Nacional De Desenvolvimento Economico E Social",
    value: "7",
  },
  {
    label: "Banco Inbursa S.A.",
    value: "12",
  },
  {
    label: "State Street Brasil S.A. – Banco Comercial",
    value: "14",
  },
  {
    label: "Bny Mellon Banco S.A.",
    value: "17",
  },
  {
    label: "Banco Tricury S.A.",
    value: "18",
  },
  {
    label: "Banestes S.A. Banco Do Estado do Espirito Santo",
    value: "21",
  },
  {
    label: "Banco Bandepe S.A.",
    value: "24",
  },
  {
    label: "Banco Alfa S.A.",
    value: "25",
  },
  {
    label: "Banco Itaú Consignado S.A.",
    value: "29",
  },
  {
    label: "Código Banco Beg S.A.",
    value: "31",
  },
  {
    label: "Banco Santander (Brasil) S.A.",
    value: "33",
  },
  {
    label: "Banco Do Estado Do Pará S.A.",
    value: "37",
  },
  {
    label: "Banco Cargill S.A.",
    value: "40",
  },
  {
    label: "Banco Do Estado Do Rio Grande Do Sul S.A. (BANRISUL)",
    value: "41",
  },
  {
    label: "Banco Do Estado De Sergipe S.A.",
    value: "47",
  },
  {
    label: "Banco de Desenvolvimento do Espírito Santo S.A.",
    value: "51",
  },
  {
    label: "Hipercard Banco Múltiplo S.A.",
    value: "62",
  },
  {
    label: "Goldman Sachs Do Brasil Banco Multiplo S.A.",
    value: "64",
  },
  {
    label: "Banco Andbank (Brasil) S.A.",
    value: "65",
  },
  {
    label: "Banco Morgan Stanley S.A.",
    value: "66",
  },
  {
    label: "Banco Crefisa S.A.",
    value: "69",
  },
  {
    label: "BrB – Banco De Brasilia S.A.",
    value: "70",
  },
  {
    label: "Banco J. Safra S.A.",
    value: "74",
  },
  {
    label: "Banco Abn Amro S.A.",
    value: "75",
  },
  {
    label: "Banco Kdb Do Brasil S.A.",
    value: "76",
  },
  {
    label: "Banco Inter S.A.",
    value: "77",
  },
  {
    label: "Haitong Banco De Investimento Do Brasil S.A.",
    value: "78",
  },
  {
    label: "Banco Original Do Agronegócio S.A.",
    value: "79",
  },
  {
    label: "Bancoseguro S.A.",
    value: "81",
  },
  {
    label: "Banco Topázio S.A.",
    value: "82",
  },
  {
    label: "Banco da China Brasil S.A.",
    value: "83",
  },
  {
    label: "Banco Randon S.A.",
    value: "88",
  },
  {
    label: "Banco Finaxis S.A.",
    value: "94",
  },
  {
    label: "Banco B3 S.A.",
    value: "96",
  },
  {
    label: "Caixa Econômica Federal",
    value: "104",
  },
  {
    label: "Banco Bocom Bbm S.A.",
    value: "107",
  },
  {
    label: "Banco Western Union Do Brasil S.A.",
    value: "119",
  },
  {
    label: "Banco Rodobens S.A.",
    value: "120",
  },
  {
    label: "Banco Agibank S.A.",
    value: "121",
  },
  {
    label: "Banco Woori Bank Do Brasil S.A.",
    value: "124",
  },
  {
    label: "Plural S.A. Banco Múltiplo",
    value: "125",
  },
  {
    label: "Ubs Brasil Banco de Investimento S.A.",
    value: "129",
  },
  {
    label: "Icbc Do Brasil Banco Múltiplo S.A.",
    value: "132",
  },
  {
    label:
      "Banco Cresol – Confederação Nacional Das Cooperativas Centrais De Crédito e Economia Familiar e Solidária",
    value: "133",
  },
  {
    label:
      "Unicred Do Brasil – Confederação Nacional Das Cooperativas Centrais Unicred Ltda.",
    value: "136",
  },
  {
    label: "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo",
    value: "139",
  },
  {
    label: "Commerzbank Brasil S.A. – Banco Múltiplo",
    value: "163",
  },
  {
    label: "Banco Olé Consignado S.A.",
    value: "169",
  },
  {
    label: "Banco Itaú BBA S.A.",
    value: "184",
  },
  {
    label: "Stone Pagamentos S.A.",
    value: "197",
  },
  {
    label: "Banco BTG Pactual S.A.",
    value: "208",
  },
  {
    label: "Banco Original S.A.",
    value: "212",
  },
  {
    label: "Banco Arbi S.A.",
    value: "213",
  },
  {
    label: "Banco John Deere S.A.",
    value: "217",
  },
  {
    label: "Banco Bs2 S.A.",
    value: "218",
  },
  {
    label: "Banco Crédit Agricole Brasil S.A.",
    value: "222",
  },
  {
    label: "Banco Fibra S.A.",
    value: "224",
  },
  {
    label: "Banco Cetelem S.A.",
    value: "233",
  },
  {
    label: "Banco Bradesco S.A.",
    value: "237",
  },
  {
    label: "Banco Classico S.A.",
    value: "241",
  },
  {
    label: "Banco Master S.A.",
    value: "243",
  },
  {
    label: "Banco Abc Brasil S.A.",
    value: "246",
  },
  {
    label: "Banco Investcred Unibanco S.A.",
    value: "249",
  },
  {
    label: "Paraná Banco S.A.",
    value: "254",
  },
  {
    label: "Nu Pagamentos S.A.",
    value: "260",
  },
  {
    label: "Banco Fator S.A.",
    value: "265",
  },
  {
    label: "Banco Cedula S.A.",
    value: "266",
  },
  {
    label: "Banco HSBC S.A.",
    value: "269",
  },
  {
    label: "Pagseguro Internet S.A.",
    value: "290",
  },
  {
    label: "Banco De La Nacion Argentina",
    value: "300",
  },
  {
    label: "Banco BMG S.A.",
    value: "318",
  },
  {
    label: "China Construction Bank (Brasil) Banco Múltiplo S.A.",
    value: "320",
  },
  {
    label: "Mercadopago.Com Representacoes Ltda.",
    value: "323",
  },
  {
    label: "Banco Digio S.A.",
    value: "335",
  },
  {
    label: "Banco C6 S.A.",
    value: "336",
  },
  {
    label: "Super Pagamentos e Administração de Meios Eletrônicos S.A.",
    value: "340",
  },
  {
    label: "Itaú Unibanco S.A.",
    value: "341",
  },
  {
    label: "Banco Xp S.A.",
    value: "348",
  },
  {
    label: "Gerencianet S.A.",
    value: "364",
  },
  {
    label: "Banco Societe Generale Brasil S.A.",
    value: "366",
  },
  {
    label: "Banco CSF S.A.",
    value: "368",
  },
  {
    label: "Banco Mizuho Do Brasil S.A.",
    value: "370",
  },
  {
    label: "UP.P Sociedade de Empréstimo Entre Pessoas S.A.",
    value: "373",
  },
  {
    label: "Banco J.P. Morgan S.A.",
    value: "376",
  },
  {
    label: "Picpay Servicos S.A.",
    value: "380",
  },
  {
    label: "Banco Mercedes-Benz Do Brasil S.A.",
    value: "381",
  },
  {
    label: "Banco Toyota Do Brasil S.A.",
    value: "387",
  },
  {
    label: "Banco Mercantil do Brasil S.A.",
    value: "389",
  },
  {
    label: "Banco Gm S.A.",
    value: "390",
  },
  {
    label: "Banco Volkswagen S.A.",
    value: "393",
  },
  {
    label: "Hub Pagamentos S.A",
    value: "396",
  },
  {
    label: "Kirton Bank S.A. – Banco Múltiplo",
    value: "399",
  },
  {
    label: "Banco Capital S.A.",
    value: "412",
  },
  {
    label: "Banco Safra S.A.",
    value: "422",
  },
  {
    label: "Banco Mufg Brasil S.A.",
    value: "456",
  },
  {
    label: "Banco Sumitomo Mitsui Brasileiro S.A.",
    value: "464",
  },
  {
    label: "Banco Caixa Geral – Brasil S.A.",
    value: "473",
  },
  {
    label: "Citibank N.A.",
    value: "477",
  },
  {
    label: "Banco Itaubank S.A.",
    value: "479",
  },
  {
    label: "Deutsche Bank S.A. – Banco Alemao",
    value: "487",
  },
  {
    label: "Jpmorgan Chase Bank",
    value: "488",
  },
  {
    label: "Ing Bank N.V.",
    value: "492",
  },
  {
    label: "Banco de La Republica Oriental del Uruguay",
    value: "494",
  },
  {
    label: "Banco De La Provincia De Buenos Aires",
    value: "495",
  },
  {
    label: "Banco Credit Suisse (Brasil) S.A.",
    value: "505",
  },
  {
    label: "Banco Luso Brasileiro S.A.",
    value: "600",
  },
  {
    label: "Banco Industrial Do Brasil S.A.",
    value: "604",
  },
  {
    label: "Banco Vr S.A.",
    value: "610",
  },
  {
    label: "Banco Paulista S.A.",
    value: "611",
  },
  {
    label: "Banco Guanabara S.A.",
    value: "612",
  },
  {
    label: "Omni Banco S.A.",
    value: "613",
  },
  {
    label: "Banco Pan S.A.",
    value: "623",
  },
  {
    label: "Banco Smartbank S.A.",
    value: "630",
  },
  {
    label: "Banco Rendimento S.A.",
    value: "633",
  },
  {
    label: "Banco Triangulo S.A.",
    value: "634",
  },
  {
    label: "Banco Sofisa S.A.",
    value: "637",
  },
  {
    label: "Banco Alvorada S.A.",
    value: "641",
  },
  {
    label: "Banco Pine S.A.",
    value: "643",
  },
  {
    label: "Itaú Unibanco Holding S.A.",
    value: "652",
  },
  {
    label: "Banco Indusval S.A.",
    value: "653",
  },
  {
    label: "Banco Digimais S.A.",
    value: "654",
  },
  {
    label: "Banco Votorantim S.A.",
    value: "655",
  },
  {
    label: "Banco Daycoval S.A.",
    value: "707",
  },
  {
    label: "Banco Ourinvest S.A.",
    value: "712",
  },
  {
    label: "Banco RNX",
    value: "720",
  },
  {
    label: "Neon Pagamentos",
    value: "735",
  },
  {
    label: "Banco Cetelem S.A.",
    value: "739",
  },
  {
    label: "Banco Ribeirao Preto S.A.",
    value: "741",
  },
  {
    label: "Banco Semear S.A.",
    value: "743",
  },
  {
    label: "Banco Citibank S.A.",
    value: "745",
  },
  {
    label: "Banco Modal S.A.",
    value: "746",
  },
  {
    label: "Banco Rabobank International Brasil S.A.",
    value: "747",
  },
  {
    label: "Banco Cooperativo Sicredi S.A.",
    value: "748",
  },
  {
    label: "Scotiabank Brasil S.A. Banco Múltiplo",
    value: "751",
  },
  {
    label: "Banco Bnp Paribas Brasil S.A.",
    value: "752",
  },
  {
    label: "Novo Banco Continental S.A. – Banco Múltiplo",
    value: "753",
  },
  {
    label: "Banco Sistema S.A.",
    value: "754",
  },
  {
    label: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
    value: "755",
  },
  {
    label: "Banco Cooperativo Do Brasil S.A. – Bancoob – Sicoob",
    value: "756",
  },
  {
    label: "Banco Keb Hana Do Brasil S.A.",
    value: "757",
  },
];

export const salary_e_social: any = [
  {
    label: "Por hora",
    value: "1",
  },
  {
    label: "Por dia",
    value: "2",
  },
  {
    label: "Por semana",
    value: "3",
  },
  {
    label: "Por quinzena",
    value: "4",
  },
  {
    label: "Por mês",
    value: "5",
  },
  {
    label: "Por tarefa",
    value: "6",
  },
  {
    label: "Não aplicável - Salário exclusivamente variável",
    value: "7",
  },
];

export const nature_e_social: any = [
  { label: "Trabalho urbano", value: "1" },
  { label: "Trabalho rural", value: "2" },
];

export const status_e_social: any = ["Ativo", "Inativo"];

export const category_e_social: any = [
  {
    label:
      "Empregado - Geral, inclusive o empregado público da administração direta ou indireta contratado pela CLT",
    value: "101",
  },
  {
    label: "Empregado - Trabalhador rural por pequeno prazo da Lei 11.718/2008",
    value: "102",
  },
  {
    label: "Empregado - Aprendiz",
    value: "103",
  },
  {
    label: "Empregado - Doméstico",
    value: "104",
  },
  {
    label: "Empregado - Contrato a termo firmado nos termos da Lei 9.601/1998",
    value: "105",
  },
  {
    label: "Trabalhador temporário - Contrato nos termos da Lei 6.019/1974",
    value: "106",
  },
  {
    label:
      "Empregado - Contrato de trabalho Verde e Amarelo - sem acordo para antecipação mensal da multa rescisória do FGTS",
    value: "107",
  },
  {
    label:
      "Empregado - Contrato de trabalho Verde e Amarelo - com acordo para antecipação mensal da multa rescisória do FGTS",
    value: "108",
  },
  {
    label: "Empregado - Contrato de trabalho intermitente",
    value: "111",
  },
  {
    label: "Trabalhador avulso portuário",
    value: "201",
  },
  {
    label: "Trabalhador avulso não portuário",
    value: "202",
  },
  {
    label:
      "Servidor público titular de cargo efetivo, magistrado, ministro de Tribunal de Contas, conselheiro de Tribunal de Contas e membro do Ministério Público",
    value: "301",
  },
  {
    label: "Servidor público ocupante de cargo exclusivo em comissão",
    value: "302",
  },
  {
    label: "Exercente de mandato eletivo",
    value: "303",
  },
  {
    label:
      "Servidor público exercente de mandato eletivo, inclusive com exercício de cargo em comissão",
    value: "304",
  },
  {
    label:
      "Servidor público indicado para conselho ou órgão deliberativo, na condição de representante do governo, órgão ou entidade da administração pública",
    value: "305",
  },
  {
    label:
      "Servidor público contratado por tempo determinado, sujeito a regime administrativo especial definido em lei própria",
    value: "306",
  },
  {
    label: "Militar",
    value: "307",
  },
  {
    label: "Conscrito",
    value: "308",
  },
  {
    label: "Agente público - Outros",
    value: "309",
  },
  {
    label: "Servidor público eventual",
    value: "310",
  },
  {
    label:
      "Ministros, juízes, procuradores, promotores ou oficiais de justiça à disposição da Justiça Eleitoral",
    value: "311",
  },
  {
    label: "Auxiliar local",
    value: "312",
  },
  {
    label:
      "Servidor público exercente de atividade de instrutoria, capacitação, treinamento, curso ou concurso, ou convocado para pareceres técnicos ou depoimentos",
    value: "313",
  },
  {
    label: "Dirigente sindical - Informação prestada pelo sindicato",
    value: "401",
  },
  {
    label:
      "Trabalhador cedido/exercício em outro órgão/juiz auxiliar - Informação prestada pelo cessionário/destino",
    value: "410",
  },
  {
    label: "Dirigente sindical - Segurado especial",
    value: "501",
  },
  {
    label:
      "Contribuinte individual - Autônomo em geral, exceto se enquadrado em uma das demais categorias de contribuinte individual",
    value: "701",
  },
  {
    label: "Contribuinte individual - Transportador autônomo de passageiros",
    value: "711",
  },
  {
    label: "Contribuinte individual - Transportador autônomo de carga",
    value: "712",
  },
  {
    label: "Contribuinte individual - Diretor não empregado, com FGTS",
    value: "721",
  },
  {
    label: "Contribuinte individual - Diretor não empregado, sem FGTS",
    value: "722",
  },
  {
    label:
      "Contribuinte individual - Empresário, sócio e membro de conselho de administração ou fiscal",
    value: "723",
  },
  {
    label:
      "Contribuinte individual - Cooperado que presta serviços por intermédio de cooperativa de trabalho",
    value: "731",
  },
  {
    label:
      "Contribuinte individual - Transportador cooperado que presta serviços por intermédio de cooperativa de trabalho",
    value: "734",
  },
  {
    label:
      "Contribuinte individual - Cooperado filiado a cooperativa de produção",
    value: "738",
  },
  {
    label: "Contribuinte individual - Microempreendedor individual",
    value: "741",
  },
  {
    label:
      "Contribuinte individual - Magistrado classista temporário da Justiça do Trabalho ou da Justiça Eleitoral que seja aposentado de qualquer regime previdenciário",
    value: "751",
  },
  {
    label:
      "Contribuinte individual - Associado eleito para direção de cooperativa, associação ou entidade de classe de qualquer natureza ou finalidade, bem como o síndico ou administrador eleito para exercer atividade de direção condominial, desde que recebam remuneração",
    value: "761",
  },
  {
    label:
      "Contribuinte individual - Membro de conselho tutelar, nos termos da Lei 8.069/1990",
    value: "771",
  },
  {
    label:
      "Ministro de confissão religiosa ou membro de vida consagrada, de congregação ou de ordem religiosa",
    value: "781",
  },
  {
    label: "Estagiário",
    value: "901",
  },
  {
    label: "Médico residente ou residente em área profissional de saúde",
    value: "902",
  },
  {
    label: "Bolsista",
    value: "903",
  },
  {
    label:
      "Participante de curso de formação, como etapa de concurso público, sem vínculo de emprego/estatutário",
    value: "904",
  },
  {
    label:
      "Beneficiário do Programa Nacional de Prestação de Serviço Civil Voluntário",
    value: "906",
  },
];

export const contract_type_e_social: any = [
  { label: "Prazo indeterminado", value: "1" },
  { label: "Prazo determinado, definido em dias", value: "2" },
  { label: "Prazo determinado, vinculado a ocorrência de um fato", value: "3" },
  { label: "PJ - Pessoa Jurídica", value: "999" },
];

export const security_type_e_social: any = [
  "GESTANTES - Desde a confirmação da gravidez, até 150 dias após o parto",
  "CIPEIRO - Membros da Comissão Interna de Prevenção de Acidentes (CIPA) têm direito à estabilidade durante o período do mandato e mais 1 ano após o mandato",
  "ACIDENTE DE TRABALHO - O trabalhador que sofre um acidente de trabalho tem direito à estabilidade no emprego por 12 meses após o término do auxílio-doença acidentário.",
  "DIRIGENTE SINDICAL - Tem direito de estabilidade de até 04 anos pelo mandato, e mais 1 ano após o mandato",
  "SERVIÇO MILITAR - No período de alistamento militar, até a dispensa de incorporação nas forças armadas",
];

export const dependents_e_social = [
  {
    label: "Cônjuge",
    value: "1",
  },
  {
    label:
      "Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua declaração de união estável",
    value: "2",
  },
  {
    label: "Filho(a) ou enteado(a)",
    value: "3",
  },
  {
    label:
      "Filho(a) ou enteado(a), universitário(a) ou cursando escola técnica de 2º grau",
    value: "4",
  },
  {
    label:
      "Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial",
    value: "6",
  },
  {
    label:
      "Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, universitário(a) ou cursando escola técnica de 2° grau, do(a) qual detenha a guarda judicial",
    value: "7",
  },
  {
    label: "Pais, avós e bisavós",
    value: "9",
  },
  {
    label: "Menor pobre do qual detenha a guarda judicial",
    value: "10",
  },
  {
    label: "A pessoa absolutamente incapaz, da qual seja tutor ou curador",
    value: "11",
  },
  {
    label: "Ex-cônjuge",
    value: "12",
  },
  {
    label: "Agregado/Outros",
    value: "99",
  },
];

export const other_relationships = [
  "Sobrinho(a)",
  "Primo(a)",
  "Tio(a)",
  "Cunhado(a)",
  "Genro/Nora",
  "Sogro(a)",
  "Amigo(a)",
  "Vizinho(a)",
  "Padrinho/Madrinha",
];

export const admission_type_e_social: any = [
  { label: "Admissão", value: "1" },
  {
    label:
      "Transferência de empresa do mesmo grupo econômico ou transferência entre órgãos do mesmo Ente Federativo",
    value: "2",
  },
  { label: "Transferência de empresa consorciada ou de consórcio", value: "3" },
  {
    label: "Transferência por motivo de sucessão, incorporação, cisão ou fusão",
    value: "4",
  },
  {
    label:
      "Transferência do empregado doméstico para outro representante da mesma unidade familiar",
    value: "5",
  },
  { label: "Mudança de CPF", value: "6" },
  {
    label:
      "Transferência quando a empresa sucedida é considerada inapta por inexistência de fato",
    value: "7",
  },
];

export const social_security_regime_e_social: any = [
  { label: "Regime Geral de Previdência Social - RGPS", value: "1" },
  { label: "Regime Próprio de Previdência Social - RPPS", value: "2" },
  { label: "Regime de Previdência Social no exterior", value: "3" },
];

export const overtime_type_e_social: any = [
  { label: "Horas Pagas", value: "1" },
  { label: "Horas Compensadas", value: "2" },
];

export const position_type: any = [
  { label: "CLT", value: "0" },
  { label: "PJ", value: "1" },
];
