/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";
import { ConfigSelection } from "../../../../components/configSelection";

export function PermissionsForgetPassword() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);
  const [options]: any = useState([
    {
      label: "Recuperação com reset para senha padrão",
      value: "1",
      key: "default"
    },
    {
      label: "Recuperação através de perguntas",
      value: "2",
      key: "secret_questions"
    },
    {
      label: "Recuperação com envio de senha por email",
      value: "3",
      key: "email"
    },
  ]);
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(updateLoading(true));

    const passwordSettings = options.reduce((acc: any, method: any) => {
      acc[method.key] = rightApprovers.includes(method.value);
      return acc;
    }, {});

    PutInstante(
      null,
      {
        password_recovery_settings: passwordSettings
      },
      "/company_settings"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  };

  useEffect(() => {
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        const recoveryMethods = response.data.data.record.password_recovery_settings

        const left = [];
        const right = [];

        for (const key in recoveryMethods) {
          const keyValue = options.find((option: any) => option.key === key ).value;
        
          if (recoveryMethods[key] === false) {
            left.push(keyValue);
          } else if (recoveryMethods[key] === true) {
            right.push(keyValue);
          }
        }

        setLeftApprovers(left);
        setRightApprovers(right);

      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/vacation'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/forgetPassword'>
              Recuperar senha
            </Link>
            <Typography fontWeight={800}>Ver detalhes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Nesta tela é permitido a configuração dos metodos de recuperar
                senha do sistema.
              </Typography>
              <Typography fontSize={16} fontWeight={400} mt={1}>
                Para realizar esse procedimento, é só selecionar os itens que
                deseja incluir no lado esquerdo, enviar para o lado direito. e
                para remover é só realizar o processo contrário, após a
                conclusão da personalização, clique em 'SALVAR MODIFICAÇÕES'
                para concluir.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>

        <ConfigSelection
          leftLabel="Métodos desativados:"
          rightLabel="Métodos ativados:"
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />
        <Grid container gap={2} mt={2} justifyContent={"end"}>
          <Button
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </Button>
          <Button
            variant={"contained"}
            type={"submit"}
            onClick={onSubmit}
            endIcon={<Edit />}>
            Salvar modificações
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
