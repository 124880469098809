/** @format */

import { Cancel, CheckCircle } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import animationData from "../assets/lotties/loading_modal.json";
import { updateLoading } from "../redux/reducers/common";
import { RemoveInstance } from "../services/api";

export function ActionRemoveVacations({ id, url, statusList }: any) {
  const dispatch = useDispatch();
  const statusDiv: any =
    statusList === "Pendente" ||
    statusList === "Aberta" ||
    statusList === "Aprovado" ||
    statusList === "Aguardando gestor" ||
    statusList === "Aguardando RH";
  const userData = useSelector((state: any) => state.user.data);
  const loading = useSelector((state: any) => state.common.loading);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function onDelete() {
    dispatch(updateLoading(false));
    RemoveInstance(id, url)
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  }
  return (
    (statusDiv || userData.role === "rh") && (
      <form>
        <AlertDialog.Root>
          <AlertDialog.Trigger>
            <Tooltip title='Cancelar'>
              <IconButton>
                <Cancel color='error' />
              </IconButton>
            </Tooltip>
          </AlertDialog.Trigger>
          <AlertDialog.Content maxWidth='450px'>
            {loading ? (
              <Lottie options={defaultOptions} height={"auto"} width={200} />
            ) : (
              <>
                <AlertDialog.Title>
                  Deseja realmente cancelar esta programação de férias?
                </AlertDialog.Title>
                <AlertDialog.Description size='2'>
                  Ao cancelar, esta programação não ficará mais visível e será
                  irreversível.
                </AlertDialog.Description>
                <Flex gap='3' mt='4' justify='end'>
                  <AlertDialog.Cancel>
                    <Button variant={"outlined"} endIcon={<Cancel />}>
                      Desistir
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant={"contained"}
                      onClick={onDelete}
                      endIcon={<CheckCircle />}>
                      Confirmar
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </>
            )}
          </AlertDialog.Content>
        </AlertDialog.Root>
      </form>
    )
  );
}
