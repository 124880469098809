/** @format */

import {
  Download,
  Edit,
  InfoOutlined,
  Undo,
  UploadFile,
} from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionAccountingReturned } from "../../../../../components/actionAccountingReturned";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionReturned } from "../../../../../components/actionReturned";
import { leave_e_social } from "../../../../../config/constants";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatDate } from "../../../../../helpers/formatDate";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstanteFormData,
  PutInstante,
} from "../../../../../services/api";

const validationSchema = yup.object({
  absence_date: yup.date().required("Data de saída é obrigatório"),
  return_date: yup
    .date()
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .test(
      "is-greater",
      "Data de retorno não pode ser anterior a data de saída",
      function (value) {
        const { absence_date } = this.parent;
        return value ? value >= absence_date : true;
      }
    ),
  e_social_motive: yup.string().nullable().required("Motivo é obrigatório"),
  observations: yup.string().nullable().required("Informação é obrigatório"),
  files: yup
    .array()
    .min(1, "Precisa ter ao menos 1 anexo")
    .nullable()
    .required("Arquivo é obrigatório"),
});

export function LeaveDetails() {
  const router = useNavigate();
  const fileInputRef: any = useRef(null);
  const { id } = useParams();
  const theme = useTheme();
  const user = useSelector((state: any) => state.user.data);

  const [data, setData]: any = useState({});
  const [showDate, setShowDate] = useState(false);
  const [files, setFiles] = useState([]);
  const [expandedAccordionAbsence, setExpandedAccordion] = useState<any>(null);
  const permissions = useSelector((state: any) => state.user.permissions);
  const edit =
    permissions !== null &&
    permissions.indexOf("requestLeaveDetailsEdit") !== -1 &&
    data?.solicitation_status === "pending";
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      absence_date: null,
      return_date: null,
      e_social_motive: "",
      observations: "",
      files: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(null, { ...values }, `/absences/${data.uuid}`)
        .then((response: any) => {
          const formData = new FormData();
          // eslint-disable-next-line array-callback-return
          formik.values.files.map((file: any) => {
            formData.append("file[]", file);
          });
          PostInstanteFormData(
            null,
            formData,
            "/absences/" + response.data.data.absence_item_id + "/upload_files"
          )
            .then(() => {
              dispatch(updateLoading(false));
              router("/request/leave");
              toast.success(response?.data?.message);
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                dispatch(updateLoading(false));
                toast.error(
                  e?.response?.data?.message ??
                    "Erro inesperado, tente novamente."
                );
              }
            });
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/absences/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const formData = response.data.data;
        setData(formData);
        formik.setValues({
          absence_date: dayjs(formData.absence_date),
          return_date: dayjs(formData.return_date),
          e_social_motive: formData.e_social_motive,
          observations: formData.last_observations,
          files: [],
        });
        setFiles(formData.solicitation_files);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.collaborator_name,
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_role),
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_code,
    },
    {
      title: "Data",
      value: FormatDate(data.solicitation_date),
    },
    {
      title: "Solicitante",
      value: EmptyData(data.created_by_name),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.action_justification),
    },
  ];

  const onFileDrop = (file: any) => {
    formik.setFieldValue("files", [file]);
    setFiles((prevState): any => [...prevState, file]);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleDragOver: any = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleChangeAccordionAbsence =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/leave'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/leave'>
              Afastamento
            </Link>
            <Typography fontWeight={800}>Ver solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações do colaborador
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {userInfo.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid>
                      <Typography fontSize={12} fontWeight={400}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes do afastamento
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "absence_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.absence_date &&
                      Boolean(formik.errors.absence_date),
                    helperText:
                      formik.touched.absence_date && formik.errors.absence_date,
                  },
                }}
                disabled={!edit}
                label='Data de saída'
                value={formik.values.absence_date}
                onChange={(value: any) => {
                  formik.setFieldValue("absence_date", value);
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "return_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.return_date &&
                      Boolean(formik.errors.return_date),
                    helperText:
                      formik.touched.return_date && formik.errors.return_date,
                  },
                }}
                disabled={!edit}
                label='Data de retorno'
                value={formik.values.return_date}
                onChange={(value: any) => {
                  formik.setFieldValue("return_date", value);
                }}
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='e_social_motive'
                select
                // disabled={!edit}
                label='Motivo do afastamento e-Social'
                value={formik.values.e_social_motive}
                onChange={(event: any) =>
                  formik.setFieldValue("e_social_motive", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.e_social_motive &&
                  Boolean(formik.errors.e_social_motive)
                }
                helperText={
                  formik.touched.e_social_motive &&
                  formik.errors.e_social_motive
                }>
                {leave_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value} - {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Informações sobre o afastamento'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  // disabled={!edit}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid container mt={2}>
              <div
                style={{
                  border: `1px ${
                    formik.errors.files && formik.touched.files
                      ? theme.palette.error.main
                      : theme.palette.text.secondary
                  } dashed`,
                  color:
                    formik.errors.files && formik.touched.files
                      ? theme.palette.error.main
                      : theme.palette.text.secondary,
                  cursor: edit ? "pointer" : "default",
                  borderRadius: 10,
                  width: "inherit",
                }}
                className='ContextMenuTrigger'
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={openFileDialog}>
                <input
                  type='file'
                  accept='*'
                  // disabled={!edit}
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
                <p style={{ display: "flex", justifyContent: "center" }}>
                  <UploadFile style={{ marginRight: 12 }} /> Arraste e solte um
                  arquivo aqui ou clique para selecionar um arquivo.
                </p>
              </div>
            </Grid>
            {formik.errors.files && formik.touched.files && (
              <Grid container mt={0.5} ml={2}>
                <Typography
                  fontSize={"0.75rem"}
                  textAlign={"center"}
                  color={"error"}>
                  {formik.errors.files}
                </Typography>
              </Grid>
            )}
            {files.length > 0 && (
              <Grid container mt={2}>
                <Typography>Arquivos anexados</Typography>
              </Grid>
            )}
            <Grid container mt={2} gap={2}>
              {files.map((file: any, index: number) => (
                <Tooltip title={"Baixar arquivo"}>
                  <a
                    title={file.name}
                    href={file.url}
                    target='_blank'
                    style={{
                      border: theme.palette.primary.main + " 1px solid",
                      color: theme.palette.primary.main,
                      padding: 8,
                      fontSize: 12,
                      borderRadius: 24,
                      fontWeight: 800,
                      textDecorationLine: "none",
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                    }}
                    download={file.name}
                    rel='noreferrer'>
                    <Download fontSize={"small"} />
                    {file.name}
                  </a>
                </Tooltip>
              ))}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>

              <Button
                disabled={!showDate}
                variant={"contained"}
                type={"submit"}
                fullWidth={isMobile}
                endIcon={<Edit />}>
                Editar
              </Button>

              {data.solicitation_status === "waiting_rh_user" && (
                <>
                  <ActionReturned
                    keyPrivate={"requestLeaveDetailsDisapprove"}
                    id={id}
                    url={"absences"}
                  />
                  <ActionDisapproved
                    keyPrivate={"requestLeaveDetailsGiveBack"}
                    id={id}
                    url={"absences"}
                  />
                  <ActionAproved
                    keyPrivate={"requestLeaveDetailsApprove"}
                    id={id}
                    url={"absences"}
                  />
                </>
              )}

              {user.role === "accountant" &&
                data?.solicitation_status === "approved" && (
                  <>
                    <ActionCompleted
                      keyPrivate={"requestDetailsComplete"}
                      id={id}
                      url={"processes"}
                    />

                    <ActionAccountingReturned
                      keyPrivate={"requestDetailsComplete"}
                      id={id}
                      url={"processes"}
                    />
                  </>
                )}
            </Grid>
            <Grid
              display='flex'
              alignItems='flex-start'
              flexDirection='column'
              mt={2}
              gap={2}>
              <Button
                fullWidth={isMobile}
                variant='outlined'
                onClick={() => setShowDate(true)}>
                Nova data de retorno
              </Button>
              {showDate ? (
                <DatePicker
                  slotProps={{
                    textField: {
                      id: "return_date",
                      onBlur: formik.handleBlur,
                      error:
                        formik.touched.return_date &&
                        Boolean(formik.errors.return_date),
                      helperText:
                        formik.touched.return_date && formik.errors.return_date,
                    },
                  }}
                  label='Data de retorno'
                  value={formik.values.return_date}
                  onChange={(value: any) => {
                    formik.setFieldValue("return_date", value);
                  }}
                />
              ) : null}
            </Grid>
            {data?.history_data?.length
              ? data?.history_data?.map((history: any) => (
                  <Accordion
                    sx={{
                      marginTop: 3.5,
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                    expanded={expandedAccordionAbsence === history.id}
                    onChange={handleChangeAccordionAbsence(history.id)}>
                    <AccordionSummary
                      aria-controls='panel1d-content'
                      id='panel1d-header'
                      expandIcon={
                        <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                      }
                      sx={{
                        margin: 0,
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                          {
                            transform: "rotate(90deg)",
                          },
                      }}>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        width='100%'>
                        <Typography
                          marginLeft={0.5}
                          fontSize={18}
                          fontWeight={500}>
                          Histórico do Afastamento
                        </Typography>
                        <Typography>
                          {FormatDate(history.created_at)}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid display='flex' flexDirection='column' gap={2}>
                        <Grid
                          gridTemplateColumns={"1fr 1fr"}
                          display={"grid"}
                          gap={2}>
                          <TextField
                            fullWidth
                            label='Data do afastamento'
                            variant='outlined'
                            rows={4}
                            disabled
                            value={FormatDate(history.absence_date)}
                          />
                          <TextField
                            fullWidth
                            label='Data do retorno'
                            variant='outlined'
                            rows={4}
                            disabled
                            value={FormatDate(history.return_date)}
                          />
                        </Grid>
                        <Grid display='flex' flexDirection='column' gap={2}>
                          <TextField
                            fullWidth
                            label='Motivo do afastamento e-social'
                            variant='outlined'
                            multiline={true}
                            rows={2}
                            disabled
                            value={
                              leave_e_social?.find(
                                (e) => e.value === history?.e_social_motive
                              )?.label
                            }
                          />
                          <TextField
                            fullWidth
                            label='Informações sobre o afastamento'
                            variant='outlined'
                            multiline={true}
                            rows={4}
                            disabled
                            value={history?.observations}
                          />
                          <Grid container mt={2} gap={2}>
                            {history.files.map((file: any, index: number) => (
                              <Tooltip title={"Baixar arquivo"}>
                                <a
                                  title={file.name}
                                  href={file.url}
                                  target='_blank'
                                  style={{
                                    border:
                                      theme.palette.primary.main + " 1px solid",
                                    color: theme.palette.primary.main,
                                    padding: 8,
                                    fontSize: 12,
                                    borderRadius: 24,
                                    fontWeight: 800,
                                    textDecorationLine: "none",
                                    display: "flex",
                                    gap: 4,
                                    alignItems: "center",
                                  }}
                                  download={file.name}
                                  rel='noreferrer'>
                                  <Download fontSize={"small"} />
                                  {file.name}
                                </a>
                              </Tooltip>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
              : null}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
