/** @format */

import { Add, Visibility } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionRemove } from "../../../../components/actionRemove";
import { optionsTable } from "../../../../config/constants";
import { IsPermissions } from "../../../../config/isPermission";
import { FilterEmpty } from "../../../../helpers/emptyData";
import { FormatDate } from "../../../../helpers/formatDate";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";

const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "collaborator_name", label: "Nome" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "dismissal_date", label: "Data do deslig. pretendida" },
  { name: "solicitation_status", label: "Status" },
];

let timerSearch: any;

export function Termination() {
  const dispatch = useDispatch();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const [openModalDismissal, setOpenModalDismissal] = useState({
    open: false,
    type: "",
    uuid: "",
    date: "",
  });
  const delegation = useSelector((state: any) => state.user.delegation_data);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      { page: page + 1, per_page: perPage, search, order_by: order },
      "/dismissals"
    )
      .then((response: any) => {
        setData((old: any) =>
          page === 0
            ? FilterEmpty(response.data.data.data)
            : [...old, ...FilterEmpty(response.data.data.data)]
        );
        setTotal(response.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);

  const handleClose = () => {
    setOpenModalDismissal((prev) => ({ ...prev, open: false }));
  };

  return (
    <Grid container gap={2}>
      <Dialog
        open={openModalDismissal.open}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'>
        <DialogTitle color='InfoText'>Em periodo de estabilidade</DialogTitle>
        <DialogContent>
          <DialogContentText
            gap={1}
            color='InfoText'
            display='flex'
            flexDirection='column'
            id='alert-dialog-slide-description'>
            <Typography variant='subtitle1'>
              Até o dia: {openModalDismissal.date}
            </Typography>
            <Typography variant='subtitle1'>
              Motivo: {openModalDismissal.type}
            </Typography>
            <Typography variant='subtitle1'>
              Para solicitar o desligamento acione o RH
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
          <Button
            variant='contained'
            fullWidth
            sx={{ alignSelf: "center" }}
            onClick={() => {
              if (user?.role === "rh") {
                router(`/request/dismissals/new/${openModalDismissal.uuid}`);
              } else {
                handleClose();
              }
            }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container gap={2} justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography>Solicitações</Typography>
          <Typography fontWeight={800}>Desligamentos</Typography>
        </Breadcrumbs>
        {!delegation?.delegated_session && (
          <Button
            variant={"contained"}
            onClick={() => {
              if (
                user.business_unit_job_security_date &&
                new Date(user.business_unit_job_security_date) >= new Date()
              ) {
                setOpenModalDismissal({
                  open: true,
                  uuid: user.uuid,
                  type: user.business_unit_job_security_type,
                  date: FormatDate(user.business_unit_job_security_date),
                });
              } else {
                router(`/request/dismissals/new/${user.uuid}`);
              }
            }}
            fullWidth={isMobile}
            endIcon={<Add />}>
            Solicitar desligamento
          </Button>
        )}
      </Grid>
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container>
                      <IsPermissions keyPrivate={"requestDismissalsDetails"}>
                        <Tooltip title={"Ver solicitação"}>
                          <IconButton
                            onClick={() =>
                              router(
                                `/request/dismissals/details/${data[dataIndex].uuid}`
                              )
                            }>
                            <Visibility color='primary' />
                          </IconButton>
                        </Tooltip>
                      </IsPermissions>
                      <ActionRemove
                        statusList={data[dataIndex].solicitation_status}
                        url='/dismissals'
                        keyPrivate='requestDismissalsDetailsRemove'
                        id={data[dataIndex].uuid}
                      />
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: false,
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
