/** @format */

import { Edit, InfoOutlined, Undo, Visibility } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionAccountingReturned } from "../../../../../components/actionAccountingReturned";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { IsPermissions } from "../../../../../config/isPermission";
import { EmptyData } from "../../../../../helpers/emptyData";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";

export function TransferDetails() {
  const router = useNavigate();
  const [data, setData]: any = useState({
    options: {
      business_unit: [],
      sector: [],
      cost_center: [],
      job_role: [],
      department: [],
      positions: [],
    },
    collaborator_data: {},
    transfer_data: {},
    rules_data: {
      transfer_with_promotion_min_percent: 0,
      transfer_with_promotion_max_percent: 0,
      transfer_without_promotion_min_percent: 0,
      transfer_without_promotion_max_percent: 0,
    },
    solicitation_data: { status: "pending" },
  });
  const { id } = useParams();
  const permissions = useSelector((state: any) => state.user.permissions);
  const user = useSelector((state: any) => state.user.data);
  const edit =
    (user.id === data.transfer_data.new_manager_id || user.role === "rh") &&
    permissions !== null &&
    permissions.indexOf("requestTransferDetailsEdit") !== -1 &&
    (data?.solicitation_data.status === "waiting_manager_user" ||
      (user.role === "rh" &&
        data?.solicitation_data.status === "waiting_rh_user"));
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    with_promotion: yup.boolean(),
    position_id: yup.string().nullable().required("Posição é obrigatório"),
    transfer_date: yup
      .date()
      .min(new Date(), "Data da transferência precisa ser futura")
      .nullable()
      .required("Data da transferência é obrigatório"),
    business_unit_id: yup
      .string()
      .nullable()
      .required("Unidade de negócio destino é obrigatório"),
    sector_id: yup
      .string()
      .nullable()
      .required("Setor / Locação destino é obrigatório"),
    cost_center_id: yup
      .string()
      .nullable()
      .required("Centro de custo destino é obrigatório"),
    job_role_id: yup
      .string()
      .nullable()
      .required("Cargo destino é obrigatório"),
    department_id: yup
      .string()
      .nullable()
      .required("Departamento destino é obrigatório"),
    observations: yup.string().nullable().required("Comentário é obrigatório"),
    new_remuneration_percentage: yup
      .number()
      .nullable()
      .when("with_promotion", ([with_promotion], schema) => {
        if (with_promotion) {
          return schema
            .required("Desconto é obrigatório")
            .min(
              data.rules_data.transfer_with_promotion_min_percent,
              `A porcentagem deve ser de pelo menos ${data.rules_data.transfer_with_promotion_min_percent}%`
            )
            .max(
              data.rules_data.transfer_with_promotion_max_percent,
              `A porcentagem não pode ser maior que ${data.rules_data.transfer_with_promotion_max_percent}%`
            );
        } else {
          return schema
            .required("Desconto é obrigatório")
            .min(
              data.rules_data.transfer_without_promotion_min_percent,
              `A porcentagem deve ser de pelo menos ${data.rules_data.transfer_without_promotion_min_percent}%`
            )
            .max(
              data.rules_data.transfer_without_promotion_max_percent,
              `A porcentagem não pode ser maior que ${data.rules_data.transfer_without_promotion_max_percent}%`
            );
        }
      }),
    new_remuneration_value: yup
      .string()
      .nullable()
      .required("Salário é obrigatório"),
  });

  const formik: any = useFormik({
    initialValues: {
      business_unit_id: "",
      transfer_date: null,
      new_remuneration_percentage: "",
      new_remuneration_value: "",
      observations: "",
      with_position: false,
      with_promotion: false,
      sector_id: "",
      cost_center_id: "",
      job_role_id: "",
      department_id: "",
      position_id: null,
      actual_salary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      PutInstante(
        id,
        {
          ...values,
          new_remuneration_value: ClearCurrency(values.new_remuneration_value),
        },
        "/transfers"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/request/transfer");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({ transfer_uuid: id }, "/transfers/update_form")
      .then((response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData((old: any) => ({ ...old, ...data }));
        const transfer_date = dayjs(data.transfer_data.transfer_date);
        formik.setValues({
          business_unit_id: data.transfer_data.business_unit_id || "",
          transfer_date: transfer_date,
          new_remuneration_percentage:
            data.transfer_data.new_remuneration_percentage || "",
          new_remuneration_value: FormatCurrency({
            target: {
              value: `${data.transfer_data.new_remuneration_value}` || "0",
            },
          }),
          observations: "",
          with_position: data.transfer_data.with_position,
          with_promotion: data.transfer_data.with_promotion || false,
          sector_id: data.transfer_data.sector_id || "",
          cost_center_id: data.transfer_data.cost_center_id || "",
          job_role_id: data.transfer_data.job_role_id || "",
          department_id: data.transfer_data.department_id || "",
          position_id: data.transfer_data.position_id || "",
          actual_salary: ClearCurrency(
            data.collaborator_data.remuneration_value
          ),
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const collaboratorInfo = [
    {
      title: "Nome",
      value: data.collaborator_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.collaborator_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_data.job_role_name),
    },
    {
      title: "Salário atual",
      value: data.collaborator_data.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_data.status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.transfer_data.created_by_name),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.transfer_data.observations),
    },
  ];

  const onChangePosition = (position_id: any) => {
    const position = data.options.positions.find(
      (position: any) => position.id === position_id
    );
    formik.setFieldValue("position_id", position_id);
    formik.setFieldValue("department_id", position.department_id);
    formik.setFieldValue("job_role_id", position.job_role_id);
    formik.setFieldValue("sector_id", position.sector_id);
    formik.setFieldValue("cost_center_id", position.cost_center_id);
    formik.setFieldValue("business_unit_id", position.business_unit_id);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/transfer'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/transfer'>
              Transferências
            </Link>
            <Typography fontWeight={800}>Ver solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {collaboratorInfo.map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"} mt={2}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid key={item.title}>
                      <Typography fontSize={12} fontWeight={600}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={400}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da transferência
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <FormControlLabel
                disabled
                id={"with_position"}
                checked={formik.values.with_position}
                onChange={(event: any) => {
                  formik.setFieldValue("with_position", event.target.checked);
                }}
                control={<Checkbox />}
                label='Transferência com a posição ?'
              />
              <FormControlLabel
                id={"with_promotion"}
                checked={formik.values.with_promotion}
                disabled={!edit}
                onChange={(event: any) => {
                  formik.setFieldValue("with_promotion", event.target.checked);
                }}
                control={<Checkbox />}
                label='Transferência com promoção ?'
              />
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                disabled
                value={data.transfer_data.new_manager_name}
                label='Gerente de destino'
              />

              {!formik.values.with_position ? (
                <TextField
                  fullWidth
                  id='position_id'
                  select
                  label='Posição destino'
                  disabled={!edit}
                  value={formik.values.position_id}
                  onChange={(event: any) =>
                    onChangePosition(event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.position_id &&
                    Boolean(formik.errors.position_id)
                  }
                  helperText={
                    formik.touched.position_id && formik.errors.position_id
                  }>
                  {data.options.positions.map((position: any) => (
                    <MenuItem key={position.id} value={position.id}>
                      {position.code} {position.job_role_name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  fullWidth
                  disabled
                  value={`${data.transfer_data.position_code} - ${data.transfer_data.position_job_role_name}`}
                  label='Posição destino'
                />
              )}

              <TextField
                fullWidth
                id='department_id'
                select
                disabled={!edit}
                label='Departamento Destino'
                value={formik.values.department_id}
                onChange={(event: any) =>
                  formik.setFieldValue("department_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.department_id &&
                  Boolean(formik.errors.department_id)
                }
                helperText={
                  formik.touched.department_id && formik.errors.department_id
                }>
                {data.options.department.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id='job_role_id'
                select
                disabled={!edit}
                label='Cargo destino'
                value={formik.values.job_role_id}
                onChange={(event: any) =>
                  formik.setFieldValue("job_role_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.job_role_id &&
                  Boolean(formik.errors.job_role_id)
                }
                helperText={
                  formik.touched.job_role_id && formik.errors.job_role_id
                }>
                {data.options.job_role.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "transfer_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.transfer_date &&
                      Boolean(formik.errors.transfer_date),
                    helperText:
                      formik.touched.transfer_date &&
                      formik.errors.transfer_date,
                  },
                }}
                disabled
                label='Data da movimentação'
                value={formik.values.transfer_date}
                onChange={(value: any) => {
                  formik.setFieldValue("transfer_date", value);
                }}
              />
              <TextField
                fullWidth
                id='business_unit_id'
                select
                disabled={!edit}
                label='Un. de negócio destino'
                value={formik.values.business_unit_id}
                onChange={(event: any) =>
                  formik.setFieldValue("business_unit_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_unit_id &&
                  Boolean(formik.errors.business_unit_id)
                }
                helperText={
                  formik.touched.business_unit_id &&
                  formik.errors.business_unit_id
                }>
                {data.options.business_unit.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id='sector_id'
                select
                disabled={!edit}
                label='Setor / Lotação destino'
                value={formik.values.sector_id}
                onChange={(event: any) =>
                  formik.setFieldValue("sector_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.sector_id && Boolean(formik.errors.sector_id)
                }
                helperText={
                  formik.touched.sector_id && formik.errors.sector_id
                }>
                {data.options.sector.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id='cost_center_id'
                select
                disabled={!edit}
                label='Centro de custo destino'
                value={formik.values.cost_center_id}
                onChange={(event: any) =>
                  formik.setFieldValue("cost_center_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.cost_center_id &&
                  Boolean(formik.errors.cost_center_id)
                }
                helperText={
                  formik.touched.cost_center_id && formik.errors.cost_center_id
                }>
                {data.options.cost_center.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id='new_remuneration_percentage'
                type={"number"}
                disabled={!edit}
                label='Porcentagem (%)'
                value={formik.values.new_remuneration_percentage}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "new_remuneration_percentage",
                    event.target.value
                  );
                  formik.setFieldValue(
                    "new_remuneration_value",
                    MaskCurrency(
                      ((parseFloat(event.target.value) + 100) *
                        parseFloat(formik.values.actual_salary)) /
                        100
                    )
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.new_remuneration_percentage &&
                  Boolean(formik.errors.new_remuneration_percentage)
                }
                helperText={
                  formik.touched.new_remuneration_percentage &&
                  formik.errors.new_remuneration_percentage
                }
              />
              <TextField
                fullWidth
                id='new_remuneration_value'
                disabled={!edit}
                label='Salário futuro'
                value={formik.values.new_remuneration_value}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "new_remuneration_percentage",
                    ReadjustSalary(
                      Number(formik.values.actual_salary),
                      ClearCurrency(FormatCurrency(event))
                    )
                  );
                  formik.setFieldValue(
                    "new_remuneration_value",
                    FormatCurrency(event)
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.new_remuneration_value &&
                  Boolean(formik.errors.new_remuneration_value)
                }
                helperText={
                  formik.touched.new_remuneration_value &&
                  formik.errors.new_remuneration_value
                }
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Comentários'
                  disabled={!edit}
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <Grid container gap={2} justifyContent={"end"}>
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  fullWidth={isMobile}
                  endIcon={<Undo />}>
                  Voltar
                </Button>
                {edit && (
                  <>
                    <Button
                      variant={"contained"}
                      type={"submit"}
                      fullWidth={isMobile}
                      endIcon={<Edit />}>
                      {user.role === "rh"
                        ? "Salvar e aprovar"
                        : "Salvar e enviar para o RH"}
                    </Button>

                    <ActionDisapproved
                      keyPrivate={"requestTransferDetailsDisapprove"}
                      id={id}
                      url={"transfers"}
                    />
                  </>
                )}

                {user.role === "accountant" &&
                  data?.solicitation_data.status === "approved" && (
                    <>
                      <ActionCompleted
                        keyPrivate={"requestDetailsComplete"}
                        id={id}
                        url={"processes"}
                      />

                      <ActionAccountingReturned
                        keyPrivate={"requestDetailsComplete"}
                        id={id}
                        url={"processes"}
                      />
                    </>
                  )}

                {data.solicitation_data.status === "approved" && (
                  <IsPermissions keyPrivate={"requestTransferDetailsView"}>
                    <Button
                      onClick={() =>
                        router("/request/transfer/details/letter/" + id)
                      }
                      fullWidth={isMobile}
                      variant={"contained"}
                      type={"submit"}
                      endIcon={<Visibility color='primary' />}>
                      Ver carta de comunicação
                    </Button>
                  </IsPermissions>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
