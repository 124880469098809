/** @format */

import { AddCircleOutline, Undo } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import animationData from "../../../assets/lotties/success.json";

export default function SuccessPasswordPage() {
  const router = useNavigate();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid container justifyContent={"center"} height={"100dvh"}>
      <Grid
        container
        justifyContent={"center"}
        flexDirection={"column"}
        id='error-page'
        padding={10}
        maxWidth={600}
        gap={2}>
        <Lottie options={defaultOptions} height={300} width={300} />
        <Typography fontSize={40} textAlign={"center"} fontWeight={800}>
          Sucesso!
        </Typography>
        <Grid
          container
					gap={1}
          flexDirection={"column"}
          justifyContent={"center"}>
          <Typography fontSize={16}>
            Senha redefinida com sucesso! siga as instruções abaixo de como é
            formada sua senha e ao final clique para retornar a aplicação para
            realizar seu acesso.
          </Typography>
          <Typography fontSize={16} fontWeight={500} style={{marginTop:24}}>
            Sua senha é composta por:
          </Typography>
          <Typography
            textAlign={"center"}
            display={"flex"}
            alignItems={"center"}
            fontSize={16}>
            <AddCircleOutline
              color='success'
              style={{ marginRight: 4 }}
              fontSize={"small"}
            />{" "}
            3 últimos dígitos do CPF;
          </Typography>
          <Typography
            textAlign={"center"}
            display={"flex"}
            alignItems={"center"}
            fontSize={16}>
            <AddCircleOutline
              color='success'
              style={{ marginRight: 4 }}
              fontSize={"small"}
            />{" "}
            Dia de nascimento com 2 dígitos;
          </Typography>
          <Typography
            textAlign={"center"}
            display={"flex"}
            alignItems={"center"}
            fontSize={16}>
            <AddCircleOutline
              color='success'
              style={{ marginRight: 4 }}
              fontSize={"small"}
            />
            Ano de nascimento com 4 dígitos;
          </Typography>
        </Grid>
				<Button
					style={{marginTop:24}}
          color={"success"}
          variant={"outlined"}
          endIcon={<Undo />}
          onClick={() => router("/")}>
          Retornar a aplicação
        </Button>
      </Grid>
    </Grid>
  );
}
