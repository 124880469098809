/** @format */

import { Undo } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import animationData from "../../../assets/lotties/people.json";

export default function NotMobilePage() {
  const router = useNavigate();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid container justifyContent={"center"} height={"100dvh"}>
      <Grid
        container
        justifyContent={"center"}
        flexDirection={"column"}
        id='error-page'
        padding={10}
        maxWidth={600}
        gap={2}>
        <Lottie options={defaultOptions} height={"auto"} width={200} />
        <Typography fontSize={40} textAlign={"center"} fontWeight={800}>
          Acesso sem suporte ao dispositivo móvel!
        </Typography>
        <Typography textAlign={"center"} fontSize={18}>
          Você está tentando acessar uma página que não tem suporte na versão
          por dispositivos móveis, por favor tente mais tarde pelo seu
          computador.
        </Typography>
        <Button
          color={"error"}
          variant={"outlined"}
          endIcon={<Undo />}
          onClick={() => router("/")}>
          Retornar a aplicação
        </Button>
      </Grid>
    </Grid>
  );
}
