/** @format */

import { AddCircle, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionConfirm } from "../../../../../components/actionConfirm";
import {
  motive_e_social,
  motive_e_social_manager,
} from "../../../../../config/constants";
import { EmptyData } from "../../../../../helpers/emptyData";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";

export function SalaryReviewNew() {
  const router = useNavigate();
  const { id } = useParams();
  const userData = useSelector((state: any) => state.user.data);
  const [user, setUser]: any = useState({});
  const [rules, setRules]: any = useState({});
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = (role: any) =>
    yup.object({
      move_date: yup
        .date()
        .min(new Date(), "Data da movimentação precisa ser futura")
        .nullable()
        .required("Data da movimentação é obrigatório"),
      e_social_motive: yup
        .string()
        .nullable()
        .required("Motivo da revisão é obrigatório"),
      percent: yup
        .number()
        .nullable()
        .test(
          "between-min-and-max-or-rh-user",
          `O valor deve ser entre ${rules.salary_review_min_percent}% e ${rules.salary_review_max_percent}%`,
          (value: any) =>
            user.role === "rh" ||
            (value >= rules.salary_review_min_percent &&
              value <= rules.salary_review_max_percent)
        )
        .required("Porcentagem é obrigatório"),
      future_salary: yup
        .string()
        .nullable()
        .test({
          message: "Salário não pode ser menor que o atual.",
          test: (value: any, { parent }) => {
            const { actual_salary } = parent;
            const valueFormat = Number(ClearCurrency(value));
            if (valueFormat < Number(actual_salary)) {
              return false;
            }
            return true;
          },
        })
        .required("Salário futuro é obrigatório"),
      observations: yup
        .string()
        .nullable()
        .required("Comentário é obrigatório"),
    });

  const formik: any = useFormik({
    initialValues: {
      e_social_motive: null,
      move_date: null,
      percent: "",
      future_salary: "",
      observations: "",
      actual_salary: "",
    },
    validationSchema: validationSchema(userData.role),
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      values.manager_id = userData.id;
      delete user.uuid;
      delete user.status;
      delete values.actual_salary;
      delete user.salary_range;
      PostInstante(
        null,
        {
          ...values,
          ...user,
          future_salary: ClearCurrency(values.future_salary),
          percent: Number(values.percent),
        },
        "/salary_reviews"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/request/salaryReview");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({ user_uuid: id }, "/salary_reviews_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser(response.data.data.user_data);
        setRules(response.data.data.rules_data);
        formik.setFieldValue(
          "actual_salary",
          ClearCurrency(response.data.data.user_data.remuneration_value) ?? "0"
        );
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: user.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(user.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(user.job_role_name),
    },
    {
      title: "Salário atual",
      value: user.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(user.status),
    },
  ];

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        if (
          Number(formik.values.percent) < 10 ||
          Number(formik.values.percent) > 25
        ) {
          setOpenModal(true);
          return;
        }
        formik.handleSubmit(event);
      }}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/salaryReview'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/salaryReview'>
              Revisão salarial
            </Link>
            <Typography fontWeight={800}>Nova solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"} mt={2}>
                <Typography fontSize={16} fontWeight={800}>
                  Instruções
                </Typography>
                <Typography fontSize={12} fontWeight={400}>
                  Para revisão salarial confira as informações e digite os
                  detalhes.
                </Typography>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar revisão salarial
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='e_social_motive'
                select
                label='Motivo da revisão'
                value={formik.values.e_social_motive}
                onChange={(event: any) =>
                  formik.setFieldValue("e_social_motive", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.e_social_motive &&
                  Boolean(formik.errors.e_social_motive)
                }
                helperText={
                  formik.touched.e_social_motive &&
                  formik.errors.e_social_motive
                }>
                {userData.role === "rh"
                  ? motive_e_social.map((option: any) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  : motive_e_social_manager.map((option: any) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
              </TextField>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "move_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.move_date &&
                      Boolean(formik.errors.move_date),
                    helperText:
                      formik.touched.move_date && formik.errors.move_date,
                  },
                }}
                disabled={false}
                label='Data da movimentação'
                value={formik.values.move_date}
                onChange={(value: any) => {
                  formik.setFieldValue("move_date", value);
                }}
              />
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id='percent'
                type={"number"}
                label='Porcentagem (%)'
                value={formik.values.percent}
                onChange={(event: any) => {
                  formik.setFieldValue("percent", event.target.value);
                  formik.setFieldValue(
                    "future_salary",
                    MaskCurrency(
                      ((parseFloat(event.target.value) + 100) *
                        parseFloat(formik.values.actual_salary)) /
                        100
                    )
                  );
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.percent && Boolean(formik.errors.percent)}
                helperText={formik.touched.percent && formik.errors.percent}
              />
              <TextField
                fullWidth
                id='future_salary'
                label='Salário futuro'
                value={formik.values.future_salary}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "percent",
                    ReadjustSalary(
                      Number(formik.values.actual_salary),
                      ClearCurrency(FormatCurrency(event))
                    )
                  );
                  formik.setFieldValue("future_salary", FormatCurrency(event));
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.future_salary &&
                  Boolean(formik.errors.future_salary)
                }
                helperText={
                  formik.touched.future_salary && formik.errors.future_salary
                }
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Comentários'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                fullWidth={isMobile}
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}>
                Cadastrar
              </Button>
              <ActionConfirm
                onConfirm={() => {
                  formik.handleSubmit(formik?.values as any);
                }}
                title={`A porcentagem do salário deve estar entre ${rules.salary_review_min_percent}% e ${rules.salary_review_max_percent}%. Deseja confirmar mesmo assim?`}
                open={openModal}
                onClose={() => setOpenModal(false)}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
