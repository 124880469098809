/** @format */

import {
  AddCircle,
  Cancel,
  CheckCircle,
  Delete,
  Edit,
  InfoOutlined,
  Undo,
} from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { AlertDialog, Callout, Flex } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { updateLoading } from "../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstante,
  PutInstante,
  RemoveInstance,
} from "../../../../services/api";
import { UserGetAll } from "../../../../services/protected/hooks/getUser";

const validationSchema = yup.object({
  initial_date: yup
    .date()
    .nullable()
    .max(
      yup.ref("final_date"),
      "Data inicial não pode ser posterior a data final"
    )
    .required("Data inicial é obrigatório"),
  final_date: yup
    .date()
    .nullable()
    .min(
      yup.ref("initial_date"),
      "Data final não pode ser anterior a data inicial"
    )
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .required("Data final é obrigatório"),
  target_user_id: yup.string().nullable().required("Atribuição é obrigatório"),
  delegated_user_id: yup.string().nullable().required("Colaborador delegado é obrigatório"),
});

export function DelegationNew() {
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  let { id } = useParams();
  const editPage = id !== undefined;
  const dispatch = useDispatch();
  const { users } = UserGetAll(dispatch);
  const formik: any = useFormik({
    initialValues: {
      initial_date: null,
      final_date: null,
      target_user_id: "",
      delegated_user_id: user.id,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      if (editPage) {
        PutInstante(null, values, "/delegations/" + id)
          .then((response: any) => {
            dispatch(updateLoading(false));
            router("/delegation");
            toast.success(response?.data?.message);
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      } else {
        PostInstante(null, values, "/delegations")
          .then((response: any) => {
            dispatch(updateLoading(false));
            router("/delegation");
            toast.success(response?.data?.message);
          })
          .catch((e: any) => {
            if (e.code !== "ERR_CANCELED") {
              dispatch(updateLoading(false));
              toast.error(
                e?.response?.data?.message ??
                  "Erro inesperado, tente novamente."
              );
            }
          });
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("delegated_user_id", user.id);
    if (editPage) {
      GetInstance({}, "/delegations/" + id)
        .then(async (response: any) => {
          dispatch(updateLoading(false));
          const initial_date = dayjs(response.data.data.initial_date);
          const final_date = dayjs(response.data.data.final_date);
          formik.setValues({
            initial_date,
            final_date,
            target_user_id: response.data.data.record.target_user_id,
            delegated_user_id: response.data.data.record.delegated_user_id,
          });
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/delegation'>
              Delegações
            </Link>
            <Typography fontWeight={800}>
              {editPage ? "Ver" : "Nova"} solicitação
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Callout.Text>
              <Grid>
                <Typography fontSize={16} fontWeight={800}>
                  Instruções
                </Typography>
                <Typography fontSize={12}>
                  Para delegar suas tarefas e fluxos de trabalho, em períodos de
                  ausência a outro colaborador digite os dados abaixo.
                </Typography>
              </Grid>
            </Callout.Text>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Delegação de fluxo de trabalho
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "initial_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.initial_date &&
                      Boolean(formik.errors.initial_date),
                    helperText:
                      formik.touched.initial_date && formik.errors.initial_date,
                  },
                }}
                disabled={
                  editPage && user.id !== formik.values.delegated_user_id
                }
                label='Data Início'
                value={formik.values.initial_date}
                onChange={(value: any) => {
                  formik.setFieldValue("initial_date", value);
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "final_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.final_date &&
                      Boolean(formik.errors.final_date),
                    helperText:
                      formik.touched.final_date && formik.errors.final_date,
                  },
                }}
                disabled={
                  editPage && user.id !== formik.values.delegated_user_id
                }
                label='Data Fim'
                value={formik.values.final_date}
                onChange={(value: any) => {
                  formik.setFieldValue("final_date", value);
                }}
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='target_user_id'
                select
                disabled={
                  editPage && user.id !== formik.values.delegated_user_id
                }
                label='Atribuir a'
                value={formik.values.target_user_id}
                onChange={(event: any) =>
                  formik.setFieldValue("target_user_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.target_user_id &&
                  Boolean(formik.errors.target_user_id)
                }
                helperText={
                  formik.touched.target_user_id && formik.errors.target_user_id
                }>
                {users.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {user.role !== 'manager' ? <TextField
                  fullWidth
                  id='delegated_user_id'
                  select
                  disabled={editPage && user.id !== formik.values.delegated_user_id}
                  label='Colaborador delegado'
                  value={formik.values.delegated_user_id}
                  onChange={(event: any) =>
                      formik.setFieldValue("delegated_user_id", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                      formik.touched.delegated_user_id &&
                      Boolean(formik.errors.delegated_user_id)
                  }
                  helperText={
                      formik.touched.delegated_user_id && formik.errors.delegated_user_id
                  }>
                {users.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                ))}
              </TextField> : null}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  router("/delegation");
                }}
                endIcon={<Undo />}>
                Voltar
              </Button>
              {!editPage ? (
                <Button
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<AddCircle />}>
                  Cadastrar
                </Button>
              ) : (
                <>
                  {user.id === formik.values.assigned_to_id && (
                    <Button
                      variant={"contained"}
                      type={"submit"}
                      endIcon={<Edit />}>
                      Salvar
                    </Button>
                  )}
                  <AlertDialog.Root>
                    <AlertDialog.Trigger>
                      <Button variant={"contained"} endIcon={<Delete />}>
                        Deletar
                      </Button>
                    </AlertDialog.Trigger>
                    <AlertDialog.Content maxWidth='450px'>
                      <AlertDialog.Title>
                        Deseja realmente excluir esta delegação?
                      </AlertDialog.Title>
                      <AlertDialog.Description size='2'>
                        Ao excluir, esta solicitação não ficará mais visível e
                        será irreversível.
                      </AlertDialog.Description>

                      <Flex gap='3' mt='4' justify='end'>
                        <AlertDialog.Cancel>
                          <Button variant={"outlined"} endIcon={<Cancel />}>
                            Cancelar
                          </Button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action>
                          <Button
                            variant={"contained"}
                            endIcon={<CheckCircle />}
                            onClick={() => {
                              dispatch(updateLoading(true));
                              RemoveInstance(id, "/delegations")
                                .then((response: any) => {
                                  dispatch(updateLoading(false));
                                  router("/delegation");
                                  toast.success(response?.data?.message);
                                })
                                .catch((e: any) => {
                                  if (e.code !== "ERR_CANCELED") {
                                    dispatch(updateLoading(false));
                                    toast.error(
                                      e?.response?.data?.message ??
                                        "Erro inesperado, tente novamente."
                                    );
                                  }
                                });
                            }}>
                            Confirmar
                          </Button>
                        </AlertDialog.Action>
                      </Flex>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
