/** @format */
import { MarkChatUnread, OpenInNew } from "@mui/icons-material";
import {
  Breadcrumbs,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { optionsTable } from "../../../config/constants";
import Chip from "../../../config/header/chip";
import { FilterEmpty } from "../../../helpers/emptyData";
import { RedirectSolicitation } from "../../../helpers/redirectSolicitation";
import {
  udateNotification,
  updateLoading,
} from "../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../services/api";

const columns = [
  { name: "created_at", label: "Data" },
  { name: "title", label: "Titulo" },
  { name: "message", label: "Mensagem" },
];

export const getNotificationsUnread = (dispatch: any, setUnread?: any) => {
  GetInstance(
    {
      unread: true,
    },
    "notifications"
  )
    .then((response: any) => {
      if (setUnread) setUnread(response.data.data.total_count);
      dispatch(
        udateNotification({
          totalNotifications: response.data.data.total_count,
          notifications: FilterEmpty(response.data.data.data.slice(0, 3)),
        })
      );
    })
    .catch((e: any) => {
      console.info(e);
    });
};
export function Notifications() {
  const user = useSelector((state: any) => state.user.data);
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [unread, setUnread] = useState(0);
  
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [order, setOrder]: any = useState("created_at DESC");

  const dispatch = useDispatch();
  const theme = useTheme();
  const router = useNavigate();

  const chipSX = {
    height: 24,
    padding: "0 6px",
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
  };

  const getNotifications = () => {
    dispatch(updateLoading(true));
    GetInstance(
      {
        page: 1,
        per_page: (page + 1) * perPage,
        order_by: order,
      },
      "notifications"
    )
      .then((response: any) => {
        setData(FilterEmpty(response.data.data.data));
        setTotal(response.data.data.total_count);
        dispatch(updateLoading(false));
        getNotificationsUnread(dispatch, setUnread);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(updateLoading(true));
      getNotifications();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, order]);

  function readNotification(values: any, message = true) {
    dispatch(updateLoading(true));
    PutInstante(values.id, { read: true }, "/notifications")
      .then(() => {
        if (message !== false) {
          dispatch(updateLoading(false));
          toast.success("Notificação lida com sucesso");
          getNotifications();
        }
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          if (message !== false) {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        }
      });
  }

  return (
    <Grid container>
      <Grid container>
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography fontWeight={800}>Notificações</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container mt={2} justifyContent={"space-between"}>
        <Grid item>
          <Grid container alignItems='center' gap={4}>
            <Grid item>
              <Grid container flexDirection={"column"}>
                <Typography component={"span"} fontSize={22}>
                  Olá,
                  <strong> {user?.social_name ?? user?.name}</strong>
                </Typography>
                <Typography component={"span"} fontSize={16}>
                  Você possui <strong>{unread}</strong> mensagen(s) não lida(s).
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <MUIDataTable
          title={"Todas as notificações"}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações / Status",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container>
                      {data[dataIndex].read_at === "Não possui" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            alignItems: "center",
                            gap: 12,
                          }}>
                          {data[dataIndex].resource_uuid !== "Não possui" && (
                            <Tooltip title={"Ir para a solicitação"}>
                              <IconButton
                                onClick={() => {
                                  readNotification(
                                    {
                                      id: data[dataIndex].uuid,
                                    },
                                    false
                                  );
                                  RedirectSolicitation(
                                    data[dataIndex].resource_type,
                                    data[dataIndex].resource_uuid,
                                    router
                                  );
                                }}>
                                <OpenInNew color='primary' />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title={"Marcar como lida"}>
                            <IconButton
                              onClick={() => {
                                readNotification({
                                  id: data[dataIndex].uuid,
                                });
                              }}>
                              <MarkChatUnread color='primary' />
                            </IconButton>
                          </Tooltip>
                          <Chip label='Não lida' sx={chipWarningSX} />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 12,
                          }}>
                          {data[dataIndex].resource_uuid !== "Não possui" && (
                            <Tooltip title={"Ir para a solicitação"}>
                              <IconButton
                                onClick={() => {
                                  readNotification(
                                    {
                                      id: data[dataIndex].uuid,
                                    },
                                    false
                                  );
                                  RedirectSolicitation(
                                    data[dataIndex].resource_type,
                                    data[dataIndex].resource_uuid,
                                    router
                                  );
                                }}>
                                <OpenInNew color='primary' />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Chip
                            label={`Lida em ${data[dataIndex].updated_at}`}
                            sx={chipSuccessSX}
                          />
                        </div>
                      )}
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            download: false,
            searchAlwaysOpen: false,
            search: false,
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
