/** @format */

import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { optionsTable } from "../../../config/constants";
import { FilterEmpty } from "../../../helpers/emptyData";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../../services/api";

const columns = [
  { name: "operation", label: "Operação" },
  { name: "functionality", label: "Funcionalidade" },
  { name: "executed_at", label: "Data" },
  { name: "username", label: "Usuário" },
];

export function Audit() {
  const dispatch = useDispatch();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [order, setOrder]: any = useState(null);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      { page: page + 1, per_page: perPage, order_by: order },
      "audits"
    )
      .then((response: any) => {
        setData((old: any) =>
          page === 0
            ? FilterEmpty(response.data.data.data)
            : [...old, ...FilterEmpty(response.data.data.data)]
        );
        setTotal(response.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, order]);

  return (
    <Grid container gap={2}>
      <Grid container justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography fontWeight={800}>Auditória</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container>
        <MUIDataTable
          title={"Auditória"}
          data={data}
          columns={columns}
          options={{
            ...optionsTable,
            searchAlwaysOpen: false,
            search: false,
            download: false,
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
