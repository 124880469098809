/** @format */

import { AddCircle, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { EmptyData } from "../../../../../helpers/emptyData";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";

const validationSchema = (min: number, max: number) =>
  yup.object({
    movement_date: yup
      .date()
      .min(new Date(), "Data da movimentação precisa ser futura")
      .nullable()
      .required("Data da movimentação é obrigatório"),
    // target_business_unit_id: yup
    //   .string()
    //   .nullable()
    //   .required("Unidade de negócio destino é obrigatório"),
    // target_sector_id: yup
    //   .string()
    //   .nullable()
    //   .required("Setor / Locação destino é obrigatório"),
    // target_cost_center_id: yup
    //   .string()
    //   .nullable()
    //   .required("Centro de custo destino é obrigatório"),
    target_job_role_id: yup
      .string()
      .nullable()
      .required("Cargo destino é obrigatório"),
    target_position_id: yup
      .string()
      .nullable()
      .required("Posição destino é obrigatório"),
    percent: yup
      .number()
      .nullable()
      .min(min, `Reajuste não pode ser menor de ${min}%`)
      .max(max, `Reajuste não pode ser maior de ${max}%`)
      .required("Porcentagem é obrigatório"),
    target_salary: yup.string().nullable().required("Salário é obrigatório"),
    observations: yup.string().nullable().required("Comentário é obrigatório"),
  });

const validationSchemaRH = yup.object({
  movement_date: yup
    .date()
    .min(new Date(), "Data da movimentação precisa ser futura")
    .nullable()
    .required("Data da movimentação é obrigatório"),
  // target_business_unit_id: yup
  //   .string()
  //   .nullable()
  //   .required("Unidade de negócio destino é obrigatório"),
  // target_sector_id: yup
  //   .string()
  //   .nullable()
  //   .required("Setor / Locação destino é obrigatório"),
  // target_cost_center_id: yup
  //   .string()
  //   .nullable()
  //   .required("Centro de custo destino é obrigatório"),
  target_job_role_id: yup
    .string()
    .nullable()
    .required("Cargo destino é obrigatório"),
  target_position_id: yup
    .string()
    .nullable()
    .required("Posição destino é obrigatório"),
  percent: yup.number().nullable().required("Porcentagem é obrigatório"),
  target_salary: yup.string().nullable().required("Salário é obrigatório"),
  observations: yup.string().nullable().required("Comentário é obrigatório"),
});

export function PromoteNew() {
  const router = useNavigate();
  const { id } = useParams();
  const [data, setData]: any = useState({
    options: {
      business_unit: [],
      sector: [],
      cost_center: [],
      job_role: [],
      job_role_group: [],
      positions: [],
    },
    user_data: {},
    rules: {},
  });
  const user = useSelector((state: any) => state?.user?.data);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      target_business_unit_id: "",
      movement_date: null,
      percent: "",
      target_salary: "",
      observations: "",
      promote_position: false,
      target_sector_id: "",
      target_cost_center_id: "",
      target_position_id: "",
      target_job_role_id: "",
      actual_salary: "",
    },
    validationSchema:
      user.role === "rh"
        ? validationSchemaRH
        : validationSchema(
            data.rules.promotions_min_percent,
            data.rules.promotions_max_percent
          ),
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...values,
          percent: Number(values.percent),
          collaborator_id: data.user_data.id,
        },
        "/promotions"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/request/promote");
          toast.success("Promoção solicitada com sucesso");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({ user_uuid: id }, "/promotions_form")
      .then(async (response: any) => {
        formik.setFieldValue(
          "actual_salary",
          ClearCurrency(response.data.data.user_data.remuneration_value) ?? "0"
        );
        dispatch(updateLoading(false));
        setData(response.data.data);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.user_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.user_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.user_data.job_role_name),
    },
    {
      title: "Posição",
      value: EmptyData(data.user_data.position_name),
    },
    {
      title: "Salário atual",
      value: data.user_data.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(data.user_data.status),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/promote'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/promote'>
              Promoções
            </Link>
            <Typography fontWeight={800}>Nova solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"}>
                <Typography fontSize={16} fontWeight={800}>
                  Instruções
                </Typography>
                <Typography fontSize={12} fontWeight={400}>
                  Para promover o colaborador confira as informações abaixo e
                  digite os detalhes da promoção.
                </Typography>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar promoção
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <FormControlLabel
                value={formik.values.promote_position}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "promote_position",
                    event.target.checked
                  );
                  if (event.target.checked) {
                    const option =
                      data.options.positions.filter(
                        (item: any) => item.value === data.user_data.position_id
                      )[0] ?? {};
                    formik.setFieldValue(
                      "target_position_id",
                      data.user_data.position_id
                    );
                    formik.setFieldValue(
                      "target_job_role_id",
                      option.job_role_id
                    );
                    formik.setFieldValue("target_sector_id", option.sector_id);
                    formik.setFieldValue(
                      "target_cost_center_id",
                      option.cost_center_id
                    );
                    formik.setFieldValue(
                      "target_business_unit_id",
                      option.business_unit_id
                    );
                  }
                }}
                control={<Checkbox />}
                label='A promoção será para a mesma posição?'
              />
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id='target_position_id'
                select
                label='Posição destino'
                disabled={formik.values.promote_position}
                value={formik.values.target_position_id}
                onChange={(event: any) => {
                  const option =
                    data.options.positions.filter(
                      (item: any) => item.value === event.target.value
                    )[0] ?? {};
                  formik.setFieldValue(
                    "target_position_id",
                    event.target.value
                  );
                  formik.setFieldValue(
                    "target_job_role_id",
                    option.job_role_id
                  );
                  formik.setFieldValue("target_sector_id", option.sector_id);
                  formik.setFieldValue(
                    "target_cost_center_id",
                    option.cost_center_id
                  );
                  formik.setFieldValue(
                    "target_business_unit_id",
                    option.business_unit_id
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.target_position_id &&
                  Boolean(formik.errors.target_position_id)
                }
                helperText={
                  formik.touched.target_position_id &&
                  formik.errors.target_position_id
                }>
                {formik.values.promote_position ? (
                  <MenuItem
                    key={data.user_data.position_id}
                    value={data.user_data.position_id}>
                    {data.user_data.position_name}
                  </MenuItem>
                ) : (
                  data.options.positions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.code} {option.job_role_name}
                    </MenuItem>
                  ))
                )}
              </TextField>
              <TextField
                fullWidth
                id='target_job_role_id'
                select
                label='Cargo destino'
                value={formik.values.target_job_role_id}
                onChange={(event: any) =>
                  formik.setFieldValue("target_job_role_id", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.target_job_role_id &&
                  Boolean(formik.errors.target_job_role_id)
                }
                helperText={
                  formik.touched.target_job_role_id &&
                  formik.errors.target_job_role_id
                }>
                {data.options.job_role.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "movement_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.movement_date &&
                      Boolean(formik.errors.movement_date),
                    helperText:
                      formik.touched.movement_date &&
                      formik.errors.movement_date,
                  },
                }}
                disabled={false}
                label='Data da movimentação'
                value={formik.values.movement_date}
                onChange={(value: any) => {
                  formik.setFieldValue("movement_date", value);
                }}
              />
              {/* {!formik.values.promote_position && (
                <>
                  <TextField
                    fullWidth
                    id='target_business_unit_id'
                    select
                    disabled
                    label='Un. de negócio destino'
                    value={formik.values.target_business_unit_id}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "target_business_unit_id",
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.target_business_unit_id &&
                      Boolean(formik.errors.target_business_unit_id)
                    }
                    helperText={
                      formik.touched.target_business_unit_id &&
                      formik.errors.target_business_unit_id
                    }>
                    {data.options.business_unit.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    disabled
                    id='target_sector_id'
                    select
                    label='Setor / Lotação destino'
                    value={formik.values.target_sector_id}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "target_sector_id",
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.target_sector_id &&
                      Boolean(formik.errors.target_sector_id)
                    }
                    helperText={
                      formik.touched.target_sector_id &&
                      formik.errors.target_sector_id
                    }>
                    {data.options.sector.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    id='target_cost_center_id'
                    select
                    disabled
                    label='Centro de custo destino'
                    value={formik.values.target_cost_center_id}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "target_cost_center_id",
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.target_cost_center_id &&
                      Boolean(formik.errors.target_cost_center_id)
                    }
                    helperText={
                      formik.touched.target_cost_center_id &&
                      formik.errors.target_cost_center_id
                    }>
                    {data.options.cost_center.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )} */}
              <TextField
                fullWidth
                id='percent'
                type={"number"}
                label='Porcentagem (%)'
                value={formik.values.percent}
                onChange={(event: any) => {
                  formik.setFieldValue("percent", event.target.value);
                  if (event.target.value.length === 0) {
                    formik.setFieldValue("target_salary", MaskCurrency(0));
                  } else {
                    formik.setFieldValue(
                      "target_salary",
                      MaskCurrency(
                        ((parseFloat(event.target.value) + 100) *
                          parseFloat(formik.values.actual_salary)) /
                          100
                      )
                    );
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.percent && Boolean(formik.errors.percent)}
                helperText={formik.touched.percent && formik.errors.percent}
              />
              <TextField
                fullWidth
                id='target_salary'
                label='Salário futuro'
                value={formik.values.target_salary}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    "percent",
                    ReadjustSalary(
                      Number(formik.values.actual_salary),
                      ClearCurrency(FormatCurrency(event))
                    )
                  );
                  formik.setFieldValue("target_salary", FormatCurrency(event));
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.target_salary &&
                  Boolean(formik.errors.target_salary)
                }
                helperText={
                  formik.touched.target_salary && formik.errors.target_salary
                }
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Comentários'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <Grid container gap={2} justifyContent={"end"}>
                <Button
                  fullWidth={isMobile}
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  endIcon={<Undo />}>
                  Voltar
                </Button>
                <Button
                  fullWidth={isMobile}
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<AddCircle />}>
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
