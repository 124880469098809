/** @format */

import { Download, InfoOutlined, Undo } from "@mui/icons-material";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatDateMonth } from "../../../../../helpers/formatDate";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";
import "./styles.css";

export function PromoteLetter() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
    record: {},
  });
  const company = useSelector((state: any) => state.company);

  useEffect(() => {
    GetInstance({}, "/promotions/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setData(response.data.data);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: data.collaborator_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.collaborator_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.collaborator_data.job_role_name),
    },
    {
      title: "Salário atual",
      value: data.collaborator_data.remuneration_value,
    },
    {
      title: "Status",
      value: FormatStatus(data.collaborator_data.status),
    },
  ];

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  return (
    <>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/promote'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/promote'>
              Promoções
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href={"/request/promote/details/" + id}>
              Detalhes da solicitação
            </Link>
            <Typography fontWeight={800}>Carta de comunicação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container flexDirection={"column"} mt={2}>
                <Typography fontSize={16} fontWeight={800}>
                  Informações da solicitação
                </Typography>
                <Grid container gap={"12px 96px"} mt={1}>
                  {requestInfo.map((item: any) => (
                    <Grid key={item.title}>
                      <Typography fontSize={12} fontWeight={600}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={400}>
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Carta de comunicação
              </Typography>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              height={"100%"}
              className='printable-content'>
              <Grid gridTemplateColumns={"1fr"} display={"grid"}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <img {...company.logoSmall} alt='Logo da empresa' />
                  <div style={{ gap: 12, display: "flex" }}>
                    {company?.logos?.map((item: any) => (
                      <img {...item} alt='Logo da empresa' />
                    ))}
                  </div>
                </div>
                <Typography
                  mb={3}
                  fontSize={16}
                  color={"primary"}
                  fontWeight={500}>
                  {data.record.e_social_motive?.toUpperCase()}
                </Typography>
                <Typography fontSize={12} mb={3}>
                  {data.collaborator_data.name},
                </Typography>
                <Typography fontSize={12} mb={3}>
                  É com grande alegria que lhe informamos sua{" "}
                  <strong>promoção</strong>.
                </Typography>
                <Typography fontSize={12} mb={3}>
                  Valorizamos seu talento e habilidades, estamos confiantes de
                  que você continuará a trazer resultados excepcionais.
                </Typography>
                <Typography fontSize={12} mb={3}>
                  Agradecemos sinceramente pelo seu comprometimento e dedicação,
                  seu trabalho desempenha um papel crucial no sucesso da
                  empresa, e estamos animados para seu crescimento contínuo.
                </Typography>
                <Typography fontSize={12} mb={3}>
                  Continue assim, juntos vamos cada vez mais longe!
                </Typography>
                <Typography fontSize={12} mb={1}>
                  <strong>Novo Cargo:</strong>{" "}
                  {data.record?.target_job_role_name ?? "Não informado"}
                </Typography>
                <Typography fontSize={12} mb={1}>
                  <strong>Novo Salário:</strong> {data.record.target_salary}
                </Typography>
                <Typography fontSize={12} mb={1}>
                  <strong>Mês de vigência:</strong>{" "}
                  {FormatDateMonth(data.record.movement_date)}
                </Typography>
              </Grid>
              <Grid gridTemplateColumns={"1fr"} display={"grid"}>
                <Typography align='center' fontSize={12}>
                  __________________________________________________________________________
                </Typography>
                <Typography fontWeight={800} align='center' fontSize={12}>
                  {data.collaborator_data?.manager_name === "Não possui"
                    ? "Não informado"
                    : data.collaborator_data?.manager_name}
                </Typography>
                <Typography align='center' fontSize={12}>
                  {data.collaborator_data?.manager_job_role === "Não possui"
                    ? "Não informado"
                    : data.collaborator_data?.manager_job_role}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <Button
              variant={"outlined"}
              onClick={() => {
                window.history.back();
              }}
              fullWidth={isMobile}
              endIcon={<Undo />}>
              Voltar
            </Button>
            <Button
              fullWidth={isMobile}
              variant={"contained"}
              onClick={() => window.print()}
              endIcon={<Download />}>
              Baixar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
