/** @format */

function twoChar(str: any) {
  return str.toString().length === 1 ? "0" + str : str;
}

export function FormatDate(value: any) {
  const date = new Date(value);
  return value !== null
    ? `${twoChar(date.getUTCDate())}/${twoChar(
        date.getUTCMonth() + 1
      )}/${date.getUTCFullYear()}`
    : `-`;
}

export function FormatDateMonth(value: any) {
  const date = new Date(value);
  return value !== null
    ? `${twoChar(date.getUTCMonth() + 1)}/${date.getUTCFullYear()}`
    : `-`;
}

export function FormatDateComplete(value: any) {
  const date = new Date(value);
  return value !== null
    ? `${twoChar(date.getUTCDate())}/${twoChar(
        date.getUTCMonth() + 1
      )}/${date.getUTCFullYear()} ${twoChar(date.getUTCHours())}:${twoChar(
        date.getUTCMinutes()
      )}h`
    : `-`;
}
