/** @format */

import { AddCircle, Clear, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { typeLetter } from "../../../../../config/constants";
import { updateLoading } from "../../../../../redux/reducers/common";
import { PostInstante } from "../../../../../services/api";

const validationSchema = yup.object({
  type: yup.string().nullable().required("Campo é obrigatório"),
  name: yup.string().nullable().required("Campo é obrigatório"),
  content: yup
    .string()
    .nullable()
    .min(100, "Minimo de 100 caracteres")
    .required("Documento é obrigatório"),
});

export function LetterDetails() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const editor = useRef(null);
  const [valide, setValide] = useState(false);

  const config = {
    readonly: false,
    placeholder: "Comece a digitar...",
    language: "pt_br",
    minHeight: 500,
  };
  const formik = useFormik({
    initialValues: {
      type: "",
      name: "",
      content: "",
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...values,
        },
        "/letter"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/settings/letter");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/letter'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/letter'>
              Cartas
            </Link>
            <Typography fontWeight={800}>Novo modelo</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar novo modelo de carta
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='type'
                select
                label='Tipo de carta'
                value={formik.values?.type}
                onChange={(event: any) =>
                  formik.setFieldValue("type", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.type)}
                helperText={formik.errors.type}>
                {typeLetter.map((option: any) => (
                  <MenuItem value={option.value}>{option?.label}</MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                id='name'
                label='Nome'
                variant='outlined'
                value={formik.values.name}
                onChange={(event: any) =>
                  formik.setFieldValue("name", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.name)}
                helperText={formik.errors.name}
              />
            </Grid>
            <Grid pt={2}>
              <JoditEditor
                ref={editor}
                value={formik.values.content}
                config={config}
                onBlur={(newContent) =>
                  formik.setFieldValue("content", newContent)
                }
                onChange={(newContent) => {}}
              />
              {formik.errors?.content && (
                <Typography
                  fontSize={"0.75rem"}
                  style={{ marginTop: 4, marginLeft: 14 }}
                  color={"error"}>
                  {formik.errors?.content}
                </Typography>
              )}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                variant={"outlined"}
                onClick={() => {
                  formik.setFieldValue("content", "");
                }}
                fullWidth={isMobile}
                endIcon={<Clear />}>
                Limpar tudo
              </Button>
              <Button
                fullWidth={isMobile}
                variant={"contained"}
                type={"submit"}
                onClick={() => {
                  setValide(true);
                  formik.handleSubmit();
                }}
                endIcon={<AddCircle />}>
                Cadastrar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
