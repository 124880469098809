/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { Edit, InfoOutlined, ToggleOn, Undo } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionUploadDocument } from "../../../../../components/actionUploadDocument";
import { fields } from "../../../../../config/constants";
import { IsPermissions } from "../../../../../config/isPermission";
import { ClearString } from "../../../../../helpers/clearString";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatCPF } from "../../../../../helpers/formatCPF";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { validationCPF } from "../../../../../helpers/validationCPF";
import { Benefits } from "../../../../../pages/protected/request/updateInformation/request/benefits";
import { Contract } from "../../../../../pages/protected/request/updateInformation/request/contract";
import { Dependents } from "../../../../../pages/protected/request/updateInformation/request/dependents";
import { Remuneration } from "../../../../../pages/protected/request/updateInformation/request/remuneration";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { Documents } from "./documents";
import { Personal } from "./personal";

const contractTypeValidation = (value: any, context: any) => {
  const position_type = context.from[0].value.position_type;

  if (value === null) return true;
  if (
    (value === "999" && position_type !== "pj") ||
    (value !== "999" && position_type === "pj")
  ) {
    return false;
  }
  return true;
};

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

const validationSchema = yup.object({
  cpf: yup
    .string()
    .nullable()
    .required("Campo é obrigatório")
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  father_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  mother_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  business_unit_contract_type: yup
    .string()
    .nullable()
    .test(
      "invalid-contract_type",
      "Tipo de contrato inválido!",
      (value, context) => contractTypeValidation(value, context)
    ),
});

export function UpdateNew() {
  const { id } = useParams();
  const [index, setIndex] = useState(0);
  const [data, setData]: any = useState({
    user_data: {},
    fields_with_required_documents: [],
    user_form_hidden_fields: [],
  });
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { uuid: "" },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(values.uuid, values, "/users")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({ user_uuid: id }, "/users_form")
      .then(async (response: any) => {
        if (
          response.data.data.address_data &&
          response.data.data.address_data.country
        ) {
          delete response.data.data?.address_data?.country;
        }

        const dataFormat = {
          city_of_birth: ClearString(
            response.data.data.record_data?.city_of_birth ?? ""
          ).toLocaleUpperCase(),
          ...response.data.data.address_data,
          dependents: response.data.data.dependant_data,
          emergency_contacts: response.data.data.emergency_contact_data,
          fields_with_required_documents:
            response.data.data.fields_with_required_documents,
          ...response.data.data.record_data,
          position_code: response.data.data.user_data.position_code,
          position_type: response.data.data.user_data.position_type,
        };
        // eslint-disable-next-line array-callback-return
        Object.entries(dataFormat).map((opt: any) => {
          if (opt[0].indexOf("date") !== -1 && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "business_unit_employment_bond" && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "business_unit_exp_extension" && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "contribution_starting_from" && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "business_unit_contract_finish" && opt[1] !== null) {
            dataFormat[opt[0]] = dayjs(opt[1]);
          }
          if (opt[0] === "cpf") {
            dataFormat[opt[0]] = FormatCPF(opt[1]);
          }
        });
        // eslint-disable-next-line array-callback-return
        dataFormat.dependents.map((item: any) => {
          item.birth_date = dayjs(item.birth_date);
          item.cpf = FormatCPF(item.cpf);
        });
        formik.setValues({ ...dataFormat, falta: null });
        setData({
          ...response.data.data,
          options: {
            ...response.data.data.options,
          },
        });
        setIndex(1);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function SetActive() {
    dispatch(updateLoading(true));
    PutInstante(formik.values.uuid, { status: "active" }, "/users")
      .then((response: any) => {
        dispatch(updateLoading(false));
        data.user_data.status = "active";
        setData(data);
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  }

  const userInfo = [
    {
      title: "Nome",
      value: data.user_data.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(data.user_data.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(data.user_data.job_role_name),
    },
    {
      title: "Status",
      value: FormatStatus(data.user_data.status),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/updateInformation'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/updateInformation'>
              Alteração cadastral
            </Link>
            <Typography fontWeight={800}>Nova solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar alteração cadastral
              </Typography>
            </Grid>
            {data.user_form_hidden_fields.length ===
            fields.filter((item: any) => item?.hidden === undefined).length ? (
              <Grid pt={5}>
                <Typography align='center' fontSize={18} fontWeight={500}>
                  Não existe campos para serem alterados.
                </Typography>
                <Grid
                  gridTemplateColumns={"1fr"}
                  mt={2}
                  display={"grid"}
                  gap={2}>
                  <Grid container gap={2} justifyContent={"end"}>
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        window.history.back();
                      }}
                      endIcon={<Undo />}>
                      Voltar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Tabs
                  value={index}
                  allowScrollButtonsMobile
                  scrollButtons
                  onChange={(event, newValue) => setIndex(newValue)}
                  aria-label='basic tabs example'>
                  <Tab label='Dados pessoais' value={1} />
                  <Tab label='Estrutura e remuneração' value={2} />
                  <Tab label='Beneficios' value={3} />
                  <Tab label='Contrato' value={4} />
                  <Tab label='Documentação' value={5} />
                  <Tab id={"dependents"} label='Dependentes' value={6} />
                </Tabs>
                <CustomTabPanel value={index} index={1}>
                  <Personal formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={2}>
                  <Remuneration formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={3}>
                  <Benefits formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={4}>
                  <Contract formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={5}>
                  <Documents formik={formik} data={data} />
                </CustomTabPanel>
                <CustomTabPanel value={index} index={6}>
                  <Dependents formik={formik} data={data} />
                </CustomTabPanel>
                <Grid
                  gridTemplateColumns={"1fr"}
                  mt={2}
                  display={"grid"}
                  gap={2}>
                  <Grid container gap={2} justifyContent={"end"}>
                    <Button
                      fullWidth={isMobile}
                      variant={"outlined"}
                      onClick={() => {
                        window.history.back();
                      }}
                      endIcon={<Undo />}>
                      Voltar
                    </Button>
                    {data.user_data.status === "pending" && (
                      <IsPermissions keyPrivate={"employeePendingActive"}>
                        <Button
                          fullWidth={isMobile}
                          variant={"contained"}
                          color='success'
                          onClick={SetActive}
                          endIcon={<ToggleOn />}>
                          Tornar ativo
                        </Button>
                      </IsPermissions>
                    )}
                    <ActionUploadDocument
                      buttonTitle='Solicitar alteração'
                      keyPrivate='requestUpdateInformationRequestSolicitation'
                      id={id}
                      form={formik}
                      url='/user_update_requests'
                      data={{ ...formik.values, status: data.user_data.status }}
                      touched={formik.touched}
                      errors={formik.errors}
                      urlBack={"/request/updateInformation"}
                    />
                    <IsPermissions
                      keyPrivate={"requestUpdateInformationRequestSubmit"}>
                      <Tooltip
                        title={
                          Object.keys(formik.touched).length === 0
                            ? "Não existem dados alterados"
                            : "Salvar dados"
                        }>
                        <div>
                          <Button
                            fullWidth={isMobile}
                            disabled={Object.keys(formik.touched).length === 0}
                            variant={"contained"}
                            type={"submit"}
                            endIcon={<Edit />}>
                            Salvar alteração
                          </Button>
                        </div>
                      </Tooltip>
                    </IsPermissions>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
