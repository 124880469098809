/** @format */

import { Info } from "@mui/icons-material";
import {
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Filter } from "../../../components/filter";
import { optionsTable } from "../../../config/constants";
import { FilterEmpty } from "../../../helpers/emptyData";
import { exportDataToCSV } from "../../../helpers/exportDataToCSV";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../../services/api";

const columns = [
  { name: "collaborator_identification", label: "Matrícula" },
  { name: "collaborator_name_formatted", label: "Nome do colaborador" },
  { name: "business_unit_contract_type", label: "Tipo de contrato" },
  { name: "solicitation_type_label", label: "Nome do processo" },
  { name: "approved_at", label: "Data de aprovação" },
  { name: "status", label: "Status" },
];

const statusOptions = [
  { label: "Aprovados", value: "approved" },
  { label: "Concluídos", value: "completed" },
  { label: "Devolução folha", value: "accounting_return" },
];

const processOptions = [
  { label: "Todos", value: "all" },
  { label: "Transferência", value: "transfer" },
  { label: "Desligamento", value: "dismissal" },
  { label: "Afastamento", value: "absence" },
  { label: "Revisão salarial", value: "salary_review" },
  { label: "Promoção", value: "promotion" },
  { label: "Férias", value: "vacation_item" },
  { label: "Posição", value: "new_position" },
  { label: "Atualização cadastral", value: "user_update_request" },
];

let timerSearch: any;

export function Processes() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);

  const formik: any = useFormik({
    initialValues: {
      status: "approved",
      solicitation_types: "all",
      from: dayjs(new Date().setDate(new Date().getUTCDate() - 30)),
      to: dayjs(new Date()),
    },
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      GetInstance(
        {
          page: page + 1,
          per_page: perPage,
          search,
          order_by: order,
          status: values.status,
          solicitation_types: values.solicitation_types,
          from: values.from.toISOString().split("T")[0],
          to: values.to.toISOString().split("T")[0],
        },
        "/processes"
      )
        .then((response: any) => {
          const data = response.data.data.data.map((item: any) => ({
            ...item,
            solicitation_type_label: processOptions.find(
              (process: any) => process?.value === item?.solicitation_type
            )?.label,
          }));
          setData((old: any) =>
            page === 0 ? FilterEmpty(data) : [...old, ...FilterEmpty(data)]
          );
          setTotal(response.data.data.total_count);
          dispatch(updateLoading(false));
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  const handleResourseRoute = (data: any) => {
    const routes: any = {
      absence: `/request/leave/details/${data.resource_uuid}`,
      dismissal: `/request/dismissals/details/${data.resource_uuid}`,
      salary_review: `/request/salaryReview/details/${data.resource_uuid}`,
      promotion: `/request/promote/details/${data.resource_uuid}`,
      transfer: `/request/transfer/details/${data.resource_uuid}`,
      vacation_item: `/request/vacation/details/${data.resource_uuid}`,
      user_update_request: `/request/updateInformation/details/${data.resource_uuid}`,
      new_position: `/request/position/details/${data.resource_uuid}`,
    };

    const route = routes[data.solicitation_type];
    if (route) {
      router(route);
    }
  };

  const handleDownloadButton = async () => {
    dispatch(updateLoading(true));
    const values = formik.values;
    GetInstance(
      {
        search,
        order_by: order,
        status: values.status,
        solicitation_types: values.solicitation_types,
        from: values.from.toISOString().split("T")[0],
        to: values.to.toISOString().split("T")[0],
      },
      "/processes/export_csv"
    )
      .then((response: any) => {
        const data = response.data.data;
        exportDataToCSV(data, "Processos");
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
  };

  useEffect(() => {
    formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);

  return (
    <Grid container gap={2}>
      <Grid container justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography fontWeight={800}>Processos</Typography>
        </Breadcrumbs>
      </Grid>
      <Filter
        formik={formik}
        keys={{
          from: { label: "Data inicio" },
          to: { label: "Data final" },
          solicitation_types: { label: "Processo", options: processOptions },
          status: { label: "Status", options: statusOptions },
        }}>
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "from",
              onBlur: formik.handleBlur,
              error: formik.touched.from && Boolean(formik.errors.from),
              helperText: formik?.errors?.from,
            },
          }}
          label='Data Inícial'
          value={formik.values.from}
          onChange={(value: any) => {
            formik.setFieldValue("from", value);
          }}
        />
        <DatePicker
          slotProps={{
            textField: {
              fullWidth: true,
              id: "to",
              onBlur: formik.handleBlur,
              error: formik.touched.to && Boolean(formik.errors.to),
              helperText: formik?.errors?.to,
            },
          }}
          label='Data Final'
          value={formik.values.to}
          onChange={(value: any) => {
            formik.setFieldValue("to", value);
          }}
        />
        <TextField
          fullWidth
          id='solicitation_types'
          name='solicitation_types'
          select
          label='Processo'
          value={formik.values.solicitation_types}
          onChange={(event: any) =>
            formik.setFieldValue("solicitation_types", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.solicitation_types &&
            Boolean(formik.errors.solicitation_types)
          }
          helperText={formik?.errors?.solicitation_types}>
          {processOptions.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id='status'
          name='status'
          select
          label='Status'
          value={formik.values.status}
          onChange={(event: any) =>
            formik.setFieldValue("status", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.status && Boolean(formik.errors.status)}
          helperText={formik?.errors?.status}>
          {statusOptions.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Filter>
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Tooltip title={"Ver solicitação"}>
                      <IconButton
                        onClick={() => handleResourseRoute(data[dataIndex])}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: true,
            onDownload: () => {
              handleDownloadButton();
              return false;
            },
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
