/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { optionsTable } from "../../../../config/constants";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";
// import { toast } from 'react-toastify';
import { Add, Visibility } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../../components/actionRemove";
import { IsPermissions } from "../../../../config/isPermission";
import { FilterEmpty } from "../../../../helpers/emptyData";

const columns = [
  { name: "solicitation_id", label: "Código" },
  { name: "target_user_name", label: "Nome" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "solicitation_status", label: "Status" },
];

let timerSearch: any;

export function UpdateInformation() {
  const dispatch = useDispatch();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState("solicitation_status DESC");
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const delegation = useSelector((state: any) => state.user.delegation_data);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      { page: page + 1, per_page: perPage, search, order_by: order },
      "/user_update_requests"
    )
      .then((response: any) => {
        setData((old: any) =>
          page === 0
            ? FilterEmpty(response.data.data.data)
            : [...old, ...FilterEmpty(response.data.data.data)]
        );
        setTotal(response.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          // toast.error(e?.response?.data?.message ?? 'Erro inesperado, tente novamente.');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);

  return (
    <Grid container gap={2}>
      <Grid container gap={2} justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography>Solicitações</Typography>
          <Typography fontWeight={800}>Alteração cadastral</Typography>
        </Breadcrumbs>
        {!delegation?.delegated_session && (
          <Button
            variant={"contained"}
            onClick={() => {
              router(`/request/updateInformation/request/${user.uuid}`);
            }}
            fullWidth={isMobile}
            endIcon={<Add />}>
            Solicitar alteração cadastral
          </Button>
        )}
      </Grid>
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container>
                      <IsPermissions
                        keyPrivate={"requestUpdateInformationDetails"}>
                        <Tooltip title={"Ver solicitação"}>
                          <IconButton
                            onClick={() =>
                              router(
                                `/request/updateInformation/details/${data[dataIndex].uuid}`
                              )
                            }>
                            <Visibility color='primary' />
                          </IconButton>
                        </Tooltip>
                      </IsPermissions>
                      <ActionRemove
                        statusList={data[dataIndex].solicitation_status}
                        url='/user_update_requests'
                        keyPrivate='requestUpdateInformationDetailsRemove'
                        id={data[dataIndex].uuid}
                      />
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: false,
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
