/** @format */

import {
  AccountCircleOutlined,
  ExitToApp,
  Settings,
} from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateLoading } from "../../redux/reducers/common";
import { PostInstante } from "../../services/api";
import MainCard from "./mainCard";
import Transitions from "./transitions";

const ProfileSection = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef: any = useRef(null);
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(updateLoading(true));
    PostInstante(null, {}, "/logout")
      .then(() => {
        dispatch(updateLoading(false));
        Cookies.remove("auth");
        router("/login");
      })
      .catch(() => {
        dispatch(updateLoading(false));
        toast.error(
          "Erro ao tentar sair, tente novamente ou então entre em contato com o suporte"
        );
      });
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const prevOpen: any = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && !open) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Tooltip title={"Ver dados da conta"}>
        <Avatar
          alt={user?.social_name ?? user?.name}
          src={user.image ?? "/image.jpeg"}
          ref={anchorRef}
          variant={"rounded"}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          sx={{
            margin: "8px 0 8px 8px !important",
            cursor: "pointer",
            background: "transparent",
            color: theme.palette.grey.A700,
            "&:hover": {
              background: theme.palette.grey.A700,
              color: theme.palette.common.white,
            },
          }}
          color='primary'
        />
      </Tooltip>
      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}>
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}>
                  <Grid
                    style={{
                      height: 40,
                      width: "100%",
                      backgroundColor: theme.palette.primary.main,
                    }}
                  />
                  <Grid container justifyContent={"center"}>
                    <Avatar
                      alt={user?.social_name ?? user?.name}
                      src={user.image ?? "/image.jpeg"}
                      sx={{
                        marginTop: -3,
                        bgcolor: theme.palette.primary.dark,
                      }}
                    />
                  </Grid>

                  <Box sx={{ p: 2, pb: 0 }}>
                    <Typography
                      textAlign={"center"}
                      fontSize={22}
                      fontWeight={800}>
                      {user?.social_name ?? user?.name}
                    </Typography>
                    <Typography textAlign={"center"} fontSize={14}>
                      {user.email ?? "N/A"}
                    </Typography>
                    <Grid
                      container
                      gap={2}
                      m={2}
                      justifyContent={"space-around"}>
                      <Grid item>
                        <Grid container flexDirection={"column"}>
                          <Typography
                            textAlign={"center"}
                            fontSize={16}
                            fontWeight={600}>
                            Equipe
                          </Typography>
                          <Typography textAlign={"center"} fontSize={12}>
                            {user.role ?? "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container flexDirection={"column"}>
                          <Typography
                            textAlign={"center"}
                            fontSize={16}
                            fontWeight={600}>
                            Localização
                          </Typography>
                          <Typography textAlign={"center"} fontSize={12}>
                            {user.cost_center_id ?? "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Divider />
                  </Box>
                  <Box sx={{ p: 2, pt: 0 }}>
                    <List
                      component='nav'
                      sx={{
                        width: "100%",
                        maxWidth: 350,
                        minWidth: 300,
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}>
                      <ListItemButton
                        style={{ borderRadius: 10 }}
                        onClick={() => {
                          setOpen(false);
                          router(
                            "/request/updateInformation/request/" + user.uuid
                          );
                        }}>
                        <ListItemIcon>
                          <AccountCircleOutlined color={"primary"} />
                        </ListItemIcon>
                        <ListItemText
                          style={{ cursor: "pointer" }}
                          primary={
                            <Typography
                              style={{ cursor: "pointer" }}
                              variant='body2'>
                              Meus dados
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        style={{ borderRadius: 10 }}
                        onClick={() => {
                          setOpen(false);
                          router("/settingsAccount");
                        }}>
                        <ListItemIcon>
                          <Settings color={"primary"} />
                        </ListItemIcon>
                        <ListItemText
                          style={{ cursor: "pointer" }}
                          primary={
                            <Typography
                              style={{ cursor: "pointer" }}
                              variant='body2'>
                              Configurações da conta
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <Divider style={{ marginTop: 6 }} />
                      <ListItemButton
                        style={{ borderRadius: 10 }}
                        onClick={handleLogout}>
                        <ListItemIcon>
                          <ExitToApp color={"primary"} />
                        </ListItemIcon>
                        <ListItemText
                          style={{ cursor: "pointer" }}
                          primary={
                            <Typography
                              style={{ cursor: "pointer" }}
                              variant='body2'>
                              Sair da conta
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
