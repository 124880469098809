import {IconButton, Tooltip} from "@mui/material";
import {Brightness4, Brightness7} from "@mui/icons-material";
import Cookies from "js-cookie";

export function ModeTheme() {
	const mode: any = Cookies.get('theme') ?? 'light';
	return <IconButton onClick={() => {
		Cookies.set('theme', mode === 'dark' ? 'light' : 'dark')
		window.location.reload();
	}} color="inherit">
		<Tooltip title="Alterar cor do sistema">
			{mode === 'dark' ? <Brightness7/> : <Brightness4/>}
		</Tooltip>
	</IconButton>
}