/** @format */

export function FormatGroupPermission(value: string) {
  const statusDict = {
    manager: "Gestor",
    rh: "RH",
    collaborator: "Colaborador",
    accountant: "Contador",
  };
  const exist = Object.entries(statusDict).filter(
    (opt: any) => opt[0] === value
  );
  return value ? (exist.length > 0 ? exist[0][1] : "Não possui") : "Não possui";
}
