/** @format */

import { AddCircle, InfoOutlined, Undo, UploadFile } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { leave_e_social } from "../../../../../config/constants";
import { EmptyData } from "../../../../../helpers/emptyData";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstante,
  PostInstanteFormData,
  RemoveInstance,
} from "../../../../../services/api";

const validationSchema = yup.object({
  absence_date: yup.date().required("Data de saída é obrigatório"),
  return_date: yup
    .date()
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .test({
      exclusive: false,
      name: "return_date",
      message: "Data de retorno não pode ser anterior a data de saída",
      test: function (value: any) {
        const { absence_date } = this.parent;
        if (value && new Date(value) <= new Date(absence_date)) return false;
        return true;
      },
    })
    .nullable(),
  e_social_motive: yup.string().nullable().required("Motivo é obrigatório"),
  observations: yup.string().nullable().required("Informação é obrigatório"),
  files: yup
    .array()
    .min(1, "Precisa ter ao menos 1 anexo")
    .nullable()
    .required("Arquivo é obrigatório"),
});

export function LeaveNew() {
  const fileInputRef: any = useRef(null);
  const router = useNavigate();
  const { id } = useParams();
  const [user, setUser]: any = useState({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      absence_date: null,
      return_date: null,
      e_social_motive: "",
      observations: "",
      files: [],
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(null, { ...values, collaborator_id: user.uuid }, "/absences")
        .then((response: any) => {
          const formData = new FormData();
          // eslint-disable-next-line array-callback-return
          formik.values.files.map((file: any) => {
            formData.append("file[]", file);
          });
          PostInstanteFormData(
            null,
            formData,
            "/absences/" + response.data.data.absence_item_id + "/upload_files"
          )
            .then(() => {
              dispatch(updateLoading(false));
              router("/request/leave");
              toast.success(response?.data?.message);
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                RemoveInstance(id, "/absences")
                  .then(() => {
                    dispatch(updateLoading(false));
                    router("/request/leave");
                  })
                  .catch((e: any) => {
                    if (e.code !== "ERR_CANCELED") {
                      dispatch(updateLoading(false));
                      toast.error(
                        e?.response?.data?.message ??
                          "Erro inesperado, tente novamente."
                      );
                    }
                  });
              }
            });
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  const onFileDrop = (file: any) => {
    const filesAll: any = formik.values.files;
    filesAll.push(file);
    formik.setFieldValue("files", filesAll);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleDragOver: any = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  useEffect(() => {
    GetInstance({}, "/absences_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser(response.data.data.user_data);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userInfo = [
    {
      title: "Nome",
      value: user.name,
    },
    {
      title: "Cód. cargo",
      value: EmptyData(user.job_role_code),
    },
    {
      title: "Cargo",
      value: EmptyData(user.job_role_name),
    },
    {
      title: "Posição",
      value: EmptyData(user.job_role_name),
    },
    {
      title: "Status",
      value: FormatStatus(user.status),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/leave'>
              Solicitações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/leave'>
              Afastamento
            </Link>
            <Typography fontWeight={800}>Nova solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações do colaborador
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {userInfo.map((item: any) => (
                  <Grid>
                    <Typography fontSize={12} fontWeight={400}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={300}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar afastamento
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "absence_date",
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.absence_date &&
                      Boolean(formik.errors.absence_date),
                    helperText:
                      formik.touched.absence_date && formik.errors.absence_date,
                  },
                }}
                label='Data de saída'
                value={formik.values.absence_date}
                onChange={(value: any) => {
                  formik.setFieldValue("absence_date", value);
                }}
              />
              <DatePicker
                slotProps={{
                  textField: {
                    fullWidth: true,
                    id: "return_date",
                    onChange: (value: any) => {
                      formik.setFieldValue("return_date", value, true);
                    },
                    onBlur: formik.handleBlur,
                    error:
                      formik.touched.return_date &&
                      Boolean(formik.errors.return_date),
                    helperText:
                      formik.touched.return_date && formik.errors.return_date,
                  },
                }}
                label='Data de retorno'
                value={formik.values.return_date}
                onChange={(value: any) => {
                  formik.setFieldValue("return_date", value, true);

                  if (value < (formik.values.absence_date as any)) {
                    formik.setFieldTouched("return_date");
                  }
                }}
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='e_social_motive'
                select
                label='Motivo do afastamento e-Social'
                value={formik.values.e_social_motive}
                onChange={(event: any) =>
                  formik.setFieldValue("e_social_motive", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.e_social_motive &&
                  Boolean(formik.errors.e_social_motive)
                }
                helperText={
                  formik.touched.e_social_motive &&
                  formik.errors.e_social_motive
                }>
                {leave_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value} - {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='observations'
                  label='Informações sobre o afastamento'
                  variant='outlined'
                  multiline={true}
                  rows={4}
                  value={formik.values.observations}
                  onChange={(event: any) =>
                    formik.setFieldValue("observations", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.observations &&
                    Boolean(formik.errors.observations)
                  }
                  helperText={
                    formik.touched.observations && formik.errors.observations
                  }
                />
              </div>
            </Grid>
            <Grid container mt={2}>
              <div
                style={{
                  border: `1px ${
                    formik.errors.files
                      ? theme.palette.error.main
                      : theme.palette.text.secondary
                  } dashed`,
                  color: formik.errors.files
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
                  cursor: "pointer",
                  borderRadius: 10,
                  width: "inherit",
                }}
                className='ContextMenuTrigger'
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={openFileDialog}>
                <input
                  type='file'
                  accept='*'
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
                <p style={{ display: "flex", justifyContent: "center" }}>
                  <UploadFile style={{ marginRight: 12 }} /> Arraste e solte um
                  arquivo aqui ou clique para selecionar um arquivo.
                </p>
              </div>
            </Grid>
            {formik.errors.files && (
              <Grid container mt={0.5} ml={2}>
                <Typography
                  fontSize={"0.75rem"}
                  textAlign={"center"}
                  color={"error"}>
                  {formik.errors.files}
                </Typography>
              </Grid>
            )}
            {formik.values.files.length > 0 && (
              <Grid container mt={2}>
                <Typography>Arquivos anexados</Typography>
              </Grid>
            )}
            <Grid container mt={2} gap={2}>
              {formik.values.files.map((file: any, index: number) => (
                <Tooltip title={"Remover arquivo"}>
                  <Chip
                    color={"primary"}
                    onDelete={() => {
                      const filesAll: any = formik.values.files;
                      filesAll.splice(index, 1);
                      formik.setFieldValue("files", filesAll);
                    }}
                    label={file.name}
                    variant='outlined'
                  />
                </Tooltip>
              ))}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                fullWidth={isMobile}
                endIcon={<Undo />}>
                Voltar
              </Button>
              <Button
                fullWidth={isMobile}
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}>
                Cadastrar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
