/** @format */

import { AddCircleOutline, GroupAdd } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionRemove } from "../../../../components/actionRemove";
import { optionsTable } from "../../../../config/constants";
import { IsPermissions } from "../../../../config/isPermission";
import { FilterEmpty } from "../../../../helpers/emptyData";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";

let timerSearch: any;

export function Position() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [data, setData]: any = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(
      { page: page + 1, per_page: perPage, search, order_by: order },
      "positions"
    )
      .then((response: any) => {
        setData((old: any) =>
          page === 0
            ? FilterEmpty(response.data.data.data)
            : [...old, ...FilterEmpty(response.data.data.data)]
        );
        setTotal(response.data.data.total_count);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, search, order]);

  const columns: any = [
    { name: "code", label: "N. da Posição" },
    { name: "manager_name", label: "Gestor" },
    { name: "job_role_name", label: "Cargo" },
    { name: "salary", label: "Remuneração" },
    { name: "cost_center_name", label: "Centro de custo" },
    { name: "department_name", label: "Departamento" },
    { name: "business_unit_name", label: "Un. de negócio" },
    { name: "status", label: "Status" },
  ];

  return (
    <Grid container gap={2}>
      <Grid container gap={2} justifyContent={"space-between"}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/public'>
            Inicio
          </Link>
          <Typography fontWeight={400}>Colaboradores</Typography>
          <Typography fontWeight={800}>Posições</Typography>
        </Breadcrumbs>
        <Button
          variant={"contained"}
          onClick={() => {
            router("/request/position/new");
          }}
          fullWidth={isMobile}
          endIcon={<GroupAdd />}>
          Nova posição
        </Button>
      </Grid>
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return data[dataIndex].status === "Aberta" ? (
                    <IsPermissions keyPrivate={"requestPersonalNew"}>
                      <Tooltip title={"Nova requisição de pessoal"}>
                        <Box display='flex'>
                          <IconButton
                            onClick={() => {
                              router(
                                `/request/personal/new?position=${data[dataIndex].uuid}`
                              );
                            }}>
                            <AddCircleOutline />
                          </IconButton>
                          <IconButton>
                            <IsPermissions keyPrivate='requestPositionDetailsRemove'>
                              <ActionRemove
                                statusList={data[dataIndex].status}
                                url='/positions'
                                keyPrivate='requestPositionDetailsRemove'
                                position
                                id={data[dataIndex].uuid}
                              />
                            </IsPermissions>
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </IsPermissions>
                  ) : null;
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            onSearchChange: (value: string) => {
              clearTimeout(timerSearch);
              timerSearch = setTimeout(() => {
                setPage(0);
                setSearch(value);
              }, 500);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
