import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const headerSX = {
	'& .MuiCardHeader-action': {mr: 0}
};
const MainCard = React.forwardRef(
	(
		{
			border = false,
			boxShadow,
			children,
			content = true,
			contentClass = '',
			contentSX = {},
			darkTitle,
			secondary,
			shadow,
			sx = {},
			title,
			...others
		}: any,
		ref
	) => {
		return (
			<Card
				ref={ref}
				{...others}
				sx={{
					border: border ? '1px solid' : 'none',
					borderColor: 'divider',
					':hover': {
						boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
					},
					...sx
				}}
			>
				{!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary}/>}
				{darkTitle && title && <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>}
                                                   action={secondary}/>}
				{title && <Divider/>}
				{content && (
					<CardContent sx={contentSX} className={contentClass}>
						{children}
					</CardContent>
				)}
				{!content && children}
			</Card>
		);
	}
);

export default MainCard;
