export const handleCellPhoneChange = (event: any, formik: any, key: string) => {
	let { value } = event.target;
	const cleanedValue = value.replace(/\D/g, '');
	let maskedValue = value;
	if (cleanedValue.length >= 2) {
		maskedValue = `(${cleanedValue.substring(0, 2)})`;
	}
	if (cleanedValue.length > 2) {
		maskedValue += ` ${cleanedValue.substring(2, 7)}`;
	}
	if (cleanedValue.length > 7) {
		maskedValue += `-${cleanedValue.substring(7, 11)}`;
	}
	formik.setFieldValue(key, maskedValue);
};