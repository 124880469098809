/**
 * eslint-disable @typescript-eslint/no-unused-expressions
 *
 * @format
 */

/* eslint-disable no-sequences */
import { createSlice } from "@reduxjs/toolkit";
import { companysOptions } from "../../config/companies";

export const initial = {
  edf: {
    avaliacao_desempenho: [
      { label: "Abaixo do esperado", value: 1 },
      { label: "Abaixo do esperado", value: 2 },
      { label: "Abaixo do esperado", value: 3 },
      { label: "Abaixo do esperado", value: 4 },
      { label: "Dentro do esperado", value: 5 },
      { label: "Dentro do esperado", value: 6 },
      { label: "Acima do esperado", value: 7 },
      { label: "Acima do esperado", value: 8 },
      { label: "Acima do esperado", value: 9 },
    ],
    remuneracao: [
      { label: "Muito abaixo do mercado", value: 80 },
      { label: "10% abaixo do mercado", value: 90 },
      { label: "No mercado", value: 100 },
      { label: "10% acima do mercado", value: 110 },
      { label: "20% acima do mercado", value: 120 },
    ],
    metas: [
      { label: "Muito abaixo do esperado", value: 80 },
      { label: "Abaixo do esperado", value: 90 },
      { label: "Dentro do esperado", value: 100 },
      { label: "Acima do esperado", value: 110 },
      { label: "Muito acima do esperado", value: 120 },
    ],
    feedback_360: [
      { label: "Muito abaixo", value: 1 },
      { label: "Abaixo", value: 2 },
      { label: "Esperado", value: 3 },
      { label: "Acima", value: 4 },
      { label: "Muito acima", value: 5 },
    ],
    promocao: [
      { label: "Não teve últimos 24 meses", value: 1 },
      { label: "Teve últimos 12 meses ", value: 2 },
      { label: "Teve últimos 6 meses ", value: 3 },
    ],
    merito: [
      { label: "Não teve últimos 24 meses", value: 1 },
      { label: "Teve últimos 12 meses ", value: 2 },
      { label: "Teve últimos 6 meses ", value: 3 },
    ],
    feedback_online: [
      { label: "Muito abaixo", value: 1 },
      { label: "Abaixo", value: 2 },
      { label: "Esperado", value: 3 },
      { label: "Acima", value: 4 },
      { label: "Muito acima", value: 5 },
    ],
    g_nps: [
      { label: "Muito abaixo do esperado", value: 60 },
      { label: "Abaixo do esperado", value: 70 },
      { label: "Dentro do esperado", value: 80 },
      { label: "Acima do esperado", value: 90 },
      { label: "Muito acima do esperado", value: 100 },
    ],
    e_nps: [
      { label: "Muito abaixo do esperado", value: 60 },
      { label: "Abaixo do esperado", value: 70 },
      { label: "Dentro do esperado", value: 80 },
      { label: "Acima do esperado", value: 90 },
      { label: "Muito acima do esperado", value: 100 },
    ],
    gestao_despesas: [
      { label: "Dentro do esperado", value: 100 },
      { label: "Acima do esperado", value: 105 },
      { label: "Muito acima do esperado", value: 120 },
    ],
    gestao_vendas: [
      { label: "Dentro do esperado", value: 100 },
      { label: "Acima do esperado", value: 105 },
      { label: "Muito acima do esperado", value: 120 },
    ],
    gestao_rentabilidade: [
      { label: "Dentro do esperado", value: 100 },
      { label: "Acima do esperado", value: 105 },
      { label: "Muito acima do esperado", value: 120 },
    ],
  },
  company: companysOptions[0].company,
  home: companysOptions[0].home,
  url: companysOptions[0].url,
  href: companysOptions[0].href,
  color: companysOptions[0].color,
  colorType: companysOptions[0].colorType,
  primary: companysOptions[0].primary,
  secondary: companysOptions[0].secondary,
  icon: companysOptions[0].icon,
  image: companysOptions[0].image,
  logoDefault: companysOptions[0].logoDefault,
  logoSmall: companysOptions[0].logoSmall,
  logoMobile: companysOptions[0].logoMobile,
  logos: [],
};
export const loadingSlice = createSlice({
  name: "company",
  initialState: initial,
  reducers: {
    updateCompany: (state, action) => {
      if (action?.payload?.company) {
        state.company = action?.payload?.company ?? initial.company;
      }
      if (action?.payload?.home) {
        state.home = action?.payload?.home ?? initial.home;
      }
      if (action?.payload?.url) {
        state.url = action?.payload?.url ?? initial.url;
      }
      if (action?.payload?.href) {
        state.href = action?.payload?.href ?? initial.href;
      }
      if (action?.payload?.color) {
        state.color = action?.payload?.color ?? initial.color;
      }
      if (action?.payload?.primary) {
        state.primary = action?.payload?.primary ?? initial.primary;
      }
      if (action?.payload?.secondary) {
        state.secondary = action?.payload?.secondary ?? initial.secondary;
      }
      if (action?.payload?.icon) {
        state.icon = action?.payload?.icon ?? initial.icon;
      }
      if (action?.payload?.colorType) {
        state.colorType = action?.payload?.colorType ?? initial.colorType;
      }
      if (action?.payload?.image) {
        state.image = action?.payload?.image ?? initial.image;
      }
      if (action?.payload?.logoDefault) {
        state.logoDefault = action?.payload?.logoDefault ?? initial.logoDefault;
      }
      if (action?.payload?.logoSmall) {
        state.logoSmall = action?.payload?.logoSmall ?? initial.logoSmall;
      }
      if (action?.payload?.logoMobile) {
        state.logoMobile = action?.payload?.logoMobile ?? initial.logoMobile;
      }
      if (action?.payload?.logos) {
        state.logos = action?.payload?.logos ?? initial.logos;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCompany } = loadingSlice.actions;

export default loadingSlice.reducer;
