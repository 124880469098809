/** @format */

import MuiChip from "@mui/material/Chip";
import { alpha, useTheme } from "@mui/material/styles";

const Chip = ({ chipcolor, disabled, sx = {}, variant, ...others }: any) => {
  const theme = useTheme();

  let defaultSX = {
    color: "primary.main",
    bgcolor: "primary.light",
  };

  let outlineSX = {
    color: "primary.main",
    bgcolor: "transparent",
    border: "1px solid",
    borderColor: "primary.main",
  };

  switch (chipcolor) {
    case "secondary":
      variant === "outlined"
        ? (outlineSX = {
            color: "secondary.main",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "secondary.main",
          })
        : (defaultSX = {
            color: "secondary.main",
            bgcolor: "secondary.light",
          });
      break;
    case "success":
      variant === "outlined"
        ? (outlineSX = {
            color: "success.dark",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "success.dark",
          })
        : (defaultSX = {
            color: "success.dark",
            bgcolor: alpha(theme.palette.success.light, 0.6),
          });
      break;
    case "error":
      variant === "outlined"
        ? (outlineSX = {
            color: "error.main",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "error.main",
          })
        : (defaultSX = {
            color: "error.dark",
            bgcolor: alpha(theme.palette.error.light, 0.6),
          });
      break;
    case "orange":
      variant === "outlined"
        ? (outlineSX = {
            color: "orange.dark",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "orange.main",
          })
        : (defaultSX = {
            color: "orange.dark",
            bgcolor: "orange.light",
          });
      break;
    case "warning":
      variant === "outlined"
        ? (outlineSX = {
            color: "warning.dark",
            bgcolor: "transparent",
            border: "1px solid",
            borderColor: "warning.dark",
          })
        : (defaultSX = {
            color: "warning.dark",
            bgcolor: "warning.light",
          });
      break;
    default:
  }

  if (disabled) {
    variant === "outlined"
      ? (outlineSX = {
          color: "grey.500",
          bgcolor: "transparent",
          border: "1px solid",
          borderColor: "grey.500",
        })
      : (defaultSX = {
          color: "grey.500",
          bgcolor: "grey.50",
        });
  }

  let SX = defaultSX;
  if (variant === "outlined") {
    SX = outlineSX;
  }
  SX = { ...SX, ...sx };
  return <MuiChip {...others} sx={SX} />;
};

export default Chip;
