/** @format */

import { Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { isMobile } from "react-device-detect";

function FormatValue(values: any, keys: any) {
  let returnString = "";
  // eslint-disable-next-line array-callback-return
  Object.entries(values).map((item: any) => {
    const keyObj: any = Object.entries(keys).filter(
      (obj: any) => obj[0] === item[0]
    )[0][1];
    const key = keyObj?.label ?? "";
    let value = item[1];
    if (dayjs(value).isValid()) {
      value = dayjs(value).format("DD/MM/YYYY");
    }
    if (keyObj.options) {
      value =
        keyObj.options.filter((item: any) => item.value === value)[0]?.label ??
        "";
    }
    returnString += ` ${key}: ${value};`;
  });
  return returnString;
}

export function Filter({ formik, children, keys }: any) {
  const [active, setActive] = useState(formik.values);

  function onSubmit() {
    setActive(formik.values);
    formik.handleSubmit();
  }

  function onReset() {
    formik.handleReset();
    setActive(formik.initialValues);
  }

  return (
    <Grid container gap={2}>
      <Grid
        gap={2}
        width={"100%"}
        flexDirection={isMobile ? "column" : "row"}
        display={"flex"}>
        <Grid
          width={"100%"}
          display={"grid"}
          gap={2}
          gridTemplateColumns={
            isMobile
              ? "1fr"
              : children.length === 1
              ? "1fr"
              : children.length === 2
              ? "1fr 1fr"
              : children.length === 3
              ? "1fr 1fr 1fr"
              : "1fr 1fr 1fr 1fr"
          }>
          {children}
        </Grid>
        <Grid display={"flex"}>
          <Button variant='contained' fullWidth={isMobile} onClick={onSubmit}>
            Filtrar
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"}>
        <Typography fontSize={12} fontWeight={400}>
          Filtros ativos: <strong>{FormatValue(active, keys)}</strong>
        </Typography>
        <Button
          style={{ marginLeft: 8 }}
          size='small'
          variant='text'
          fullWidth={isMobile}
          onClick={onReset}>
          Resetar filtros
        </Button>
      </Grid>
    </Grid>
  );
}
