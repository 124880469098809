/** @format */

import { InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { Callout } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import { PostInstanteFormData } from "../../../services/api";
import { GetPhoto } from "../../../services/protected/getPhoto";
import { ChangePassword } from "./changePassword";
import { SecretAnswers } from "./secretAnswers";

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

export function SettingsAccount() {
  const [urlAvatar, setUrlAvatar] = useState<string>();
  const [index, setIndex] = useState(0);
  const user = useSelector((state: any) => state.user.data);
  const theme = useTheme();

  const handleImageAvatar = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar", e.target.files[0]);
    if (e.target.files[0]) {
      PostInstanteFormData(null, formData, `/users/${user.uuid}/upload_avatar`)
        .then((res) => {
          setUrlAvatar(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (user.uuid) {
      GetPhoto(user.uuid, setUrlAvatar);
    }
  }, [user.uuid]);

  return (
    <Grid container gap={4}>
      <Grid container>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ cursor: "pointer" }}
            color='inherit'
            href='/'>
            Inicio
          </Link>
          <Typography fontWeight={800}>Configurações da conta</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container>
        <Callout.Root size='2' style={{ alignItems: "center" }}>
          <Callout.Icon>
            <InfoOutlined />
          </Callout.Icon>
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Instruções
            </Typography>
            <Grid container gap={"12px 96px"} mt={1}>
              <Typography fontSize={12} fontWeight={400}>
                Nesta tela é possível realizar a troca de senha seguindo os
                passos abaixo.
              </Typography>
            </Grid>
          </Grid>
        </Callout.Root>
      </Grid>
      <Grid width={isMobile ? "inherit" : "520px"}>
        <Paper sx={{ p: 3 }}>
          <>
            <Grid
              gap={1}
              container
              flexDirection='column'
              alignItems={"center"}>
              <Typography fontSize={18} fontWeight={600} mb={2}>
                Foto de perfil
              </Typography>
              <Box
                display='flex'
                alignItems='center'
                gap={1}
                mb={2}
                flexDirection='row'>
                <Grid
                  item
                  style={{
                    width: "140px",
                    height: "140px",
                    background: `linear-gradient(to bottom left, transparent 0%, ${theme?.palette.primary.main} 150%)`,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}>
                  <Grid
                    item
                    style={{
                      width: "100px",
                      height: "100px",
                      background: `linear-gradient(to top right, transparent 0%, ${theme?.palette.primary.main} 200%)`,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "50%",
                    }}>
                    <Avatar
                      alt={user?.social_name ?? user?.name}
                      src={urlAvatar ?? "/image.png"}
                      sx={{
                        width: 128,
                        height: 128,
                        bgcolor: theme.palette.primary.main,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <input
                style={{ display: "none" }}
                type='file'
                id='input-avatar'
                onChange={handleImageAvatar}
              />
              <label htmlFor='input-avatar'>
                <Button component='span'>Alterar foto</Button>
              </label>
            </Grid>
            <Grid gap={2} container flexDirection='column' mt={2}>
              <Tabs
                value={index}
                allowScrollButtonsMobile
                scrollButtons
                onChange={(event, newValue) => setIndex(newValue)}
                aria-label='basic tabs example'>
                <Tab label='Alteração de senha' value={0} />
                <Tab label='Perguntas secretas' value={1} />
              </Tabs>

              <CustomTabPanel value={index} index={0}>
                <ChangePassword />
              </CustomTabPanel>
              <CustomTabPanel value={index} index={1}>
                <SecretAnswers />
              </CustomTabPanel>
            </Grid>
          </>
        </Paper>
      </Grid>
    </Grid>
  );
}
