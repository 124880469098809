/** @format */

export function HiddenCheck({ user_form_hidden_fields, children }: any) {
  return (
    !user_form_hidden_fields.includes(
      children?.props?.id ||
        children?.props?.slotProps?.textField?.id ||
        (Array.isArray(children) && children[0].props?.id)
    ) && children
  );
}

export function HiddenCheckChildren({
  children,
  user_form_hidden_fields,
}: any) {
  const hiddenAll = children[0].props?.id
    ? [!user_form_hidden_fields.includes(children[0].props?.id)]
    : Array.isArray(children[1]?.props?.children)
    ? children[1].props.children.map((item: any) => {
        return !user_form_hidden_fields.includes(
          item?.props?.id ||
            item?.props?.slotProps?.textField?.id ||
            item?.props?.children?.props?.slotProps?.textField?.id ||
            item?.props?.children?.props?.id
        );
      })
    : [
        !user_form_hidden_fields.includes(
          children[1].props.children?.props?.id ||
            children[1].props.children?.props?.children?.props?.id ||
            children[1].props.children?.props?.children[0]?.props?.id ||
            children[1].props.children?.props?.slotProps?.textField?.id
        ),
      ];

  return hiddenAll.indexOf(true) !== -1 && children;
}
