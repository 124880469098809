import { useNavigate, useRouteError } from 'react-router-dom';
import Lottie from 'react-lottie';
import React from 'react';
import animationData from '../../assets/lotties/people.json';
import { Button, Grid, Typography } from '@mui/material';
import { KeyboardReturnOutlined } from '@mui/icons-material';

export default function ErrorPage() {
	const error: any = useRouteError();
	const router = useNavigate();
	console.error(error);
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};
	
	return (
		<Grid container justifyContent={'center'} height={'100dvh'}>
			<Grid container justifyContent={'center'} flexDirection={'column'} id="error-page" padding={10}
			      maxWidth={600} gap={2}>
				<Lottie
					options={defaultOptions}
					height={'auto'}
					width={'auto'}
				/>
				<Typography textAlign={'center'} mt={-10} fontSize={40} fontWeight={800}>Oops!</Typography>
				<Typography textAlign={'center'} fontSize={18}>Parece que você tentou acessar algo que não existe mais ou está
					sem permissão, tente novamente ou clique abaixo para retornar a aplicação.</Typography>
				<Button endIcon={<KeyboardReturnOutlined/>} color={'primary'} style={{marginTop:16}} variant={'contained'} onClick={() => router('/')}>Retornar a
					aplicação</Button>
				<Typography textAlign={'center'} paddingTop={4} fontSize={12}>Descrição da
					falha: {error.status} - {error.statusText || error.message}</Typography>
			
			</Grid>
		</Grid>
	);
}