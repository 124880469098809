import { Grid, Tooltip, Button } from "@mui/material";
import { customList } from "./customList";
import { useState } from "react";

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function ConfigSelection({
  leftLabel,
  rightLabel,
  left,
  right,
  options,
  setLeft,
  setRight,
}: any) {

  const [checked, setChecked] = useState<any>([]);

  const leftCheckedApprovers = intersection(checked, left);
  const rightCheckedApprovers = intersection(checked, right);

  return (
    <Grid
      gridTemplateColumns={"5fr 1fr 5fr"}
      display={"grid"}
      gap={2}
      alignItems='center'>
      <Grid item>
        {customList(
          left,
          leftLabel,
          "amber",
          checked,
          setChecked,
          options
        )}
      </Grid>
      <Grid item>
        <Tooltip
          title={
            rightCheckedApprovers.length === 0 &&
              leftCheckedApprovers.length === 0
              ? "Selecione ao menos 1 item"
              : null
          }>
          <Grid container direction='column' alignItems='center'>
            <Button
              sx={{ my: 0.5, width: 150 }}
              variant='contained'
              size='small'
              onClick={() => {
                setRight(right.concat(left));
                setLeft([]);
              }}
              aria-label='move selected right'>
              adicionar todos &gt;
            </Button>
            <Button
              sx={{ my: 0.5, width: 150 }}
              variant='contained'
              size='small'
              onClick={() => {
                setRight(
                  right.concat(leftCheckedApprovers)
                );
                setLeft(not(left, leftCheckedApprovers));
                setChecked(
                  not(checked, leftCheckedApprovers)
                );
              }}
              disabled={leftCheckedApprovers.length === 0}
              aria-label='move selected right'>
              ADICIONAR &gt;
            </Button>
            <Button
              sx={{ my: 0.5, width: 150 }}
              variant='contained'
              size='small'
              onClick={() => {
                setLeft(
                  left.concat(rightCheckedApprovers)
                );
                setRight(
                  not(right, rightCheckedApprovers)
                );
                setChecked(
                  not(checked, rightCheckedApprovers)
                );
              }}
              disabled={rightCheckedApprovers.length === 0}
              aria-label='move selected left'>
              &lt; REMOVER
            </Button>
            <Button
              sx={{ my: 0.5, width: 150 }}
              variant='contained'
              size='small'
              onClick={() => {
                setLeft(left.concat(right));
                setRight([]);
              }}
              aria-label='move selected right'>
              &lt; remover todos
            </Button>
          </Grid>
        </Tooltip>
      </Grid>
      <Grid item>
        {customList(
          right,
          rightLabel,
          "grass",
          checked,
          setChecked,
          options
        )}
      </Grid>
    </Grid>
  );
}