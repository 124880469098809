/** @format */

import { request } from "../api";

export async function GetPhoto(id: string, setPhoto: any) {
  try {
    const { data } = await request().get(`/users/${id}/avatar`);
    if (data !== undefined) {
      setPhoto(data.data.avatar);
    }
  } catch (e) {
    console.info("Not found photo");
  }
}
