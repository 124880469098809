/** @format */

import { Backdrop, CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import "dayjs/locale/pt-br";
import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom/client";
import Lottie from "react-lottie";
import { Provider, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import animationData from "./assets/lotties/loading.json";
import { router } from "./config/router";
import { ThemeMui } from "./config/theme";
import "./index.css";
import store from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function Loading() {
  const loading = useSelector((state: any) => state.common.loading);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}>
      <Lottie options={defaultOptions} height={100} width={"auto"} />
    </Backdrop>
  );
}

function App() {
  const mode: any = Cookies.get("theme") ?? "light";
  const company = useSelector((state: any) => state?.company);

  return (
    <ThemeProvider theme={ThemeMui()}>
      <Theme appearance={mode} accentColor={company.colorType}>
        <CssBaseline />
        <Loading />
        <RouterProvider router={router} />
      </Theme>
    </ThemeProvider>
  );
}

root.render(
  <React.StrictMode>
    <ToastContainer
      position={"top-right"}
      autoClose={5000}
      closeOnClick
      pauseOnFocusLoss
      pauseOnHover
      theme={"colored"}
    />
    <Provider store={store}>
      <LocalizationProvider adapterLocale={"pt-br"} dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);
