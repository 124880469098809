/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { GetInstance } from '../../../services/api';
import { updateLoading } from '../../../redux/reducers/common';
import { toast } from 'react-toastify';

export function UserGetAll(dispatch: any) {
	const [data, setData] = useState([]);
	useEffect(() => {
		dispatch(updateLoading(true));
		GetInstance({}, 'users/options').then((response) =>
			setData(response.data.data)).catch((e: any) => {
			if (e.code !== 'ERR_CANCELED') {
				dispatch(updateLoading(false));
				toast.error(e?.response?.data?.message ?? 'Erro inesperado, tente novamente.');
			}
		});
	}, []);
	return { users: data };
}