/** @format */

import { Edit, InfoOutlined, Undo } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

const validationSchema = yup.object().shape({
  promotions_min_percent: yup
    .number()
    .required("Campo obrigatório")
    .min(0, "Minimo de 0%")
    .max(99, "Máximo de 99%"),
  promotions_max_percent: yup
    .number()
    .required("Campo obrigatório")
    .min(0, "Minimo de 1%")
    .max(99, "Máximo de 100%"),
});

export function PermissionsPromote() {
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      audit_retain_days: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          promotions_min_percent: values.promotions_min_percent,
          promotions_max_percent: values.promotions_max_percent,
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        formik.setValues({
          promotions_max_percent:
            response.data.data.record.promotions_max_percent,
          promotions_min_percent:
            response.data.data.record.promotions_min_percent,
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/promote'>
              Configurações
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/settings/promote'>
              Promoção
            </Link>
            <Typography fontWeight={800}>Ver detalhes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={400}>
                Nesta tela é permitido a configuração de minimo e máximo do
                percentual de reajuste salarial.
              </Typography>
            </Grid>
          </Callout.Root>
        </Grid>
        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Configurações
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            display={"grid"}
            m={2}
            gap={2}>
            <TextField
              fullWidth
              id={`promotions_min_percent`}
              type='number'
              label='Minimo de reajuste(%)'
              variant='outlined'
              value={formik.values.promotions_min_percent}
              onChange={(event: any) => {
                formik.setFieldValue(
                  `promotions_min_percent`,
                  event.target.value
                );
              }}
              error={Boolean(formik.errors.promotions_min_percent)}
              helperText={formik.errors.promotions_min_percent}
            />
            <TextField
              fullWidth
              id={`promotions_max_percent`}
              type='number'
              label='Máximo de reajuste (%)'
              variant='outlined'
              value={formik.values.promotions_max_percent}
              onChange={(event: any) => {
                formik.setFieldValue(
                  `promotions_max_percent`,
                  event.target.value
                );
              }}
              error={Boolean(formik.errors.promotions_max_percent)}
              helperText={formik.errors.promotions_max_percent}
            />
          </Grid>
        </Paper>
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <Button
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </Button>
          <Button
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Edit />}>
            Salvar modificações
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
