/** @format */

import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { companysOptions } from "../../../config/companies";
import { ModeTheme } from "../../../config/mode";
import { updateCompany } from "../../../redux/reducers/company";

export function CompanyOptionsPage() {
  const company = useSelector((state: any) => state.company);
  const dispatch = useDispatch();
  const router = useNavigate();
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      height={"100dvh"}
      padding={10}>
      <Grid style={{ position: "absolute", top: 10, right: 10 }}>
        <ModeTheme />
      </Grid>
      <Grid
        container
        alignItems={"center"}
        maxWidth={600}
        flexDirection={"column"}
        gap={4}>
        <img {...company.logoDefault} alt='Logo da empresa' />
        <Typography fontSize={18}>
          Bem vindo as configurações do sistema, por favor selecione abaixo a
          empresa que deseja visualizar e clique para retornar ao sistema.
        </Typography>
        <TextField
          fullWidth
          id='outlined-select-company'
          select
          label='Selecione a empresa'
          defaultValue={company.company}
          onChange={(event) => {
            const companySelect = companysOptions.filter(
              (item: any) => item.company === event.target.value
            )[0];
            Cookies.set("company", event.target.value);
            dispatch(updateCompany(companySelect));
          }}
          helperText='Selecione a empresa que deseja utilizar no sistema'>
          {companysOptions.map((option) => (
            <MenuItem key={option.company} value={option.company}>
              {option.company}
            </MenuItem>
          ))}
        </TextField>
        <Button
          fullWidth
          onClick={() => {
            router("/");
          }}
          variant={"contained"}>
          Entrar no sistema
        </Button>
      </Grid>
    </Grid>
  );
}
